import { createAction, props } from '@ngrx/store'
import { SmartLookError } from '@core/analytics/models/smartlook.models'
import { McpProfile } from '@auth/models/auth.dto'

export const trackUserToSmartLook = createAction(
  '[SmartLook] Track user',
  props<{ profile: McpProfile }>(),
)
export const trackErrorToSmartLook = createAction(
  '[SmartLook] Track error',
  props<{ error: SmartLookError }>(),
)
