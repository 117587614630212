<app-dialog
  class="mcp-position-selector-dialog"
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  [styleClass]="extraClasses"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header c-position-selector-dialog__header-title">
    <span>{{ 'MTR_TITLE_PLAYER_VS_POSITION' | translate }}</span>
  </div>

  <div class="c-layout__block c-player-selection__body">
    <mc-list
      [data]="positions"
      (onClick)="positionSelect.emit($event)"
      [itemTemplate]="simpleItem"
    ></mc-list>
  </div>
</app-dialog>

<ng-template #simpleItem let-item>
  <mc-title
    [text]="item.text"
    [alignment]="TitleAlignment.Center"
    [size]="TitleSize.SM"
    [weight]="TitleWeight.Bold"
  ></mc-title>
</ng-template>
