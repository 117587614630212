<div class="mcp-player-list-item {{ extraClasses }}" [attributes]="data?.attributes">
  <img
    class="mcp-player-list-item__avatar"
    [appDefaultImage]="playerPlaceholder"
    [srcUrl]="data?.imgUrl"
    [alt]="data?.name"
  />
  <div class="mcp-player-list-item__block mcp-player-list-item__name-and-position">
    <span class="mcp-player-list-item__name"
      >{{ !!data?.shirtNumber || data?.shirtNumber === 0 ? data.shirtNumber + '. ' : '' }}
      {{ data?.name || '-' | uppercase }}</span
    >
    <span class="mcp-player-list-item__position">{{
      (data | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ') || '-'
    }}</span>
  </div>
  <div class="mcp-player-list-item__block mcp-player-list-item__block--row">
    <img
      class="mcp-player-list-item__team-shield"
      *ngIf="data?.teamShield"
      [appDefaultImage]="teamPlaceholder"
      [srcUrl]="data?.teamShield"
      [alt]="data?.teamName ? data.teamName : ''"
    />
    <mcui-icon
      *ngIf="data?.navigateUrl || isNavigationActive"
      svgIcon="arrow-right-rounded"
      class="mcp-player-list-item__icon mcp-player-list-item__icon--action mc-translate-gradient-animation"
    ></mcui-icon>
  </div>
</div>
