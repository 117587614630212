import { Pipe, PipeTransform } from '@angular/core'
import { Match } from '@core/models/dto/match.dto'
import { ScoreRelativeTo } from '@mediacoach/ui'
import { MatchState } from '@mediacoach-ui-library/global'
import { ApiTeam } from '@core/models/models/team.models'

@Pipe({
  name: 'scoreRelativeToResolver',
})
export class ScoreRelativeToResolverPipe implements PipeTransform {
  transform(team: ApiTeam | undefined, match: Match | undefined): ScoreRelativeTo {
    if (team && match) {
      if (
        [MatchState.Delayed, MatchState.Postponed, MatchState.Abandoned].includes(
          match?.state?.name as MatchState,
        )
      ) {
        return undefined
      }

      if (team.id === match.homeTeam.teamId) {
        return 'left'
      } else if (team.id === match.awayTeam.teamId) {
        return 'right'
      } else {
        return undefined
      }
    }

    return undefined
  }
}
