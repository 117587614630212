import { Pipe, PipeTransform } from '@angular/core'
import { ComparablePlayer } from '@core/models/dto/player.dto'

@Pipe({
  name: 'excludePlayer',
})
export class ExcludePlayerPipe implements PipeTransform {
  transform(playerList: ComparablePlayer[], playerId: string): ComparablePlayer[] {
    if (playerList && playerId) {
      return playerList.filter((p) => p.id !== playerId)
    }
    return playerList
  }
}
