<ng-container *ngIf="match?.schedule">
  <span class="c-match-item__date">
    {{
      match.schedule?.toBeConfirmed
        ? ('MATCHES_CONTENT_LAB_TOBEDEFINED' | translate)
        : (match?.schedule?.translated?.date | localizedDate: 'EEEE')
    }}
  </span>
  <span>
    {{
      match?.schedule?.translated?.date
        | localizedDate: (match?.schedule?.toBeConfirmed ? 'dd/MM/y ' : 'dd/MM/y - HH:mm')
    }}
  </span>
</ng-container>
