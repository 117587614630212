export enum ReportType {
  Teams = 'teams',
  Players = 'players',
  Goalkeepers = 'goalkeepers',
  StyleEfficiency = 'styleEfficiency',
  PlayerStats = 'playerStats',
  AccumulatedTeams = 'accumulatedTeams',
  PhysicalPerformance = 'physicalPerformance',
  PhysicalIntervals = 'physicalIntervals',
  Referees = 'referees',
  GameInterruptions = 'gameInterruptions',
  CompEvolutionEffectiveTime = 'compEvolutionEffectiveTime',
  PlayersMLPhysical = 'playersMLPhysical',
  TeamsMl = 'teamsMl',
  PlayersMl = 'playersMl',
  WCSMl = 'wcsMl',
  Finishing = 'finishing',
  PlayModels = 'playModels',
}
