<div class="c-input-container" [class.c-input-container--error]="hasError">
  <label *ngIf="label" class="c-input-container__label">{{ label }}</label>
  <div class="c-input-container__body">
    <ng-content select="[input-template=input]"></ng-content>
    <span class="c-input-container__bar"></span>
  </div>
  <div class="c-input-container__error">
    <ng-content select="[input-template=error]"></ng-content>
  </div>
</div>
