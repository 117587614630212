import { Widget, WidgetColumn, WidgetRow } from '../models/widget.models'
import {
  compareWithDirection,
  deepClone,
  exists,
  MenuItem,
  SortDirection,
  SortEvent,
} from '@mediacoach/ui'
import { markAsActive } from '@widgets/utils/menu-item.utils'
import * as _ from 'lodash'
import { WidgetIdentifier } from '@widgets/enums/widget-identifier.enum'

export const isWidgetOfType = (widget: Widget, types: WidgetIdentifier[]): boolean =>
  types.includes(widget.id as WidgetIdentifier)

export const handleWidgetMenuItemHighlight = (item: Partial<MenuItem>, widget: Widget): Widget => {
  if (item.highlightable) {
    widget.menuItems = markAsActive(widget.menuItems, item.id)
  }

  return widget
}

const isSortableDirection = (direction: SortDirection): boolean =>
  exists(direction) && direction !== 'def'

export const shouldSortWidgetData = (widget: Widget): boolean =>
  !!(
    widget?.displayMode?.sorting?.columnKey &&
    isSortableDirection(widget?.displayMode?.sorting?.sortColumnDirection)
  )

export const updateColumnSorting = (widget: Widget, columns: WidgetColumn[]): WidgetColumn[] => {
  return shouldSortWidgetData(widget)
    ? columns.map((column) => ({
        ...column,
        sortDirection:
          widget.displayMode?.sorting?.columnKey === column.key
            ? widget.displayMode?.sorting?.sortColumnDirection
            : undefined,
      }))
    : columns
}

export const updateRowSorting = (widget: Widget, rows: WidgetRow<any>): WidgetRow<any> => {
  if (
    widget?.displayMode?.sorting?.rowKey &&
    isSortableDirection(widget?.displayMode?.sorting?.sortRowDirection)
  ) {
    return rows.map((row) => ({
      ...row,
      sortDirection:
        widget.displayMode?.sorting?.rowKey === row.metricName
          ? widget?.displayMode?.sorting?.sortRowDirection
          : undefined,
    }))
  }
  return rows
}

export const sortWidgetTableDataBy = (widget: Widget, widgetData: any = []): any => {
  const rows = deepClone(widgetData)

  return rows.sort((a, b) => {
    const valA = _.get(a, widget.displayMode.sorting.columnKey)
    const valB = _.get(b, widget.displayMode.sorting.columnKey)
    return compareWithDirection(valA, valB, widget.displayMode?.sorting?.sortColumnDirection)
  })
}

const builtInSort = (
  a: any,
  b: any,
  sortedColumn: WidgetColumn,
  sortableKey: string,
  defaultKey: string,
  safeUnSortableValue: number,
): number => {
  const valA =
    a.skipSort && sortedColumn?.skipSortAware
      ? safeUnSortableValue
      : _.get(a, sortableKey, a[defaultKey] ?? 0)
  const valB =
    b.skipSort && sortedColumn?.skipSortAware
      ? safeUnSortableValue
      : _.get(b, sortableKey, b[defaultKey] ?? 0)
  const shirtNumberA = _.get(a, 'shirtNumber', 0)
  const shirtNumberB = _.get(b, 'shirtNumber', 0)

  return compareWithDirection(valA, valB, sortedColumn.sortDirection) || shirtNumberA - shirtNumberB
}

export const sortRows = (
  widget: Widget,
  tableRows: WidgetRow<any>[],
  tableColumns: WidgetColumn[],
): WidgetRow<any>[] => {
  const sortedColumn = tableColumns?.find((c) => exists(c.sortDirection))
  const key = sortedColumn?.sortableKey || sortedColumn?.key || sortedColumn?.label
  const rows = deepClone(tableRows || [])
  const shouldSort = ['asc', 'desc'].includes(sortedColumn?.sortDirection)

  if (sortedColumn?.sortable && key && shouldSort) {
    const _sortableKey = `${key}.${widget.displayMode.aggregation}`
    const _safeUnSortableValue = Number.MAX_VALUE * (sortedColumn.sortDirection === 'asc' ? -1 : 1)

    return rows.sort((a, b) =>
      sortedColumn.sortFn
        ? sortedColumn.sortFn(a, b, sortedColumn)
        : builtInSort(a, b, sortedColumn, _sortableKey, key, _safeUnSortableValue),
    )
  }
  return rows
}

export const updateSorting = (sort: SortEvent, widget: Widget) => {
  return {
    columnKey: sort.column?.key || widget.displayMode?.sorting?.columnKey,
    sortColumnDirection:
      sort.column?.sortDirection || widget.displayMode?.sorting?.sortColumnDirection,
    rowKey: sort.row?.metricName || widget.displayMode?.sorting?.rowKey,
    sortRowDirection: sort?.row?.sortDirection || widget.displayMode?.sorting?.sortRowDirection,
  }
}
