import { PlaylistContextMenuAction } from '@core/enums/playlist-context-menu-action.enum'

export const PLAYLIST_ITEM_INFO_MENU_OPTIONS = (isHighlighted: boolean) => [
  {
    id: PlaylistContextMenuAction.editComment,
    label: 'MTR_PLAYLIST_OPTIONS_EDIT_COMMENT',
  },
  {
    id: PlaylistContextMenuAction.shareClip,
    label: 'MTR_PLAYLIST_OPTIONS_SHARE_CLIP',
  },
  {
    id: PlaylistContextMenuAction.markAsFavorite,
    label: isHighlighted
      ? 'MTR_PLAYLIST_OPTIONS_UNMARK_AS_FAVORITE'
      : 'MTR_PLAYLIST_OPTIONS_MARK_AS_FAVORITE',
    icon: `tag-star${isHighlighted ? '-selected' : ''}`,
  },
  {
    id: PlaylistContextMenuAction.deleteClipFromPlaylist,
    label: 'MTR_PLAYLIST_OPTIONS_DELETE_CLIP_FROM_PLAYLIST',
  },
]
