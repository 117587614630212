import { createSelector, DefaultProjectorFn, MemoizedSelector, select } from '@ngrx/store'
import {
  getMatch,
  isPlayListTabSelected,
} from '@core/state/selectors/stream-match.merged-selectors'
import { hasFiltersActive } from '@features/playlist/utils/playlist.utils'
import { PlayListConfig } from '@features/playlist/models/playlist.models'
import { StreamState } from '@core/state/models/stream.state'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { selectUserId } from '@core/state/selectors/user.selectors'

export const selectPlaylist = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(feature, (state) => state?.playlist)

export const selectTags = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.tags)

export const selectPlaylistsResponse = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.playlists)

export const selectPlaylists = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylistsResponse(feature), (state) => state?.results)

export const selectNotPrivatePlaylists = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylists(feature), (playlists) => playlists?.filter((p) => !p.isDefault))

const selectRawSelectedPlaylist = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.selectedPlaylist)

export const selectCurrentSelectedPlaylist = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawSelectedPlaylist(feature),
    getMatch(feature),
    (selectedPlaylist, currentMatch) =>
      selectedPlaylist && currentMatch && selectedPlaylist[currentMatch.id],
  )

export const selectSelectedTagItem = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.selectedTagItem)

export const selectPlaylistLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.playlistLoader)

export const selectPlaylistFilterLoader = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.playlistFilterLoader)

export const selectActiveFilters = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.filterActive)

export const selectRawSortFilter = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.sortFilter)

export const selectParsedTagItems = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.parsedTagItems)

export const selectIsDeletingTagItem = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.isDeletingTagItem)

export const selectIsChangingPlaylist = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.isChangingPlaylist)

export const selectFilterConfig = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.filterConfig)

export const selectCurrentSortFilter = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectRawSortFilter(feature),
    getMatch(feature),
    (sortFilter, currentMatch) => sortFilter && currentMatch && sortFilter[currentMatch.id],
  )

export const selectCurrentMatchFilterActive = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectActiveFilters(feature),
    getMatch(feature),
    (filterActive, currentMatch) => filterActive && currentMatch && filterActive[currentMatch.id],
  )

export const selectHasFiltersActive = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    selectActiveFilters(feature),
    getMatch(feature),
    (filterActive, currentMatch) =>
      filterActive && currentMatch && hasFiltersActive(filterActive[currentMatch.id]),
  )

export const selectParsedTagItemsWithConfig = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(
        selectParsedTagItems(feature),
        isPlayListTabSelected(feature),
        (parsedTagItems, isPListTabSelected) => ({
          parsedTagItems,
          isPListTabSelected,
        }),
      ),
    ),
    filter(({ isPListTabSelected }) => !!isPListTabSelected),
    map(({ parsedTagItems }) => parsedTagItems),
  )

export const selectPlaylistConfig = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  createSelector(
    isPlayListTabSelected(feature),
    selectTags(feature),
    selectHasFiltersActive(feature),
    selectSelectedTagItem(feature),
    (playListTabSelected, tags, hasActiveFilters, selectedTagItem) =>
      ({
        hideVideoSource: playListTabSelected && !tags?.pager?.count,
        notHasTags: !hasActiveFilters && !tags?.pager?.count,
        playListTabSelected,
        selectedTagItem,
      }) as PlayListConfig,
  )

export const selectTagItemToPlay = (
  feature: MemoizedSelector<object, StreamState, DefaultProjectorFn<StreamState>>,
) =>
  pipe(
    select(
      createSelector(
        selectIsDeletingTagItem(feature),
        isPlayListTabSelected(feature),
        selectSelectedTagItem(feature),
        selectIsChangingPlaylist(feature),
        (isDeletingTagItem, isPlaylistSelected, selectedTagItem, isChangingPlaylist) => ({
          isDeletingTagItem,
          isPlaylistSelected,
          selectedTagItem,
          isChangingPlaylist,
        }),
      ),
    ),
    filter(({ isPlaylistSelected }) => !!isPlaylistSelected),
  )

export const selectPlaylistFilteredCollaborators = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) =>
  createSelector(selectPlaylist(feature), selectUserId, (state, userId) =>
    (state?.collaborators || []).filter(({ id }) => id !== userId),
  )

export const selectPlaylistCollaborators = <T extends StreamState>(
  feature: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
) => createSelector(selectPlaylist(feature), (state) => state?.collaborators)
