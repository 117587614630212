import { SocketState } from '@core/state/models/socket.state'
import { HubConnectionState } from '@microsoft/signalr'
import { Action, createReducer, on } from '@ngrx/store'
import { setSocketId, setSocketStatus, setTopic } from '@core/state/actions/socket.actions'
import { Topic } from '@sockets/enums/socket.enum'

const initialState: SocketState = {
  topics: {
    [Topic.IsLive]: false,
    [Topic.LiveMatches]: [],
  },
  connectionId: undefined,
  status: HubConnectionState.Disconnected,
}

const reducer = createReducer(
  initialState,
  on(setTopic, (state, { topic }) => ({ ...state, topics: { ...state.topics, ...topic } })),
  on(setSocketId, (state, { id }) => ({ ...state, connectionId: id })),
  on(setSocketStatus, (state, { status }) => ({ ...state, status })),
)

export function socketReducer(state: SocketState | undefined, action: Action) {
  return reducer(state, action)
}
