import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core'
import { TourStep } from '@shared/modules/tour/models/tour-step.models'
import { TourStepComponent } from '../../components/tour-step/tour-step.component'

@Injectable({ providedIn: 'root' })
export class StepDrawerService {
  private _refMap: { [key: string]: ComponentRef<TourStepComponent> } = {}

  constructor(
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _appRef: ApplicationRef,
    private readonly _injector: Injector,
  ) {}

  attach(step: TourStep) {
    // 1. Create a component reference from the component
    const ref: ComponentRef<TourStepComponent> = this._componentFactoryResolver
      .resolveComponentFactory(TourStepComponent)
      .create(this._injector)

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this._appRef.attachView(ref.hostView)

    // 3. Get DOM element from component
    const domElem = (ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement

    // 4. Append DOM element to the body
    document.body.appendChild(domElem)

    const instance: TourStepComponent = ref.instance
    instance.step = step
    ref.changeDetectorRef.detectChanges()
    step.stepInstance = instance

    this._refMap[step.name] = ref
  }

  detach(step: TourStep) {
    this._appRef.detachView(this._refMap[step.name].hostView)
    this._refMap[step.name].destroy()
  }
}
