export function isElement(obj: any): boolean {
  return typeof HTMLElement === 'object'
    ? obj instanceof HTMLElement
    : obj &&
        typeof obj === 'object' &&
        true &&
        obj.nodeType === 1 &&
        typeof obj.nodeName === 'string'
}

export function getHTMLElement(ref): HTMLElement | 'body' {
  if (isElement(ref) || ref === 'body') {
    return ref
  } else if (ref && ref.nativeElement) {
    return ref.nativeElement
  } else if (ref && ref.viewContainer && ref.viewContainer.element) {
    return ref.viewContainer.element.nativeElement
  }
}
