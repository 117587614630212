import { Inject, Injectable } from '@angular/core'

import { LOCAL_STORAGE } from '@core/injection-tokens/local-storage.token'
import { SESSION_STORAGE } from '@core/injection-tokens/session-storage.token'

const STRING_EXP = /^"(.*)"$/

export enum StorageType {
  Local,
  Session,
}

@Injectable()
export class StorageService {
  storageByType = {
    [StorageType.Local]: this._localStorage,
    [StorageType.Session]: this._sessionStorage,
  }

  constructor(
    @Inject(LOCAL_STORAGE) private _localStorage: Storage,
    @Inject(SESSION_STORAGE) private _sessionStorage: Storage,
  ) {}

  private getValue = (value, isRaw = false) => {
    let result = value === 'undefined' ? undefined : value === 'null' ? null : value
    try {
      result = JSON.parse(isRaw ? value : decodeURIComponent(value))
    } catch (error) {
      console.error('[StorageService] error parsing data', error, value)
    }
    return result
  }

  private setValue = (value = 'undefined', isRaw = false) => {
    value = JSON.stringify(value)
    if (value.match(STRING_EXP)) {
      value = value.replace(STRING_EXP, '$1')
    }
    return isRaw ? value : encodeURIComponent(value)
  }

  get(storage: StorageType, key) {
    return this.getValue(this.storageByType[storage].getItem(key))
  }

  set(storage: StorageType, key, value) {
    if (!key) {
      return
    }
    this.storageByType[storage].setItem(key, this.setValue(value))
  }

  delete(storage: StorageType, key) {
    this.storageByType[storage].removeItem(key)
  }

  getRaw(storage: StorageType, key) {
    return this.getValue(this.storageByType[storage].getItem(key), true)
  }

  setRaw(storage: StorageType, key, value) {
    if (!key) {
      return
    }
    this.storageByType[storage].setItem(key, this.setValue(value, true))
  }
}
