<app-page [gtmCategory]="'applications_portal'">
  <ng-container *ngIf="(userProfile$ | async) || userProfileDefault as userProfile">
    <mcui-application-menu
      [pageMode]="true"
      [config]="appMenuConfig"
      [applicationUserProfileTypes]="applications$ | async"
      (deepLinkChange)="appDialog.openModal(applicationDialogType.Download, $event, userProfile)"
    >
    </mcui-application-menu>
    <app-dialog-application #appDialog></app-dialog-application>
  </ng-container>
</app-page>
