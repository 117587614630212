<div class="mcp-playlists-share__header grid grid-header">
  <span>{{ 'MTR_PLAYLIST_DIALOG_PLAYLISTS_NAME' | translate }}</span>
  <span>{{ 'MTR_PLAYLIST_DIALOG_PLAYLIST_SHARE' | translate }}</span>
  <mcui-checkbox
    size="small"
    [checked]="allSelected"
    [labelColor]="defaultAvatarColor"
    [reversed]="true"
    [label]="'MONITORING_REPORT_FILTERS_OPTIONS_ALL' | translate"
    (checkedChange)="onCheckChange()"
  />
</div>
<div class="mcp-playlists-share__list" cdkListbox>
  @for (playlist of playlists; track playlist.id) {
    <div
      class="mcp-playlists-share__playlist-item grid"
      [class.last]="$last"
      [cdkOption]="playlist"
      (click)="selection.toggle(playlist)"
    >
      <span class="mcp-playlists-share__playlist-item-name">{{ playlist.name }}</span>
      <mcp-collaborators-avatar-list [collaborators]="playlist.collaborators" />
      <div class="mcp-playlists-share__playlist-item-check">
        <mcui-icon *ngIf="selection.isSelected(playlist)" svgIcon="check" />
      </div>
    </div>
    @if (!$last) {
      <div class="mcp-playlists-share__playlist-item-divider"></div>
    }
  }
</div>
