import videojs from 'video.js'

const clickableComponent = videojs.getComponent('ClickableComponent')

// Define the QuickTagButton class
export class QuickTagButton extends clickableComponent {
  constructor(player, options) {
    super(player, options)

    // Add a custom class for styling
    this.addClass('mcp-quick-tag-button__container')

    // Create the button icon
    const icon = videojs.dom.createEl('div', {
      className: 'mcp-quick-tag-button__icon icon-lightning',
    })
    this.el_.appendChild(icon)

    // Placeholder for the click callback
    this.callback = null

    // Attach click/tap event
    this.on(['click', 'tap'], () => {
      // Invoke the callback if available
      if (this.callback) {
        this.callback({ time: player.currentTime(), isFullscreen: player.isFullscreen() })
      }
    })
  }

  /**
   * Set or update the click callback.
   * @param {Function} callback - The callback to execute on click.
   */
  setCallback(callback) {
    this.callback = callback
  }
}

videojs.registerComponent('QuickTagButton', QuickTagButton)
export default QuickTagButton
