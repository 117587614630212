export const OVERLAY_RULES_MAP = new Map<string, string>([
  ['position', 'absolute'],
  ['top', '0'],
  ['bottom', '0'],
  ['left', '0'],
  ['right', '0'],
  ['background', 'rgba(0,0,0,0.37)'],
  ['z-index', '2'],
  ['opacity', '0'],
])
export const OVERLAY_CLASSNAME = 'c-dialog__overlay'

export const PARENT_REF_RULES_MAP = new Map<string, string>([
  ['position', 'relative'],
  ['display', 'block'],
  ['height', '100%'],
])

export const RULES_TO_BACKUP = ['height', 'overflowY']

export const DIALOG_TIMING_FN = '250ms cubic-bezier(0, 0, 0.2, 1)'
export const OVERLAY_TIMING_FN = '200ms ease-in'
