import { inject, Injectable } from '@angular/core'
import { deepEqual, DialogService } from '@mediacoach/ui'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { Observable, OperatorFunction, throwError } from 'rxjs'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { catchError, debounceTime, filter, map, share, switchMap, tap } from 'rxjs/operators'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { BaseEffectsHandler } from '@core/models/base-classes/base-effects.handler'
import {
  applyFilter,
  emptyAction,
  fetchCodes,
  fetchDimensions,
  fetchFilterData,
  fetchTags,
  openDialogPlaylistFilter,
  setCodes,
  setDimensions,
  setFilterActive,
  setFilterConfigByType,
  setPlaylistFilterLoader,
} from '@core/state/actions/stream-playlist.merged-actions'
import { concatLatestFrom } from '@ngrx/operators'
import {
  selectCurrentMatchFilterActive,
  selectCurrentSelectedPlaylist,
  selectFilterConfig,
} from '@core/state/selectors/stream-playlist.merged-selectors'
import { selectSimplifiedLang } from '@core/state/selectors/user.selectors'
import { getTeamPlayers, translateTagName } from '@features/playlist/utils/playlist.utils'
import { FilterItem, TagCode } from '@features/playlist/models/playlist.models'
import { getMatch } from '@core/state/selectors/stream-match.merged-selectors'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { DEFAULT_ALL_FILTERS } from '@features/playlist/constants/playlist.constants'
import { DialogPlaylistFilterComponent } from '@features/playlist/dialogs/dialog-playlist-filter/components/dialog-playlist-filter/dialog-playlist-filter.component'
import { filterByList } from '@core/utils/collection.utils'
import { MatchTeamData } from '@core/models/dto/match.dto'
import { McpPlayer } from '@core/models/dto/player.dto'
import * as _ from 'lodash'

@Injectable({ providedIn: 'root' })
export class PlaylistFilterEffectsHandler extends BaseEffectsHandler {
  protected readonly _store = inject(Store)
  protected readonly _dialog = inject(DialogService)
  protected readonly _api = inject(PlaylistApi)

  fetchFilterData(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(selectCurrentSelectedPlaylist(_selector))),
        switchMap(([{ matchId }, selectedPlaylist]) => [
          fetchCodes(_identifier)({
            matchId,
            playlistId: selectedPlaylist?.id,
          }),
          fetchDimensions(_identifier)({
            matchId,
            playlistId: selectedPlaylist?.id,
          }),
        ]),
      )
  }

  prepareDialogPlaylistFilter(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => [
          this._store.select(getMatch(_selector)),
          this._store.select(selectCurrentMatchFilterActive(_selector)),
        ]),
        switchMap(([{ displayMode }, match, filterActive]) => [
          fetchFilterData(_identifier)({ matchId: match?.id }),
          !filterActive
            ? setFilterActive(_identifier)({
                filterActive: {
                  [FilterType.All]: [DEFAULT_ALL_FILTERS[0]],
                },
                matchId: match?.id,
              })
            : emptyAction(_identifier)(),
          openDialogPlaylistFilter(_identifier)({ displayMode }),
        ]),
      )
  }

  openDialogPlaylistFilter(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ displayMode }) =>
          this._translate
            .get('MTR_PLAYLIST_FILTERS_MODAL_TITLE')
            .pipe(map((header) => ({ header, displayMode }))),
        ),
        switchMap(({ header, displayMode }) =>
          this._dialog
            .open(DialogPlaylistFilterComponent, {
              header,
              styleClass: `mcp-playlist-dialog mcp-dialog-playlist-filter ${
                displayMode === 'modal' ? 'mcp-playlist-dialog--dark m-app--dark' : ''
              }`,
              data: {
                selector: _selector,
              },
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((filterActive) => applyFilter(_identifier)({ filterActive })),
      )
  }

  applyFilter(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(_selector))),
        map(([{ filterActive }, { id: matchId }]) => ({
          filterActive,
          matchId,
        })),
        switchMap(({ filterActive, matchId }) => [
          setFilterActive(_identifier)({ matchId, filterActive }),
          fetchTags(_identifier)({ matchId }),
        ]),
        share(),
      )
  }

  resetFilterActive(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(_selector))),
        map(([, match]) => ({ matchId: match?.id })),
        map(({ matchId }) =>
          setFilterActive(_identifier)({
            filterActive: { [FilterType.All]: [DEFAULT_ALL_FILTERS[0]] },
            matchId,
          }),
        ),
        share(),
      )
  }

  fetchDimensions(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        tap(() => this._store.dispatch(setPlaylistFilterLoader(_identifier)(true))),
        switchMap(({ matchId, playlistId }) =>
          this._api.getPlaylistDimensions(playlistId, matchId).pipe(
            map((dimensions) => setDimensions(_identifier)({ dimensions })),
            catchError((err) => {
              this._store.dispatch(setPlaylistFilterLoader(_identifier)(false))
              return throwError(() => err)
            }),
          ),
        ),
      )
  }

  setTeamConfig(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(_selector))),
        map(
          ([{ dimensions }, { home, away }]) =>
            filterByList([home.team, away.team], dimensions) as MatchTeamData[],
        ),
        map((teams) =>
          teams.map((team) => ({
            id: 'dimension',
            value: team.id,
            label: team.shortName || team.name,
          })),
        ),
        switchMap((items: FilterItem[]) => [
          setFilterConfigByType(_identifier)({
            filterType: FilterType.Team,
            items,
          }),
          setPlaylistFilterLoader(_identifier)(false),
        ]),
        share(),
      )
  }

  setPlayerConfig(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(getMatch(_selector))),
        map(
          ([{ dimensions }, { home, away }]) =>
            filterByList(
              [...getTeamPlayers(home.team), ...getTeamPlayers(away.team)],
              dimensions,
            ) as McpPlayer[],
        ),
        map((players) =>
          players.map((player) => ({
            id: 'dimension',
            value: player.id,
            label: player.name,
          })),
        ),
        switchMap((items: FilterItem[]) => [
          setFilterConfigByType(_identifier)({
            filterType: FilterType.Player,
            items,
          }),
          setPlaylistFilterLoader(_identifier)(false),
        ]),
        share(),
      )
  }

  fetchCodes(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        tap(() => this._store.dispatch(setPlaylistFilterLoader(_identifier)(true))),
        switchMap(({ matchId, playlistId }) =>
          this._api.getPlaylistCodes(playlistId, matchId).pipe(
            catchError((err) => {
              this._store.dispatch(setPlaylistFilterLoader(_identifier)(false))
              return throwError(() => err)
            }),
          ),
        ),
        concatLatestFrom(() => this._store.select(selectSimplifiedLang)),
        map(([codes, lang]) =>
          codes
            .filter((tagCode) => tagCode.name !== 'MTR_TAGGING_QUICK_TAG')
            .map((tagCode) => ({
              ...tagCode,
              localizedName: translateTagName(tagCode, this._translate.translations[lang]),
            })),
        ),
        map((codes: TagCode[]) => setCodes(_identifier)({ codes })),
      )
  }

  setEventConfig(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        map(({ codes }) =>
          codes.map((tagCode: TagCode) => ({
            id: 'codeSnapshot.name',
            value: tagCode.name,
            label: tagCode.localizedName,
            color: tagCode.color,
          })),
        ),
        switchMap((items: FilterItem[]) => [
          setFilterConfigByType(_identifier)({
            filterType: FilterType.Event,
            items: items.sort((a, b) => (a.label < b.label ? -1 : 1)),
          }),
          setPlaylistFilterLoader(_identifier)(false),
        ]),
        share(),
      )
  }

  checkActiveFilterConsistence(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        debounceTime(600),
        concatLatestFrom(() => [
          this._store.select(getMatch(_selector)),
          this._store.select(selectFilterConfig(_selector)),
          this._store.select(selectCurrentMatchFilterActive(_selector)),
        ]),
        filter(([, , , filterActive]) => !!filterActive),
        switchMap(([, { id: matchId }, config, filterActive]) =>
          Object.keys(filterActive).some(
            (key) => _.differenceBy(filterActive[key], config[key], 'value').length > 0,
          )
            ? [
                setFilterActive(_identifier)({
                  filterActive: { [FilterType.All]: [DEFAULT_ALL_FILTERS[0]] },
                  matchId,
                }),
                fetchTags(_identifier)({ matchId }),
              ]
            : [emptyAction(_identifier)()],
        ),
      )
  }
}
