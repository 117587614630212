<div class="mcp-camera-selector" [ngClass]="extraClasses">
  <mc-select
    panelStyleClass="mcp-camera-selector__panel"
    class="mcp-camera-selector__select"
    [(ngModel)]="selected"
    [options]="cameraList || []"
    [disabled]="disabled || isLive || isAllCameraDisabled"
    [optionTemplate]="selectedItemTemplate"
    [optionSelectedTemplate]="selectedItemTemplate"
    (selectItem)="cameraChange.emit($event)"
  >
    <ng-template let-item="item" #selectedItemTemplate>
      <div class="mcp-camera-selector__item-wrapper">
        <i class="mcp-camera-selector__icon" [class.selected]="selected === item?.value"></i>
        <span class="mcp-camera-selector__item item">{{ item?.label | translate }}</span>
        <span class="mcp-camera-selector__item--sm item">{{ item?.translation | translate }}</span>
      </div>
    </ng-template>
  </mc-select>

  <div class="mcp-camera-selector--condensed">
    <ng-container *ngFor="let camera of cameraList">
      <button
        [class.selected]="selected === camera.value"
        class="mcp-camera-selector__button"
        [disabled]="camera.disabled"
        (click)="onCameraChange(camera)"
      >
        <i class="mcp-camera-selector__icon" [class.selected]="selected === camera.value"></i>
        <span class="mcp-camera-selector__item item">{{ camera?.label | translate }}</span>
        <span class="mcp-camera-selector__item--sm item">{{
          camera?.translation | translate
        }}</span>
      </button>
    </ng-container>
  </div>
</div>
