<ng-template
  [ngIf]="item?.value?.imageSrc || config?.defaultImage"
  [ngIfElse]="dropdownWithoutImage"
>
  <img
    class="c-filter__item-image"
    [class.c-filter__list-item-image]="isListItem"
    [ngClass]="'c-filter__item-image--' + config?.key"
    [title]="item?.label"
    [appDefaultImage]="config?.defaultImage"
    [srcUrl]="item?.value?.imageSrc"
    #img="defaultImage"
  />
  <ng-container
    *ngTemplateOutlet="
      dropdownLabel;
      context: {
        item: item,
        hideLabel: config?.imageOnly && !img?.isBrokenImg && item?.value?.imageSrc
      }
    "
  ></ng-container>
</ng-template>
<ng-template #dropdownWithoutImage>
  <ng-container *ngTemplateOutlet="dropdownLabel; context: { item: item }"></ng-container>
</ng-template>

<ng-template let-item="item" let-hideLabel="hideLabel" #dropdownLabel>
  <span *ngIf="item?.label && !hideLabel" [ngClass]="item.label">{{
    item?.label | translate
  }}</span>
</ng-template>
