import { Component, Input } from '@angular/core'

import { AssetsAvailability } from '@mediacoach-ui-library/global'

import { environment } from '@env'

import { ASSET_ORDER, ASSET_TYPE_TRANSLATIONS } from './match-asset-availability.constants'

@Component({
  selector: 'app-match-asset-availability',
  templateUrl: './match-asset-availability.component.html',
  styleUrls: ['./match-asset-availability.component.scss'],
})
export class MatchAssetAvailabilityComponent {
  private _assetAvailability: AssetsAvailability

  ASSET_ORDER = ASSET_ORDER
  ASSET_TYPE_TRANSLATIONS = ASSET_TYPE_TRANSLATIONS
  successIcon = environment.STATICS_URL_ASSETS + 'img/portal/check-circle.svg'
  errorIcon = environment.STATICS_URL_ASSETS + 'img/portal/times-circle.svg'

  get assetAvailability() {
    return this._assetAvailability
  }
  @Input() set assetAvailability(_assetAvailability) {
    this._assetAvailability =
      _assetAvailability &&
      Object.entries(_assetAvailability).reduce(
        (obj, [key, value]) => ({ ...obj, [key.toLowerCase()]: value }),
        <AssetsAvailability>{},
      )
  }

  constructor() {}
}
