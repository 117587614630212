import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  authFailed,
  logOut,
  removeUser,
  signInRedirect,
  signInRedirectCallback,
  signInSilent,
  signInSilentCallback,
} from '@core/state/actions/oidc.actions'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { iif, Observable, of } from 'rxjs'
import { navigate, navigateExternal } from '@core/router/state/actions/router.actions'
import { environment } from '@env'
import { Action, Store } from '@ngrx/store'
import { User } from 'oidc-client'
import { OAuthErrorCode } from '@auth/enums/auth.enum'
import { getTokenId } from '@core/state/selectors/user.selectors'
import { clearStorageByPrefix } from '@core/utils/application.utils'
import { purgeUserProfile } from '@core/state/actions/profile.actions'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { AnalyticsFacade } from '@core/analytics/analytics.facade'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class OidcEffects {
  signInRedirect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInRedirect),
        switchMap(({ state }) =>
          this._manager.signInRedirect({ state }).pipe(catchError(this._handleSignInError)),
        ),
      ),
    { dispatch: false },
  )

  signInRedirectCallback$ = createEffect(() =>
    this._actions$.pipe(
      ofType(signInRedirectCallback),
      switchMap(() =>
        this._manager.signInRedirectCallback().pipe(
          catchError((err) =>
            iif(
              () => err === OAuthErrorCode.AccessDenied,
              of(
                navigateExternal({
                  path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
                }),
              ),
              this._handleSignInError(err),
            ),
          ),
        ),
      ),
      map((oidc: User) => navigate({ path: oidc.state || environment.DEFAULT_ROUTE })),
    ),
  )

  singInSilent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInSilent),
        switchMap(() => this._manager.signInSilent()),
      ),
    { dispatch: false },
  )

  signInSilentCallback$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(signInSilentCallback),
        switchMap(({ uri }) => this._manager.signInRedirectCallback(uri)),
      ),
    { dispatch: false },
  )

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(logOut),
      tap(() =>
        this._analytics.logEvent(AnalyticsEvent.logout, {
          [AnalyticsParam.category]: AnalyticsCategory.auth,
        }),
      ),
      concatLatestFrom(() => this._store.select(getTokenId)),
      switchMap(([{ uri }, idToken]) => {
        clearStorageByPrefix(localStorage, 'oidc.')
        return this._manager.removeUser().pipe(map(() => ({ uri, idToken })))
      }),
      map(({ uri, idToken }) => {
        window.location.href =
          environment.IDENTITY_SERVER.API_LOGOUT +
          '?id_token_hint=' +
          idToken +
          '&' +
          environment.IDENTITY_SERVER.TAG_POST_LOGOUT_REDIRECT_URI +
          '=' +
          uri

        return purgeUserProfile()
      }),
    ),
  )

  removeUser$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(removeUser),
        switchMap(() => this._manager.removeUser()),
      ),
    { dispatch: false },
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _manager: OidcService,
    private readonly _store: Store,
    private readonly _analytics: AnalyticsFacade,
  ) {}

  private _handleSignInError(err): Observable<Action> {
    console.warn('[OIDC] Redirecting', err.message)
    clearStorageByPrefix(localStorage, 'oidc.')
    window.location.href = window.location.origin
    return this._manager?.removeUser().pipe(switchMap(() => of(authFailed())))
  }
}
