import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core'

import { CardBlockItem } from './card-block.models'
import { TemplateDirective } from '../../directives/template/template.directive'

@Component({
  selector: 'app-card-block',
  templateUrl: './card-block.component.html',
  styleUrls: ['./card-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBlockComponent implements AfterContentInit {
  titleTemplate: TemplateRef<any>
  contentTemplate: TemplateRef<any>
  footerTemplate: TemplateRef<any>

  @Input() data: CardBlockItem[]
  @Input() loading: boolean
  @Input() isPlayer: boolean

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  constructor() {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'title':
          this.titleTemplate = item.template
          break
        case 'content':
          this.contentTemplate = item.template
          break
        case 'footer':
          this.footerTemplate = item.template
          break
        default:
          this.contentTemplate = item.template
      }
    })
  }
}
