import { inject, Pipe, PipeTransform } from '@angular/core'
import { RadioOption } from '@mediacoach/ui'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'toRadioOption',
})
export class ToRadioOptionPipe implements PipeTransform {
  private readonly _translate = inject(TranslateService)
  transform(value: string[]): RadioOption[] {
    if (value?.length) {
      return value.map((v) => ({ label: this._translate.instant(v), value: v }) as RadioOption)
    }

    return []
  }
}
