export enum StoreToken {
  user = 'user',
  socket = 'socket',
  seasons = 'seasons',
  selection = 'selection',
  assets = 'assets',
  storage = 'storage',
  navigation = 'navigation',
  stream = 'stream',
  matchDetails = 'match-details',
}
