<div
  class="mcp-season-competition-selector"
  [class.mcp-season-competition-selector--seasons-hidden]="hideSeasons"
>
  <div class="mcp-widget-dialog__block" *ngIf="seasons.length && !hideSeasons">
    @for (season of seasons; track season.id) {
      <div
        class="mcp-widget-dialog__item"
        [class.mcp-widget-dialog__item--active]="selectedSeason?.id === season.id"
        (click)="seasonChangeData(season)"
        #seasonElement
      >
        <span class="mcp-widget-dialog__item-content">
          {{ 'COMMONS_TITLE_SEASON' | translate }} {{ season.officialName }}
        </span>
      </div>
    }
  </div>
  <div class="mcp-widget-dialog__block--divider" *ngIf="!hideSeasons"></div>
  <div class="mcp-widget-dialog__block mcp-season-competition-selector__competition">
    @for (competition of selectedSeason?.competitions || []; track competition.id) {
      <div
        class="mcp-widget-dialog__item"
        [class.mcp-widget-dialog__item--active]="selectedCompetition?.id === competition.id"
        [title]="competition?.name || ''"
        (click)="selectedCompetition = competition; competitionChange.emit(competition)"
        #competitionElement
      >
        <img
          class="mcp-widget-dialog__item-image mcp-season-competition-selector__competition-image"
          [srcUrl]="competition.logo"
          [appDefaultImage]="competitionPlaceholder"
        />
        <span class="mcp-season-competition-selector__competition-name" *ngIf="!competition.logo">{{
          competition?.name || ''
        }}</span>
      </div>
    }
  </div>
</div>
