<div class="c-page" [ngClass]="extraClasses" [attr.data-cy]="cyName">
  <div
    [stickyOrder]="stickyHeader ? 0 : -1"
    class="c-page__header"
    [ngClass]="headerExtraClasses"
    [class.c-page__header--no-title]="!showHeader"
    appIsSticky
  >
    <h3 *ngIf="showHeader" class="c-page__title-content">{{ headerTitle | translate }}</h3>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <span class="c-page__filter-icon" *ngIf="hasFilter">
      <i
        class="u-icon u-icon--lg"
        [ngClass]="'u-icon--funnel' + (!isFilterOpen ? '-outlined' : '')"
        (click)="filterToggle()"
      ></i>
    </span>
  </div>
  <div class="c-page__body">
    <ng-content></ng-content>
  </div>
</div>
