import { CompactType, DisplayGrid, GridsterConfig, GridType } from 'angular-gridster2'
import { environment } from '@env'
import { EnvType } from '@mediacoach/ui'

export const DEFAULT_GRIDSTER_RESIZABLE_HANDLES = {
  s: true,
  e: true,
  n: false,
  w: true,
  se: true,
  ne: false,
  sw: true,
  nw: false,
}

export const GRIDSTER_CONFIG: GridsterConfig = {
  gridType: GridType.VerticalFixed,
  compactType: CompactType.None,
  margin: 20,
  outerMarginRight: 20,
  outerMarginLeft: 20,
  outerMarginBottom: 0,
  outerMarginTop: 0,
  outerMargin: true,
  useTransformPositioning: true,
  mobileBreakpoint: 1200,
  useBodyForBreakpoint: false,
  minCols: 6,
  maxCols: 6,
  minRows: 3,
  maxRows: 100,
  maxItemCols: 100,
  minItemCols: 2,
  maxItemRows: 100,
  minItemRows: 1,
  maxItemArea: 2500,
  minItemArea: 1,
  defaultItemCols: 2,
  defaultItemRows: 1,
  fixedColWidth: 105,
  fixedRowHeight: 200,
  keepFixedHeightInMobile: true,
  scrollSensitivity: 10,
  scrollSpeed: 20,
  enableEmptyCellClick: false,
  enableEmptyCellContextMenu: false,
  enableEmptyCellDrop: false,
  enableEmptyCellDrag: false,
  enableOccupiedCellDrop: false,
  emptyCellDragMaxCols: 50,
  emptyCellDragMaxRows: 50,
  ignoreMarginInRow: false,
  draggable: {
    ignoreContent: true,
    enabled: true,
  },
  resizable: {
    enabled: true,
  },
  swap: true,
  pushItems: true,
  disablePushOnDrag: false,
  disablePushOnResize: false,
  pushDirections: { north: true, east: true, south: true, west: true },
  pushResizeItems: true,
  displayGrid: DisplayGrid.OnDragAndResize,
  disableWindowResize: false,
  disableWarnings: environment.envType !== EnvType.Dv,
  scrollToNewItems: false,
  allowMultiLayer: false,
  setGridSize: true,
}
