import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { TranslateService } from '@ngx-translate/core'
import { map, take, tap } from 'rxjs/operators'
import { FilterItem } from '@features/playlist/models/playlist.models'
import { ChipItemChangeEvent, ChipItemClickEvent } from '@mediacoach/ui'
import { Observable } from 'rxjs'
import { DEFAULT_ALL_FILTERS } from '@features/playlist/constants/playlist.constants'
import { hasFiltersActive } from '@features/playlist/utils/playlist.utils'
import {
  selectCurrentMatchFilterActive,
  selectFilterConfig,
  selectPlaylistFilterLoader,
} from '@core/state/selectors/stream-playlist.merged-selectors'
import { PlaylistDialogBase } from '@features/playlist/models/playlist-dialog.base'

@Component({
  selector: 'mcp-dialog-playlist-filter',
  templateUrl: './dialog-playlist-filter.component.html',
  styleUrls: ['./dialog-playlist-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPlaylistFilterComponent extends PlaylistDialogBase {
  private readonly _translate = inject(TranslateService)

  private _translateItemLabel = (items: FilterItem[]) =>
    items?.map((item) => ({
      ...item,
      label: item?.label ? this._translate.instant(item.label) : null,
    }))

  config$: Observable<any>
  filterActive$: Observable<any>
  filterLoader$: Observable<boolean>

  readonly filterType = FilterType

  filterActive: any

  constructor() {
    super()
    this._initializeSubs()
  }

  private _initializeSubs() {
    this.filterLoader$ = this._store.select(selectPlaylistFilterLoader(this.selector))
    this.config$ = this._store.select(selectFilterConfig(this.selector)).pipe(
      map((config) => ({
        ...config,
        all: this._translateItemLabel(config.all),
        other: this._translateItemLabel(config.other),
      })),
    )

    this.filterActive$ = this._store.select(selectCurrentMatchFilterActive(this.selector)).pipe(
      take(1),
      tap((filterActive) => (this.filterActive = filterActive)),
    )
  }

  private _checkHasFilterActive(filterActive: any) {
    if (hasFiltersActive(filterActive)) {
      this.filterActive = filterActive
    } else {
      this.filterActive = {
        [FilterType.All]: [DEFAULT_ALL_FILTERS[0]],
      }
    }
  }

  private _setDefaultFilters() {
    this.filterActive = {
      [FilterType.All]: [DEFAULT_ALL_FILTERS[0]],
    }
  }

  private _toggleAllItems(items: any[], item: any) {
    const values = [...(items || [])]
    const index = items.findIndex((filterItem) => filterItem.value === item?.value)
    if (index !== -1) {
      values.splice(index, 1)
    } else {
      values.push(item)
    }
    return values
  }

  itemAllClick({ item }: ChipItemClickEvent, currentFilters: any) {
    if (item?.id === FilterType.All) {
      this._setDefaultFilters()
    } else {
      const filterActive = { ...(currentFilters || {}) }
      filterActive[FilterType.All] = (filterActive[FilterType.All] || []).filter(
        (filterItem: any) => filterItem.id !== FilterType.All,
      )
      filterActive[FilterType.All] = this._toggleAllItems(filterActive[FilterType.All], item)
      this._checkHasFilterActive(filterActive)
    }
  }

  selectionChange(filterType: FilterType, { value }: ChipItemChangeEvent) {
    const filterActive = { ...(this.filterActive || {}) }
    filterActive[filterType] = value
    filterActive[FilterType.All] = (filterActive[FilterType.All] || []).filter(
      (item: any) => item.id !== FilterType.All,
    )
    this._checkHasFilterActive(filterActive)
  }

  close() {
    this._dialogRef.close(this.filterActive)
  }
}
