<app-modal #legal [modalBody]="bodyTemplate" [modalFooter]="footerTemplate" size="md">
  <ng-template #bodyTemplate>
    <ng-container *ngIf="legal$ | async as legalText; else loading">
      <div class="c-dialog-legal__content" [innerHTML]="legalText | safe: 'html'"></div>
    </ng-container>
  </ng-template>
  <ng-template #footerTemplate>
    <button class="c-dialog-legal__button" (click)="closeModal()">
      <span>{{ 'COMMONS_LEGALCONDITIONS_BTN_CLOSE' | translate }}</span>
    </button>
  </ng-template>
</app-modal>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
