<div
  class="c-morph-button"
  [ngClass]="
    (state ? 'c-morph-button__icon--' + state : ' ') +
    ' ' +
    (color ? 'c-morph-button--' + color : '')
  "
>
  <span *ngFor="let x of ' '.repeat(4).split('')"></span>
</div>
