import { StreamEffectsBase } from '@core/models/base-classes/stream-effects.base'
import { Injectable } from '@angular/core'
import { StreamState } from '@core/state/models/stream.state'
import {
  fetchStreamData,
  fetchStreamPlayerMetrics,
  setStreamPlayerMetrics,
} from '@core/state/actions/stream.actions'
import { createEffect, ofType } from '@ngrx/effects'
import { delay, map, share, switchMap, tap } from 'rxjs/operators'
import {
  fetchMatch,
  fetchMatchHeatMap,
  fetchMatchPassMatrix,
  fetchMatchStream,
  fetchMatchTeamMetrics,
  fetchTimelineConfig,
  refreshMatchOnLive,
  setMatch,
  setMatchPlayerMetricsLoader,
  setMatchStream,
  updateMatchWithSocket,
} from '@core/state/actions/stream-match.merged-actions'
import { mapMatchPlayerMetrics } from '@core/utils/player.utils'
import { catchRequestError } from '@shared/operators/catch-request-error.operator'
import { getStream } from '@core/state/selectors/stream.selectors'
import { updateMatchScores } from '@features/matches/utils/matches.utils'
import { fetchPlayListData } from '@core/state/actions/stream-playlist.merged-actions'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class StreamEffects extends StreamEffectsBase<StreamState> {
  fetchStreamData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchStreamData),
      switchMap(({ matchId }) => [
        fetchMatchStream(this.identifier)({ id: matchId }),
        fetchMatch(this.identifier)({ matchId }),
        fetchStreamPlayerMetrics({ matchId }),
        fetchMatchTeamMetrics(this.identifier)({ matchId }),
        fetchTimelineConfig(this.identifier)({ matchId }),
        fetchMatchPassMatrix(this.identifier)({ matchId }),
        fetchMatchHeatMap(this.identifier)({ matchId }),
        fetchPlayListData(this.identifier)({ matchId }),
      ]),
      share(),
    ),
  )

  refreshMatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(refreshMatchOnLive(this.identifier)),
      switchMap(({ updatedMatch }) => [
        updateMatchWithSocket(this.identifier)({ updatedMatch }),
        fetchStreamPlayerMetrics({ matchId: updatedMatch.id }),
        fetchTimelineConfig(this.identifier)({ matchId: updatedMatch.id }),
        fetchMatchHeatMap(this.identifier)({ matchId: updatedMatch.id }),
        fetchMatchPassMatrix(this.identifier)({ matchId: updatedMatch.id }),
        fetchMatchTeamMetrics(this.identifier)({ matchId: updatedMatch.id }),
        fetchPlayListData(this.identifier)({ matchId: updatedMatch.id, omitClean: true }),
      ]),
      share(),
    ),
  )

  updateMatchesWithSocket$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateMatchWithSocket(this.identifier)),
      this._updateWithSocketCallback(),
      concatLatestFrom(() => this._store.select(getStream)),
      map(([updatedMatch, matchStream]) => ({
        currentMatch: updatedMatch,
        stream: updateMatchScores(matchStream, updatedMatch),
      })),
      switchMap(({ currentMatch, stream }) => [
        setMatch(this.identifier)({ currentMatch }),
        setMatchStream(this.identifier)({ stream }),
      ]),
      share(),
    ),
  )

  fetchStreamPlayerMetrics$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchStreamPlayerMetrics),
      tap(() =>
        this._store.dispatch(
          setMatchPlayerMetricsLoader(this.identifier)({ playerMetricsLoader: true }),
        ),
      ),
      delay(400),
      switchMap(({ matchId }) =>
        this._commonApi.fetchMatchPlayersMetrics(matchId).pipe(
          map((playerMetrics) =>
            setStreamPlayerMetrics({ playerMetrics: mapMatchPlayerMetrics(playerMetrics) }),
          ),
          catchRequestError(),
        ),
      ),
      tap(() =>
        this._store.dispatch(
          setMatchPlayerMetricsLoader(this.identifier)({ playerMetricsLoader: false }),
        ),
      ),
      share(),
    ),
  )
}
