<app-dialog
  class="mcp-position-selector-dialog"
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  [styleClass]="extraClasses"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header c-position-selector-dialog__header-title">
    <span>{{ 'MTR_TITLE_PLAYER_VS_POSITION' | translate }}</span>
  </div>

  <div class="c-layout__block c-player-selection__body">
    <div class="c-player-selection__positions">
      @for (position of positions; track position.id) {
        <span (click)="positionSelect.emit(position)" class="c-player-selection__positions-item">{{
          position.text | translate
        }}</span>
      }
    </div>
  </div>
</app-dialog>
