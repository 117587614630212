<div class="mcp-dialog-metric-ranking" *ngIf="{ rows: metricRanking$ | async } as data">
  <div class="mcp-dialog-metric-ranking__content">
    <div class="mcp-dialog-metric-ranking__content-aggregation">
      <mcui-segmented-group
        class="match-lineup__segmented-lineup-mode icon-group"
        [(ngModel)]="selectedAggregationValue"
        (change)="onAggregationChange($event)"
      >
        @for (opt of aggregationOptions; track opt.id) {
          <mcui-segmented-control [value]="opt.id">
            <div class="segment">
              <span class="segment-label">{{ opt.label | translate }}</span>
            </div>
          </mcui-segmented-control>
        }
      </mcui-segmented-group>
    </div>
    <mcui-table
      class="mcp-dialog-metric-ranking__table remove-row-gap not-standing"
      [columns]="dialogData?.columns"
      [rows]="$any(data?.rows)"
      [cellTpl]="$any(cellTpl)"
      [headerTpl]="$any(headerTpl)"
      [emptyMessageTitle]="'MTR_WIDGET_NO_DATA' | translate"
      [emptyMessageSubtitle]="'MTR_WIDGET_NO_DATA_OF_A_METRIC' | translate"
      headerRenderStrategy="native"
      [sortIconConfig]="sortIconConfig"
    />
    <ng-template #headerTpl let-column="column">
      <div
        class="mcp-dialog-metric-ranking__table-header"
        [mcuiTooltip]="column.tooltip | translate"
      >
        {{ column.label | translate }}
      </div>
    </ng-template>

    <ng-template #cellTpl let-column="column" let-row="row" let-isFirstColumn="isFirstColumn">
      <mcp-widget-table-cell
        [class.asterisk]="row.lowInvolvement && column.showAsterisk"
        [lang]="dialogData?.lang"
        [row]="row"
        [column]="column"
        [isFirstColumn]="isFirstColumn"
        [mcpNavigable]="row[column.navigableKey]"
      ></mcp-widget-table-cell>
    </ng-template>
  </div>
  <div
    class="mcp-dialog-metric-ranking__legend"
    [@fadeInSlide]
    *ngIf="dialogData?.minutesPlayed < dialogData?.threshold && data?.rows?.length"
  >
    {{
      (dialogData?.lowInvolvementLegend
        ? dialogData?.lowInvolvementLegend
        : 'MTR_WIDGET_LOW_INVOLVEMENT_LEGEND'
      ) | translate
    }}
  </div>
</div>
