<app-dialog
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  class="c-player-comparison"
  styleClass="c-dialog--attached"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header c-player-comparison__dialog--header-title">
    <span> {{ 'MTR_TITLE_PLAYER_VS' | translate: { player: initialPlayer?.nameAndDorsal } }}</span>
  </div>

  <div class="c-player-comparison__dialog--content">
    <mcui-segmented-group
      class="c-player-comparison__lineup-team segmented-group"
      [ngModel]="selectedTeam"
      (ngModelChange)="onTeamChange($event)"
    >
      @for (opt of teamSegmentOptions; track opt.id) {
        <mcui-segmented-control [value]="opt.id">
          <div class="c-player-comparison__team-segment segment">
            <div
              class="c-player-comparison__team-segment-img segment-image"
              [mcpBackgroundImage]="opt.image"
            ></div>
            <span class="c-player-comparison__team-segment-label segment-label">{{
              opt.label
            }}</span>
          </div>
        </mcui-segmented-control>
      }
    </mcui-segmented-group>
    <div class="c-player-comparison__dialog--content-list">
      <mc-list
        [data]="match?.playerList[selectedTeam] | excludePlayer: initialPlayer?.id"
        [itemTemplate]="player"
        (onClick)="playerSelect.emit({ playerA: initialPlayer, playerB: $event })"
      ></mc-list>
    </div>
  </div>
</app-dialog>
<ng-template #player let-item>
  <mcp-player-list-item
    [data]="item"
    extraClasses="mcp-player-list-item__hover"
    [isNavigationActive]="true"
  >
  </mcp-player-list-item>
</ng-template>
