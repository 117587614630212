import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'mcp-tour-button',
  templateUrl: './tour-button.component.html',
  styleUrls: ['./tour-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourButtonComponent {
  @Input() outlined: boolean
  @Output() clicked: EventEmitter<any> = new EventEmitter()
}
