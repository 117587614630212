import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { combineLatest, merge, Observable, Subject } from 'rxjs'
import { HotkeysService } from '@core/services/hotkeys.service'
import { AdvancedControlAction } from './models/video-markers.models'
import { VideoComponent } from '@shared/components/video-container/components/video/video.component'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { DIFF_TIME } from '@shared/components/video-container/constants/video.constants'
import { debounce, Marker, parseMarkers, VideoType } from '@mediacoach-ui-library/global'
import { formatTimeByPeriods } from '@core/utils/timeline.utils'
import { fadeInDownOnEnterAnimation } from '@shared/animations/animations/fade-in-down.animation'
import { PeriodDto } from '@core/models/dto/timeline.dto'
import { VideoAnalyticsEvent } from '@shared/components/video-container/models/video.models'
import { Store } from '@ngrx/store'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'

@UntilDestroy()
@Component({
  selector: 'mcp-video-container',
  templateUrl: './video-container.component.html',
  styleUrls: ['./video-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInDownOnEnterAnimation({ duration: 1000 })],
})
export class VideoContainerComponent {
  private _time$$ = new Subject<number>()
  private _rawMarkers$$ = new Subject<Marker[]>()
  private _markers$$ = new Subject<Marker[]>()
  private _advancedMarkers$$ = new Subject<Marker[]>()
  private _offSetMarkers$$ = new Subject<void>()
  private _playingOffsetEnd$$ = new Subject<void>()
  private _playingOffsetEnd$: Observable<Marker[]> = this._playingOffsetEnd$$
    .asObservable()
    .pipe(map(() => this._markerList))
  private _totalTime = 0
  private _videoPlayer: VideoComponent
  private _marker: Marker | Marker[]

  private get _markerList(): Marker[] {
    return this._marker as Marker[]
  }

  private get _element(): Element {
    return this.hostElement || document.querySelector('mcp-stream-content')
  }

  @Input() set markers(_markers) {
    this._rawMarkers$$.next(_markers)
  }

  @Input() set marker(_marker) {
    this._marker = _marker
    if (Array.isArray(_marker)) {
      this._offSetMarkers$$.next()
    } else {
      this.onOffset(_marker)
    }
    if (this.videoPlayer && !_marker) {
      this.videoPlayer.playOffset(0, 0)
    }
  }

  get marker() {
    return this._marker
  }

  @Input() withCredentials: boolean
  @Input() streamTitle: string
  @Input() autoplayMode: 'any' | boolean = false
  @Input() debug: boolean
  @Input() source: string
  @Input() formatTimeFn: (...args: any[]) => any
  @Input() showAdvancedControls: boolean
  @Input() hostElement: Element
  @Input() lang: string
  @Input() hasQuickTagButton: boolean

  @Output() fullscreenChange = new EventEmitter<boolean>()
  @Output() playChange = new EventEmitter<boolean>()
  @Output() videoError = new EventEmitter<unknown>()
  @Output() playerReady = new EventEmitter<boolean>()
  @Output() quickTagChange = new EventEmitter<number>()
  @Output() analytics = new EventEmitter<VideoAnalyticsEvent>()

  @ViewChild('videoMarkers', { read: ElementRef }) set markersElement(element: ElementRef) {
    if (element) {
      this.videoMarkersElement = element
      this._cdr.detectChanges()
    }
  }

  get videoPlayer() {
    return this._videoPlayer
  }

  @ViewChild('videoPlayer')
  set videoPlayer(vp: VideoComponent) {
    this._videoPlayer = vp
    if (!this.shortcuts$ && !!vp) {
      this._setupHotkeys()
    }
    if (this.hasQuickTagButton && !!vp) {
      this._createQuickTagButton()
    }
  }

  markersInjected: boolean
  videoMarkersElement: ElementRef
  offSetMarketFormatTimeFn = formatTimeByPeriods(
    {} as PeriodDto,
    VideoType.Tactical,
    this._translate.instant('MTR_COMMON_MATCH_STATE_HALFTIME'),
  )

  markers$: Observable<Marker[]> = this._markers$$.asObservable()
  advancedMarkers$: Observable<Marker[]> = this._advancedMarkers$$.asObservable()
  time$: Observable<number> = this._time$$
    .asObservable()
    .pipe(tap((time) => (this._totalTime = time)))
  offSetMarkers$: Observable<Marker[]> = this._offSetMarkers$$.asObservable().pipe(
    tap(() => this.onOffset(this._markerList.shift())),
    switchMap(() => this._playingOffsetEnd$),
    tap(() => this._markerList.length === 0 && (this.marker = null)),
    filter((markers) => !!markers.length),
    tap(() => this.onOffset(this._markerList.shift())),
  )

  shortcuts$: Observable<KeyboardEvent>

  constructor(
    private readonly _store: Store,
    private readonly _hotkeys: HotkeysService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _translate: TranslateService,
  ) {
    combineLatest([
      this._time$$.asObservable().pipe(filter((t) => t !== undefined && t !== Infinity)),
      this._rawMarkers$$.asObservable(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([time, markers]) => this._handleMarkers(time, markers))
  }

  private _handleMarkers(time, markList: Marker[]): void {
    this._advancedMarkers$$.next((markList || []).filter((marker) => marker.isAdvancedControl))
    const _markers = parseMarkers(markList, time, this._translate)
    this._markers$$.next(_markers)
  }

  @debounce()
  private _setupHotkeys() {
    this.shortcuts$ = merge(
      this._hotkeys.addShortcut({ keys: 'space', element: this._element }),
      this._hotkeys.addShortcut({ keys: 'arrowleft', element: this._element }),
      this._hotkeys.addShortcut({ keys: 'arrowright', element: this._element }),
      this._hotkeys.addShortcut({
        keys: 'shift.arrowleft',
        element: this._element,
      }),
      this._hotkeys.addShortcut({
        keys: 'shift.arrowright',
        element: this._element,
      }),
      this._hotkeys.addShortcut({
        keys: 'alt.arrowleft',
        element: this._element,
      }),
      this._hotkeys.addShortcut({
        keys: 'alt.arrowright',
        element: this._element,
      }),
    )
  }

  private _createQuickTagButton() {
    const quickTagContainer = this.videoPlayer?.player.addChild('button', {
      className: 'mcp-quick-tag-button__container',
    })
    quickTagContainer.addChild('button', {
      className: 'mcp-quick-tag-button__icon icon-lightning',
    })
    quickTagContainer.on('click', () => this.quickTagChange.emit(this.getCurrentTime()))
  }

  getCurrentTime(): number {
    return this.videoPlayer?.getCurrentTime()
  }

  isFullScreen(): boolean {
    return this.videoPlayer?.isFullscreen()
  }

  setPlayerTime(time: number): void {
    this.videoPlayer?.setPlayerTime(time)
  }

  sendAnalytics(params: VideoAnalyticsEvent): void {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: null,
        eventParams: {
          eventCategory: 'streaming_portal',
          eventAction: params.event,
          eventLabel: this.streamTitle,
        },
      }),
    )
    this.analytics.emit(params)
  }

  seekToLiveEdge(): void {
    this.videoPlayer?.seekToLiveEdge()
  }

  onMove({ direction, length }: AdvancedControlAction): void {
    this.videoPlayer?.playFrom(direction === 'back' ? -length : length)
  }

  onMarker(marker: Partial<Marker>): void {
    if (marker?.time <= this._totalTime && this.videoPlayer) {
      this.videoPlayer.playAt(marker.time)
    }
  }

  onOffset(marker: Partial<Marker>): void {
    if (marker?.time <= this._totalTime && this.videoPlayer) {
      this.videoPlayer.playOffset(marker.time - DIFF_TIME, marker.time + DIFF_TIME)
    }
  }

  onTimeUpdate(time: number): void {
    this._time$$.next(time)
  }

  onError(error): void {
    this.videoError.emit(error)
  }

  onPlayOffsetEnd(): void {
    this._playingOffsetEnd$$.next()
  }

  stop() {
    this.videoPlayer?.stop()
  }
}
