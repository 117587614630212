import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  Output,
  QueryList,
} from '@angular/core'

import { BehaviorSubject } from 'rxjs'

import { TemplateDirective } from '@shared/directives/template/template.directive'

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements AfterContentInit {
  isFilterOpen = false
  headerTemplate

  @Input() hasFilter = false
  @Input() headerTitle: string
  @Input() stickyHeader = true
  @Input() showHeader = false
  @Input() extraClasses: string
  @Input() headerExtraClasses: string
  @Input() gtmCategory: string
  @Input() cyName: string
  @Output() onFilterToggle = new BehaviorSubject<boolean>(this.isFilterOpen)

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  constructor() {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template
          break
      }
    })
  }

  filterToggle() {
    this.isFilterOpen = !this.isFilterOpen
    this.onFilterToggle.next(this.isFilterOpen)
  }
}
