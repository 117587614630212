import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core'
import { AsyncPipe } from '@angular/common'
import { isObservable, Observable } from 'rxjs'
import { QueryDropdownOption } from '@shared/components/query/models/query-dropdown.model'

@Pipe({
  name: 'optionsResolver',
})
export class OptionsResolverPipe implements PipeTransform {
  private _async: AsyncPipe = new AsyncPipe(this._cdr)

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  transform(value: Observable<QueryDropdownOption> | QueryDropdownOption): any {
    return isObservable(value) ? this._async.transform(value) : value || []
  }
}
