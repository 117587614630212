<div class="mcp-playlist-item-info">
  <div class="mcp-playlist-item-info__content">
    <div class="mcp-playlist-item-info__content-index">
      {{ item?.index }}
    </div>
    <mcp-tag-info [item]="item"></mcp-tag-info>
  </div>
  <div class="mcp-playlist-item-info__actions">
    <mcui-button
      shape="rounded-shape"
      mode="outline"
      iconPosition="icon-only"
      icon="menu-dots"
      [mcuiMenuTriggerFor]="$any(m.menu)"
    />
    <mcui-menu
      #m
      [items]="playlistItemInfoMenuItems | translateMenu: lang"
      [styleClass]="isModal ? 'm-app--dark' : ''"
      (menuClick)="menuClick.emit($event)"
    />
  </div>
</div>
