import { ChangeDetectionStrategy, Component } from '@angular/core'
import { Observable } from 'rxjs'
import { PlaylistDialogBase } from '@features/playlist/models/playlist-dialog.base'
import { selectPlaylistFilteredCollaborators } from '@core/state/selectors/stream-playlist.merged-selectors'
import {
  PlaylistCollaborator,
  PlaylistResponse,
} from '@features/playlist/models/playlist.response.models'

@Component({
  selector: 'mcp-dialog-create-playlist',
  templateUrl: './dialog-create-playlist.component.html',
  styleUrl: './dialog-create-playlist.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogCreatePlaylistComponent extends PlaylistDialogBase {
  playlistName: string
  allSelected: boolean
  collaborators$: Observable<PlaylistCollaborator[]>

  get playlist(): PlaylistResponse {
    return this._dialogConfig?.data?.playlist
  }
  get _matchId(): string {
    return this._dialogConfig?.data?.matchId || this.playlist?.matchId
  }

  constructor() {
    super()
    this._resolvePayload()
  }

  private _resolvePayload() {
    this.collaborators$ = this._store.select(selectPlaylistFilteredCollaborators(this.selector))
    this.playlistName = this.playlist?.name
  }

  close(collaborators: PlaylistCollaborator[]): void {
    this._dialogRef.close({
      ...this.playlist,
      name: this.playlistName,
      collaborators: collaborators.map(({ id }) => id),
      matchId: this._matchId,
    })
  }
}
