@if ({ playlists: playlists$ | async, userId: userId$ | async, lang: lang$ | async }; as data) {
  <div class="mcp-dialog-manage-playlist">
    <div class="mcp-dialog-manage-playlist__header" [style.grid-row]="1" [style.grid-column]="1">
      {{ 'MTR_PLAYLIST_DIALOG_PLAYLISTS_NAME' | translate }}
    </div>
    <div class="mcp-dialog-manage-playlist__header">
      {{ 'MTR_PLAYLIST_DIALOG_PLAYLIST_SHARE' | translate }}
    </div>
    <div class="mcp-dialog-manage-playlist__header"></div>
    @for (playlist of data?.playlists; track playlist) {
      <div class="mcp-dialog-manage-playlist__name">{{ playlist.name }}</div>
      <div class="mcp-dialog-manage-playlist__shared-with">
        <mcp-collaborators-avatar-list [collaborators]="playlist.collaborators" />
      </div>
      <div class="mcp-dialog-manage-playlist__menu" [mcuiMenuTriggerFor]="$any(m.menu)">
        <mcui-icon svgIcon="menu-dots" />
        <mcui-menu
          #m
          [items]="contextMenu | translateMenu: data?.lang"
          [styleClass]="displayMode === 'modal' ? 'm-app--dark' : ''"
          [disableFn]="disableMenuItem(playlist, data?.userId)"
          (menuClick)="onMenuClick($event, playlist)"
        />
      </div>
    }
  </div>
}
