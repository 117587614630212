import {
  PlaylistCollaborator,
  PlaylistRequest,
} from '@features/playlist/models/playlist.response.models'

export const addUserAsCollaborator = (
  playlist: PlaylistRequest,
  availableCollaborators: PlaylistCollaborator[],
  userId: string,
): PlaylistRequest => {
  if (playlist.collaborators?.some((c) => c === userId)) {
    return playlist
  }

  const owner = (availableCollaborators || []).find((c) => c.id === userId)
  const collaborators = [owner?.id, ...(playlist.collaborators || [])].reduce(
    (list, collaborator) => {
      if (collaborator) {
        list.push(collaborator)
      }
      return list
    },
    [],
  )
  return {
    ...playlist,
    collaborators,
  }
}
