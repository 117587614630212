import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core'
import VimeoPlayer from '@vimeo/player'
import { Video } from '@features/videos/models/videos.models'
import { Ga4Params } from '@core/analytics/models/gtag.models'
import { Store } from '@ngrx/store'
import {
  PLAYER_DEFAULT_HEIGHT,
  PLAYER_DEFAULT_WIDTH,
} from '@features/videos/constants/videos.constants'
import { VimeoEvent } from '@shared/directives/vimeo/enums/vimeo-player.enums'
import { VIMEO_EVENT_ANALYTICS_MAP } from '@shared/directives/vimeo/constants/vimeo-player.constants'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'

@Directive({
  selector: '[mcpVimeoPlayer]',
})
export class VimeoPlayerDirective implements OnInit {
  private _player: VimeoPlayer

  @Input('mcpVimeoPlayer') video: Video
  @Output() vimeoError: EventEmitter<Error> = new EventEmitter<Error>()
  @Output() analytics = new EventEmitter<Ga4Params>()

  constructor(
    private readonly _host: ElementRef,
    private readonly _store: Store,
  ) {}

  ngOnInit() {
    if (this.video) {
      this._player = new VimeoPlayer(this._host.nativeElement, {
        url: this.video.link,
        width: PLAYER_DEFAULT_WIDTH,
        height: PLAYER_DEFAULT_HEIGHT,
      })
      this._setupListeners()
    } else {
      this._player.destroy()
      this._player = null
    }
  }

  private _setupListeners() {
    this._player.ready().catch((err) => {
      this.vimeoError.emit(new Error(err))
      console.error('Error loading Vimeo video: ' + err)
    })

    Object.values(VimeoEvent).forEach((ev) => {
      this._player.on(ev, () =>
        Promise.all(this._getPlayerPromises()).then(([fullscreen, time, title, pip]) => {
          this.analytics.emit({
            name: VIMEO_EVENT_ANALYTICS_MAP[ev],
            [AnalyticsParam.category]: AnalyticsCategory.streaming,
            [AnalyticsParam.videoFullscreen as string]: fullscreen,
            [AnalyticsParam.videoTime as string]: time,
            [AnalyticsParam.videoName as string]: title,
            [AnalyticsParam.videoPip as string]: pip,
          })
        }),
      )
    })
  }

  private _getPlayerPromises(): Promise<unknown>[] {
    return [
      this._player.getFullscreen(),
      this._player.getCurrentTime(),
      this._player.getVideoTitle(),
      this._player.getPictureInPicture(),
    ]
  }
}
