import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() size: string
  @Input() type: string
  @Input() extraClasses: string
  @Input() backgroundImage: string
  @Input() hasCloseButton = true
  @Input() closeOnBackdrop = false
  @Input() inlineMode = false
  @Input() routeOnClose: string
  @Input() appendTo
  @Input() modalHeader: TemplateRef<any>
  @Input() modalBody: TemplateRef<any>
  @Input() modalFooter: TemplateRef<any>
  @Output() onClose = new EventEmitter()
  @Output() onOpen = new EventEmitter()

  isOpen = false
  isInline: boolean
  sizeClass = ''
  typeClass = ''

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.size) {
      this.sizeClass = `c-modal--${this.size}`
    }

    if (this.type) {
      this.typeClass = `c-modal--${this.type}`
    }

    this.isInline = this.inlineMode
  }

  openModal() {
    this.isOpen = true
    this.isInline = false
    if (this.appendTo) {
      this.renderer.addClass(this.appendTo, 'append-to--modal')
      this.renderer.addClass(document.body, 'append-to-body--modal')
    } else {
      this.renderer.addClass(document.body, 'document--modal')
    }
    this.onOpen.emit(this.isInline)
    this._cdr.detectChanges()
  }

  closeModal() {
    this.isOpen = false
    this.isInline = this.inlineMode
    if (this.appendTo) {
      this.renderer.removeClass(this.appendTo, 'append-to--modal')
      this.renderer.removeClass(document.body, 'append-to-body--modal')
    } else {
      this.renderer.removeClass(document.body, 'document--modal')
    }
    if (this.routeOnClose) {
      this.router.navigateByUrl(this.routeOnClose)
    }
    this.onClose.emit(this.isInline)
    this._cdr.detectChanges()
  }
}
