import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { DeviceDetectorService, SelectComponent } from '@mediacoach-ui-library/global'
import { NavigableItem } from '../../models/navigable-item.model'

@Component({
  selector: 'mcp-navigable-dropdown',
  templateUrl: './navigable-dropdown.component.html',
  styleUrls: ['./navigable-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigableDropdownComponent {
  @ViewChild('select', { static: false })
  select: SelectComponent<NavigableItem>

  @Input() model: NavigableItem
  @Input() showOnHover = true
  @Input() liveMatches: boolean
  @Input() currentUrl: string
  @Input() selectedItemTemplate: TemplateRef<any>
  @Input() optionTemplate: TemplateRef<any>

  @Output() itemClick: EventEmitter<NavigableItem> = new EventEmitter<NavigableItem>()

  constructor(private deviceDetector: DeviceDetectorService) {
    this.showOnHover = !deviceDetector.isTablet() && !deviceDetector.isMobile()
  }

  handleDropdownClick() {
    this.select?.hideDropdown()
    this.itemClick.emit(this.model)
  }
}
