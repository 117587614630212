<div
  class="mcp-tour-step__holder"
  [id]="'mcp-tour-step-' + step.name"
  [mcpStepPosition]="{ step: step, options: options, isCustomized: isCustomized }"
>
  <mcp-tour-arrow
    *ngIf="showArrow"
    class="mcp-tour-step__arrow"
    [position]="arrowDirection"
  ></mcp-tour-arrow>
  <div #stepContainer class="mcp-tour-step__container">
    <div class="mcp-tour-step__close" (click)="close()">
      <ng-container
        *ngTemplateOutlet="step?.closeButtonTpl ?? options?.closeButtonTpl ?? defaultClose"
      ></ng-container>
    </div>
    <div *ngIf="options?.showProgress" class="mcp-tour-step__counter-container">
      <ng-container
        *ngTemplateOutlet="
          step?.progressTpl ?? options?.progressTpl ?? defaultProgress;
          context: { progress: progressData }
        "
      ></ng-container>
    </div>
    <div class="mcp-tour-step__header" *ngIf="step?.asyncTitle || step?.title">
      <div class="mcp-tour-step__title" [style.color]="options?.themeColor">
        {{ step?.asyncTitle ? (step?.asyncTitle | async) : step?.title }}
      </div>
    </div>
    <div class="mcp-tour-step__body">
      <ng-container
        *ngTemplateOutlet="step?.stepContent ?? defaultContent; context: step?.stepContentParams"
      ></ng-container>
    </div>
    <div class="mcp-tour-step__footer">
      <div
        class="mcp-tour-step__button mcp-tour-step__button--prev"
        *ngIf="options?.showPrevButton && !isFirstStep()"
        (click)="prev()"
      >
        <ng-container
          *ngTemplateOutlet="
            step?.prevButtonTpl ?? options?.prevButtonTpl ?? defaultPrevButton;
            context: { step: step }
          "
        ></ng-container>
      </div>
      <div
        class="mcp-tour-step__button mcp-tour-step__button--next"
        *ngIf="!isLastStep(); else defaultDone"
        (click)="next()"
      >
        <ng-container
          *ngTemplateOutlet="
            step?.nextButtonTpl ?? options?.nextButtonTpl ?? defaultNextButton;
            context: { step: step }
          "
        ></ng-container>
      </div>
      <ng-template #defaultDone>
        <div class="mcp-tour-step__button mcp-tour-step__button--done" (click)="close(true)">
          <ng-container
            *ngTemplateOutlet="
              step?.doneButtonTpl ?? options?.doneButtonTpl ?? defaultDoneButton;
              context: { step: step }
            "
          ></ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- DEFAULT TEMPLATES -->

<ng-template #defaultClose>
  <mcp-tour-close class="mcp-tour-step__close-button"></mcp-tour-close>
</ng-template>

<ng-template #defaultProgress>
  <mcp-tour-progress [stepProgress]="progressData"></mcp-tour-progress>
</ng-template>

<ng-template #defaultContent>
  <span [innerHTML]="step.asyncText ? (step?.asyncText | async) : step?.text"></span>
</ng-template>

<ng-template #defaultPrevButton>
  <mcp-tour-button [outlined]="true">{{ options?.buttonsText?.prev$ | async }}</mcp-tour-button>
</ng-template>

<ng-template #defaultNextButton>
  <mcp-tour-button>{{ options?.buttonsText?.next$ | async }}</mcp-tour-button>
</ng-template>

<ng-template #defaultDoneButton>
  <mcp-tour-button>{{ options?.buttonsText?.done$ | async }}</mcp-tour-button>
</ng-template>
