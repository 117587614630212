import {
  PlaylistResponse,
  TagResponse,
  TagVideoTypeResponse,
} from '@features/playlist/models/playlist.response.models'
import { AssetMatch, MatchTeamData } from '@core/models/dto/match.dto'
import { TagDimensionType, TagType, TagVideoType } from '@features/playlist/enums/playlist.enums'
import { getTeamPlayers, translateTagName } from '@features/playlist/utils/playlist.utils'
import { McpPlayer } from '@core/models/dto/player.dto'
import { SafeObjectData, TeamType, VideoType } from '@mediacoach-ui-library/global'
import { PATH_QUICK_IMAGE } from '@mediacoach/ui'
import { PlayListPeriod } from '@features/playlist/models/playlist.models'
import {
  GET_PERIOD_NUMBER_BY_PERIOD_TYPE,
  GET_VIDEO_ID_BY_VIDEO_TYPE,
} from '@features/playlist/constants/playlist.constants'
import {
  PLAYER_DEMARCATION_FALLBACK_KEY,
  PLAYER_DEMARCATION_KEYS,
} from '@core/constants/player.constants'
import { isGoalKeeper, resolveGenericPosition } from '@shared/pipes/demarcation/demarcation.utils'
import { TagItem } from '@shared/components/tag-item/models/tag-item.models'

export const parseTagItem = (
  item: TagResponse,
  index: number,
  match: AssetMatch,
  translations,
  parsedPeriods: PlayListPeriod[],
  videoType: VideoType,
  selectedPlaylist: PlaylistResponse,
) => {
  const iText = String(index + 1)
  const indexText = iText.length === 1 ? `0${iText}` : iText
  const dimensionItem = getDimensionType(item, match, translations)
  const timeDescription = getTagMatchTime(item, match, parsedPeriods, videoType, translations)
  const time = seekTime(item, parsedPeriods, videoType)
  const owner = item?.playlists.find(({ id }) => id === selectedPlaylist.id)?.by

  return {
    ...item,
    idx: index,
    index: indexText,
    dimensionType: dimensionItem?.type,
    title: dimensionItem?.title,
    eventName: translateTagName(item.codeSnapshot, translations),
    eventColor: item?.codeSnapshot?.color,
    teamType: dimensionItem?.teamType,
    subtitle: dimensionItem?.subtitle,
    playerPosition: dimensionItem?.playerPosition,
    image: dimensionItem?.image,
    timeDescription,
    hasEventOpacity: item.tagTypeId !== TagType.Event,
    time,
    owner,
    period: translations[`MTR_COMMON_PERIOD_${item.periodNumber}P`],
  } as TagItem
}

const getDimensionType = (
  item: TagResponse,
  { home, away }: AssetMatch,
  translations,
): {
  type: TagDimensionType
  teamType?: TeamType
  title?: string
  subtitle?: string
  image?: string
  playerPosition?: string
} => {
  const team: MatchTeamData = findById([home.team, away.team], item.dimension)
  const player: McpPlayer = findById(
    [...getTeamPlayers(home.team), ...getTeamPlayers(away.team)],
    item.dimension,
  )
  if (item.dimension && !!team) {
    return {
      type: TagDimensionType.Team,
      title: team.shortName || team.name,
      teamType: team.id === home.team.id ? TeamType.Home : TeamType.Away,
      image: SafeObjectData(team, 'parsedImages.Portrait.Small2x', ''),
    }
  } else if (item.dimension && !!player) {
    const playerTeam: MatchTeamData = player.teamId === home.team.id ? home.team : away.team
    const translatedPosition = getPlayerPosition(player, translations)
    return {
      type: TagDimensionType.Player,
      title: `${player.shirtNumber}. ${player.name} (${playerTeam.abbreviation})`,
      teamType: player.teamId === home.team.id ? TeamType.Home : TeamType.Away,
      playerPosition: `${translatedPosition}`,
      image: player.portraitLogo,
    }
  }
  return { type: TagDimensionType.Quick, image: PATH_QUICK_IMAGE }
}

const getPlayerPosition = (player: McpPlayer, translations: any) => {
  if (isGoalKeeper(player)) {
    return translations[resolveGenericPosition(player)]
  } else {
    const playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
    const playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY
    const playerProps = _mapPlayerProps(player, playerDemarcationKeys)

    if (playerProps.length) {
      return playerProps.map((p) => translations[p]).join(' - ')
    }
    return translations[player[playerDemarcationFallbackKey]] || ''
  }
}

const _mapPlayerProps = (player: McpPlayer, params: string[]): string[] => {
  return params.reduce((list: string[], param: string) => {
    const _prop = (player as any)[param]
    if (_prop) {
      list.push(_prop)
    }
    return list
  }, [] as string[])
}

const findById = (items, value) => items.find(({ id }) => id === value)
const getTagTimeByVideoType = (item: TagResponse, videoId: TagVideoType) =>
  item?.videosInfo?.find((video) => (video.type as TagVideoTypeResponse).id === videoId)?.seconds ??
  0

const getTagMatchTime = (
  item: TagResponse,
  match: AssetMatch,
  parsedPeriods: PlayListPeriod[],
  videoType: VideoType,
  translations,
) => {
  const secondsVisualizeBefore = item?.codeSnapshot?.secondsVisualizeBefore || 0

  const periods = parsedPeriods?.find((period) => period.type === videoType)?.periods || []
  if (!periods?.length) return '-'

  const videoId = GET_VIDEO_ID_BY_VIDEO_TYPE[videoType]
  const value = getTagTimeByVideoType(item, videoId)

  let tagTime = '-'

  for (const period of periods) {
    if (value >= period.startTime) {
      let seconds = value - period.startTime
      if (period.endTime > 0 && value >= period.endTime) {
        // No play time
        if (!periods.some((p) => p.type === period.type + 1) && !match.isLive) {
          tagTime = translations['MTR_MATCH_STATE_FULLTIME'] || '-'
        } else {
          tagTime = translations['MTR_TIMELINE_HALFTIME'] || '-'
        }
      } else if (
        periods.some((p) => p.type === period.type + 1) &&
        seconds > (period.defaultEnd + 1) * 60
      ) {
        // Extra time
        seconds -= period.defaultEnd * 60
        tagTime = `${period.defaultEnd}+${Math.floor(seconds / 60)}`
      } else {
        // Inside time
        if (seconds < secondsVisualizeBefore) {
          const currentMinutes = String(period.start).padStart(2, '0')
          tagTime = `${currentMinutes}:00`
        } else {
          seconds += period.start * 60
          seconds -= secondsVisualizeBefore
          const currentMinutes = String(Math.floor(seconds / 60)).padStart(2, '0')
          const currentSeconds = String(Math.abs(seconds % 60)).padStart(2, '0')
          tagTime = `${currentMinutes}:${currentSeconds}`
        }
      }
    }
  }

  return tagTime
}

const seekTime = (item: TagResponse, parsedPeriods: PlayListPeriod[], videoType: VideoType) => {
  const periods = parsedPeriods?.find((period) => period.type === videoType)?.periods || []
  const secondsVisualizeBefore = item?.codeSnapshot?.secondsVisualizeBefore ?? 0
  const videoId = GET_VIDEO_ID_BY_VIDEO_TYPE[videoType]
  const value = getTagTimeByVideoType(item, videoId)

  let time: number

  for (const period of periods) {
    if (value >= period.startTime) {
      const seconds = value - period.startTime
      if (period.endTime > 0 && value >= period.endTime) {
        // No play time
        time = value - secondsVisualizeBefore
      } else if (
        periods.some((p) => p.type === period.type + 1) &&
        seconds > (period.defaultEnd + 1) * 60
      ) {
        // Extra time
        time = value - secondsVisualizeBefore
      } else {
        // Inside time
        if (seconds < secondsVisualizeBefore) {
          time = period.startTime
        } else {
          time = value - secondsVisualizeBefore
        }
      }
    }
  }

  return time
}

export const parsePlaylistPeriods = (periods) =>
  Object.keys(periods?.firstHalf?.startTimeItems || {}).reduce(
    (result, videoType) => [
      ...result,
      {
        type: videoType,
        periods: Object.keys(periods).map((key) => {
          const period = periods[key]
          return {
            type: GET_PERIOD_NUMBER_BY_PERIOD_TYPE[key],
            start: period.start,
            end: period.end,
            defaultEnd: period.defaultEnd,
            startTime: period.startTimeItems[videoType]?.timeInSeconds,
            endTime: period.endTimeItems[videoType]?.timeInSeconds,
          }
        }),
      },
    ],
    [],
  )
