import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { DomService } from '@shared/modules/tour/services/dom/dom.service'
import { Subject } from 'rxjs'
import { Scroll } from '../../models/tour-dom.models'

@Injectable({ providedIn: 'root' })
export class EventListenerService {
  private readonly _renderer: Renderer2
  private _scrollListener: () => void
  private _resizeListener: () => void

  scrollEvent: Subject<Scroll> = new Subject<Scroll>()
  resizeEvent: Subject<number> = new Subject<number>()

  constructor(
    private readonly _rendererFactory: RendererFactory2,
    private readonly _dom: DomService,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null)
  }

  startListening() {
    this._startListeningResizeEvents()
    this._startListeningScrollEvents()
  }

  stopListening() {
    this._scrollListener()
    this._resizeListener()
  }

  private _startListeningScrollEvents() {
    this._scrollListener = this._renderer.listen('document', 'scroll', (_) => {
      this.scrollEvent.next({
        scrollX: this._dom.winRef.scrollX,
        scrollY: this._dom.winRef.scrollY,
      })
    })
  }

  private _startListeningResizeEvents() {
    this._resizeListener = this._renderer.listen('window', 'resize', (evt) => {
      this.resizeEvent.next(evt)
    })
  }
}
