<div
  class="mcp-loader"
  [class.mcp-loader--loading]="loading"
  [class.mcp-loader--no-height]="noHeight"
>
  <ng-content></ng-content>
</div>
<div class="mcp-loader__spinner">
  @if (loading) {
    <mcui-loading [message]="title" />
  }
</div>
