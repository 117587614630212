<div class="mcp-video-advanced-controls">
  <div class="mcp-video-advanced-controls__actions">
    <ng-container *ngFor="let item of advancedMarkers || []">
      <a
        class="mcp-video-advanced-controls__actions-button"
        [class.c-video-player__advanced-mode-button--separator]="periods.includes(item.id)"
        (click)="marker.emit(item)"
        >{{ item.label | translate }}</a
      >
    </ng-container>
    <a
      class="mcp-video-advanced-controls__actions-button mcp-video-advanced-controls__actions-rewind mcp-video-advanced-controls__actions-separator"
      (click)="move.emit({ direction: 'back', length: defaultTime })"
      >-{{ defaultTime }}</a
    >
    <a
      class="mcp-video-advanced-controls__actions-button mcp-video-advanced-controls__actions-forward"
      (click)="move.emit({ direction: 'forward', length: defaultTime })"
      >+{{ defaultTime }}</a
    >
  </div>
</div>
