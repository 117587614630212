import { Injectable } from '@angular/core'
import { getUserAgent } from '@core/utils/window.utils'
import { DomToImageOptions } from '@shared/services/exporters/models/dom-to-image.models'
import domtoimage from 'dom-to-image'
import { removeElements } from '@core/utils/dom.utils'
import { saveAs } from 'file-saver'

@Injectable({ providedIn: 'root' })
export class ImageExporter {
  private readonly _removeImages: boolean = ['safari', 'firefox'].includes(getUserAgent())

  exportAsPNG(element: HTMLElement, filename: string, options: DomToImageOptions) {
    return (this._removeImages ? removeElements(element, 'img') : Promise.resolve(element))
      .then((el) => {
        el.classList.add('remove-scrolls')
        return domtoimage.toBlob(el, options)
      })
      .then((blob) => saveAs(blob, `${filename}.png`))
      .catch((err) => console.error(err))
      .finally(() => element.classList.remove('remove-scrolls'))
  }
}
