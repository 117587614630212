import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { fetchSeasons, setSeasons, setSelectedSeason } from '@core/state/actions/season.actions'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { SeasonApi } from '@core/requests/api/season/season.api'
import { mapSeasons } from '@core/utils/season.utils'
import { throwError } from 'rxjs'
import { Store } from '@ngrx/store'
import { Season } from '@core/models/dto/season.dto'
import { getProfile } from '@core/state/selectors/user.selectors'

@Injectable()
export class SeasonsEffects {
  fetchSeasons$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchSeasons),
      switchMap(() => this._store.select(getProfile)),
      filter((profile) => !!profile),
      switchMap((profile) =>
        this._api.fetchSeasons(profile?.idCompetitionOrderCriteria).pipe(
          map((seasons: { seasons: Season[] }) => mapSeasons(seasons)),
          // TODO: Current flag will be useless eventually
          map((seasons: Season[]) => [{ ...seasons[0], current: true }, ...seasons.slice(1)]),
          catchError((err) => {
            console.error(err)
            return throwError(err)
          }),
        ),
      ),
      switchMap((seasons) => [
        setSeasons({ list: seasons }),
        setSelectedSeason({ selected: seasons[0] }),
      ]),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: SeasonApi,
    private readonly _store: Store,
  ) {}
}
