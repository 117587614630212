import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { translateKey } from '@core/utils/translate.utils'

@Pipe({
  name: 'multipleTranslate',
})
export class MultipleTranslatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}
  transform(
    value: string,
    splitSymbol = ', ',
    joinSymbol = ', ',
    config?: { lang: string; isI18NLoadedChangeDetector?: number },
  ): string {
    if (value) {
      return value
        .split(splitSymbol)
        .map((v) => translateKey(this.translate, v, config?.lang))
        .join(joinSymbol)
    }
    return value
  }
}
