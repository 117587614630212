export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 Byte'
  }
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const areEmptyFiles = (files: File[], minFileSize: number): boolean => {
  for (let i = 0; i < (files || []).length; i++) {
    if (files[i].size <= minFileSize) {
      return true
    }
  }
  return false
}
