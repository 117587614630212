import {
  McpDualMetric,
  McpFormattedMetricsConfig,
  McpMetricSelected,
} from '@core/models/dto/metric.dto'
import {
  FormattedMetricsConfig,
  GetLeftRightValue,
  LA_LIGA_LIST_NAME,
  ListHeaderContent,
  PLACEHOLDER_IMAGES,
  SafeObjectData,
  ListHeader,
} from '@mediacoach-ui-library/global'
import { PlayerSummary } from '@core/models/dto/player.dto'
import { MetricAggregation } from '@core/enums/metric.enums'
import { TeamSummary } from '@core/models/dto/team.dto'
import { MatchMetric } from '@core/models/dto/match.dto'

export const parseLeftRightMetrics = (arr) =>
  arr.map(({ leftAverageMetricResult, rightAverageMetricResult }) => [
    leftAverageMetricResult,
    rightAverageMetricResult,
  ])

const buildLeftContent = (config: FormattedMetricsConfig): ListHeaderContent => ({
  ...(config?.left || ({} as any)),
  title: config.isSwitchable ? null : config?.left?.title,
  subtitle: config.isSwitchable
    ? null
    : config?.left?.subtitle
      ? config?.left?.subtitle
      : config?.fromMatch
        ? 'MTR_COMMON_MATCH_VALUE'
        : 'MTR_COMMON_AVERAGE',
})

const buildRightContent = (
  config: FormattedMetricsConfig,
  competitionImg: string,
  competitionName: string = LA_LIGA_LIST_NAME,
): ListHeaderContent => ({
  ...(config?.right || ({} as any)),
  img: config?.fromMatch ? config?.right?.img : competitionImg || PLACEHOLDER_IMAGES.COMPETITION,
  title: config.isSwitchable ? null : config.fromMatch ? config?.right?.title : competitionName,
  subtitle: config?.isSwitchable
    ? null
    : config?.right?.subtitle
      ? config?.right?.subtitle
      : 'MTR_COMMON_AVERAGE',
})

const buildMetricList = (
  config: FormattedMetricsConfig,
  aggregation: MetricAggregation,
  metricKeys: string[],
) =>
  SafeObjectData(config.metricsProperty, 'groups', []).reduce(
    (rData, group) => [
      ...rData,
      ...SafeObjectData(group, aggregation, []).map((matchValue) => ({
        text: SafeObjectData(matchValue, 'key', ''),
        ...GetLeftRightValue(matchValue, metricKeys),
      })),
    ],
    [],
  )

export const buildMetrics = (
  config: FormattedMetricsConfig,
  aggregation: MetricAggregation,
  metricKeys: string[],
  competitionImg: string,
  competitionName?: string,
) => ({
  header: {
    leftContent: buildLeftContent(config),
    rightContent: buildRightContent(config, competitionImg, competitionName),
  },
  list: buildMetricList(config, aggregation, metricKeys),
})

const resolveMetricKeys = (
  config: McpFormattedMetricsConfig,
): { averageKeys: string[]; matchKeys: string[]; accumulatedKeys: string[] } => ({
  averageKeys: config.averageValueKeys || ['leftAverageMetricResult', 'rightAverageMetricResult'],
  matchKeys: config.matchValueKeys || ['leftMatchMetricResult', 'rightMatchMetricResult'],
  accumulatedKeys: config.accumulatedValueKeys || [
    'leftAccumulatedMetricResult',
    'rightAccumulatedMetricResult',
  ],
})

const resolveMetrics = (
  config: McpFormattedMetricsConfig,
  competitionImg?: string,
  competitionName?: string,
) => {
  const { averageKeys, matchKeys, accumulatedKeys } = resolveMetricKeys(config)

  return {
    avgMetrics: buildMetrics(
      config,
      MetricAggregation.AverageValues,
      averageKeys,
      competitionImg,
      competitionName,
    ),
    matchMetrics: buildMetrics(
      config,
      MetricAggregation.MatchValues,
      matchKeys,
      competitionImg,
      competitionName,
    ),
    accumulatedMetrics: buildMetrics(
      config,
      MetricAggregation.AccumulatedValues,
      accumulatedKeys,
      competitionImg,
      competitionName,
    ),
  }
}

export const mapMetricsAsList = (
  config: McpFormattedMetricsConfig,
  competitionImg?: string,
  competitionName?: string,
  matchValuesHeader?: any,
  averageValuesHeader?: any,
  accumulatedValuesHeader?: any,
) => {
  const id = SafeObjectData(config.metricsProperty, 'key')

  const { avgMetrics, matchMetrics, accumulatedMetrics } = resolveMetrics(
    config,
    competitionImg,
    competitionName,
  )

  return {
    id,
    label: id,
    isSwitchable: config.isSwitchable,
    metrics:
      !config.isSwitchable && !matchValuesHeader
        ? avgMetrics
        : {
            matchValues: {
              ...matchMetrics,
              ...(matchValuesHeader ? { header: matchValuesHeader } : {}),
            },
            averageValues: {
              ...avgMetrics,
              ...(averageValuesHeader ? { header: averageValuesHeader } : {}),
            },
            accumulatedValues: {
              ...accumulatedMetrics,
              ...(accumulatedValuesHeader ? { header: accumulatedValuesHeader } : {}),
            },
          },
  }
}

export const mapSelectedMetric = (
  key: string,
  { header, list }: { header: ListHeader; list: MatchMetric[] },
  summary: TeamSummary | PlayerSummary,
  t?: { competitionId?: string; name: string; portraitLogo: string },
): McpMetricSelected => {
  const { metricPlayerName, team } = summary as PlayerSummary
  const { shortName, portraitLogo, name } = summary as TeamSummary
  const teamData = t || team || ({} as PlayerSummary)
  const [filterItem] = list
    .filter(({ text }) => text === key)
    .map((item) => ({
      leftValue: item.homeLabel,
      rightValue: item.awayLabel,
      leftUnit: item.homeUnit,
      rightUnit: item.awayUnit,
    }))

  return {
    dualData: { header, metric: filterItem as unknown as McpDualMetric },
    metricLabel: key,
    competition: summary.competition,
    imgUrl: SafeObjectData(header, 'leftContent.img', ''),
    name: SafeObjectData(header, 'leftContent.title', ''),
    nameWithShirtNumber: metricPlayerName,
    team: {
      shortName: (teamData as PlayerSummary).name || shortName || name,
      portraitLogo: teamData.portraitLogo || portraitLogo,
    },
  }
}
