import { Pipe, PipeTransform } from '@angular/core'
import { ConcatParamsPipe } from '@mediacoach/ui'
import { TranslateService } from '@ngx-translate/core'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate/multiple-translate.pipe'
import { translateKey } from '@core/utils/translate.utils'
import { isGoalKeeper, resolveGenericPosition } from './demarcation.utils'

@Pipe({
  name: 'demarcation',
})
export class DemarcationPipe<T = unknown> implements PipeTransform {
  concatParamsPipe = new ConcatParamsPipe()
  multipleTranslatePipe = new MultipleTranslatePipe(this.translate)

  constructor(private readonly translate: TranslateService) {}

  transform(
    value: T,
    params: string[],
    fallback: string | string[],
    divider: string = ' ',
    config?: { lang: string; isI18NLoadedChangeDetector?: number },
  ): string {
    if (value && params && fallback) {
      const item = value as any
      return isGoalKeeper(item)
        ? translateKey(this.translate, resolveGenericPosition(item), config?.lang)
        : this.multipleTranslatePipe.transform(
            this.concatParamsPipe.transform(item, params, fallback, divider),
            divider,
            ' - ',
            config,
          )
    }

    return undefined
  }
}
