<div class="mcp-tag-info__content-tag" [class.is-form-mode]="isFormMode">
  <div class="mcp-tag-info__content-tag-image" *ngIf="!isFormMode">
    <img
      class="mcp-tag-info__content-tag-image-logo"
      [mcuiDefaultImage]="placeholderImage"
      [srcUrl]="item?.image"
      [title]="item?.title"
    />
  </div>
  <div class="mcp-tag-info__content-tag-info">
    <div class="mcp-tag-info__content-tag-info__container">
      <div class="mcp-tag-info__content-tag-info__entity" *ngIf="isEntity">
        <div class="mcp-tag-info__label mcp-tag-info__label--subtitle">
          {{ item?.playerPosition || item?.subtitle || ('MTR_TITLE_TEAM' | translate) }}
        </div>
        <div class="mcp-tag-info__value mcp-tag-info__value--title">
          <img
            *ngIf="isFormMode"
            class="mcp-tag-info__content-tag-form-logo"
            [mcuiDefaultImage]="placeholderImage"
            [srcUrl]="item?.image"
            [title]="item?.title"
          />
          {{ item?.title }}
        </div>
      </div>
      <div class="mcp-tag-info__content-tag-info__event">
        <div class="mcp-tag-info__label mcp-tag-info__label--event">
          {{ 'MTR_TITLE_EVENT' | translate }}
        </div>
        <div class="mcp-tag-info__value mcp-tag-info__value--event">
          {{ item?.eventName }}
        </div>
      </div>
      <div class="mcp-tag-info__content-tag-info__time">
        <div class="mcp-tag-info__label mcp-tag-info__label--time">
          {{ 'MTR_TITLE_TIME' | translate }}
        </div>
        <div class="mcp-tag-info__value mcp-tag-info__value--time">
          {{ item?.timeDescription }}
        </div>
      </div>
      <div class="mcp-tag-info__content-tag-info__owner" *ngIf="item?.owner">
        <span class="mcp-tag-info__content-tag-info__owner-send-by">{{
          'MTR_TITLE_OWNER_SEND_BY' | translate
        }}</span>
        <span class="mcp-tag-info__content-tag-info__owner-name"
          >{{ item?.owner?.name }} {{ item?.owner?.surname }}</span
        >
        <mcui-avatar
          class="mcp-tag-info__content-tag-info__owner-avatar"
          [backgroundColor]="item?.owner?.color"
          [imageSource]="item?.owner?.avatarPath"
          title="{{ item?.owner?.name }} {{ item?.owner?.surname }}"
          [text]="item?.owner?.name | firstCharacter: item?.owner?.surname"
        ></mcui-avatar>
      </div>
    </div>
    <div
      class="mcp-tag-info__content-tag-info__comment"
      *ngIf="item && item['comment'] && !isFormMode"
    >
      <span class="mcp-tag-info__content-tag-info__comment-title">
        {{ 'MTR_TITLE_COMMENTS' | translate }}
      </span>
      {{ item && item['comment'] }}
    </div>
  </div>
</div>
<app-textarea
  *ngIf="isFormMode"
  [label]="'MTR_TITLE_COMMENTS' | translate"
  [ngModel]="item?.comment"
  (ngModelChange)="commentChange.emit($event)"
></app-textarea>
