import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { VimeoEvent } from '../enums/vimeo-player.enums'

export const VIMEO_EVENT_ANALYTICS_MAP = {
  [VimeoEvent.Play]: AnalyticsEvent.videoPlay,
  [VimeoEvent.Pause]: AnalyticsEvent.videoPause,
  [VimeoEvent.Ended]: AnalyticsEvent.videoEnded,
  [VimeoEvent.EnterPictureinPicture]: AnalyticsEvent.videoPiP,
  [VimeoEvent.LeavePictureinPicture]: AnalyticsEvent.videoPiP,
  [VimeoEvent.FullscreenChange]: AnalyticsEvent.videoFullscreen,
}
