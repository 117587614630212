import { UserManager } from 'oidc-client'
import { USER_MANAGER_SETTINGS } from '../constants'

export function OidcFactory() {
  let instance: UserManager
  if (!instance) {
    instance = new UserManager(USER_MANAGER_SETTINGS)
  }
  return instance
}
