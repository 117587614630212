<mcp-loader [loading]="competition?.loading" [noHeight]="true">
  <div class="mcp-competition-changer" data-qa-id="competition_change">
    <div class="mcp-competition-changer__wrapper">
      <div
        class="mcp-competition-changer__content"
        [mcpGA4Click]="{
          name: ga4Event,
          event_category: ga4Category,
          competition_id: competition?.value?.id,
        }"
        (click)="openSeasonCompetitionDialog()"
      >
        <img
          class="mcp-competition-changer__image"
          [srcUrl]="competition?.value?.portraitLogo"
          [appDefaultImage]="competitionPlaceholder"
          [title]="competition?.value?.name || ''"
        />
        <img
          class="mcp-competition-changer__image--landscape"
          [srcUrl]="competition?.value?.imageSrc"
          [appDefaultImage]="competitionPlaceholder"
          [title]="competition?.value?.name || ''"
        />
        <span>
          <i class="u-icon--loop"></i>
        </span>
      </div>
    </div>
  </div>
</mcp-loader>
<ng-container *ngIf="openSeasonCompetitionDialogSubject$ | async"></ng-container>
