import { UntilDestroy } from '@ngneat/until-destroy'
import { Directive, inject } from '@angular/core'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { Competition, Season } from '@core/models/dto/season.dto'
import { Store } from '@ngrx/store'
import { DialogConfig, DialogRef } from '@mediacoach/ui'
import {
  AgainstToMetadata,
  Widget,
  WidgetQuery,
  WidgetQueryCompare,
} from '@widgets/models/widget.models'

@UntilDestroy()
@Directive()
export abstract class DialogSeasonCompetitionSelectorBase {
  protected _store = inject(Store)
  protected _dialogRef = inject(DialogRef)
  protected readonly competitionPlaceholder: string = PLACEHOLDER_IMAGES.COMPETITION
  protected readonly seasons$ = this._store.select(getSeasons)

  protected selectedSeason: Partial<Season>
  protected selectedCompetition: Partial<Competition>

  protected get seasonId(): string {
    return this.data.widget?.query?.seasonId || this.data?.seasonId
  }

  protected get competitionId(): string {
    return this.data.widget?.query?.competitionId || this.data?.competitionId
  }

  protected get widget(): Widget {
    return this.data?.widget
  }

  protected get query(): WidgetQuery {
    return this.widget?.query
  }

  protected get againstToMetadata(): AgainstToMetadata {
    return this.widget?.displayMode?.againstToMetadata
  }

  protected get data() {
    return this._dialogConfig?.data
  }

  protected get compare() {
    return (
      this.query.compare && Array.isArray(this.query.compare)
        ? this.query.compare[0]
        : this.query.compare
    ) as WidgetQueryCompare
  }

  protected constructor(protected _dialogConfig: DialogConfig) {
    this.selectedSeason = { id: this.seasonId }
    this.selectedCompetition = { id: this.competitionId }
  }
}
