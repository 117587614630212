import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  selector: '[appTemplate]',
})
export class TemplateDirective {
  @Input('appTemplate') type: string

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.type
  }
}
