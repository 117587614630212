<app-modal
  #modal
  [modalHeader]="favCompetitionHeader"
  [modalBody]="favCompetitionBody"
  [hasCloseButton]="false"
  [closeOnBackdrop]="false"
>
  <ng-template #favCompetitionHeader>
    <div>{{ 'FILTERS_LAB_COMPETITIONS' | translate }}</div>
  </ng-template>
  <ng-template #favCompetitionBody>
    <mcp-competition-selector
      [competitions]="competitions"
      [currentCompetitionId]="selected"
      (competitionSelected)="onCompetitionSelected($event)"
    ></mcp-competition-selector>
    <div class="mcp-competition-dialog__footer-item">
      <button (click)="competitionSelected.emit(competition)">
        {{ 'P_COMMONS_CONTRACT_BTN_ACCEPT' | translate }}
      </button>
    </div>
  </ng-template>
</app-modal>
