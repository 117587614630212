import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { environment } from '@env'
import { AssetGroupConfig, AssetItem } from '@core/models/dto/assets.dto'
import { AnalyticsTrackEvent } from '@core/analytics/models/analytics.models'
import {
  REPORTS_WITH_TWO_TEAMS,
  REPORTS_WITH_TWO_VERSIONS,
  TWO_TEAM_CODES,
  VIDEO_EXTENSIONS,
} from '@core/constants/matches.constants'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { DownloadableAssetItemStreamEvent } from '../../models/downloadable-block.models'
import { GA_LITERALS_FOR_ASSET_TITLES } from '../../constants/downloadable-block.constants'

@Component({
  selector: 'mcp-downloadable-asset-item',
  templateUrl: './downloadable-asset-item.component.html',
  styleUrl: './downloadable-asset-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadableAssetItemComponent {
  readonly VIDEO_EXTENSIONS = VIDEO_EXTENSIONS
  readonly ALLOW_ASSET_STREAMING = environment.ALLOW_ASSET_STREAMING

  @Input() item: AssetItem
  @Input() assetGroup: AssetGroupConfig<AssetItem>
  @Output() streaming = new EventEmitter<DownloadableAssetItemStreamEvent>()
  @Output() analytics = new EventEmitter<AnalyticsTrackEvent>()

  emitStreaming(assetItem: AssetItem) {
    const { label } = this._getActionLabel(assetItem)

    this.streaming.emit({
      onDemandVideo: { finalUrl: assetItem.url },
      gaLabel: label,
    })
  }

  gaTrack(assetItem: AssetItem) {
    const contentTypeGroup = this._getContentTypeGroup(assetItem)
    const { contentType, extension } = assetItem
    let { action, label } = this._getActionLabel(assetItem)
    action = 'download_' + action
    label = 'Download ' + label

    if (REPORTS_WITH_TWO_TEAMS.includes(contentTypeGroup)) {
      const code = contentType.replace(contentTypeGroup, '')
      action += `_${TWO_TEAM_CODES[code]}`
      label += `${TWO_TEAM_CODES[code]}`
    } else if (REPORTS_WITH_TWO_VERSIONS.includes(contentTypeGroup)) {
      const quality = assetItem.quality.toLowerCase()
      action += `_${quality}_${extension}`
      label += `${quality} ${extension.toUpperCase()}`
    }

    this.analytics.emit(
      analyticsTrackEvent({
        eventName: null,
        eventParams: {
          eventCategory: 'download_portal',
          eventAction: action,
          eventLabel: label,
          eventValue: 10,
        },
      }),
    )
  }

  private _getContentTypeGroup(assetItem: AssetItem): string {
    return (this.assetGroup.contentTypeGroup || assetItem.contentTypeItem()) as string
  }

  private _getActionLabel(assetItem: AssetItem) {
    const contentTypeGroup = this._getContentTypeGroup(assetItem)
    return GA_LITERALS_FOR_ASSET_TITLES[contentTypeGroup]
  }
}
