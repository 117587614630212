@if (
  {
    text: text$ | async,
    textSearched: textSearched$ | async,
    seasons: seasons$ | async,
    loading: loading$ | async,
    results: results$ | async,
  };
  as data
) {
  <mcp-dialog-content-container
    [disabled]="
      ((!selectedSeason || selectedSeason?.id === seasonId) &&
        (!selectedCompetition || selectedCompetition?.id === competitionId) &&
        (!againstToItem || (!!againstToItem && againstToItem?.id === toCompareId)) &&
        (!vsCompetition || isVsCompetition === vsCompetition)) ||
      (!againstToItem && !vsCompetition)
    "
    (apply)="applySelection()"
  >
    <div class="mcp-dialog-vs-selector">
      <mcp-season-competition-selector
        [seasons]="data?.seasons"
        [seasonId]="seasonId"
        [competitionId]="competitionId"
        [forceEmitCompetition]="true"
        (competitionChange)="onCompetitionChange($event, data?.seasons); searchData()"
        (seasonChange)="onSeasonChange($event, data?.seasons)"
      ></mcp-season-competition-selector>
      <div class="mcp-widget-dialog__block--divider"></div>
      <div class="mcp-dialog-vs-selector__selector">
        <div class="mcp-dialog-vs-selector__selector-header">
          <div class="mcp-dialog-vs-selector__text-search">
            <app-input
              class="mcp-dialog-vs-selector__text-search-input"
              icon="search"
              [ngModel]="dialogConfig?.againstTo === 'player' ? data?.textSearched : null"
              [placeholder]="dialogConfig.searchTextPlaceholder | translate"
              (onKeyUp)="onSearch($event)"
            />
          </div>
          <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
          <div
            class="mcp-widget-dialog__item mcp-dialog-vs-selector__item-vs-competition"
            [class.mcp-widget-dialog__item--active]="vsCompetition === true"
            (click)="onCompetitionClick(data?.seasons)"
          >
            <span class="mcp-widget-dialog__item-content">
              vs
              {{
                aggregationTrans[
                  widget?.displayMode?.aggregation || widget?.displayMode?.rightAggregation
                ] | translate
              }}
              {{ 'MTR_COMPETITION' | translate }}
            </span>
          </div>
          <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
        </div>
        <div
          class="mcp-widget-dialog__block mcp-dialog-vs-selector__selector-block"
          *mcuiLoading="data?.loading"
        >
          @for (result of data?.results; track result.id) {
            <div
              class="mcp-widget-dialog__item mcp-dialog-vs-selector__item"
              [title]="result?.formattedName || result.shortName || result.name || ''"
              [class.mcp-widget-dialog__item--active]="result.id === againstToItem?.id"
              (click)="onItemClick(result, data?.seasons)"
              #selectorElement
            >
              @if (dialogConfig?.againstTo === 'team') {
                <img
                  class="mcp-dialog-vs-selector__item-image"
                  [appDefaultImage]="dialogConfig.imagePlaceholder"
                  [srcUrl]="result?.portraitLogo"
                  [alt]="result?.name"
                />
                <span
                  class="mcp-widget-dialog__item-content mcp-dialog-vs-selector__item-content"
                  [innerHTML]="
                    data?.textSearched?.length >= spaceMinCharToFilter
                      ? (result.shortName || result.name | highlight: data?.textSearched : true)
                      : result.shortName || result.name
                  "
                ></span>
              } @else {
                <mc-player-list-item [data]="result"></mc-player-list-item>
              }
              <div class="mcp-dialog-vs-selector__selector-header--divider"></div>
            </div>
          } @empty {
            <span
              class="mcp-dialog-vs-selector__no-results"
              *ngIf="data?.textSearched?.length >= spaceMinCharToFilter"
              >{{ 'MTR_COMMON_NO_RESULTS' | translate }}</span
            >
          }
        </div>
      </div>
    </div>
  </mcp-dialog-content-container>
}
