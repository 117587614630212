import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core'
import { CdkListbox } from '@angular/cdk/listbox'
import { SelectionModel } from '@angular/cdk/collections'
import { PlaylistResponse } from '@features/playlist/models/playlist.response.models'

@Component({
  selector: 'mcp-playlists-share',
  templateUrl: './playlists-share.component.html',
  styleUrl: './playlists-share.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistsShareComponent {
  @ViewChild(CdkListbox) list: CdkListbox
  @Input() playlists: PlaylistResponse[] = []

  get allSelected(): boolean {
    return this.selection.selected.length === this.playlists?.length
  }

  get selected() {
    return this.selection.selected
  }

  readonly defaultAvatarColor = '#a1a1a1'
  readonly selection = new SelectionModel<PlaylistResponse>(true, [])

  onCheckChange(): void {
    if (this.allSelected) {
      this.selection.clear()
    } else {
      this.selection.select(...this.playlists)
    }
  }
}
