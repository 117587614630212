import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { Store } from '@ngrx/store'
import { selectUserHeaderData } from '@core/state/selectors/user.selectors'
import { map, switchMap } from 'rxjs/operators'
import { completeWhen } from '@shared/operators/complete-when.operator'

const AUTH_DOMAINS = [
  environment.API.MAIN_PATH,
  environment.INTEGRATED_API.MAIN_PATH,
  environment.APIM_API.MAIN_PATH,
]

@Injectable()
export class UserInterceptor implements HttpInterceptor {
  private _userHeader$ = this._store
    .select(selectUserHeaderData)
    .pipe(completeWhen(({ token }) => !!token))

  constructor(private readonly _store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AUTH_DOMAINS.some((domain) => request.url.indexOf(domain) === 0)) {
      return this._userHeader$.pipe(
        map(({ token, language }) => {
          let headers = request.headers.set(
            'Authorization',
            `${environment.API_KEY_SECURE} ${token}`,
          )

          if (language) {
            headers = headers.append('language', language)
          }

          if (request.detectContentTypeHeader() === 'text/plain') {
            headers = headers.set('Content-Type', 'application/json')
          }

          return request.clone({ headers })
        }),
        switchMap((req: HttpRequest<any>) => next.handle(req)),
      )
    }
    return next.handle(request)
  }
}
