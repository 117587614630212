import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { startupApplication } from '@core/state/actions/startup.actions'
import { delay, switchMap, tap } from 'rxjs/operators'
import { setOidcUser } from '@core/state/actions/oidc.actions'
import { fetchUserProfile } from '@core/state/actions/profile.actions'
import { Store } from '@ngrx/store'
import { connectSocket } from '@core/state/actions/socket.actions'

@Injectable()
export class StartupEffects {
  startup$ = createEffect(() =>
    this._actions$.pipe(
      ofType(startupApplication),
      tap(({ oidc }) => this._store.dispatch(setOidcUser({ oidc }))),
      delay(0),
      switchMap(() => [connectSocket(), fetchUserProfile()]),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
