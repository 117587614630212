export const STEP_MIN_WIDTH = 200
export const STEP_MAX_WIDTH = 400
export const CUSTOM_STEP_MAX_WIDTH_VW = 90
export const STEP_HEIGHT = 200
export const ASPECT_RATIO = 1.212
export const DEFAULT_DISTANCE_FROM_MARGIN_TOP = 2
export const DEFAULT_DISTANCE_FROM_MARGIN_LEFT = 2
export const DEFAULT_DISTANCE_FROM_MARGIN_BOTTOM = 5
export const DEFAULT_DISTANCE_FROM_MARGIN_RIGHT = 5
export const SCROLLBAR_SIZE = 20
export const DISTANCE_FROM_TARGET = 15
export const ARROW_SIZE = 10
export const ROUTE_SEPARATOR = '@'
export const DEFAULT_THEME_COLOR = '#061601'
export const STEP_DEFAULT_POSITION = 'bottom'
export const DEFAULT_TIMEOUT_BETWEEN_STEPS = 10
export const NO_POSITION = 'NO_POSITION'
export const DEFAULT_STEP_BLUR = '5px'

export const DEFAULT_PREV_TEXT = 'prev'
export const DEFAULT_NEXT_TEXT = 'next'
export const DEFAULT_DONE_TEXT = 'done'
export const DEFAULT_CLOSE_TEXT = 'close'
export const DEFAULT_PROGRESS_TEXT = 'step'
