import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { unauthorizedError } from '@core/state/actions/error.actions'
import { switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { getUser } from '@core/state/selectors/user.selectors'
import { isValidToken } from '@auth/utils/auth.utils'
import { logOut, signInSilent } from '@core/state/actions/oidc.actions'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { environment } from '@env'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'

@Injectable()
export class ErrorEffects {
  unauthorizedError$ = createEffect(() =>
    this._actions$.pipe(
      ofType(unauthorizedError),
      switchMap(() => this._store.select(getUser)),
      switchMap((user) => {
        if (isValidToken(user, 0)) {
          return [
            analyticsTrackEvent({
              eventName: AnalyticsEvent.concurrentUser,
              eventParams: {
                [AnalyticsParam.category]: AnalyticsCategory.auth,
              },
            }),
            logOut(`${environment.ORIGIN_DOMAIN}/concurrencia-usuario`),
          ]
        } else {
          return [
            analyticsTrackEvent({
              eventName: AnalyticsEvent.unAuthorizedUser,
              eventParams: {
                [AnalyticsParam.category]: AnalyticsCategory.auth,
              },
            }),
            signInSilent(),
          ]
        }
      }),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
