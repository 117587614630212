import { Component } from '@angular/core'

import { AppPermissionType, PermissionType } from '@core/enums/permissions.enum'

import { tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { getProfile } from '@core/state/selectors/user.selectors'
import { navigate } from '@core/router/state/actions/router.actions'
import { completeWhen } from '@shared/operators/complete-when.operator'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  // redirectOnUserProfile$ = this.authService.getUserProfileData$().pipe(
  redirectOnUserProfile$ = this._store.select(getProfile).pipe(
    completeWhen((userProfile) => Object.keys(userProfile).length > 0),
    tap((userProfile) => {
      const routeUrl = userProfile[PermissionType.ApplicationPermissions][
        AppPermissionType.Route
      ].find((url) => !url.includes('.+'))
      // this.router.navigate(['/' + routeUrl])
      this._store.dispatch(navigate({ path: '/' + routeUrl }))
    }),
  )

  constructor(private readonly _store: Store) {}
}
