import { NgModule, Optional, Self } from '@angular/core'
import { RouterEffects } from '@core/router/state/effects/router.effects'
import {
  routerReducer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { MergedRouterStateSerializer } from '@core/router/merged-route.serializer'
import { Router } from '@angular/router'
import { FEATURE_TOKEN, ROUTER_CONFIG } from '@core/router/constants'
import { environment } from '@env'

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_TOKEN, routerReducer),
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule.forRoot(ROUTER_CONFIG),
  ],
  exports: [StoreModule, StoreRouterConnectingModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
  ],
})
export class NgrxRouterStoreModule {
  constructor(@Self() @Optional() router: Router) {
    if (!environment.production) {
      if (router) {
        console.log('All good, NgrxRouterStoreModule')
      } else {
        console.error(
          'NgrxRouterStoreModule must be imported in the same same level as RouterModule',
        )
      }
    }
  }
}
