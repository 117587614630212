import { Observable, OperatorFunction } from 'rxjs'
import { filter, take } from 'rxjs/operators'

export function completeWhen<T>(
  predicate: (value: T, index: number) => boolean,
): OperatorFunction<T, T> {
  return function (source: Observable<T>): Observable<T> {
    return source.pipe(filter(predicate), take(1))
  }
}
