import { Column, SortDirection } from '../models/grid.models'

export const mapGridColumn = (column) => ({
  ...column,
  tooltipLabel: column?.label?.replace(new RegExp(/_SHORT/gi), ''),
})

export const getNewDirection = (c: Column): SortDirection => {
  if (!c.sortDirection || c.sortDirection === 'def') {
    return 'asc'
  } else if (c.sortDirection === 'asc') {
    return 'desc'
  } else {
    return 'def'
  }
}

export const defaultTrackBy = (index: number): unknown => index

export const compareStringToSort = (a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0)

export const sortFnBy = (
  a: any,
  b: any,
  sortDirection: SortDirection,
  attribute: string,
): number => (sortDirection === 'desc' ? b[attribute] - a[attribute] : a[attribute] - b[attribute])
