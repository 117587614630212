import { environment } from '@env'
import { AssetExtension, AssetFile, AssetGroupType, AssetOS } from '@core/enums/assets.enum'
import { UserProfileType } from '@mediacoach-ui-library/global'

export const DOWNLOADS_FEATURE_TOKEN = 'downloads'

export const ASSET_GROUP_TYPE = 'groupType'

export const FILES_WITH_VERSION = [
  {
    path: environment.DOWNLOADS_VERSION_FILE_PATH.desktop.win,
    name: AssetFile.MCDesktopWinInstaller,
  },
  {
    path: environment.DOWNLOADS_VERSION_FILE_PATH.desktop.mac,
    name: AssetFile.MCDesktopMacInstaller,
  },
  {
    path: environment.DOWNLOADS_VERSION_FILE_PATH.mobile.ios,
    name: AssetFile.MobileIOS,
  },
  {
    path: environment.DOWNLOADS_VERSION_FILE_PATH.mobile.android,
    name: AssetFile.MobileAndroid,
  },
  {
    path: environment.DOWNLOADS_VERSION_FILE_PATH.livePro.ios,
    name: AssetFile.MCLiveProIOS,
  },
]

export const DOWNLOAD_ELEMENT_CONFIG = [
  {
    src: 'img/portal/downloads/downloads_sample-img_desktop.png',
    logo: 'img/portal/downloads/MC_logo_Desktop.svg',
    title: 'DOWNLOAD_PRODUCTS_LAB_PROD1',
    assetGroup: [
      AssetGroupType.DesktopInstaller,
      AssetGroupType.DesktopManual,
      AssetGroupType.DesktopUtility,
    ],
  },
  {
    src: 'img/portal/downloads/downloads_sample-img_mobile.png',
    logo: 'img/portal/downloads/MC_logo_Mobile.svg',
    title: 'DOWNLOAD_PRODUCTS_LAB_MOBILE',
    assetGroup: [AssetGroupType.MobileInstaller, AssetGroupType.MobileManual],
  },
  {
    src: 'img/portal/downloads/downloads_sample-img_livepro.png',
    logo: 'img/portal/downloads/MC_logo_LivePro.svg',
    title: 'DOWNLOAD_PRODUCTS_LAB_LIVE_PRO',
    assetGroup: [AssetGroupType.LiveProInstaller, AssetGroupType.LiveProManual],
  },
  {
    src: 'img/portal/downloads/downloads_sample-img_portal.png',
    logo: 'img/portal/downloads/MC_logo_Portal.svg',
    title: 'HOME_MCPRODUCTS_LAB_MAIN13',
    assetGroup: [AssetGroupType.PortalManual],
  },
]

export const ASSET_GROUP_CONFIG = {
  [AssetGroupType.DesktopInstaller]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_INSTALL',
    background: true,
  },
  [AssetGroupType.DesktopManual]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_MANUALS',
    background: true,
  },
  [AssetGroupType.DesktopUtility]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_ADDONS',
    background: true,
  },
  [AssetGroupType.PortalManual]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_MANUALS',
    background: true,
  },
  [AssetGroupType.MobileInstaller]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_INSTALL',
    background: true,
  },
  [AssetGroupType.MobileManual]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_MANUALS',
    background: true,
  },
  [AssetGroupType.LiveProInstaller]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_INSTALL',
    background: true,
  },
  [AssetGroupType.LiveProManual]: {
    title: 'DOWNLOAD_PRODUCTS_LAB_MANUALS',
    background: true,
  },
}

export const ASSET_CONFIG = {
  [AssetFile.MCDesktopWinInstaller]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_INSTALLERS2_PROD1',
    icon: 'windows',
  },
  [AssetFile.MCDesktopMacInstaller]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_INSTALLERS2_PROD1',
    icon: 'apple',
  },
  [AssetFile.MCDesktopWinInstallManualEn]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'windows',
  },
  [AssetFile.MCDesktopWinInstallManualEs]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'windows',
  },
  [AssetFile.MCDesktopMacInstallManualEn]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'apple',
  },
  [AssetFile.MCDesktopMacInstallManualEs]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'apple',
  },
  [AssetFile.TeamViewer]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_ADDONS1',
  },
  [AssetFile.HASPUserSetup]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_ADDONS2',
    icon: 'windows',
  },
  [AssetFile.SentinelRuntime]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_ADDONS2',
    icon: 'apple',
  },
  [AssetFile.MCPortalReportManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_REPORT_PROD3',
  },
  [AssetFile.PCReportManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_REPORT2_PROD3',
  },
  [AssetFile.WimuUserManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_WIMU_MANUAL',
  },
  [AssetFile.MCPowerBiWCSManual]: {
    text: 'DOWNLOAD_PRODUCTS_POWER_BI_WCS_MANUAL',
  },
  [AssetFile.MCWCSManual]: {
    text: 'DOWNLOAD_PRODUCTS_WCS_MANUAL',
  },
  [AssetFile.MCBeyondStatsManual]: {
    text: 'DOWNLOAD_PRODUCTS_BEYOND_STATS_MANUAL',
  },
  [AssetFile.MCAnalysisMediacoahManual]: {
    text: 'DOWNLOAD_PRODUCTS_ANALYSIS_MEDIACOACH_MANUAL',
  },
  [AssetFile.MCPlayerDemarcations]: {
    text: 'DOWNLOAD_PRODUCTS_PLAYER_DEMARCATIONS_MANUAL',
  },
  [AssetFile.MCPlayModels]: {
    text: 'DOWNLOAD_PRODUCTS_PLAY_MODELS_MANUAL',
  },
  [AssetFile.MobileIOS]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MOBILE_INSTALLERS',
    icon: 'apple',
  },
  [AssetFile.MobileAndroid]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MOBILE_INSTALLERS',
    icon: 'android',
  },
  [AssetFile.MobileIOSManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'apple',
  },
  [AssetFile.MobileAndroidManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'android',
  },
  [AssetFile.MobileUserManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD1',
  },
  [AssetFile.MCLiveProIOS]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_LIVE_PRO_INSTALLERS',
    icon: 'apple',
  },
  [AssetFile.McLiveProIOSManual]: {
    text: 'DOWNLOAD_PRODUCTS_LAB_MANUALS_PROD2',
    icon: 'apple',
  },
}
export const ASSET_ITEM_CONFIG = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TeamViewer: {
    extension: 'LINK',
  },
}

export const DOWNLOADABLE_EXTENSIONS = [
  AssetExtension.EXE,
  AssetExtension.APK,
  AssetExtension.IOS,
  AssetExtension.DMG,
]

export const ASSET_GROUP_APPLICATION_MAPPING = {
  [AssetGroupType.DesktopInstaller]: UserProfileType.DesktopUserProfile,
  [AssetGroupType.DesktopManual]: UserProfileType.DesktopUserProfile,
  [AssetGroupType.DesktopUtility]: UserProfileType.DesktopUserProfile,
  [AssetGroupType.PortalManual]: UserProfileType.PortalUserProfile,
  [AssetGroupType.MobileInstaller]: UserProfileType.MobileUserProfile,
  [AssetGroupType.MobileManual]: UserProfileType.MobileUserProfile,
  [AssetGroupType.LiveProInstaller]: UserProfileType.LiveProUserProfile,
  [AssetGroupType.LiveProManual]: UserProfileType.LiveProUserProfile,
}

export const ASSET_EXTENSION_OS_MAPPING = {
  [AssetExtension.EXE]: AssetOS.Windows,
  [AssetExtension.APK]: AssetOS.Android,
  [AssetExtension.IOS]: AssetOS.IOS,
  [AssetExtension.DMG]: AssetOS.MacOS,
}

export const TEAMVIEWER_INSTALLER_NAME = 'TeamViewerQS'
export const DONGLE_DRIVER_INSTALLER_NAME = 'Sentinel Runtime'

export const USER_PROFILE_RGX = /userprofile/i
export const TEAMVIEWER_RGX = /teamviewer/i
