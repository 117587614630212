import {
  DEFAULT_DISTANCE_FROM_MARGIN_BOTTOM,
  DEFAULT_DISTANCE_FROM_MARGIN_LEFT,
  DEFAULT_DISTANCE_FROM_MARGIN_RIGHT,
  DEFAULT_DISTANCE_FROM_MARGIN_TOP,
} from '@shared/modules/tour/constants/tour.constants'
import { ElementDimensions } from '@shared/modules/tour/models/tour-dom.models'

export const adjustLeftPosition = (
  stepLeft: number,
  arrowLeft: number,
): { stepLeft: number; arrowLeft: number } => {
  if (stepLeft < 0) {
    arrowLeft = arrowLeft + stepLeft - DEFAULT_DISTANCE_FROM_MARGIN_LEFT
    stepLeft = DEFAULT_DISTANCE_FROM_MARGIN_LEFT
  }
  return { stepLeft, arrowLeft }
}

export const adjustRightPosition = (
  { stepLeft, arrowLeft }: { stepLeft: number; arrowLeft: number },
  { width, absoluteLeft }: ElementDimensions,
  windowWidth: number,
): { stepLeft: number; arrowLeft: number } => {
  if (absoluteLeft + width > windowWidth) {
    const newLeftPos =
      stepLeft - (absoluteLeft + width + DEFAULT_DISTANCE_FROM_MARGIN_RIGHT - windowWidth)
    const deltaLeftPosition = newLeftPos - stepLeft

    stepLeft = newLeftPos
    arrowLeft = arrowLeft - deltaLeftPosition
  }
  return { stepLeft, arrowLeft }
}

export const adjustTopPosition = (
  stepTop: number,
  arrowTop: number,
  absoluteTop: number,
): { stepTop: number; arrowTop: number } => {
  if (absoluteTop < 0) {
    arrowTop = arrowTop + stepTop - DEFAULT_DISTANCE_FROM_MARGIN_TOP
    stepTop = DEFAULT_DISTANCE_FROM_MARGIN_TOP
  }
  return { stepTop, arrowTop }
}

export const adjustBottomPosition = (
  { stepTop, arrowTop }: { stepTop: number; arrowTop: number },
  { height, absoluteTop }: ElementDimensions,
  documentHeight: number,
): { stepTop: number; arrowTop: number } => {
  if (absoluteTop + height > documentHeight) {
    const newTopPos =
      stepTop - (absoluteTop + height + DEFAULT_DISTANCE_FROM_MARGIN_BOTTOM - documentHeight)
    const deltaTopPosition = newTopPos - stepTop

    stepTop = newTopPos
    arrowTop = arrowTop - deltaTopPosition
  }
  return { stepTop, arrowTop }
}
