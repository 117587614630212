<div class="mcp-competition-selector">
  <div class="mcp-competition-selector__label">
    <label>{{ 'P_COMMONS_CONTRACT_LAB_COMPETITION' | translate }}</label>
  </div>
  <div class="mcp-competition-selector__wrapper" *ngIf="competitions; else loadingCompetitions">
    <div
      *ngFor="let competition of competitions"
      class="mcp-competition-selector__item"
      (click)="competitionSelected.emit(competition)"
      [class.is-selected]="currentCompetitionId === competition?.id"
    >
      <img
        class="mcp-competition-selector__item-image"
        [srcUrl]="competition.portraitLogo"
        [appDefaultImage]="competitionPlaceholder"
        [mcuiTooltip]="competition?.name"
        [tooltipDisabled]="!competition?.name"
      />
    </div>
  </div>

  <ng-template #loadingCompetitions>
    <app-loading></app-loading>
  </ng-template>
</div>
