import { Directive, HostBinding, Input, OnInit } from '@angular/core'

const EXECUTABLE_EXTENSIONS = ['DMG', 'EXE', 'APK', 'IOS']

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[href][download][extension]',
})
export class DownloadExecutableLinkDirective implements OnInit {
  @HostBinding('attr.target') targetAttr
  @Input() extension: string

  ngOnInit(): void {
    this.targetAttr = '_blank'
  }
}
