import { MetricAggregationModeSelectable } from '../models/models/metric.models'
import { MetricAggregation } from '../enums/metric.enums'

export const COMMON_AGGREGATION_MODES: MetricAggregationModeSelectable[] = [
  { id: MetricAggregation.AccumulatedValues, text: 'MTR_COMMON_ACCUMULATED' },
  {
    id: MetricAggregation.AverageValues,
    text: 'MTR_COMMON_AVERAGE',
    alt: 'MTR_COMMON_AVERAGE_COMPETITION',
  },
]
export const MATCH_CONTEXT_AGGREGATION_MODES: MetricAggregationModeSelectable[] = [
  ...COMMON_AGGREGATION_MODES,
  { id: MetricAggregation.MatchValues, text: 'MTR_COMMON_MATCH_VALUE' },
]

export const AGGREGATED_METRIC_LABEL_MAP = {
  [MetricAggregation.AverageValues]: 'MTR_COMMON_AVERAGE',
  [MetricAggregation.AccumulatedValues]: 'MTR_COMMON_ACCUMULATED',
  [MetricAggregation.MatchValues]: 'MTR_COMMON_MATCH_VALUE',
}
