import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { debounce, DialogConfig } from '@mediacoach/ui'
import {
  setSpaceDialogSearchResults,
  setSpaceDialogSearchScope,
  setSpaceDialogSearchText,
} from '@core/space/state/actions/space.actions'
import {
  selectSpaceDialogSearchLoading,
  selectSpaceDialogSearchText,
} from '@core/space/state/selectors/space.selectors'
import { Subject } from 'rxjs'
import { debounceTime, startWith, tap } from 'rxjs/operators'
import {
  WidgetDialogConfig,
  WidgetDisplayMode,
  WidgetQuery,
  WidgetQueryCompare,
} from '@widgets/models/widget.models'
import { WidgetIdentifier } from '@widgets/enums/widget-identifier.enum'
import { SPACE_MIN_CHAR_TO_FILTER } from '@core/space/utils/space-dialog.utils'
import { WIDGET_AGGREGATION_I18N_MAP } from '@widgets/constants/widget.constants'
import { scrollByElementList } from '@core/utils/dom.utils'
import { DialogSeasonCompetitionSelectorBase } from '../../base-classes/dialog-season-competition-selector.base'
import { Season } from '@core/models/dto/season.dto'
import { getSeasonAndCompetitionById } from '@core/utils/season.utils'
import {
  getStaticItem,
  smallColorLandscapeLogoPredicate,
  smallColorLogoPredicate,
} from '@core/utils/assets.utils'
import * as _ from 'lodash'
import { isWidgetOfType } from '@widgets/utils/widget.utils'
import { Competition } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-dialog-vs-selector',
  templateUrl: './dialog-vs-selector.component.html',
  styleUrl: './dialog-vs-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogVsSelectorComponent
  extends DialogSeasonCompetitionSelectorBase
  implements OnDestroy
{
  private readonly _excludedProperties = [
    'seasonName',
    'competitionName',
    'competitionLandscapeLogo',
    'competitionPortraitLogo',
    'teamAbbreviation',
    'teamName',
    'teamPortraitLogo',
    'playerMetricName',
    'playerPortraitLogo',
    'playerPosition',
  ]

  @ViewChildren('selectorElement') selectorElements: QueryList<ElementRef>
  readonly results$ = this._store
    .select(this.dialogConfig.resultsSelector)
    .pipe(tap((results) => this._handleAutoSelection(results)))
  readonly textSearched$ = this._store.select(selectSpaceDialogSearchText)
  readonly loading$ = this._store.select(selectSpaceDialogSearchLoading)
  readonly textSubject$: Subject<string> = new Subject<string>()
  readonly text$ = this.textSubject$.pipe(
    debounceTime(500),
    startWith(
      this.dialogConfig?.againstTo === 'player' &&
        this.againstToMetadata?.label &&
        !this.dialogConfig.preventItemAutoSelection
        ? this.againstToMetadata?.label
        : '',
    ),
    tap((text) => this._store.dispatch(setSpaceDialogSearchText({ text: (text || '').trim() }))),
  )
  readonly aggregationTrans = WIDGET_AGGREGATION_I18N_MAP
  readonly spaceMinCharToFilter = SPACE_MIN_CHAR_TO_FILTER

  againstToItem: any
  vsCompetition: boolean
  isVsCompetition: boolean

  override get seasonId(): string {
    return this.compare?.seasonId || this.data?.seasonId
  }

  override get competitionId(): string {
    return this.compare?.competitionId || this.data?.competitionId
  }

  get dialogConfig(): WidgetDialogConfig {
    return this.data?.config
  }
  get toCompareId() {
    return (this.compare || {})[this.dialogConfig.againstToProperty]
  }

  get queryCompareByAgainstTo() {
    return this.dialogConfig.againstTo === 'player'
      ? {
          teamId: this.againstToItem.teamId || this.compare?.teamId,
          [this.dialogConfig.againstToProperty]: this.againstToItem?.id,
        }
      : { [this.dialogConfig.againstToProperty]: this.againstToItem?.id }
  }

  private get _comparisonDynamicContent() {
    return {
      teamId: this.againstToItem.teamId || this.againstToItem.id,
      teamAbbreviation: this.againstToItem.teamAbbreviation || this.againstToItem.abbreviation,
      teamName: this.againstToItem.teamName || this.againstToItem.shortName,
      teamPortraitLogo: getStaticItem(
        this.againstToItem.teamStatics || this.againstToItem.statics,
        smallColorLogoPredicate,
      ),

      playerId: this.againstToItem.teamId ? this.againstToItem.id : undefined,
      playerPortraitLogo: this.againstToItem.teamId ? this.againstToItem.portraitLogo : undefined,
      playerMetricName: this.againstToItem.metricPlayerName,
      playerPosition: this.againstToItem.playerPositionKey,
    }
  }

  private get _comparisonCommonContext() {
    return {
      uuid: crypto.randomUUID(),
      seasonId: this.selectedSeason.id,
      competitionId: this.selectedCompetition.id,
      seasonCompetitionId: this.selectedCompetition.seasonCompetitionId,
      seasonName: this.selectedSeason.officialName,
      competitionName: this.selectedCompetition.officialName,
      competitionLandscapeLogo: getStaticItem(
        this.selectedCompetition.statics,
        smallColorLandscapeLogoPredicate,
      ),
      competitionPortraitLogo: getStaticItem(
        this.selectedCompetition.statics,
        smallColorLogoPredicate,
      ),
    }
  }

  private get _isSpiderChartWidget() {
    return isWidgetOfType(this.widget, [
      WidgetIdentifier.teamStats,
      WidgetIdentifier.playerStats,
      WidgetIdentifier.goalkeeperStats,
    ])
  }

  private get _includeSeasonCompetition() {
    return this._isSpiderChartWidget ? {} : { includeSeasonCompetition: !!this.vsCompetition }
  }

  constructor(_dialogConfig: DialogConfig) {
    super(_dialogConfig)
    this._store.dispatch(setSpaceDialogSearchScope({ scope: this.dialogConfig.againstTo }))
    this.isVsCompetition = this.againstToMetadata?.vsCompetition || !this.query?.compare
    this.vsCompetition = this.againstToMetadata?.vsCompetition || !this.query?.compare
    this.searchData()
  }

  ngOnDestroy() {
    this._store.dispatch(setSpaceDialogSearchText({ text: null }))
    this._store.dispatch(setSpaceDialogSearchResults({ results: null }))
  }

  applySelection() {
    const comparison = this._resolveComparePayload({
      ...this._comparisonCommonContext,
      ...(!this.vsCompetition ? this._comparisonDynamicContent : {}),
      ...(!this.vsCompetition ? this.queryCompareByAgainstTo : {}),
    })

    const query = this._buildQuery(comparison)
    const displayMode = this._buildDisplayMode(comparison)

    this._dialogRef.close({ ...this.widget, query, displayMode })
  }

  searchData() {
    this._store.dispatch(
      this.dialogConfig.searchAction({
        seasonId: this.selectedSeason.id,
        competitionId: this.selectedCompetition.id,
      }),
    )
  }

  onSearch(text: string) {
    this.textSubject$.next(text)
  }

  onCompetitionClick(seasons: Season[]) {
    this.vsCompetition = true
    this.againstToItem = null
    this._setSelectedSeasonAndCompetition(seasons)
  }

  onItemClick(selection: any, seasons: Season[]) {
    this.againstToItem = selection
    this.vsCompetition = false
    this._setSelectedSeasonAndCompetition(seasons)
  }

  onSeasonChange(selection: Season, seasons: Season[]) {
    this.selectedSeason = selection
    this._setSelectedSeasonAndCompetition(seasons)
    this.searchData()
  }

  onCompetitionChange(selection: Competition, seasons: Season[]) {
    this.selectedCompetition = selection
    this._setSelectedSeasonAndCompetition(seasons)
    this.searchData()
  }

  @debounce(0)
  private _autoScrollToActiveItems() {
    scrollByElementList(this.selectorElements, 'mcp-widget-dialog__item--active')
  }

  private _handleAutoSelection(results: any) {
    if (!this.dialogConfig.preventItemAutoSelection) {
      if (
        results?.length &&
        this.againstToItem &&
        !results?.find(({ id }) => id === this.againstToItem.id)
      ) {
        this.againstToItem = null
      }
      if (!this.vsCompetition && this.compare && results?.length) {
        this.againstToItem = results.find(({ id }) => id === this.toCompareId)
      }
      this._autoScrollToActiveItems()
    }
  }

  private _buildQuery(comparison: WidgetQueryCompare[]): WidgetQuery {
    return {
      ...this.query,
      ...this._includeSeasonCompetition,
      compare: comparison.map((c) => _.omit(c, this._excludedProperties)) as WidgetQueryCompare[],
    }
  }
  private _buildDisplayMode(comparison: WidgetQueryCompare[]): WidgetDisplayMode {
    return {
      ...this.widget.displayMode,
      comparison,
      againstToMetadata: !this.vsCompetition
        ? {
            label: this.againstToItem?.formattedName || this.againstToItem?.name,
            image: this.againstToItem?.portraitLogo,
          }
        : { vsCompetition: true },
      againstTo: !this.vsCompetition ? this.dialogConfig.againstTo : 'competition',
    }
  }

  private _setSelectedSeasonAndCompetition(seasons: Season[]) {
    const { season, competition } = getSeasonAndCompetitionById(
      this.selectedSeason.id,
      this.selectedCompetition.id,
      seasons,
    )

    this.selectedSeason = season
    this.selectedCompetition = competition
  }

  private _resolveComparePayload(comparison: WidgetQueryCompare) {
    if (this._isSpiderChartWidget) {
      const currentComparison = this.widget.displayMode.comparison || []
      const alreadyExist = currentComparison.some((c) => {
        let entityMatch: boolean
        if (this.dialogConfig.againstTo === 'player') {
          entityMatch =
            c.teamId === comparison.teamId &&
            c[this.dialogConfig.againstToProperty] ===
              comparison[this.dialogConfig.againstToProperty]
        } else {
          entityMatch =
            c[this.dialogConfig.againstToProperty] ===
            comparison[this.dialogConfig.againstToProperty]
        }
        return (
          c.seasonId === comparison.seasonId &&
          c.competitionId === comparison.competitionId &&
          entityMatch
        )
      })

      if (!alreadyExist) {
        return [...currentComparison, comparison]
      }
    }
    return [comparison]
  }
}
