<app-modal
  #modal
  [modalHeader]="profileHeaderTemplate"
  [modalBody]="profileBodyTemplate"
  size="profile"
  [modalFooter]="profileFooterTemplate"
  class="mcp-profile-dialog"
  (onClose)="picErrors = []"
>
  <ng-template #profileHeaderTemplate>
    {{ 'P_COMMONS_PROFILE_SECTION_TITLE' | translate }}
  </ng-template>
  <ng-template #profileBodyTemplate>
    <form class="mcp-profile-dialog__form" [formGroup]="form">
      <div class="mcp-profile-dialog__form-body">
        <div
          *ngIf="picErrors.length > 0"
          class="mcp-profile-dialog__form-errors alert alert-danger"
          role="alert"
        >
          <label *ngFor="let error of picErrors">{{ error }}</label>
        </div>
        <div class="mcp-profile-dialog__profile">
          <div class="mcp-profile-dialog__profile-item">
            <div class="mcp-profile-dialog__profile-avatar p-col-fixed">
              <app-file-upload
                *ngIf="modal.isOpen"
                (errorsEvent)="picErrors = $event"
                (filesSelectedEvent)="onFileSelected($event)"
                [avatarProps]="{
                  title: profile?.name || '',
                  color: profile?.color,
                  text: profile?.name | firstCharacter: profile?.surname,
                }"
                [base64Value]="profile?.avatar"
              >
              </app-file-upload>
            </div>
          </div>
          <div class="mcp-profile-dialog__profile-item">
            <app-input-container
              class="mcp-profile-dialog__profile-sub-item"
              label="{{ field.label | translate }}"
              [hasError]="!!validationMessages[field.name]"
              *ngFor="let field of formFields"
            >
              <input
                *ngIf="field.type === 'text'"
                class="form-control input-text"
                input-template="input"
                [formControlName]="field.name"
              />
              <div *ngIf="validationMessages" input-template="error">
                {{ validationMessages[field.name] | translate }}
              </div>
            </app-input-container>
            <div class="mcp-profile-dialog__profile-sub-item">
              <div
                class="mcp-profile-dialog__profile-sub-item mcp-profile-dialog__profile-language"
              >
                <mcui-select
                  formControlName="language"
                  label="{{ 'P_COMMONS_PROFILE_SELECT_LANGUAGE' | translate }}"
                  [options]="languages"
                  [optionSelectedTemplate]="selected"
                  [optionTemplate]="option"
                >
                  <ng-template #selected let-displayText="displayText">{{
                    displayText | translate
                  }}</ng-template>
                  <ng-template #option let-item="item">
                    <span [translate]="item.label"></span>
                  </ng-template>
                </mcui-select>
              </div>
            </div>
          </div>
          <div class="mcp-profile-dialog__profile-item">
            <div class="mcp-profile-dialog__profile-team">
              <img
                class="mcp-profile-dialog__profile-team-image"
                [srcUrl]="profile?.clubTeamLogoUrl"
                [appDefaultImage]="teamPlaceholder"
              />
              <label class="mcp-profile-dialog__profile-team-name">{{
                'P_COMMONS_PROFILE_LAB_MYTEAM' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="mcp-profile-dialog__profile-preferences">
        <mcp-competition-selector
          [competitions]="competitions"
          [currentCompetitionId]="form?.get('favourites')?.get('competitionId')?.value"
          (competitionSelected)="onCompetitionSelected($event)"
        ></mcp-competition-selector>
        <div class="mcp-profile-dialog__profile-save mcp-profile-dialog__footer-item">
          <button
            class="button-profile"
            (click)="save.emit(form)"
            [disabled]="!(form.dirty && form.valid)"
          >
            {{ 'P_COMMONS_PROFILE_BTN_SAVE' | translate }}
          </button>
        </div>
      </div>
    </form>
    <div class="mcp-profile-dialog__footer-item mcp-profile-dialog__terms">
      <div class="mcp-profile-dialog__disclaimer">
        <span (click)="conditions.emit()" class="mcp-profile-dialog__disclaimer-link">{{
          'TERMS_AND_CONDITIONS_TITLE' | translate
        }}</span>
        <span> | </span>
        <a [href]="privacyPolicyUrl" externalLink class="mcp-profile-dialog__disclaimer-link">{{
          'PRIVACY_POLICY_TITLE' | translate
        }}</a>
      </div>
    </div>
  </ng-template>
  <ng-template #profileFooterTemplate>
    <div class="mcp-profile-dialog__footer-item">
      <span class="mcp-profile-dialog__build-version">
        Version {{ appData?.appVersion }} | Build {{ appData?.appBuild }} | Release
        {{ appData?.appRelease }}
      </span>
    </div>
  </ng-template>
</app-modal>
