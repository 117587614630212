import { Directive, Input } from '@angular/core'
import { Match } from '@core/models/dto/match.dto'

@Directive()
export class MatchItemTemplateBase {
  private _match: Match

  @Input() set match(m: Match) {
    this._match = m
  }

  get match() {
    return this._match
  }
}
