import { TagDimensionType } from '../enums/tag-item.enums'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'

export const PATH_QUICK_IMAGE = 'assets/images/quick.png'

export const PATH_TAG_STAR_IMAGE = (selected: boolean | undefined) =>
  `assets/images/tag-star${selected ? '-selected' : ''}.png`

export const IMAGE_CLASS_BY_DIMENSION_TYPE = {
  [TagDimensionType.Player]: 'mcp-tag-item__image--player',
  [TagDimensionType.Team]: 'mcp-tag-item__image--team',
  [TagDimensionType.Quick]: 'mcp-tag-item__image--quick',
  undefined: '',
}

export const DEFAULT_IMAGE_BY_DIMENSION_TYPE = {
  [TagDimensionType.Player]: PLACEHOLDER_IMAGES.PLAYER,
  [TagDimensionType.Team]: PLACEHOLDER_IMAGES.TEAM,
  [TagDimensionType.Quick]: PATH_QUICK_IMAGE,
  undefined: PLACEHOLDER_IMAGES.DEFAULT,
}

export const COLORED_DIMENSION_TYPES = [TagDimensionType.Team, TagDimensionType.Player]
