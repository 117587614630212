import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  fetchAssets,
  fetchVersions,
  setAssets,
  setVersions,
} from '@core/state/actions/assets.actions'
import { catchError, last, map, switchMap } from 'rxjs/operators'
import { forkJoin, of } from 'rxjs'
import {
  ASSET_GROUP_TYPE,
  FILES_WITH_VERSION,
} from '@features/downloads/constants/downloads.constants'
import { AssetsApi } from '@core/requests/api/assets.api'
import { ToDictionary } from '@mediacoach-ui-library/global'
import { DomSanitizer } from '@angular/platform-browser'
import { AssetFile } from '@core/enums/assets.enum'
import { mapAssetsDictionary } from '@core/utils/assets.utils'
import { catchRequestError } from '@shared/operators/catch-request-error.operator'

@Injectable()
export class AssetsEffects {
  fetchVersions$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchVersions),
      switchMap(() => {
        const filenames = {}
        return forkJoin(
          FILES_WITH_VERSION.map((file, index) => {
            filenames[index] = file.name
            return this._api
              .getVersionFiles(file.path, file.name === AssetFile.MCDesktopMacInstaller)
              .pipe(catchError(() => of('')))
          }),
        ).pipe(
          last(),
          map((versions) => ({
            ...versions.reduce(
              (prevItem: any, { Version }: any, index) => ({
                ...prevItem,
                [filenames[index]]: Version,
              }),
              {},
            ),
          })),
          map((versions) => setVersions({ versions })),
          catchRequestError(),
        )
      }),
    ),
  )

  fetchAssets$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchAssets),
      switchMap(() =>
        this._api.getAssets().pipe(
          map((data) =>
            mapAssetsDictionary(
              ToDictionary(data?.Assets || [], ASSET_GROUP_TYPE, true),
              this._sanitizer,
            ),
          ),
          map((assets) => setAssets({ assets })),
          catchRequestError(),
        ),
      ),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: AssetsApi,
    private readonly _sanitizer: DomSanitizer,
  ) {}
}
