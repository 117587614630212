import videojs from 'video.js'
import { QuickTagButton } from './videojs-quick-tag.component'

const Plugin = videojs.getPlugin('plugin')

class QuickTagPlugin extends Plugin {
  constructor(player) {
    super(player)

    this.quickTagButton = player.addChild('QuickTagButton', {})
    this.callback = null
  }

  setCallback(callback) {
    this.callback = callback

    if (this.quickTagButton) {
      this.quickTagButton.setCallback(callback)
    }
  }
}

videojs.registerPlugin('quickTagPlugin', QuickTagPlugin)
export default QuickTagPlugin
