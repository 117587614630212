import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TagItemComponent } from './components/tag-item.component'
import { AvatarModule, DefaultImageModule, FirstCharacterModule } from '@mediacoach/ui'

@NgModule({
  imports: [CommonModule, DefaultImageModule, AvatarModule, FirstCharacterModule],
  declarations: [TagItemComponent],
  exports: [TagItemComponent],
})
export class TagItemModule {}
