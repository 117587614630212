import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { fromEvent, Observable, Subscription } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable()
export class ScrollService {
  private scrollSub: Subscription = new Subscription()
  private resizeSub: Subscription = new Subscription()

  scrollObs: Observable<any>
  resizeObs: Observable<any>
  pos: number

  constructor(@Inject(DOCUMENT) private document: Document) {
    // set initial value
    this._setViewportHeightVar()
    this._manageScrollPos()
    this._checkIfTouchable()

    // create observable for scrolling and resizing
    this.scrollObs = fromEvent(window, 'scroll')
    this.resizeObs = fromEvent(window, 'resize')

    // initiate subscription to update values
    this.scrollSub = this.scrollObs
      .pipe(untilDestroyed(this))
      .subscribe(() => this._manageScrollPos())
    this.resizeSub = this.resizeObs.pipe(untilDestroyed(this)).subscribe(() => {
      this._setViewportHeightVar()
      this._manageScrollPos()
      this._checkIfTouchable()
    })
  }

  private _checkIfTouchable() {
    this.document.body.classList[this.hasTouch() ? 'add' : 'remove']('has-touch')
  }

  private _setViewportHeightVar() {
    this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }

  private _manageScrollPos(): void {
    this.pos = window.pageYOffset
  }

  hasTouch() {
    return (
      'ontouchstart' in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.maxTouchPoints > 0
    )
  }
}
