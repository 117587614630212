import { PeriodEdge, VideoType } from '@mediacoach-ui-library/global'

export const PROPS_TO_FILTER = ['name', 'shortName']
export const MIN_CHAR_TO_FILTER = 2

export const REPORTS_WITH_TWO_VERSIONS = ['ReportTeamPostMatch', 'ReportPlayersPostMatch']

export const TWO_VERSION_CODES = ['Completed', 'Reviewed']

export const TWO_VERSION_EXTENSIONS = ['csv', 'pdf']

export const VIDEO_EXTENSIONS = ['mp4']

export const REPORTS_WITH_TWO_TEAMS = [
  'ReportTeamAdvancePreMatch',
  'ReportTeamPreMatch',
  'ReportPhy',
  'WarmUp',
  'ReportMaxExigency',
]

export const TWO_TEAM_CODES = {
  H: 'home',
  A: 'away',
}

export const ASSET_TYPE_TIMELINE_MAPPING = {
  Tv: VideoType.Tv,
  Panoramic: VideoType.Panoramic,
  Tac: VideoType.Tactical,
  Live: VideoType.Live,
}

export const MARKER_PERIOD_EDGES = [PeriodEdge.Start, PeriodEdge.End] // [PeriodEdge.Start, PeriodEdge.End]

export const PERIOD_EDGE_MAPPING = {
  [PeriodEdge.Start]: 'startTimeItems',
  [PeriodEdge.End]: 'endTimeItems',
}

export const DEFAULT_MATCH_SWITCHER = [
  { id: 'matchValues', text: 'MTR_COMMON_MATCH_VALUE' },
  { id: 'averageValues', text: 'MTR_COMMON_AVERAGE' },
]
