import '../extensions/string.extensions'

const MEMOIZED_VALUE_KEY = '_memoizedValue'
const EMPTY_HASH = '_'
let KEY = ''

export function Cacheable(expirationTimeMs: number = 60000, log = false): MethodDecorator {
  let start

  return (target: any, propertyName: string, descriptor: TypedPropertyDescriptor<any>) => {
    if (descriptor.value != null) {
      const originalMethod = descriptor.value
      const fn = function (...args: any[]) {
        if (!start && log) {
          start = performance.now()
        }

        KEY =
          MEMOIZED_VALUE_KEY + args && args.length > 0 ? JSON.stringify(args).hash() : EMPTY_HASH

        if (!fn[KEY]) {
          fn[KEY] = originalMethod.apply(this, args)
          setTimeout(() => {
            clearMemoizedValue(fn)
            start = null
          }, expirationTimeMs)
        } else if (log) {
          const timeLeft = expirationTimeMs - (performance.now() - start)
          if (timeLeft > 0) {
            console.log(
              `${target.constructor.name}.${propertyName}() cached for:`,
              new Date(timeLeft).toISOString().substr(11, 8),
            )
          }
        }

        return fn[KEY]
      }
      descriptor.value = fn
      return descriptor
    }
  }
}

export function clearMemoizedValue(method) {
  delete method[KEY]
}
