import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { Competition } from '@core/models/dto/season.dto'
import { UnavailableContentComponent } from '@shared/components/unavailable-content/unavailable-content.component'
import { exists } from '@mediacoach/ui'
import { BehaviorSubject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { filter } from 'rxjs/operators'

@Directive({
  selector: '[mcpNoContent]',
})
export class NoContentDirective {
  private viewCreated: 'no-content' | 'view' | undefined
  private ref: ComponentRef<any>
  private _payload$$ = new BehaviorSubject<{ condition: boolean; competition: Competition }>(
    undefined,
  )

  @Input({ alias: 'mcpNoContent', required: true }) set condition(condition: boolean) {
    this._payload$$.next({ ...this._payload$$.value, condition })
  }

  @Input({ alias: 'mcpNoContentCompetition', required: true }) set competition(
    competition: Competition,
  ) {
    this._payload$$.next({ ...this._payload$$.value, competition })
  }
  @Input('mcpNoContentMessage') message: string
  @Input('mcpNoContentNoPadding') noPadding: boolean

  constructor(
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _templateRef: TemplateRef<unknown>,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    this._payload$$
      .pipe(
        filter((payload) => exists(payload?.condition) && exists(payload?.competition)),
        takeUntilDestroyed(),
      )
      .subscribe(({ condition, competition }) => {
        this._handleContent(condition, competition)
      })
  }

  private _handleContent(showNoContent: boolean, competition: Competition) {
    if (showNoContent && this.viewCreated !== 'no-content') {
      this._showNoContent(competition)
    } else if (!showNoContent && this.viewCreated !== 'view') {
      this._showRegularContent()
    }
  }

  private _showNoContent(competition: Competition) {
    this._viewContainerRef.clear()
    this.ref = this._viewContainerRef.createComponent(UnavailableContentComponent)
    this.ref.setInput('competition', competition)
    this.ref.setInput('message', this.message)
    this.ref.setInput('noPadding', this.noPadding)
    this.ref.changeDetectorRef.detectChanges()
    this.viewCreated = 'no-content'
  }

  private _showRegularContent() {
    this._viewContainerRef.clear()
    this._viewContainerRef.createEmbeddedView(this._templateRef)
    this.viewCreated = 'view'
  }
}
