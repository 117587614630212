export const CURRENT_BACKDROP_STYLES: Partial<CSSStyleDeclaration> = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  zIndex: '1000',
}

export const TOP_MIDDLE_BACKDROP_STYLES: Partial<CSSStyleDeclaration> = {
  width: '100%',
  flexShrink: '0',
}

export const BACKDROP_CONTENT_STYLES: Partial<CSSStyleDeclaration> = {
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}

export const BACKDROP_MIDDLE_CONTENT_STYLES: Partial<CSSStyleDeclaration> = {
  height: '100%',
  width: '100%',
  display: 'flex',
}
