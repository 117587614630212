import { Meta, Title } from '@angular/platform-browser'
import { Component, OnInit, Renderer2 } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'

import { filter, map, mergeMap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { getTokenExpired } from '@core/state/selectors/socket.selectors'
import { analyticsTrackPage } from '@core/analytics/state/actions/analytics.actions'
import { signInSilent } from '@core/state/actions/oidc.actions'
import { TranslateService } from '@ngx-translate/core'
import { DeviceDetectorService, DeviceOs } from '@mediacoach-ui-library/global'

const INDEX_STATIC = 'Mediacoach | '

@UntilDestroy()
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  _tokenExpired$: Observable<boolean> = this._store.pipe(getTokenExpired)

  constructor(
    private readonly _meta: Meta,
    private readonly _router: Router,
    private readonly _titleService: Title,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _translateService: TranslateService,
    private readonly _renderer: Renderer2,
    private readonly _deviceDetector: DeviceDetectorService,
    private readonly _store: Store,
  ) {
    // In this function we are accessing the root of the routes to access the description of the index
    this.setTitleOrDescriptionToIndex(true)
    this._router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._store.dispatch(analyticsTrackPage({ event }))
        window.scrollTo(0, 0)
      }
    })

    // Handle if user token is expired
    this._tokenExpired$
      .pipe(untilDestroyed(this))
      .subscribe(() => this._store.dispatch(signInSilent()))

    if (this._deviceDetector.getDeviceInfo().os === DeviceOs.Windows) {
      this._renderer.addClass(document.body, 'is-windows-os')
    }
  }

  ngOnInit() {
    // In this function we are going through the children of the route to access the title of each page
    this.setTitleOrDescriptionToIndex(false)
  }

  setTitleOrDescriptionToIndex(root) {
    this._router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route) => {
          if (root && route.firstChild) {
            route = route.firstChild
          } else {
            while (route.firstChild) {
              route = route.firstChild
            }
          }
          return route
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        untilDestroyed(this),
      )
      .subscribe((event) => {
        if (event['title']) {
          this._translateService
            .stream(event['title'])
            .pipe(untilDestroyed(this))
            .subscribe((text) => this._titleService.setTitle(INDEX_STATIC + text))
        }
        if (event['description']) {
          this._translateService
            .stream(event['description'])
            .pipe(
              untilDestroyed(this),
              map((text) => ({
                text,
                method: this._meta.getTags('name=description') ? 'updateTag' : 'addTag',
              })),
            )
            .subscribe(({ text, method }) =>
              this._meta[method]({ name: 'description', content: text }),
            )
        }
      })
  }
}
