import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'

import { Match } from '@core/models/dto/match.dto'
import { TableComponent } from '@shared/components/table/table.component'
import { ContentType, ITableConfig, ItemDisplay } from '@shared/components/table/table.model'
import { MatchItemTemplateConfig } from '@shared/components/match-item/models/match-item.model'
import { DEFAULT_ROW_TEMPLATES } from '@shared/components/match-item/constants/match-item.constants'
import { debounce } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-match-item-list',
  templateUrl: './match-item-list.component.html',
  styleUrls: ['./match-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemListComponent {
  private _config$$ = new BehaviorSubject<ITableConfig[]>(null)
  private _template$$ = new BehaviorSubject<TemplateRef<unknown>>(null)

  @Input() set config(config: ITableConfig[]) {
    if (config) {
      this._config$$.next(config)
    }
  }

  @Input() matches: Match[]
  @Input() selectedMatches: Match[]
  @Input() template: TemplateRef<unknown>
  @Input() matchItemTemplates: MatchItemTemplateConfig[] = DEFAULT_ROW_TEMPLATES
  @Input() keepSelection: boolean
  @Input() stickyHeader: boolean
  @Input() allLoaded: boolean
  @Input() blockParentOverflow: boolean

  @Output() matchSelect: EventEmitter<Match> = new EventEmitter<Match>()
  @Output() scrollDown: EventEmitter<void> = new EventEmitter<void>()

  @ViewChild('matchesTable') table: TableComponent

  @ViewChild('matchAssets') set matchAssets(template: TemplateRef<unknown>) {
    if (template) {
      this._template$$.next(template)
    }
  }

  loading = true
  display = ItemDisplay.Lg
  config$ = combineLatest([
    this._config$$.asObservable(),
    this._template$$.asObservable().pipe(distinctUntilChanged()),
  ]).pipe(
    debounceTime(0),
    filter(([config, tpl]) => !!config && !!tpl),
    map(([config, tpl]) => [
      ...config,
      {
        type: ContentType.Template,
        template: tpl,
        extraClass: 'c-table__cell--no-padding',
      },
    ]),
    tap(() => (this.loading = false)),
  )

  @debounce(0)
  loaded(): void {
    this.loading = false
  }
}
