import { Store } from '@ngrx/store'
import { Injectable } from '@angular/core'
import { delay, filter } from 'rxjs/operators'
import { HubConnectionState } from '@microsoft/signalr'
import { fetchTopic, setSocketId, setSocketStatus } from '@core/state/actions/socket.actions'
import { getSocketStatus } from '@core/state/selectors/socket.selectors'
import { SignalrService } from '@core/websockets/signalr/services/signalr.service'
import { RECONNECT_DELAY } from '@core/websockets/signalr/constants/signalr.constants'
import { ROOM_RECEIVE_MESSAGE } from '@sockets/constants/socket.constants'

@Injectable()
export class SocketService {
  constructor(
    private readonly _signal: SignalrService,
    private readonly _store: Store,
  ) {}

  connect(): void {
    this._signal.startConnection(ROOM_RECEIVE_MESSAGE).subscribe((hub) => {
      this._store.dispatch(setSocketId({ id: hub.connectionId }))
      this._store.dispatch(setSocketStatus({ status: hub.state }))
    })
    this._signal.message$.subscribe((socketMessage) =>
      this._store.dispatch(fetchTopic({ socketMessage })),
    )

    this._signal.statusChange$.subscribe(({ status }) => {
      this._store.dispatch(setSocketStatus({ status }))
      if (status !== HubConnectionState.Connected) {
        console.warn('Socked is disconnected')
        this._signal
          .stopConnection()
          .pipe(delay(RECONNECT_DELAY))
          .subscribe(() => this.connect())
      }
    })
  }

  onConnect() {
    return this._store
      .select(getSocketStatus)
      .pipe(filter((status) => status === HubConnectionState.Connected))
  }

  dispose(): void {
    this._signal.dispose()
  }
}
