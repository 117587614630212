import { Injectable } from '@angular/core'

import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'

import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { selectProfile } from '@core/state/selectors/user.selectors'
import { trackUserToSmartLook } from '@core/analytics/state/actions/smart-look.actions'
import { AnalyticsFacade } from '@core/analytics/analytics.facade'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import {
  analyticsTrackEvent,
  analyticsTrackPage,
  analyticsTrackSearchEvent,
  analyticsTrackTeamAccumulated,
  analyticsTrackUser,
} from '@core/analytics/state/actions/analytics.actions'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class AnalyticsEffects {
  trackEvent$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(analyticsTrackEvent),
        tap(({ eventName, eventParams }) => this._analytics.trackEvent(eventName, eventParams)),
      ),
    { dispatch: false },
  )

  trackSearchEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(analyticsTrackSearchEvent),
      debounceTime(2000),
      filter(({ searchText }) => !!searchText),
      map(({ searchText }) =>
        analyticsTrackEvent({
          eventName: AnalyticsEvent.search,
          eventParams: {
            [AnalyticsParam.category]: AnalyticsCategory.search,
            [AnalyticsParam.searchText]: searchText,
          },
        }),
      ),
    ),
  )

  trackPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(analyticsTrackPage),
        tap(({ event }) => this._analytics.trackPage(event)),
      ),
    { dispatch: false },
  )

  trackUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(analyticsTrackUser),
      concatLatestFrom(() => this._store.select(selectProfile)),
      switchMap(([_, profile]) => {
        const obs = profile?.customerTeamTranslationKey
          ? this._translateService.get(profile.customerTeamTranslationKey)
          : of('')
        return obs.pipe(map((translatedKey) => ({ profile, translatedKey })))
      }),
      tap(({ profile, translatedKey }) => {
        this._analytics.trackUser(profile, translatedKey)
      }),
      map(({ profile }) => trackUserToSmartLook({ profile })),
    ),
  )

  trackTeamAccumulated$ = createEffect(() =>
    this._actions$.pipe(
      ofType(analyticsTrackTeamAccumulated),
      map(() =>
        analyticsTrackEvent({
          eventParams: {
            eventCategory: 'download_portal',
            eventAction: 'download_team_accumulated',
            eventLabel: 'Download team accumulated',
            eventValue: 10,
          },
        }),
      ),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _analytics: AnalyticsFacade,
    private readonly _translateService: TranslateService,
  ) {}
}
