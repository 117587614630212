import { Component, EventEmitter, Input, Output } from '@angular/core'

import { MatchItemModifier, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

import { IMatch } from '@core/models/dto/match.dto'
import { RoutePermissionKey } from '@auth/enums/auth.enum'

@Component({
  selector: 'app-match-card',
  templateUrl: './match-card.component.html',
  styleUrls: ['./match-card.component.scss'],
})
export class MatchCardComponent {
  @Input() match: IMatch
  @Output() goToMatchDetail = new EventEmitter()

  itemModifier = MatchItemModifier
  permissionKey = RoutePermissionKey.MatchDetail

  readonly placeholder = PLACEHOLDER_IMAGES
}
