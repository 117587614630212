import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Competition } from '@core/models/dto/season.dto'
import { Loadable } from '@core/models/models/loadable.models'
import { ApiTeam } from '@core/models/models/team.models'
import { McpPlayer } from '@core/models/dto/player.dto'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'

@Component({
  selector: 'mcp-space-selector',
  templateUrl: './space-selector.component.html',
  styleUrls: ['./space-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceSelectorComponent {
  readonly playerPlaceholder = PLACEHOLDER_IMAGES.PLAYER
  readonly teamPlaceholder = PLACEHOLDER_IMAGES.TEAM

  @Input() competition: Loadable<Competition>
  @Input() teams: Loadable<ApiTeam[]>
  @Input() players: Loadable<McpPlayer[]>
  @Input() loading: boolean
  @Input() displayPlayers: boolean
  @Input() selectedTeamIndex: number
  @Input() selectedPlayerIndex: number

  @Output() competitionChange = new EventEmitter()
  @Output() teamChange = new EventEmitter()
  @Output() playerChange = new EventEmitter()
}
