import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { PLACEHOLDER_IMAGES, TabChangeEvent } from '@mediacoach/ui'
import { CompetitionTab } from './models/competition-tab.models'

@Component({
  selector: 'mcp-competition-tabs',
  templateUrl: './competition-tabs.component.html',
  styleUrls: ['./competition-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionTabsComponent {
  readonly competitionPlaceholder = PLACEHOLDER_IMAGES.COMPETITION

  @Input() selectedIndex: number
  @Input() tabs: CompetitionTab[]
  @Input() loading: boolean
  @Input() hideTabsSubtitle: boolean

  @Output() tabChange = new EventEmitter<{ tab: CompetitionTab; index: number }>()

  onTabChange({ index }: TabChangeEvent) {
    this.tabChange.emit({ tab: this.tabs[index], index })
  }
}
