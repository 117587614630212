export enum AnalyticsRouteTitle {
  home = 'Home',
  matches = 'Matches',
  matchDetail = 'Match Detail',
  players = 'Players',
  calendar = 'Calendar',
  playerDetail = 'Player Detail',
  competitions = 'Competitions',
  teams = 'Teams',
  teamDetail = 'Team Detail',
  monitoring = 'Monitoring',
  monitoringHistory = 'Monitoring | History',
  beyondStats = 'Beyond Stats',
  more = 'More',
  downloads = 'More | Downloads',
  videos = 'More | Videos',
  issues = 'More | Report Issue',
  playground = 'More | Playground',
  documentation = 'More | Documentation',
  apps = 'Applications',
  login = 'Login',
  logout = 'Logout',
}
