import { camelToSnakeCase } from '@core/utils/string.utils'

export const parseComparisonIds = (
  map: { [key: string]: string },
  key: string,
  isComparison: boolean,
): string =>
  isComparison && ['teamId', 'id', 'competitionId', 'seasonId'].includes(key)
    ? `${camelToSnakeCase(map[key])}_A`
    : map[key]

export const mapRouteParams = (
  params: { [key: string]: string },
  map: { [key: string]: string },
  isComparison?: boolean,
): { [key: string]: string } =>
  Object.keys(map).reduce((paramMap, key) => {
    if (params && params[key]) {
      paramMap = {
        ...paramMap,
        [parseComparisonIds(map, key, isComparison)]: params[key],
      }
    }
    return paramMap
  }, {})
