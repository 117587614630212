import { Action, createReducer, on } from '@ngrx/store'
import { setSeasons, setSelectedSeason } from '@core/state/actions/season.actions'
import { SeasonsState } from '@core/state/models/seasons.state'
import { REFRESH_TIME } from '@core/constants/time.constants'

const initialState: SeasonsState = {
  list: undefined,
  selected: undefined,
  requestTimestamp: undefined,
  ttl: REFRESH_TIME.PER_DAY * 1000,
}

const reducer = createReducer(
  initialState,
  on(setSeasons, (state, { list }) => ({ ...state, list, requestTimestamp: new Date().getTime() })),
  on(setSelectedSeason, (state, { selected }) => ({ ...state, selected })),
)

export function seasonsReducer(state: SeasonsState | undefined, action: Action) {
  return reducer(state, action)
}
