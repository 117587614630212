import { createAction, props } from '@ngrx/store'
import { GridsterConfig } from 'angular-gridster2'
import { Widget } from '@widgets/models/widget.models'
import { SpaceTemplate } from '@core/space/models/space.models'

export const setSpaceWidgetLoading = createAction(
  '[Space][layout] set space widget loading',
  props<{ widgetLoading: boolean }>(),
)
export const setSpaceLayoutConfig = createAction(
  '[Space][layout] set space layout config',
  props<{ config: GridsterConfig }>(),
)
export const setSpaceWidgets = createAction(
  '[Space][layout] set space widgets',
  props<{ widgets: Widget[] }>(),
)

export const setSpaceTemplates = createAction(
  '[Space][layout] set space templates',
  props<{ templates: SpaceTemplate[] }>(),
)

export const setSpaceMediacoachTemplates = createAction(
  '[Space][layout] set space Mediacoach templates',
  props<{ mcTemplates: SpaceTemplate[] }>(),
)
