import { Inject, Injectable, isDevMode } from '@angular/core'
import { from, Observable, Subject } from 'rxjs'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { delay, map, switchMap, takeUntil } from 'rxjs/operators'
import { FACTORY_TOKEN, RECONNECT_DELAY } from '../constants/signalr.constants'
import { SocketMessage, SocketStatus } from '../models/signalr.model'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable()
export class SignalrService {
  private readonly destroy$$: Subject<void> = new Subject()
  private readonly message$$: Subject<SocketMessage> = new Subject<SocketMessage>()
  private readonly statusChange$$: Subject<SocketStatus> = new Subject<SocketStatus>()

  readonly message$ = this.message$$.asObservable()
  readonly statusChange$ = this.statusChange$$.asObservable()

  constructor(@Inject(FACTORY_TOKEN) private readonly _hub: Observable<HubConnection>) {}

  private _updateStatus(status: HubConnectionState, payload: unknown): void {
    this.statusChange$$.next({ status, payload })
  }

  private async _start(hub: HubConnection): Promise<void> {
    try {
      return await hub.start()
    } catch (e) {
      return await Promise.resolve()
    }
  }

  startConnection(room: string, emitEvents = true): Observable<HubConnection> {
    return this._hub.pipe(
      takeUntil(this.destroy$$),
      switchMap((hub) => {
        if (emitEvents) {
          hub.on(room, (topic: string, message: string) => {
            if (isDevMode()) {
              console.log(
                `%c[Socket] ${new Date().toLocaleTimeString()} - ${topic} updated`,
                'color: DodgerBlue',
              )
            }
            this.message$$.next({ topic, message })
          })
          hub.onreconnecting((error) => this._updateStatus(HubConnectionState.Reconnecting, error))
          hub.onreconnected((connectionId) =>
            this._updateStatus(HubConnectionState.Connected, connectionId),
          )
          hub.onclose((error) => {
            this._updateStatus(HubConnectionState.Disconnected, error)
            this.reconnect(room, emitEvents).pipe(untilDestroyed(this)).subscribe()
          })
        }
        return from(this._start(hub)).pipe(map(() => hub))
      }),
    )
  }

  stopConnection(): Observable<void> {
    return this._hub.pipe(
      takeUntil(this.destroy$$),
      switchMap((hub) => from(hub.stop())),
    )
  }

  reconnect(room: string, emitEVents = true): Observable<HubConnection> {
    return this.stopConnection().pipe(
      delay(RECONNECT_DELAY),
      switchMap(() => this.startConnection(room, emitEVents)),
    )
  }

  dispose(): void {
    this.destroy$$.next()
    this.destroy$$.complete()
  }
}
