<div
  class="mcp-navigable-dropdown-item"
  [routerLink]="item?.route ? ['/' + item?.route] : undefined"
  [routerLinkActive]="routerActiveAware ? 'active' : ''"
  [attr.data-qa-id]="item.qaId"
  [attr.data-cy]="item?.label"
  [class.selected]="selectable && item?.selected"
  [ngClass]="[styleClass, itemType].join(' ')"
  (click)="item.command && item.command(item); itemClick.emit(item)"
>
  <i
    *ngIf="item?.icon"
    class="mcp-navigable-dropdown-item__icon u-icon u-icon--{{ item.icon }} "
  ></i>
  <i class="mcp-navigable-dropdown-item__live-icon fa fa-circle" *ngIf="liveBullet"></i>
  <span class="mcp-navigable-dropdown-item__label">{{ item?.label | translate }}</span>
</div>
