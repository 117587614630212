import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Match } from '@core/models/dto/match.dto'
import { RoutePermissionKey } from '@auth/enums/auth.enum'

@Component({
  selector: 'mcp-match-item-list-footer',
  templateUrl: './match-item-list-footer.component.html',
  styleUrls: ['./match-item-list-footer.component.scss'],
})
export class MatchItemListFooterComponent {
  private _allSelected = false

  @Input() limit: number
  @Input() total: number
  @Input() matches: Match[]

  @Input() set allSelected(_allSelected) {
    this._allSelected = _allSelected
  }

  get allSelected() {
    return this._allSelected
  }

  @Output() toggleChange = new EventEmitter()
  @Output() matchesSelected = new EventEmitter<Match[]>()
  @Output() limitReached = new EventEmitter<void>()

  routeAccess = RoutePermissionKey

  get selectedMatches() {
    return this.matches
  }

  goToDetail(): void {
    if (this.selectedMatches.length > this.limit) {
      this.limitReached.emit()
    } else {
      this.matchesSelected.emit(this.selectedMatches)
    }
  }

  onClick(checked: boolean): void {
    this.toggleChange.emit(checked)
  }
}
