<input
  #recaptchaButton
  class="recaptcha-button"
  [id]="id"
  [name]="id"
  [class.recaptcha-button--loading]="isLoading"
  [disabled]="isDisabled"
  type="checkbox"
  hidden
/>
<label #recaptchaButtonLabel class="recaptcha-button__label" [for]="id">
  <div class="recaptcha-button__check-icon">
    <span class="recaptcha-button__icon-line recaptcha-button__icon-line--tip"></span>
    <span class="recaptcha-button__icon-line recaptcha-button__icon-line--long"></span>
    <div class="recaptcha-button__icon-circle"></div>
  </div>
  <span class="recaptcha-button__label-text" translate="COMMONS_NOT_A_ROBOT"></span>
</label>
