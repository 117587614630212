<mcui-dialog
  [visible]="visible"
  [modal]="modal"
  [blockScroll]="blockScroll"
  [dismissableMask]="dismissableMask"
  [closeOnEscape]="closeOnEscape"
  [closable]="true"
  [style]="style"
  [styleClass]="styleClass"
  [ngClass]="styleClass"
  [position]="position"
  [baseZIndex]="baseZIndex"
  [inlineWrapperSelector]="inlineWrapperSelector"
  [autoZIndex]="autoZIndex"
  [minX]="minX"
  [minY]="minY"
  [focusOnShow]="focusOnShow"
  [focusTrap]="focusTrap"
  [maximizable]="maximizable"
  [transitionOptions]="transitionOptions"
  [minimizeIcon]="minimizeIcon"
  [maximizeIcon]="maximizeIcon"
  [showHeader]="false"
  [draggable]="false"
  (hideChange)="onHide($event)"
  (showChange)="onShow($event)"
  (visibleChange)="onVisibleChange($event)"
>
  <div class="c-dialog__header">
    <div class="c-dialog__header-content">
      <ng-content select=".dialog-header"></ng-content>
    </div>
    <mcui-icon
      class="c-dialog__header-close-button"
      svgIcon="times"
      *ngIf="closable"
      (click)="close()"
    ></mcui-icon>
  </div>
  <ng-content></ng-content>
  <ng-content select=".dialog-footer"></ng-content>
</mcui-dialog>
