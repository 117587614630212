import { Directive, inject } from '@angular/core'
import { DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { DialogConfig, DialogRef } from '@mediacoach/ui'
import { selectISOLang } from '@core/state/selectors/user.selectors'
import { MatchTabDisplayMode } from '@features/match-tabs/models/match-tab.models'

@Directive()
export abstract class PlaylistDialogBase {
  protected readonly _store = inject(Store)
  protected readonly _dialogConfig = inject(DialogConfig)
  protected readonly _dialogRef = inject(DialogRef)

  protected readonly lang$ = this._store.select(selectISOLang)

  protected get displayMode(): MatchTabDisplayMode {
    return this._dialogConfig?.data?.displayMode
  }

  protected get selector(): MemoizedSelector<object, any, DefaultProjectorFn<any>> | undefined {
    return this._dialogConfig?.data?.selector
  }

  abstract close(...args: unknown[]): void
}
