import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'resolveCellValue',
})
export class ResolveCellValuePipe implements PipeTransform {
  transform(data: any, key: string): string {
    if (data && key) {
      // eslint-disable-next-line no-prototype-builtins
      const hasValueProperty = data[key]?.hasOwnProperty('value')
      return hasValueProperty ? data[key]?.value : data[key]
    }

    return undefined
  }
}
