import { Injectable } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { fetchAssets, fetchVersions } from '@core/state/actions/assets.actions'
import { getAssetsRefreshFlag } from '@core/state/selectors/assets.selector'

@Injectable()
export class AssetsResolver {
  constructor(private readonly _store: Store) {}

  resolve(): Observable<void> | Promise<void> | void {
    return this._store.pipe(
      getAssetsRefreshFlag(),
      map(({ assets, versions }) => {
        if (assets) {
          this._store.dispatch(fetchAssets())
        }
        if (versions) {
          this._store.dispatch(fetchVersions())
        }
        return
      }),
    )
  }
}
