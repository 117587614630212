import { createAction, props } from '@ngrx/store'

import { AnalyticsSearchEvent, AnalyticsTrackEvent } from '@core/analytics/models/analytics.models'
import { NavigationEnd } from '@angular/router'

export const analyticsTrackUser = createAction('[Analytics] Track User')
export const analyticsTrackPage = createAction(
  '[Analytics] Track Page',
  props<{ event: NavigationEnd }>(),
)
export const analyticsTrackEvent = createAction(
  '[Analytics] Track Event',
  props<AnalyticsTrackEvent>(),
)
export const analyticsTrackSearchEvent = createAction(
  '[Analytics] Track Search Event',
  props<AnalyticsSearchEvent>(),
)

export const analyticsTrackTeamAccumulated = createAction('[Analytics] Track team accumulated')
