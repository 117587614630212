import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { Observable, Subscription } from 'rxjs'
import { QueryDropdownOption } from '@shared/components/query/models/query-dropdown.model'
import { UntypedFormControl } from '@angular/forms'

export type ControlType = 'dropdown' | 'text' | 'date' | 'multiselect'

export interface UpdatableField {
  targetName: string
  fieldKey: string
  matchKey?: string
  updatesFn?: (...args) => any
}

export interface QueryBaseOptions<T> {
  value?: T
  id?: string
  key?: string
  label?: string
  required?: boolean
  disabled?: boolean
  clearable?: boolean
  order?: number
  controlType?: ControlType
  placeholder?: string
  updates?: UpdatableField
  gtmAction?: string
}

export class QueryBase<T> {
  id: string
  value: T
  key: string
  label: string
  required: boolean
  order: number
  controlType: ControlType
  placeholder: string
  disabled: boolean
  clearable: boolean
  updates?: UpdatableField
  gtmAction?: string

  constructor(options: QueryBaseOptions<T> = {}) {
    this.value = options.value
    this.id = options.id
    this.key = options.key || ''
    this.label = options.label || ''
    this.required = !!options.required
    this.disabled = !!options.disabled
    this.clearable = !!options.clearable
    this.order = options.order ?? 1
    this.controlType = options.controlType || 'text'
    this.placeholder = options.placeholder
    this.updates = options.updates
    this.gtmAction = options.gtmAction
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Queryable {}

export class Selectable extends QueryBase<unknown> {
  options: QueryDropdownOption[] | Observable<QueryDropdownOption[]>
  imageOnly: boolean
  defaultImage: string

  constructor(options: QueryBaseOptions<unknown> = {}) {
    super(options)
    this.options = options['options'] || []
    this.imageOnly = options['imageOnly']
    this.defaultImage = options['defaultImage']
    this.updates = options['updates']
  }
}

@Directive()
export class QueryableField<T extends QueryBase<any>> implements OnDestroy {
  private _subscription: Subscription
  private _control: UntypedFormControl
  get control(): UntypedFormControl {
    return this._control
  }

  @Input() set control(ctrl: UntypedFormControl) {
    this._control = ctrl
    if (ctrl && !this._subscription) {
      this._subscription = ctrl.valueChanges.subscribe((value) => {
        this.queryChange.emit({ value, field: this.field })
      })
    }
  }

  @Input() set query(g: QueryBase<any>) {
    this.field = g as T
  }

  @Output() queryChange = new EventEmitter()

  field: T

  ngOnDestroy() {
    this._subscription?.unsubscribe()
  }
}
