import { NgModule } from '@angular/core'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { spaceReducer } from './state/reducers/space.reducer'
import { SpaceEffects } from './state/effects/space.effects'
import { SpaceApi } from '@core/space/api/space.api'
import { SPACE_FEATURE_TOKEN } from '@core/space/tokens/space.tokens'
import { SpaceSelectorEffects } from '@core/space/state/effects/space-selector.effect'
import { DemarcationPipe } from '@shared/pipes/demarcation/demarcation.pipe'
import { HighlightPipe } from '@mediacoach/ui'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate/multiple-translate.pipe'

@NgModule({
  imports: [
    StoreModule.forFeature(SPACE_FEATURE_TOKEN, spaceReducer),
    EffectsModule.forFeature([SpaceEffects, SpaceSelectorEffects]),
  ],
  providers: [SpaceApi, DemarcationPipe, HighlightPipe, MultipleTranslatePipe],
})
export class SpaceModule {}
