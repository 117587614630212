<div class="c-input__container" [ngClass]="{ 'is-disabled': disabled, 'is-locked': locked }">
  <app-label
    [label]="label"
    [tooltip]="tooltip"
    [tooltipStyleClass]="tooltipStyleClass"
  ></app-label>
  <input
    class="c-input__input"
    [id]="id"
    [type]="type"
    [placeholder]="placeholder || ''"
    [value]="value"
    [disabled]="disabled"
    (input)="onChange($event?.target?.value)"
    (blur)="onTouched()"
    (keyup)="onKeyUp.emit($event?.target?.value)"
    (focusin)="onFocusIn.emit()"
    (focusout)="onFocusOut.emit()"
  />
  <span *ngIf="icon && !locked" class="c-input__icon u-icon--{{ icon }}"></span>
  <span *ngIf="locked" class="c-input__icon u-icon--lock"></span>
</div>
