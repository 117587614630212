import { Observable } from 'rxjs'
import { StepActionType } from '@shared/modules/tour/enums/tour.enum'
import { TemplateRef } from '@angular/core'
import { TourStepComponent } from '@shared/modules/tour/components/tour-step/tour-step.component'
import { NO_POSITION } from '@shared/modules/tour/constants/tour.constants'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'

export class TourStepInfo {
  number: number
  step: TourStep
  actionType: StepActionType
}

export interface StepHandler {
  startTour(options: TourOptions): Observable<TourStepInfo>

  close(isCompleted?: boolean): void

  prev(): void

  next(): void

  getOptions?(): TourOptions
}

export type StepPosition =
  | 'top'
  | 'right'
  | 'left'
  | 'center'
  | 'bottom'
  | 'bottom|right'
  | typeof NO_POSITION
export type ArrowDirection = 'top' | 'right' | 'left' | 'bottom' | null

export class Step {
  id: string
  step: TourStep
}

export class TourCustomTemplates {
  prevButtonTpl?: TemplateRef<any>
  doneButtonTpl?: TemplateRef<any>
  nextButtonTpl?: TemplateRef<any>
  closeButtonTpl?: TemplateRef<any>
  progressTpl?: TemplateRef<any>
}

export class TourStep extends TourCustomTemplates {
  order?: number
  selector: string
  name: string
  position?: StepPosition
  title?: string
  text?: string
  asyncText?: Observable<string>
  asyncTitle?: Observable<string>
  preventNavigation?: boolean
  permissionUrl?: string

  translationKey?: string
  gtagTitle?: string

  stepContent?: TemplateRef<any>
  stepContentParams?: any

  transformCssStyle?: string
  dependsOn?: string
  isElementOrAncestorFixed?: boolean
  stepInstance?: TourStepComponent
  timeout?: number
  waitUntilRendered?: string[]

  hideBackdrop?: boolean
  navigateTo?: string

  beforeShow?: (...args) => Promise<unknown>
  beforeShowDelay?: number
  beforeShowTimeout?: number

  afterShow?: (...args) => Promise<unknown>
  afterShowDelay?: number
  afterShowTimeout?: number

  onNext?: (...args) => unknown
  onPrev?: (...args) => unknown
  onDone?: (...args) => unknown

  constructor() {
    super()
    this.position = NO_POSITION
  }
}

export interface TourStepTexts {
  prev?: string | Observable<string>
  next?: string | Observable<string>
  done?: string | Observable<string>
  close?: string | Observable<string>
  progress?: string | Observable<string>
}

export interface StepProgress {
  step: number
  total: number
  text?: Observable<string>
}
