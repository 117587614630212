import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { collapseHorizontallyAnimation } from '@shared/animations/animations/collapse.animation'

@Component({
  selector: 'mcp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseHorizontallyAnimation()],
})
export class SidebarComponent {
  protected readonly collapseHorizontallyAnimation = collapseHorizontallyAnimation

  @Input() collapsed = true
  @Output() animationDone = new EventEmitter<boolean>()

  toggle(): void {
    this.collapsed = !this.collapsed
  }

  onAnimationDone() {
    this.animationDone.emit(this.collapsed)
  }
}
