import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations'
import { OVERLAY_TIMING_FN } from '@shared/components/dialog/dialog.constants'

export const getAnimationPlayer = (
  builder: AnimationBuilder,
  element: Element,
  animateIn: boolean,
  timing: string = OVERLAY_TIMING_FN,
): AnimationPlayer =>
  builder.build([animate(timing, style({ opacity: animateIn ? 1 : 0 }))]).create(element)
