<div class="mcp-tour-progress">
  <div class="mcp-tour-progress__text">
    {{ stepProgress.text | async }} {{ stepProgress.step }}/{{ stepProgress.total }}
  </div>
  <div class="mcp-tour-progress__stepper">
    <div
      class="mcp-tour-progress__stepper-step"
      [style.grid-column]="i + 1"
      *ngFor="let step of ' '.repeat(stepProgress.total || 0).split(''); index as i"
      [class.completed]="i + 1 <= stepProgress.step"
    ></div>
  </div>
</div>
