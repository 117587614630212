import { Component, Input } from '@angular/core'
import { QueryDropdownOption } from '../../models/query-dropdown.model'
import { QueryMultiselect } from '../../models/query-multiselect.model'

@Component({
  selector: 'mcp-query-multiselect-item',
  templateUrl: './query-multiselect-item.component.html',
  styleUrls: ['./query-multiselect-item.component.scss'],
})
export class QueryMultiselectItemComponent {
  @Input() items: any[]
  @Input() item: QueryDropdownOption<any>
  @Input() config: QueryMultiselect
}
