import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core'
import { AppendToDirective } from '@shared/components/dialog/directives/append-to.directive'
import { take } from 'rxjs/operators'
import { DIALOG_TIMING_FN } from '@shared/components/dialog/dialog.constants'
import { debounce } from '@mediacoach-ui-library/global'
import { DialogPosition } from '@mediacoach/ui'

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.theme.scss', './dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  private _styleClass: string
  private _visible: boolean

  @Input()
  set visible(v: boolean) {
    this._visible = v
    this.visibleChange.emit(v)
    if (!!v && this.maximized) {
      this.maximize()
    }
  }

  get visible(): boolean {
    return this._visible
  }

  @Input()
  set styleClass(value: string) {
    this._styleClass = value
  }

  get styleClass(): string {
    return [
      this._styleClass,
      this.inLine ? 'c-dialog--in-line' : undefined,
      this.keepUnderToolbar ? 'c-dialog--under-toolbar' : undefined,
    ]
      .filter(Boolean)
      .join(' ')
  }

  @Input() resizable = false
  @Input() closable = true
  @Input() closeOnEscape = true
  @Input() dismissableMask = true
  @Input() focusOnShow = true
  @Input() focusTrap = true
  @Input() baseZIndex = 0
  @Input() autoZIndex = true
  @Input() modal = true
  @Input() blockScroll = true
  @Input() minX = 0
  @Input() minY = 0
  @Input() transitionOptions = DIALOG_TIMING_FN
  @Input() minimizeIcon = 'pi pi-window-minimize'
  @Input() maximizeIcon = 'pi pi-window-maximize'
  @Input() closeIconColor: 'white' | 'gray' = 'white'

  @Input() style: Partial<CSSStyleDeclaration>
  @Input() position: DialogPosition = 'top'
  @Input() maximizable: boolean
  @Input() keepUnderToolbar: boolean
  @Input() maximized: boolean
  @Input() inlineWrapperSelector: string

  @ViewChild(DialogComponent, { static: false }) dialog: DialogComponent

  @Output() showEvent: EventEmitter<Event> = new EventEmitter<Event>()
  @Output() hideEvent: EventEmitter<Event> = new EventEmitter<Event>()
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  inLine: boolean

  constructor(
    @Optional() @Host() private readonly _appendTo: AppendToDirective,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.inLine = !!_appendTo
    this.modal = !_appendTo
  }

  private _handleOverlayClick(): void {
    if (this.dismissableMask && !!this._appendTo.close$) {
      this._appendTo.close$.pipe(take(1)).subscribe(() => this.close())
    }
  }

  open(): void {
    this.visible = true
    this.cdr.detectChanges()
    if (this._appendTo && this.inLine) {
      this._appendTo.appendOverlay()
      this._handleOverlayClick()
    }
  }

  close(): void {
    this.visible = false
    this.cdr.detectChanges()
    if (this._appendTo && this.inLine) {
      this._appendTo.removeOverlay()
    }
  }

  onVisibleChange(visible: boolean): void {
    this.visibleChange.emit(visible)
    this.visible = visible
  }

  onShow(event): void {
    this.showEvent.emit(event)
  }

  onHide(event): void {
    this.hideEvent.emit(event)
  }

  @debounce(0)
  center(): void {
    this.dialog?.center()
  }

  @debounce(0)
  maximize(): void {
    this.dialog?.maximize()
  }
}
