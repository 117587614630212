<mc-match-item
  [match]="matchItemData"
  [hasState]="false"
  [modifiers]="modifiers || []"
></mc-match-item>
<p
  *ngIf="matchItemData?.state?.minuteDescription != null && isBanner"
  class="c-match-item__result-state-min"
>
  ({{
    matchItemData?.state?.name !== MatchState.HalfTime
      ? ('COMMONS_PLAY_LIVE_MATCH_MIN'
        | translate: { min: matchItemData?.state?.minuteDescription })
      : (MATCH_STATE_CONFIG[matchItemData?.state?.name]?.label | translate)
  }})
</p>
