import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MatchMetric } from '@core/models/dto/match.dto'
import { Competition, PLACEHOLDER_IMAGES, ListHeader } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-metric-list',
  templateUrl: './metric-list.component.html',
  styleUrls: ['./metric-list.component.theme.scss', './metric-list.component.scss'],
})
export class MetricListComponent {
  @Input() lang: string
  @Input() metrics: {
    header: ListHeader
    list: MatchMetric[]
  }
  @Input() centerText: string
  @Input() rightTriggerIcon: string
  @Input() competition: Competition
  @Input() isMatchData: boolean

  @Output() aggregationModeClick = new EventEmitter<void>()

  readonly competitionIdentifier = 'LALIGA'
  readonly playerPlaceholder = PLACEHOLDER_IMAGES.PLAYER
  readonly competitionPlaceholder = PLACEHOLDER_IMAGES.COMPETITION
  readonly teamPlaceholder = PLACEHOLDER_IMAGES.TEAM
}
