import { CameraAsset } from '@core/models/dto/assets.dto'
import { VideoType } from '@mediacoach-ui-library/global'

export const ASSETS_TO_SANITIZE = ['manifest.plist', 'mobile-manifest.plist']

export const STREAMING_EXCLUDED_ASSETS = ['WarmUp']

export const LITERALS_FOR_VIDEO_TYPES = {
  [VideoType.Tv]: 'MATCHDETAIL_MEDIA_SUB_TV',
  [VideoType.Panoramic]: 'MATCHDETAIL_MEDIA_SUB_PANA',
  [VideoType.Tactical]: 'MATCHDETAIL_MEDIA_SUB_TACTICALCAMERA',
}

export const LITERALS_FOR_VIDEO_TITLES = {
  [VideoType.Tv]: 'MATCHDETAIL_MEDIA_LAB_TV',
  [VideoType.Panoramic]: 'MATCHDETAIL_MEDIA_LAB_PANA',
  [VideoType.Tactical]: 'MATCHDETAIL_MEDIA_LAB_TACTICALCAMERA',
}

export const DEFAULT_CAMERA_ASSET: CameraAsset[] = [
  {
    assetType: 'tac',
    label: 'MATCHDETAIL_MEDIA_LAB_TACTICALCAMERA',
    value: 'tac',
    disabled: true,
  },
]
