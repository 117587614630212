import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { QueryBase } from '@shared/components/query/models/query-base.model'

@Component({
  selector: 'mcp-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryComponent {
  @Input() query: QueryBase<any>
  @Input() control: UntypedFormControl

  @Output() queryChange = new EventEmitter()
}
