import { Action, createReducer, on } from '@ngrx/store'
import { SelectionState } from '@core/state/models/selection.state'
import { setSelectedMatches } from '@core/state/actions/selection.actions'

const initialState: SelectionState = {
  matches: [],
}

const reducer = createReducer(
  initialState,
  on(setSelectedMatches, (state, { matches }) => ({ ...state, matches })),
)

export function selectionReducer(state: SelectionState | undefined, action: Action) {
  return reducer(state, action)
}
