<div
  class="app-loader"
  [class.app-loader--loading]="loading"
  [class.app-loader--no-height]="noHeight"
>
  <ng-content></ng-content>
</div>
<div class="app-loader__spinner">
  <i *ngIf="loading" class="pi pi-spinner pi-spin" aria-hidden="true"></i>
  <p *ngIf="loading" class="app-loader__title">{{ title | translate }}</p>
</div>
