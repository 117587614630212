import { GRIDSTER_CONFIG } from '../../constants/gridster-layout.constants'
import { Action, createReducer, on } from '@ngrx/store'

import { SpaceState } from '../models/space.state'
import {
  setSpaceSelectorCompetition,
  setSpaceSelectorLoading,
  setSpaceSelectorPlayer,
  setSpaceSelectorPlayers,
  setSpaceSelectorTeam,
  setSpaceSelectorTeams,
} from '@core/space/state/actions/space-selector.actions'
import {
  setSpaceLayoutConfig,
  setSpaceMediacoachTemplates,
  setSpaceTemplates,
  setSpaceWidgetLoading,
  setSpaceWidgets,
} from '@core/space/state/actions/space-layout.actions'
import {
  purgeSpace,
  setSpaceDemarcations,
  setSpaceDialogSearchLoading,
  setSpaceDialogSearchResults,
  setSpaceDialogSearchScope,
  setSpaceDialogSearchText,
  setSpaceGoalkeeperMetricDefinition,
  setSpaceHeaderLoading,
  setSpacePlayerMetricDefinition,
  setSpaceTeamMetricDefinition,
} from '@core/space/state/actions/space.actions'

const initialState: SpaceState = {
  widgets: undefined,
  templates: undefined,
  mcTemplates: undefined,
  config: GRIDSTER_CONFIG,

  widgetLoading: true,
  headerLoading: undefined,
  selectorLoading: undefined,

  selectorCompetition: undefined,
  selectorTeam: undefined,
  selectorTeams: undefined,
  selectorPlayer: undefined,
  selectorPlayers: undefined,

  teamMetricDefinition: undefined,
  playerMetricDefinition: undefined,
  goalkeeperMetricDefinition: undefined,

  demarcations: undefined,

  dialogSearch: {
    text: null,
    loading: false,
    results: null,
  },
}

const reducer = createReducer(
  initialState,
  //header
  on(
    setSpaceHeaderLoading,
    (state, { headerLoading }): SpaceState => ({ ...state, headerLoading }),
  ),
  // selector
  on(
    setSpaceSelectorTeams,
    (state, { selectorTeams }): SpaceState => ({ ...state, selectorTeams }),
  ),
  on(setSpaceSelectorTeam, (state, { selectorTeam }): SpaceState => ({ ...state, selectorTeam })),
  on(
    setSpaceSelectorPlayers,
    (state, { selectorPlayers }): SpaceState => ({ ...state, selectorPlayers }),
  ),
  on(
    setSpaceSelectorPlayer,
    (state, { selectorPlayer }): SpaceState => ({ ...state, selectorPlayer }),
  ),
  on(
    setSpaceSelectorLoading,
    (state, { selectorLoading }): SpaceState => ({ ...state, selectorLoading }),
  ),
  on(
    setSpaceSelectorCompetition,
    (state, { selectorCompetition }): SpaceState => ({
      ...state,
      selectorCompetition,
    }),
  ),

  // definitions
  on(
    setSpacePlayerMetricDefinition,
    (state, { playerMetricDefinition }): SpaceState => ({ ...state, playerMetricDefinition }),
  ),
  on(
    setSpaceGoalkeeperMetricDefinition,
    (state, { goalkeeperMetricDefinition }): SpaceState => ({
      ...state,
      goalkeeperMetricDefinition,
    }),
  ),
  on(
    setSpaceTeamMetricDefinition,
    (state, { teamMetricDefinition }): SpaceState => ({
      ...state,
      teamMetricDefinition,
    }),
  ),
  on(
    setSpaceDemarcations,
    (state, { demarcations }): SpaceState => ({
      ...state,
      demarcations,
    }),
  ),

  // layout | widgets
  on(
    setSpaceWidgetLoading,
    (state, { widgetLoading }): SpaceState => ({ ...state, widgetLoading }),
  ),
  on(
    setSpaceLayoutConfig,
    (state, { config }): SpaceState => ({
      ...state,
      config,
    }),
  ),
  on(setSpaceWidgets, (state, { widgets }): SpaceState => ({ ...state, widgets })),

  on(setSpaceTemplates, (state, { templates }): SpaceState => ({ ...state, templates })),
  on(
    setSpaceMediacoachTemplates,
    (state, { mcTemplates }): SpaceState => ({ ...state, mcTemplates }),
  ),

  // dialog
  on(
    setSpaceDialogSearchText,
    (state, { text }): SpaceState => ({
      ...state,
      dialogSearch: { ...state.dialogSearch, text },
    }),
  ),
  on(
    setSpaceDialogSearchScope,
    (state, { scope }): SpaceState => ({
      ...state,
      dialogSearch: { ...state.dialogSearch, scope },
    }),
  ),
  on(
    setSpaceDialogSearchLoading,
    (state, { loading }): SpaceState => ({
      ...state,
      dialogSearch: { ...state.dialogSearch, loading },
    }),
  ),
  on(
    setSpaceDialogSearchResults,
    (state, { results }): SpaceState => ({
      ...state,
      dialogSearch: { ...state.dialogSearch, results },
    }),
  ),
  on(purgeSpace, (): SpaceState => ({ ...initialState })),
)

export const spaceReducer = (state: SpaceState | undefined, action: Action) =>
  reducer(state, action)
