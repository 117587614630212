import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core'
import { DOCUMENT } from '@angular/common'

import { fromEvent, interval, Subject } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter, map, pairwise, tap, throttleTime } from 'rxjs/operators'

import { getScrollParent } from '@core/utils/application.utils'

const MAX_SCROLL_PERCENT = 0.95
const MIN_INTERVAL_MS = 1000

@UntilDestroy()
@Directive({
  selector: '[appInfiniteScroller]',
})
export class InfiniteScrollerDirective implements AfterViewInit {
  private _scrollableParent: HTMLElement
  private _throttledScroll = new Subject<void>()
  private _scrollEvent$ = fromEvent(window, 'scroll').pipe(
    map(() => this._getScrollY()),
    pairwise(),
    filter(
      (scrolls) => this._isUserScrollingDown(scrolls) && this._isScrollExpectedPercent(scrolls[1]),
    ),
    tap(() => this._throttledScroll.next()),
    untilDestroyed(this),
  )

  @Input() throttleTime = 500
  @Input() scrollPercent = 90
  @Output() scrolled = new EventEmitter<void>()

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _el: ElementRef,
  ) {}

  private _isUserScrollingDown = (scrolls) => scrolls[0] < scrolls[1]

  private _getScrollPercent = (scrollY = this._getScrollY()) =>
    (scrollY + this._getViewportHeight()) / this._scrollableParent.scrollHeight

  private _isScrollExpectedPercent = (scrollY = this._getScrollY()) =>
    this._getScrollPercent(scrollY) > this.scrollPercent / 100

  private _getScrollY() {
    return this._scrollableParent === this.document.body
      ? window.scrollY
      : this._scrollableParent.scrollTop
  }

  private _getViewportHeight() {
    return this._scrollableParent === this.document.body
      ? window.innerHeight
      : this._scrollableParent.clientHeight
  }

  ngAfterViewInit() {
    this._scrollableParent = getScrollParent(this.document, this._el.nativeElement)

    this._throttledScroll
      .pipe(
        throttleTime(this.throttleTime),
        tap(() => this.scrolled.next()),
        untilDestroyed(this),
      )
      .subscribe()

    this._scrollEvent$.subscribe()
    interval(Math.max(MIN_INTERVAL_MS, this.throttleTime))
      .pipe(
        tap(() => {
          if (this._getScrollPercent() > MAX_SCROLL_PERCENT) {
            this._throttledScroll.next()
          }
        }),
        untilDestroyed(this),
      )
      .subscribe()
  }
}
