import { ChangeDetectionStrategy, Component } from '@angular/core'
import { PlaylistClipSharePayload } from '@features/playlist/dialogs/dialog-playlist-share/models/dialog-playlist-share.models'
import { PlaylistDialogBase } from '@features/playlist/models/playlist-dialog.base'
import { selectNotPrivatePlaylists } from '@core/state/selectors/stream-playlist.merged-selectors'
import { Observable } from 'rxjs'
import { PlaylistResponse } from '@features/playlist/models/playlist.response.models'

@Component({
  selector: 'mcp-dialog-playlist-share',
  templateUrl: './dialog-playlist-share.component.html',
  styleUrls: ['./dialog-playlist-share.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPlaylistShareComponent extends PlaylistDialogBase {
  payload: PlaylistClipSharePayload
  playlists$: Observable<PlaylistResponse[]>

  constructor() {
    super()
    this._resolvePayload()
  }

  private _resolvePayload() {
    this.playlists$ = this._store.select(selectNotPrivatePlaylists(this.selector))
    this.payload = this._dialogConfig?.data?.payload
  }

  close(selectedCollaborators: PlaylistResponse[]) {
    this._dialogRef.close({ ...this.payload, playlists: selectedCollaborators.map(({ id }) => id) })
  }
}
