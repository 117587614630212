import { Component, Input } from '@angular/core'

import { PlayerImage, Stat } from './player-stats.models'
import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { getDistinctSimplifiedLang } from '@core/state/selectors/user.selectors'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

@Component({
  selector: 'app-player-stats',
  templateUrl: './player-stats.component.html',
  styleUrls: ['./player-stats.component.scss'],
})
export class PlayerStatsComponent {
  @Input()
  set stats(_stats: Stat[]) {
    this.statistics = (_stats || []).map((stat) => ({ ...stat, isNumber: !isNaN(stat.value) }))
  }

  @Input() playerImage: PlayerImage

  statistics: Stat[]
  lang$: Observable<string> = this._store.pipe(getDistinctSimplifiedLang())

  readonly placeholder = PLACEHOLDER_IMAGES

  constructor(private readonly _store: Store) {}
}
