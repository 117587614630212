import { Directive, ElementRef, HostListener } from '@angular/core'
import { EventParams } from '@core/analytics/models/gtag.models'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { Store } from '@ngrx/store'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'

@Directive()
export abstract class GatgClickBase {
  @HostListener('click')
  onClick() {
    const params = this.getParams()
    if (params) {
      this._store.dispatch(analyticsTrackEvent(params))
    }
  }

  constructor(
    protected readonly _host: ElementRef,
    protected readonly _store: Store,
  ) {}

  abstract getParams(): { eventName: AnalyticsEvent; eventParams: EventParams }
}
