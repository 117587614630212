<div
  class="c-modal {{ sizeClass }} {{ typeClass }}"
  *ngIf="isOpen || isInline"
  [ngClass]="extraClasses"
  [class.is-open]="isOpen"
  [class.is-inline]="isInline"
  [class.has-appendTo]="appendTo"
>
  <div
    class="c-modal__backdrop"
    *ngIf="!isInline"
    (click)="hasCloseButton || closeOnBackdrop ? closeModal() : null"
  ></div>
  <div class="c-modal__container">
    <div
      class="c-modal__header"
      *ngIf="!isInline"
      [style.background-image]="backgroundImage && 'url(' + backgroundImage + ')'"
    >
      <div *ngIf="modalHeader" class="c-modal__title">
        <ng-container *ngTemplateOutlet="modalHeader"></ng-container>
      </div>
      <div *ngIf="hasCloseButton" class="c-modal__btn" (click)="closeModal()">
        <mcp-morph-button
          [state]="'close'"
          [color]="type === 'dark' ? 'white' : ''"
        ></mcp-morph-button>
      </div>
    </div>
    <div class="c-modal__body">
      <ng-container *ngTemplateOutlet="modalBody"></ng-container>
    </div>
    <div *ngIf="modalFooter && !isInline" class="c-modal__footer">
      <ng-container *ngTemplateOutlet="modalFooter"></ng-container>
    </div>
  </div>
</div>
