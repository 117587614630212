import { ApiTeam } from '../../models/models/team.models'
import { getStaticItem, smallColorLogoPredicate } from '../../utils/assets.utils'
import {
  SpaceLeaderboardWidgetData,
  SpaceMatchDayLeaderboard,
  SpaceTeam,
  SpaceTemplate,
} from '@core/space/models/space.models'
import { TeamSpacePlayer } from '@features/team-space/models/team-space.models'
import { Widget, WidgetDisplayMode } from '@widgets/models/widget.models'
import { decodeBase64 } from '@core/utils/string.utils'

export const parseSpaceTeam = (team: ApiTeam, seasonId?: string, competitionId?: string): any => ({
  ...team,
  seasonId,
  competitionId,
  portraitLogo: getStaticItem(team.statics, smallColorLogoPredicate),
})

export const parseSpaceLeaderboard = (
  matchdayData: SpaceMatchDayLeaderboard,
  itemId: string,
  displayMode: WidgetDisplayMode,
): SpaceLeaderboardWidgetData[] =>
  matchdayData.standings.map(({ team, ...item }) => ({
    matchdayId: matchdayData.matchdayId,
    ...item,
    team: { ...team, img: getStaticItem(team.statics, smallColorLogoPredicate) },
    ...(team.id === itemId && displayMode?.rowHighlight ? { styleClass: 'row-highlight' } : {}),
  }))

export const buildTeamSpaceUrl = (
  team: SpaceTeam,
  competitionId: string,
  seasonId: string,
): string => `/teams/${team.id}/competitions/${competitionId}/seasons/${seasonId}`

export const buildPlayerSpaceUrl = (
  player: TeamSpacePlayer,
  teamId: string,
  competitionId: string,
  seasonId: string,
): string =>
  `/players/${player.id}/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

export const resolveWidgets = (widgets: string): Widget[] => {
  let widgetList = []
  try {
    widgetList = JSON.parse(decodeBase64(widgets)) as Widget[]
  } catch (error) {
    console.error(
      '[resolveWidgets] Unable to decode base64 widgets. | ',
      `[widgets]: ${widgets} | `,
      `[error]: ${error}`,
    )
  }

  return widgetList
}

export const mapSpaceTemplate = (tpl: any): SpaceTemplate => ({
  name: tpl.Name || tpl.name,
  body: tpl.Body || tpl.body,
  widgets: resolveWidgets(tpl.Body || tpl.body),
})
