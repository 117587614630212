import { createAction, props } from '@ngrx/store'
import { HubConnectionState } from '@microsoft/signalr'
import { Topics } from '@sockets/models/socket.model'
import { SocketMessage } from '@core/websockets/signalr/models/signalr.model'

export const connectSocket = createAction('[Socket] Connect')
export const fetchTopic = createAction(
  '[Socket] Get topics',
  props<{ socketMessage: SocketMessage }>(),
)
export const setSocketId = createAction('[Socket] Set socket id', props<{ id: string }>())
export const setTopic = createAction('[Socket] Set topic', props<{ topic: Topics }>())
export const setSocketStatus = createAction(
  '[Socket] Set socket status',
  props<{ status: HubConnectionState }>(),
)
