import {InteractionModeMap, PointStyle, TooltipItem} from 'chart.js'

export const COLOR_TRANSPARENT = '#ffffff00'

export const RADAR_CHART_OPTIONS = {
  interaction: {
    mode: 'index' as keyof InteractionModeMap,
  },
  layout: {
    padding: 105,
  },
  responsive: true,
  elements: {
    line: {
      borderWidth: 2,
    },
    point: {
      radius: 5,
      hoverRadius: 3,
    },
  },
  scales: {
    r: {
      min: 0,
      max: 100,
      beginAtZero: true,
      ticks: {
        display: false,
        stepSize: 20,
      },
      title: {
        padding: 8,
      },
      pointLabels: {
        display: false,
      },
    },
  },
  parsing: {
    key: 'value',
  },
  plugins: {
    tooltip: {
      padding: 15,
      boxWidth: 6,
      boxPadding: 5,
      titleColor: '#3f3f3f',
      titleMarginBottom: 15,
      bodyColor: '#3f3f3f',
      backgroundColor: '#ffffff',
      borderWidth: 1,
      borderColor: '#bebebe',
      bodySpacing: 4,
      position: 'nearest' as any,
      usePointStyle: true,
      displayColor: true,
      callbacks: {
        labelPointStyle: (_: TooltipItem<any>) => {
          return {
            pointStyle: 'circle' as PointStyle,
            radius: 3,
            hitRadius: 1,
            hoverRadius: 1,
            borderWidth: 1,
            rotation: 0,
          }
        },
        labelColor: (context: TooltipItem<any>) => {
          const { chart, datasetIndex } = context
          const dataset: any = chart.data.datasets[datasetIndex]
          return {
            borderColor: dataset.borderColor,
            backgroundColor: dataset.borderColor,
          }
        },
        label: (context: TooltipItem<any>): string | string[] | void => {
          const { datasetIndex, dataIndex, chart } = context
          const dataset: any = chart.data.datasets[datasetIndex]
          const data: any = dataset.data[dataIndex]

          const seasonName = dataset.entity.seasonName
            ? dataset.entity.seasonName.replace(/^\d{2}/g, '').replace(/ - \d{2}/g, '/')
            : ''

          const _label =
            dataset.isCompetition && seasonName?.length
              ? `${dataset.label} (${seasonName})`
              : dataset.label

          return `${_label}: ${data.label} `
        },
      },
    },
    radarChartLegend: {
      position: 'bottom' as any,
    },
    legend: {
      display: false,
      position: 'bottom' as any,
    },
  },
}
