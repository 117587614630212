export enum StepActionType {
  next = 'next',
  prev = 'prev',
  close = 'close',
  done = 'done',
}

export enum ElementPositioning {
  beyond = 1,
  beneath = 2,
  unset = 3,
}
