import { createSelector, select } from '@ngrx/store'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { Season } from '@core/models/dto/season.dto'
import { pipe } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { selectSpaceFeature } from './space.selectors'
import { PLAYER_POSITION_PLURAL_KEYS } from '@core/constants/player.constants'

export const selectSpaceSelectorLoading = createSelector(
  selectSpaceFeature,
  (state) => state.selectorLoading,
)

export const selectSpaceSelectorTeam = createSelector(
  selectSpaceFeature,
  (state) => state.selectorTeam,
)

export const selectSpaceSelectorTeams = createSelector(
  selectSpaceFeature,
  (state) => state.selectorTeams,
)

export const selectSpaceSelectorFilteredTeams = () =>
  pipe(
    select(selectSpaceSelectorTeams),
    filter((teams) => !!teams),
    map((teams) => teams),
  )

const selectParamsAndSeasons = createSelector(
  getMergedRouteParams,
  getSeasons,
  (params: { [key: string]: string }, seasons: Season[]) => ({ params, seasons }),
)

export const selectSpaceSelectorCompetition = createSelector(
  selectSpaceFeature,
  (state) => state.selectorCompetition,
)

export const selectSpaceIsRegularCompetition = createSelector(
  selectSpaceSelectorCompetition,
  (competition) => competition?.competitionType?.toLowerCase() === 'regular',
)

export const selectSpaceSelectorCompetitions = () =>
  pipe(
    select(selectParamsAndSeasons),
    filter(({ params, seasons }) => !!params && !!seasons?.length),
    map(
      ({ params, seasons }) =>
        (seasons.find((s) => s.id === params['seasonId']) || ({} as Season)).competitions,
    ),
  )

export const selectSpaceSelectorTeamIndex = createSelector(
  selectSpaceSelectorTeams,
  selectSpaceSelectorTeam,
  (teams, selectorTeam) => {
    if (teams?.length && selectorTeam) {
      return teams.findIndex((t) => t.id === selectorTeam.id)
    }
  },
)

export const selectSpaceSelectorPlayer = createSelector(
  selectSpaceFeature,
  (state) => state.selectorPlayer,
)

export const selectSpaceSelectorPlayers = createSelector(
  selectSpaceFeature,
  (state) => state.selectorPlayers,
)

export const selectSpaceSelectorFilteredPlayers = () =>
  pipe(
    select(selectSpaceSelectorPlayers),
    filter((players) => !!players),
    map((players) =>
      players.map((p, idx) => ({
        ...p,
        hasDivider:
          idx == 0
            ? true
            : idx > 0 &&
              ((p.playerPosition !== players[idx - 1].playerPosition &&
                players[idx - 1].shirtNumber !== 0) ||
                (players[idx - 1].shirtNumber !== 0 && p.shirtNumber === 0)),
        dividerTitle:
          p.shirtNumber === 0
            ? 'MTR_PLAYERPOSITION_NO_SQUAD'
            : PLAYER_POSITION_PLURAL_KEYS[p.playerPosition],
      })),
    ),
  )

export const selectSpaceSelectorPlayerIndex = createSelector(
  selectSpaceSelectorPlayers,
  selectSpaceSelectorPlayer,
  (players, selectorPlayer) => {
    if (players?.length && selectorPlayer) {
      return players.findIndex((t) => t.id === selectorPlayer.id)
    }
  },
)

export const selectCurrentSeason = createSelector(
  getSeasons,
  getMergedRouteParams,
  (seasons, params) => {
    if (seasons?.length && params['seasonId']) {
      return seasons.find((s) => s.id === params['seasonId'])
    }
  },
)

export const selectCurrentRegularMatchDay = createSelector(selectCurrentSeason, (season) => {
  if (season) {
    const competition = season.competitions.find(
      (c) => c.competitionType.toLowerCase() === 'regular',
    )
    if (competition) {
      const matchday = competition.matchdays.find((s) => s.selected)
      if (matchday) {
        return matchday.id
      }
    }
  }
})
