<app-dialog
  class="mcp-aggregation-selector-dialog"
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  [styleClass]="extraClasses"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header mcp-aggregation-selector-dialog__header-title">
    <span>{{ 'MTR_COMMON_AGGREGATION_METRICS_TITLE' | translate }}</span>
  </div>

  <div class="c-layout__block mcp-aggregation-selector-dialog__body">
    @for (aggregation of aggregationModes || []; track aggregation.id) {
      <div
        class="mcp-aggregation-selector-dialog__item"
        [class.mcp-aggregation-selector-dialog__item--active]="
          aggregationSelected === aggregation.id
        "
        (click)="aggregationSelected = aggregation.id"
      >
        <span class="mcp-aggregation-selector-dialog__item-content">
          {{
            (againstCompetition && aggregation.alt ? aggregation.alt : aggregation.text) | translate
          }}
        </span>
      </div>
    }
  </div>
  <div class="mcp-aggregation-selector-dialog__footer">
    <mcui-button
      [disabled]="!aggregationSelected || aggregationSelected === selection"
      (click)="selectionChange.emit(aggregationSelected)"
      [text]="'MTR_COMMON_SELECT' | translate"
    ></mcui-button>
  </div>
</app-dialog>
