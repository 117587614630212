import { Action, createReducer, on } from '@ngrx/store'
import { setLanguage, setTranslationsLoaded } from '@core/i18n/state/actions/i18n.actions'
import { I18nState } from '@core/i18n/models/i18n.models'

const initialState: I18nState = {
  loaded: false,
  lang: undefined,
}

const reducer = createReducer(
  initialState,
  on(setTranslationsLoaded, (state, { loaded }) => ({ ...state, loaded })),
  on(setLanguage, (state, { lang }) => ({ ...state, lang })),
)

export const i18nReducer = (state: I18nState | undefined, action: Action) => reducer(state, action)
