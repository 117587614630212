<div
  class="c-table"
  [ngClass]="['c-table--' + justify, sizeClass]"
  [class.c-table--grid]="isTypeGrid"
>
  <div
    class="c-table__header"
    [ngClass]="headerExtraClasses"
    appIsSticky
    [stickyOrder]="stickyHeader ? 1 : -1"
  >
    <div class="c-table__row c-table__row--header">
      <ng-container *ngFor="let header of contentConfig">
        <div
          *ngIf="header['header']"
          class="c-table__cell
                        {{ header['extraClassHeader'] }}
                        {{ header['defaultClass'] }}
                        {{ header['display'] }}
                        {{ header['extraClass'] }}"
        >
          {{ header['header'] | translate }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="c-table__body">
    <virtual-scroller
      class="c-table__virtual-scroll-container"
      #scroll
      [items]="items"
      [enableUnequalChildrenSizes]="true"
    >
      <ng-container *ngFor="let item of scroll.viewPortItems; let odd = odd; trackBy: trackByFn">
        <div
          *ngIf="item"
          class="c-table__row"
          [class.c-table__row--odd]="odd"
          [class.is-selected]="item['selected']"
          [class.c-table__row--selectable]="isSelectable"
          [ngClass]="item['extraClass']"
          [ngStyle]="item['customStyle']"
        >
          <div
            class="c-table__cell {{ content['defaultClass'] }} {{ content['display'] }} {{
              content['extraClass']
            }}"
            *ngFor="let content of contentConfig"
            [ngSwitch]="true"
          >
            <ng-container
              *ngSwitchCase="content['type'] === ContentType.Template"
              [ngTemplateOutlet]="content['template']"
              [ngTemplateOutletContext]="{ $implicit: item }"
            >
            </ng-container>
            <ng-container *ngSwitchCase="content['type'] === ContentType.Image">
              <img
                class="c-table__img {{ content['imageControl'] }}"
                [srcUrl]="item[content['value']]"
                [appDefaultImage]="content['defaultImageValue']"
                [title]="item[content['altValue']] || ''"
              />
              <span class="c-table__label" *ngIf="item[content['label']]">{{
                item[content['label']]
              }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="content['type'] === ContentType.Date">
              {{
                item[content['value']]?.toString()
                  | localizedDate: content['dateFormat'] || 'dd/MM/yyyy'
              }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ item[content['value']]?.toString() | translate }}
            </ng-container>
          </div>
        </div>
      </ng-container>
    </virtual-scroller>
  </div>
</div>
