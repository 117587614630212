<ng-container *ngIf="data">
  <div class="player__container" [class.is-from-match]="isFromMatch">
    <ng-container *ngIf="isFromMatch">
      <div class="player__container--title">
        <app-title
          extraClasses="no-gradient"
          [size]="TitleSize.M"
          [title]="'MTR_METRICCATEGORY_RESUMEN' | translate"
        ></app-title>
      </div>
    </ng-container>
    <mcui-dual-header
      [leftText]="data?.spiderChartHeader?.leftContent?.title"
      [leftImage]="data?.spiderChartHeader?.leftContent?.img"
      [leftSubtitleImage]="competition?.logo"
      [leftImagePlaceholder]="playerPlaceholder"
      [leftSubtitleImagePlaceholder]="competitionPlaceholder"
      [leftPills]="[data?.spiderChartHeader?.leftContent?.subtitle | translate]"
      [rightPills]="[data?.spiderChartHeader?.rightContent?.subtitle | translate]"
      [rightText]="
        data?.spiderChartHeader?.rightContent?.title === competitionIdentifier
          ? competition.officialName
          : data?.spiderChartHeader?.rightContent?.title
      "
      [rightImage]="data?.spiderChartHeader?.rightContent?.img"
      [rightSubtitleImage]="competition?.logo"
      [rightImagePlaceholder]="
        data?.spiderChartHeader?.rightContent?.isPlayer ? playerPlaceholder : competitionPlaceholder
      "
      [rightSubtitleImagePlaceholder]="competitionPlaceholder"
      [showAccent]="true"
    />
    <mc-spider-chart
      [flatMode]="true"
      [data]="data?.spiderChart"
      (onClickMetric)="chartMetricChange.emit($event)"
    >
      <ng-template mcTemplate="header">
        <div class="player__position-btn">
          <div (click)="changePlayerPosition()" class="player__position-text">
            <span>{{ currentPlayer.metricPlayerPositionKey | translate }}</span>
            <div class="player__position-text-icon" *ngIf="canChangePlayerPosition()">
              <i class="u-icon u-icon--loop"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </mc-spider-chart>
    <ng-container *ngIf="isFromMatch">
      <div class="player__container--title">
        <app-title
          extraClasses="no-gradient"
          [size]="TitleSize.M"
          [title]="
            'MTR_METRIC_OF'
              | translate
                : {
                    position: currentPlayer.metricPlayerPositionKey | translate,
                  }
          "
        ></app-title>
      </div>
    </ng-container>
    <mcp-metric-list
      [metrics]="metricsData"
      [competition]="competition"
      [centerText]="aggregationHeader?.text | translate"
      [rightTriggerIcon]="showAggregationIcon && 'loop'"
      [lang]="lang"
      (aggregationModeClick)="aggregationModeClick.emit()"
    />
    <ng-container *ngIf="!hasLineup && aggregationMode === MetricAggregation.MatchValues">
      <div class="player__not-available">
        <p>{{ 'MATCHDETAIL_MEDIA_LAB_NOTAVAILABLE_FOR' | translate }}</p>
        <img
          *ngIf="competition"
          class="player__not-available-img"
          [srcUrl]="competition?.logo"
          [title]="competition?.officialName"
          appDefaultImage
        />
      </div>
    </ng-container>
  </div>
</ng-container>
