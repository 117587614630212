<div class="mcp-competition-tabs" [class.disabled]="loading">
  <mcui-tabview [(activeIndex)]="selectedIndex" (tabChange)="onTabChange($event)">
    <mcui-tab-panel *ngFor="let tab of tabs; index as i" [disabled]="loading">
      <ng-template mcuiTemplate="header">
        <div [class.selected]="i === selectedIndex" [ngClass]="tab?.display">
          <div
            class="mcp-competition-tabs__tab--text"
            *ngIf="tab.display === 'text'"
            [class.mcp-competition-tabs__tab--only-text]="
              tabs.length === 1 && tab.display === 'text'
            "
          >
            <span class="mcp-competition-tabs__tab-title">
              {{
                tab?.titleExtras
                  ? (tab?.title | translate: tab?.titleExtras)
                  : (tab?.title | translate)
              }}</span
            >
            <span
              *ngIf="tab?.subtitle && !hideTabsSubtitle"
              class="mcp-competition-tabs__tab-subtitle"
            >
              {{ tab?.subtitle | translate: tab?.subtitleExtras }}</span
            >
          </div>

          <img
            *ngIf="tab.display === 'competition'"
            [mcuiDefaultImage]="competitionPlaceholder"
            [srcUrl]="tab.logo"
            [alt]="tab.title"
          />
          <span
            class="mcp-competition-tabs__tab--image-subtitle"
            *ngIf="tab.display === 'competition'"
          >
            {{
              tab?.subtitle && !hideTabsSubtitle
                ? (tab?.subtitle
                  | translate
                    : {
                        position: tab?.subtitleExtras?.position || '',
                        matchday: tab?.subtitleExtras?.matchday || ''
                      })
                : ''
            }}</span
          >
        </div>
      </ng-template>
    </mcui-tab-panel>
  </mcui-tabview>
</div>
