import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core'

import {
  ConnectionCoordinateType,
  FieldDiagramModifier,
  FieldDirection,
  IFieldDiagramData,
  TeamType,
} from '@mediacoach-ui-library/global'

import { debounceTime, map, tap } from 'rxjs/operators'

import { FIELD_DIAGRAM_SEGMENT_OPTIONS } from './field-diagram.constants'
import { SegmentOption } from '@core/models/models/common.models'

@Component({
  selector: 'app-field-diagram',
  templateUrl: './field-diagram.component.html',
  styleUrls: ['./field-diagram.component.scss'],
})
export class FieldDiagramComponent {
  private _data: IFieldDiagramData

  get data(): IFieldDiagramData {
    return this._data
  }
  @Input() set data(_data: IFieldDiagramData) {
    this._data = null
    const selectedPlayerNumber = this.selectedPlayerNumber
    this.selectedPlayerNumber = null
    this._ref.detectChanges()
    this.selectedPlayerNumber = selectedPlayerNumber
    this._data = _data
  }

  @Input() loading = false
  @Input() showPopUp
  @Input() title
  @Input() type: TeamType = TeamType.Home
  @Input() resetWhenClickOutside = true
  @Input() options: SegmentOption[] = [...FIELD_DIAGRAM_SEGMENT_OPTIONS]
  @Input() segmentValue: string = FIELD_DIAGRAM_SEGMENT_OPTIONS[0].id
  @Input() isColored: boolean
  @Input() playerPopUpTemplate: TemplateRef<any>
  @Input() arrowDirection: FieldDirection = FieldDirection.Right
  @Input() connectionCoordinateType: ConnectionCoordinateType = ConnectionCoordinateType.Target
  @Input() selectedPlayerNumber = null
  @Input() keepPlayerSelected = false

  @Output() onChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() onPlayerChange: EventEmitter<number | string> = new EventEmitter<number | string>()

  selectedPlayer$ = this.onPlayerChange.pipe(
    debounceTime(0),
    map((player) => !!player),
  )
  connectionCoordinateChange$ = this.onChange.pipe(
    debounceTime(0),
    tap((type: ConnectionCoordinateType) => (this.connectionCoordinateType = type)),
  )
  FieldDiagramModifier = FieldDiagramModifier

  constructor(private _ref: ChangeDetectorRef) {}

  onSegmentChange(segmentValue: any) {
    this.segmentValue = segmentValue
    this.onChange.emit(this.segmentValue)
  }
}
