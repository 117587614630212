import { HeaderMenuAction } from '@core/main/components/header/enum/header-menu-actions.enum'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'

export const HEADER_MENU_ENTRIES: NavigableItem[] = [
  {
    label: 'P_COMMONS_PROFILE_SECTION_TITLE',
    action: HeaderMenuAction.openProfile,
  },
  {
    label: 'P_COMMONS_PROFILE_LAB_CHANGE_PWD',
    action: HeaderMenuAction.changePassword,
  },
  {
    label: 'P_COMMONS_HEADER_LAB_LOGOUT',
    action: HeaderMenuAction.logout,
    icon: 'logout',
    separatorTop: true,
  },
]

export const ONBOARDING_MENU_ENTRY: NavigableItem = {
  label: 'P_COMMONS_PROFILE_LAB_START_TOUR',
  action: HeaderMenuAction.startTour,
}
