<div
  [ngClass]="{
    'c-metric-dialog--player': type === MetricCategory.Player,
    'c-metric-dialog--team': type === MetricCategory.Team
  }"
>
  <app-dialog
    [closable]="false"
    [styleClass]="extraClasses"
    [mcpAppendTo]="ref"
    [inlineWrapperSelector]="ref"
    [keepUnderToolbar]="!!ref"
    (showEvent)="onShow()"
  >
    <div class="dialog-header c-metric-dialog__header" [ngSwitch]="type">
      <ng-container *ngSwitchCase="MetricCategory.Player">
        <ng-container *ngTemplateOutlet="playerHeader; context: { $implicit: data }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="MetricCategory.Team">
        <ng-container *ngTemplateOutlet="teamHeader; context: { $implicit: data }"></ng-container>
      </ng-container>
    </div>
    <div class="c-metric-dialog__body">
      <div class="c-metric-dialog__data" [class.is-dual]="isDual">
        <span class="c-metric-dialog__data-key">{{ data?.metricLabel | translate }}</span>
        <ng-container *ngIf="!isDual; else dualMode">
          <span class="c-metric-dialog__data-value">
            {{ (data?.metricValue | number: '' : (language$ | async)) || '-'
            }}{{ (data?.metricUnit | translate) || '' }}
          </span>
        </ng-container>
      </div>
      <div class="c-metric-dialog__button-container">
        <button class="c-metric-dialog__button" (click)="close()">
          {{ 'DEMO_FORM_BTN_CLOSE_DIALOG' | translate }}
        </button>
      </div>
    </div>
  </app-dialog>
</div>

<ng-template #dualMode>
  <mc-list [data]="data?.dualData" [itemTemplate]="metricItem"></mc-list>
</ng-template>

<ng-template #metricItem let-item>
  <mc-metric-list-item [data]="item"></mc-metric-list-item>
</ng-template>

<ng-template #playerHeader let-data>
  <div class="c-metric-dialog__header-content">
    <img
      class="c-metric-dialog__header-content-logo"
      slot="start"
      [appDefaultImage]="PLAYER_PLACEHOLDER_IMAGE"
      [srcUrl]="data?.imgUrl"
      [alt]="data?.name"
    />
    <div class="c-metric-dialog__header-content-info">
      <span class="c-metric-dialog-info__name">
        {{ data?.name }}
      </span>
      <span class="c-metric-dialog-info__position">
        {{ data?.position | translate }}
      </span>
      <div class="c-metric-dialog-info__team">
        <img
          [appDefaultImage]="TEAM_PLACEHOLDER_IMAGE"
          [srcUrl]="data?.team?.portraitLogo"
          [alt]="data?.team?.name"
        />
        <span class="c-metric-dialog-info__team-name">
          {{ data?.team?.name }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #teamHeader let-data>
  <div class="c-metric-dialog__header-content">
    <img
      class="c-metric-dialog__header-content-logo"
      slot="start"
      [appDefaultImage]="TEAM_PLACEHOLDER_IMAGE"
      [srcUrl]="data?.imgUrl"
      [alt]="data?.name"
    />
    <div class="c-metric-dialog__header-content-info">
      <span class="c-metric-dialog-info__name">
        {{ data?.name }}
      </span>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="open$$ | async"></ng-container>
