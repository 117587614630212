import { Directive, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  selector: '[mcpDisable]',
})
export class DisableDirective {
  constructor(private readonly _ctrl: NgControl) {}

  @Input() set disable(condition: boolean) {
    const method = condition ? 'disable' : 'enable'
    if (this._ctrl) {
      this._ctrl.control[method]({ emitEvent: false, onlySelf: true })
    }
  }
}
