import { Pipe, PipeTransform } from '@angular/core'
import { Column } from '@mediacoach/ui'

@Pipe({
  name: 'nestedSortableKey',
})
export class NestedSortableKeyPipe implements PipeTransform {
  transform(columns: Column[], path: string, valueKey: string = 'value'): Column[] {
    if (columns && path) {
      return columns.map((c) => ({
        ...c,
        sortableKey: `${path}.${c.sortableKey || c.key || c.label}.${valueKey}`,
      }))
    }
    return columns
  }
}
