import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { CameraAsset } from '@core/models/dto/assets.dto'
import { MatchItemData, MatchItemModifier } from '@mediacoach-ui-library/global'
import { toMatchItemData } from '@core/utils/match.utils'
import { getCameraAssets } from '@core/utils/stream.utils'
import { RoutePermissionKey } from '@auth/enums/auth.enum'
import { StreamMatch } from '@core/models/models/match.models'

@Component({
  selector: 'mcp-stream-header',
  templateUrl: './stream-header.component.html',
  styleUrls: ['./stream-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreamHeaderComponent {
  private _match: StreamMatch

  get match() {
    return this._match
  }
  @Input() set match(m: StreamMatch) {
    this.matchItemData = toMatchItemData(m)
    this._match = m
    if (m) {
      this.cameraList = getCameraAssets(m.isLive, m?.streamsVoD)
    }
  }
  @Input() cameraList: CameraAsset[]
  @Input() selectedCamera: string
  @Input() hideNavigation: boolean
  @Input() ref: any

  @Output() cameraChange = new EventEmitter<CameraAsset>()
  @Output() navigate = new EventEmitter<StreamMatch>()
  @Output() close = new EventEmitter<void>()

  readonly matchItemModifier = MatchItemModifier
  readonly routePermissionKey = RoutePermissionKey.MatchDetail

  matchItemData: MatchItemData
}
