import { Directive, DoCheck, ElementRef, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Directive({
  selector: 'mcp-match-item-list-container',
})
export class MatchItemListFooterWidthDirective implements DoCheck {
  private _width = 0
  private get _currentWidth(): number {
    return this._host.nativeElement.getBoundingClientRect().width
  }

  constructor(
    private readonly _host: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngDoCheck() {
    if (this._width !== this._currentWidth) {
      this._width = this._currentWidth
      this.document.documentElement.style.setProperty('--matches-list-width', `${this._width}px`)
    }
  }
}
