<div [ngClass]="{ 'c-file-upload__container': type === fileUploadType.Common }">
  <ng-container *ngIf="type === fileUploadType.Common">
    <app-label
      [label]="label"
      [tooltip]="tooltip"
      [tooltipStyleClass]="tooltipStyleClass"
    ></app-label>
  </ng-container>
  <div class="c-file-upload__subtitle" *ngIf="subtitle">
    <p class="c-file-upload__subtitle-line" *ngFor="let line of subtitle">
      {{ line.text | translate: { args: line.args } }}
    </p>
  </div>
  <ng-container *ngIf="type === fileUploadType.Common">
    <div class="c-file-upload__row" *ngFor="let file of fileUpload.files">
      <div>{{ file.name }}</div>
      <span class="u-icon u-icon--times" (click)="removeFile(file, fileUpload)"></span>
    </div>
  </ng-container>
  <root-file-upload
    class="c-file-upload__wrapper c-file-upload--{{ type }}"
    auto="true"
    [chooseLabel]="'MTR_COMMON_SELECT_FILE' | translate"
    [multiple]="multiple"
    [disabled]="disabled"
    [class.c-file-upload--dragging]="isDragging | async"
    [class.c-file-upload--disabled]="disabled"
    [class.c-file-upload--loading]="loading"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [minFileSize]="minFileSize"
    [invalidMaxFileSizeMessageDetail]="invalidMaxFileSizeMessageDetail"
    [invalidMinFileSizeMessageDetail]="invalidMinFileSizeMessageDetail"
    [invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
    (selectEvent)="onPreselect()"
    (uploadHandler)="onSelectFn($event)"
    #fileUpload
  >
    <ng-container [ngSwitch]="type">
      <mcui-avatar
        *ngSwitchCase="fileUploadType.Avatar"
        class="c-file-upload__image"
        [title]="avatarProps?.title"
        [text]="avatarProps?.text"
        [backgroundColor]="avatarProps?.color"
        [imageSource]="base64Value"
        placeholderIcon="camera"
      ></mcui-avatar>
    </ng-container>
  </root-file-upload>
</div>
