import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'

@Directive({
  selector: '[mcpGa3Attributes]',
})
export class AnalyticsAttributesDirective {
  @Input('mcpGa3Attributes') set attribs(attr: Record<string, any>) {
    if (attr) {
      this._setAttributes(attr)
    }
  }

  private get _element() {
    return this._host?.nativeElement
  }

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2,
  ) {}

  private _setAttributes(attr: Record<string, any>) {
    Object.keys(attr).forEach((key) => {
      this._renderer.setAttribute(this._element, key, attr[key])
    })
  }
}
