import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnInit,
  SkipSelf,
  ViewChild,
} from '@angular/core'
import {
  ArrowDirection,
  StepProgress,
  TourStep,
} from '@shared/modules/tour/models/tour-step.models'
import { EventListenerService } from '@shared/modules/tour/services/event-listener/event-listener.service'
import { TourStepsContainerService } from '@shared/modules/tour/services/tour-steps-container/tour-steps-container.service'
import { TourStepService } from '@shared/modules/tour/services/tour-step/tour-step.service'
import { getArrowDirection } from '@shared/modules/tour/utils/tour.utils'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { DomService } from '@shared/modules/tour/services/dom/dom.service'
import { StepPositionDirective } from '@shared/modules/tour/directives/step-position.directive'
import { DebuggableBaseService } from '@shared/modules/tour/services/debuggable-base/debuggable-base.service'

@Component({
  selector: 'mcp-tour-step',
  templateUrl: './tour-step.component.html',
  styleUrls: ['./tour-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourStepComponent extends DebuggableBaseService implements OnInit, AfterViewInit {
  @ViewChild(StepPositionDirective, { static: false }) private _stepDrawer: StepPositionDirective
  @Input() step: TourStep

  showArrow = true
  arrowDirection: ArrowDirection
  progressData: StepProgress
  options: TourOptions
  isCustomized: boolean

  get targetDimensions() {
    return this._stepDrawer?.getTargetDimensions()
  }

  get stepDimensions() {
    return this._stepDrawer?.getStepDimensions()
  }

  constructor(
    @SkipSelf() private readonly stepService: TourStepService,
    private injector: Injector,
    private readonly stepsContainerService: TourStepsContainerService,
    private readonly eventListenerService: EventListenerService,
    private readonly _dom: DomService,
  ) {
    super(injector)
  }

  ngOnInit(): void {
    this.options = this.stepService.getOptions()
    this.step.position = this.step?.position || this.options?.stepDefaultPosition
    this.showArrow = this.step.position !== 'center' && this.step.position !== 'bottom|right'
    this.arrowDirection = getArrowDirection(this.step.position)
    this.progressData = {
      step: this.stepsContainerService.getStepNumber(this.step.name),
      total: this.stepsContainerService.getStepsCount(),
      text: this.options.buttonsText.progress$,
    }
  }

  ngAfterViewInit() {
    this.isCustomized = this._hasCustomTemplates()
  }
  private _hasCustomTemplates(): boolean {
    return !!(
      this.step.stepContent ||
      this.step.closeButtonTpl ||
      this.step.prevButtonTpl ||
      this.step.doneButtonTpl ||
      this.step.nextButtonTpl ||
      this.options.closeButtonTpl ||
      this.options.prevButtonTpl ||
      this.options.doneButtonTpl ||
      this.options.nextButtonTpl
    )
  }

  prev() {
    this.stepService.prev()
  }

  next() {
    this.stepService.next()
  }

  close(isCompleted?: boolean) {
    this.stepService.close(isCompleted)
  }

  isFirstStep() {
    return this.stepsContainerService.getStepNumber(this.step.name) === 1
  }

  isLastStep(): boolean {
    return (
      this.stepsContainerService.getStepNumber(this.step.name) ===
      this.stepsContainerService.getStepsCount()
    )
  }
}
