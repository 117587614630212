import { Directive, Input } from '@angular/core'
import { EventParams, Ga4Params } from '@core/analytics/models/gtag.models'
import { GatgClickBase } from '@shared/modules/analytics-companion/models/gatg-click.base'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'

@Directive({
  selector: '[mcpGA4Click]',
})
export class GtagClickDirective extends GatgClickBase {
  @Input('mcpGA4Click') ga4Params: Ga4Params

  getParams() {
    if (this.ga4Params) {
      const { name, ...eventParams } = this.ga4Params || {}
      return {
        eventName: name as AnalyticsEvent,
        eventParams: eventParams as EventParams,
      }
    }

    return undefined
  }
}
