export const runDebounced = (
  method: (...args) => unknown,
  ms: number = 300,
  callback?: () => unknown,
): void => {
  setTimeout(() => {
    method()
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !!callback && callback()
  }, ms)
}

export const runAsPromise = (method: (...args) => unknown): Promise<void> => {
  method()
  return Promise.resolve()
}

export const runDebouncedPromise = (
  method: (...args) => unknown,
  ms: number = 300,
): Promise<void> => new Promise((resolve) => runDebounced(method, ms, resolve))
