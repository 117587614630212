import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { translateKey } from '@core/utils/translate.utils'

@Pipe({
  name: 'toTranslateObject',
})
export class ToTranslateObjectPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}
  transform(keys: string[], _lang: string): { [key: string]: string } {
    if (keys) {
      return keys.reduce(
        (rData, key) => ({ ...rData, [key]: translateKey(this.translate, key, _lang) || key }),
        {},
      )
    }
    return null
  }
}
