import { Observable } from 'rxjs'

export const readFile = (blob: Blob, readType: string, reader: FileReader = new FileReader()) =>
  new Observable((obs) => {
    if (!blob) {
      return
    }
    if (blob && !(blob instanceof Blob)) {
      obs.error(new Error('`blob` must be an instance of File or Blob.'))
      return
    }

    reader.onerror = (err) => obs.error(err)
    reader.onabort = (err) => obs.error(err)
    reader.onload = () => obs.next(reader.result)
    reader.onloadend = () => obs.complete()

    return reader[readType](blob)
  })
