import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import {
  changeLocation,
  navigate,
  navigateByUrl,
  navigateExternal,
  navigateToNewTab,
  navigateToSelectedMatch,
} from '@core/router/state/actions/router.actions'
import { Location } from '@angular/common'
import { Store } from '@ngrx/store'
import { storeData } from '@core/state/actions/storage.actions'
import { STORAGE_KEY_MATCHES_SELECTED } from '@features/matches/constants/matches.constants'
import { StorageType } from '@shared/services/storage/storage.service'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { parseMatchToLocalStorage } from '@core/utils/match.utils'

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigate),
        tap(({ path, relativeTo, replaceUrl }) =>
          this._router.navigate([path], { relativeTo, replaceUrl }),
        ),
      ),
    { dispatch: false },
  )

  navigateToNewTab$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateToNewTab),
        map(({ path }) => this._router.serializeUrl(this._router.createUrlTree([path]))),
        tap((url) => window.open(url, '_blank')),
      ),
    { dispatch: false },
  )

  navigateExternal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateExternal),
        tap(({ path }) =>
          this._router.navigate(['/externalRedirect', { externalUrl: path, target: '_self' }]),
        ),
      ),
    { dispatch: false },
  )

  navigateByUrl$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(navigateByUrl),
        tap(({ path }) => this._router.navigateByUrl(path)),
      ),
    { dispatch: false },
  )

  changeLocation$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(changeLocation),
        tap(({ path }) => this._location.go(path)),
      ),
    { dispatch: false },
  )

  navigateToSelectedMatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(navigateToSelectedMatch),
      tap(({ selectedMatches }) =>
        this._store.dispatch(
          storeData(
            STORAGE_KEY_MATCHES_SELECTED,
            selectedMatches.map((s) => parseMatchToLocalStorage(s)),
            StorageType.Local,
          ),
        ),
      ),
      switchMap(({ selectedMatches }) => [
        navigate({ path: `match-detail/${selectedMatches[0].id}` }),
        analyticsTrackEvent({
          eventName: AnalyticsEvent.clickMatch,
          eventParams: {
            [AnalyticsParam.category]: AnalyticsCategory.navigation,
            [AnalyticsParam.numMatches]: selectedMatches?.length,
          },
        }),
      ]),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _store: Store,
  ) {}
}
