import { EventEmitter, Injectable } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'

import { BehaviorSubject } from 'rxjs'

@Injectable()
export class NavService {
  public isOpen: boolean
  public isOpenUpdated = new EventEmitter()
  public currentUrl = new BehaviorSubject<string>(this.router.url)

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects)
      }
    })
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.isOpenUpdated.emit(this.isOpen)
  }
}
