import { Pipe, PipeTransform } from '@angular/core'
import { GridsterComponent, GridsterItem } from 'angular-gridster2'

@Pipe({
  name: 'gridsterEmptyItemCalc',
})
export class GridsterEmptyItemCalcPipe implements PipeTransform {
  transform(item: GridsterItem, grid: GridsterComponent): GridsterItem {
    if (item && grid) {
      return grid.getLastPossiblePosition(item)
    }
    return null
  }
}
