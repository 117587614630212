<div class="c-side-navigation__container">
  <mcui-expansion-panel
    *ngFor="let item of items; index as i"
    [expanded]="i === activeIndex"
    [hideToggle]="!item.items"
  >
    <mcui-expansion-panel-header>
      <div
        class="c-side-navigation__item-root"
        [routerLinkActive]="'c-side-navigation__item-root--selected'"
        [routerLinkActiveOptions]="{ exact: false }"
        [class.c-side-navigation__item-root--selected]="item?.selected"
        [routerLink]="['/' + item.route]"
        [attr.data-qa-id]="item.qaId"
        (click)="item?.command && item.command(item); itemClick.emit(item)"
      >
        <span>
          {{ item.label | translate }}
        </span>
        <i
          class="c-side-navigation__live fa fa-circle"
          *ngIf="item.id === 'matches' && (hasLiveMatches$ | async)"
        ></i>
        <i
          *ngIf="item.items"
          class="c-side-navigation__item-toggle-icon u-icon u-icon--arrow-down"
        ></i>
      </div>
    </mcui-expansion-panel-header>
    <ul class="c-side-navigation__sub-items" *ngIf="item.items">
      <li
        class="c-side-navigation__sub-item"
        *ngFor="let subItem of item.items"
        [routerLinkActive]="'c-side-navigation__sub-item--selected'"
        [routerLink]="['/' + subItem.route]"
        [class.c-side-navigation__sub-item--selected]="subItem?.selected"
        [attr.data-qa-id]="item.qaId"
        (click)="subItem.command && item.command(subItem); itemClick.emit(subItem)"
      >
        <span>{{ subItem.label | translate }}</span>
      </li>
    </ul>
  </mcui-expansion-panel>
</div>
<ng-container *ngIf="currentUrl$ | async"></ng-container>
