import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'

@Component({
  selector: 'mcp-team-logo',
  templateUrl: './team-logo.component.html',
  styleUrls: ['./team-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamLogoComponent {
  readonly placeholder = PLACEHOLDER_IMAGES.TEAM

  @Input() logo: string
  @Input() label: string
  @Input() reversed: boolean
}
