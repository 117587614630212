<div *ngIf="assetAvailability" class="match-asset-availability">
  <ng-container *ngFor="let asset of ASSET_ORDER">
    <div *ngIf="assetAvailability[asset] != null" class="match-asset-availability__item">
      <img
        *ngIf="
          (assetAvailability[asset]
            ? 'MTR_COMMON_SUCCESS_AVAILABLE'
            : 'MTR_COMMON_ERROR_NOT_AVAILABLE'
          ) | translate as translatedText
        "
        [src]="assetAvailability[asset] ? successIcon : errorIcon"
        [alt]="translatedText"
        [title]="translatedText"
      />
      <span class="match-asset-availability__type">{{
        ASSET_TYPE_TRANSLATIONS[asset] | translate
      }}</span>
    </div>
  </ng-container>
</div>
