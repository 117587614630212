import { Directive, Input } from '@angular/core'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { EventParams, Ga4Params } from '@core/analytics/models/gtag.models'
import { GatgClickBase } from '@shared/modules/analytics-companion/models/gatg-click.base'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'

const FILENAME_RGX = /^.*&n=/
const INSTALLER_NAME_RGX = /(^.*&n=)|(\.[^/.]+$)/

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[download]',
})
export class GtagDownloadDirective extends GatgClickBase {
  @Input() ga4Params: Ga4Params

  getParams() {
    if (this.ga4Params) {
      const { name, ...params } = this.ga4Params || {}
      return {
        eventName: name as AnalyticsEvent,
        eventParams: {
          ...params,
          [AnalyticsParam.fileName]: this._host.nativeElement.href,
        } as EventParams,
      }
    }
  }
}
