import { createAction, props } from '@ngrx/store'
import { ApiTeam } from '@core/models/models/team.models'
import { Competition } from '@core/models/dto/season.dto'
import { McpPlayer } from '@core/models/dto/player.dto'
import { SpaceNavigationPayload } from '@core/space/models/space.models'

export const fetchSpaceSelectorTeams = createAction(
  '[Space] fetch selector teams',
  props<{ payload: SpaceNavigationPayload }>(),
)

export const findSpaceSelectorTeam = createAction(
  '[Space] find selector teams',
  props<{ teams: ApiTeam[] }>(),
)

export const setSpaceSelectorTeams = createAction(
  '[Space] set selector teams',
  props<{ selectorTeams: ApiTeam[] }>(),
)

export const findSpaceSelectorCompetition = createAction(
  '[Space] find selector selected competition',
  props<{ payload: SpaceNavigationPayload }>(),
)

export const setSpaceSelectorCompetition = createAction(
  '[Space] set selector selected competition',
  props<{ selectorCompetition: Competition }>(),
)

export const setSpaceSelectorTeam = createAction(
  '[Space] set selector selected team',
  props<{ selectorTeam: ApiTeam }>(),
)

export const fetchSpaceSelectorPlayers = createAction(
  '[Space] fetch selector players',
  props<{ payload: SpaceNavigationPayload }>(),
)

export const findSpaceSelectorPlayer = createAction(
  '[Space] find selector players',
  props<{ players: McpPlayer[] }>(),
)

export const setSpaceSelectorPlayers = createAction(
  '[Space] set selector players',
  props<{ selectorPlayers: McpPlayer[] }>(),
)

export const setSpaceSelectorPlayer = createAction(
  '[Space] set selector selected player',
  props<{ selectorPlayer: McpPlayer }>(),
)

export const setSpaceSelectorLoading = createAction(
  '[Space] set selector loading',
  props<{ selectorLoading: boolean }>(),
)

export const changeSpaceSelectorCompetition = createAction(
  '[Space] change selector selected competition',
  props<{ competition: Competition }>(),
)

export const changeSpaceSelectorTeam = createAction(
  '[Space] change selector selected team',
  props<{ team: ApiTeam }>(),
)

export const changeSpaceSelectorPlayer = createAction(
  '[Space] change selector selected player',
  props<{ player: McpPlayer }>(),
)
