import { Component, OnInit, ViewChild } from '@angular/core'

import {
  ApplicationMenuModels,
  ApplicationName,
  DeviceDetectorService,
} from '@mediacoach-ui-library/global'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { DialogType, DownloadItem } from './dialog-application.models'
import {
  GetBackgroundImage,
  GetHeader,
  IMAGE_BASE_PATH,
  INSTALLER_CONFIG_BY_APPLICATION,
  NOT_INSTALLATION_ALLOWED,
  SHOW_OS_ICON_APPLICATIONS,
} from './dialog-application.constants'

import { ModalComponent } from '@shared/components/modal/modal.component'
import { AssetsApi } from '@core/requests/api/assets.api'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { McpProfile } from '@auth/models/auth.dto'

@UntilDestroy()
@Component({
  selector: 'app-dialog-application',
  templateUrl: './dialog-application.component.html',
  styleUrls: ['./dialog-application.component.scss'],
})
export class DialogApplicationComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalComponent
  type: DialogType
  application: ApplicationMenuModels
  userProfile = {} as McpProfile
  backgroundImage: string
  headerImages = []
  deviceOs = ''
  isDesktop = false
  isTablet = false
  isMobile = false
  notInstallationAllowed = false

  downloadList$: Observable<DownloadItem[]> = this._downloadsApi.getAssets().pipe(
    untilDestroyed(this),
    filter(() => !!this.application && !!this.type),
    map((assets) =>
      this.type === DialogType.Download
        ? this._getDownloadList(assets, this.application.name as ApplicationName)
        : undefined,
    ),
  )

  IMAGE_BASE_PATH = IMAGE_BASE_PATH
  SHOW_OS_ICON_APPLICATIONS = SHOW_OS_ICON_APPLICATIONS

  constructor(
    private readonly _downloadsApi: AssetsApi,
    private readonly _store: Store,
    private deviceDetector: DeviceDetectorService,
  ) {
    this.isDesktop = this.deviceDetector.isDesktop()
    this.isTablet = this.deviceDetector.isTablet()
    this.isMobile = this.deviceDetector.isMobile()
  }

  private _getDownloadList(assetsDownload, applicationName: ApplicationName) {
    const installers = assetsDownload.Assets.filter(
      ({ groupType }) => groupType === `${applicationName}Installer`,
    )
    return [
      ...INSTALLER_CONFIG_BY_APPLICATION[applicationName].map((item) => ({
        ...item,
        url: [ApplicationName.Mobile, ApplicationName.Desktop].includes(applicationName)
          ? ((installers || []).find((appItem) => appItem.extension === item.extension) || {}).path
          : installers[0].path,
      })),
    ]
  }

  ngOnInit() {
    this.deviceOs = this.deviceDetector.getDeviceInfo().os
  }

  openModal(type: DialogType, application: ApplicationMenuModels, userProfile: McpProfile) {
    this.notInstallationAllowed =
      NOT_INSTALLATION_ALLOWED[application.name] && NOT_INSTALLATION_ALLOWED[application.name](this)

    this.backgroundImage = GetBackgroundImage(type, application.name)
    this.headerImages = GetHeader(type, application.name)
    this.type = type
    this.application = application
    this.userProfile = userProfile
    this.modal.openModal()
  }

  closeModal() {
    this.modal.closeModal()
  }
}
