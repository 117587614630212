import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { AdvancedControlAction } from '../../models/video-markers.models'
import {
  DEFAULT_TIME_FOR_MARKER,
  PERIOD_FIRST_OF_GROUP_IDS,
} from '../../constants/video-advanced-controls.constants'
import { Marker } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-video-advanced-controls',
  templateUrl: './video-advanced-controls.component.html',
  styleUrls: ['./video-advanced-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoAdvancedControlsComponent {
  @Input() advancedMarkers: Marker[]
  @Output() marker = new EventEmitter<Marker>()
  @Output() move = new EventEmitter<AdvancedControlAction>()

  periods = PERIOD_FIRST_OF_GROUP_IDS
  defaultTime = DEFAULT_TIME_FOR_MARKER
}
