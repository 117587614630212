<div class="mcp-collaborators-avatar-list">
  @for (collaborator of collaborators; track collaborator.id; let i = $index) {
    @if (i <= 2) {
      <mcui-avatar
        [imageSource]="collaborator.avatarPath"
        [text]="collaborator?.name | firstCharacter: collaborator?.surname"
        [backgroundColor]="collaborator?.color || defaultAvatarColor"
        [title]="collaborator?.name || ''"
      />
    }
  }
  @if (collaborators?.length > 3) {
    <mcui-avatar
      [text]="'+' + (collaborators?.length - 3)"
      [backgroundColor]="defaultAvatarColor"
      [title]="otherCollaborators"
    />
  }
</div>
