import { Component, Input } from '@angular/core'
import { environment } from '@env'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
  @Input() extraClasses: boolean
  urlLoaderImg = environment.STATICS_URL_ASSETS + 'img/portal/loader_ball.gif'

  constructor() {}
}
