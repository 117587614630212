import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent implements OnInit, ControlValueAccessor {
  @Input() id = ''
  @Input() label: string
  @Input() placeholder = ''
  @Input() maxLength: string
  @Input() disabled: boolean
  @Input() icon: string
  @Input() tooltip: string
  @Input() tooltipStyleClass: string
  @Input() set disable(ds: boolean) {
    this.setDisabledState(ds)
  }

  @Output() onFocusIn = new EventEmitter()
  @Output() onFocusOut = new EventEmitter()
  @Output() onKeyUp = new EventEmitter()

  value: any = ''

  onChange = (event) => {}
  onTouched = () => {}

  ngOnInit() {}

  writeValue(value) {
    this.value = value
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }
}
