import { SegmentOption } from '@core/models/models/common.models'

export const AGGREGATION_OPTIONS: SegmentOption[] = [
  {
    id: 'Average',
    label: 'MTR_COMMON_AVERAGE',
  },
  {
    id: 'Accumulate',
    label: 'MTR_COMMON_ACCUMULATED',
  },
]
