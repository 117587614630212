<div class="c-dispatcher-grid">
  @if (dispatcherItems?.length) {
    @for (item of dispatcherItems; track item.route) {
      <mcp-dispatcher-card
        *permissionKey="item.route; isRouteKey: true"
        (onclick)="itemClick.emit($event)"
        [data]="item"
      >
      </mcp-dispatcher-card>
    }
  }
</div>
