import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { alignTimeByAssetType, getCameraAssets, getSelectedStream } from '@core/utils/stream.utils'
import { CameraAsset, StreamType } from '@core/models/dto/assets.dto'
import { updateListItemAttributes } from '@core/analytics/utils/analytics.utils'
import { TABS } from '@features/stream-modal/constants/stream-modal-container.contants'
import {
  AppTheme,
  debounce,
  DeviceDetectorService,
  DeviceMobile,
  Marker,
  PeriodGroup,
  TimelineEvent,
  VideoType,
} from '@mediacoach-ui-library/global'
import {
  findInitialSeconds,
  formatTimeByPeriods,
  getEventOffsetTime,
  timelineDisableEventFn,
} from '@core/utils/timeline.utils'
import { TimelineConfigDto, TimelineEventDto } from '@core/models/dto/timeline.dto'
import { VideoContainerComponent } from '@shared/components/video-container/video-container.component'
import { PeriodEvent } from '@core/models/models/timeline.models'
import {
  fadeInUpAnimation,
  fadeInUpOnEnterAnimation,
} from '@shared/animations/animations/fade-in-up.animation'
import { fadeInDownAnimation } from '@shared/animations/animations/fade-in-down.animation'
import { MatchTabsContainerComponent } from '@features/match-tabs/match-tabs-container.component'
import { StreamMatch } from '@core/models/models/match.models'
import { ScrollbarBodyHandlerDirective } from '@shared/directives/scrollbar-body-handler/scrollbar-body-handler.directive'
import { filter, take } from 'rxjs/operators'
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { trackErrorToSmartLook } from '@core/analytics/state/actions/smart-look.actions'
import { Store } from '@ngrx/store'
import { getSimplifiedLang } from '@core/state/selectors/user.selectors'
import { VideoAnalyticsEvent } from '@shared/components/video-container/models/video.models'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { VIDEO_EVENT_MAP } from '@shared/components/video-container/constants/video.constants'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { PlayListConfig } from '@features/playlist/models/playlist.models'
import { MenuItemEvent } from '@mediacoach/ui'
import { wipeStreamData } from '@core/state/actions/stream-match.merged-actions'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { SegmentOption } from '@core/models/models/common.models'
import { PlaylistContextMenuAction } from '@core/enums/playlist-context-menu-action.enum'

@Component({
  selector: 'mcp-stream-content',
  templateUrl: './stream-content.component.html',
  styleUrls: ['./stream-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInUpAnimation(), fadeInUpOnEnterAnimation(), fadeInDownAnimation()],
})
export class StreamContentComponent implements AfterViewInit, OnDestroy {
  private _videoElementLoaded$$ = new BehaviorSubject<boolean>(false)
  private _firstTimeVideoLoaded$$ = new Subject<VideoType>()
  private _timelineLoaded$$ = new Subject<boolean>()
  private _firstRun = true
  private _fullscreen: boolean
  private _timeline: TimelineConfigDto
  private _match: StreamMatch
  private _playlistConfig: PlayListConfig

  get match(): StreamMatch {
    return this._match
  }

  @Input() set match(m: StreamMatch) {
    this._match = m

    // resets video if the selected match has no streaming
    if (!m && !!this.url) {
      this.url = null
    }

    if (m) {
      this._setupTabs(m)
      this._setupVideo(m)
      this._setupCameraList(m)
    }
  }

  get playlistConfig() {
    return this._playlistConfig
  }

  @Input() set playlistConfig(_playlistConfig: PlayListConfig) {
    this._playlistConfig = _playlistConfig
    if (this.match) {
      if (_playlistConfig.hideVideoSource) {
        this.videoContainer?.stop()
      } else {
        this._setupCameraList(this._match)
      }
      if (this.url && this.match.isLive && !this.playlistConfig?.playListTabSelected) {
        this.videoContainer.seekToLiveEdge()
      }
    }
  }

  get fullscreen() {
    return this._fullscreen
  }

  @Input() set fullscreen(theaterMode: boolean) {
    this._fullscreen = theaterMode
    this._enableScrollbar(!theaterMode)
    this._animateDebounced(!!theaterMode)
    if (!this._firstRun) {
      this._store.dispatch(
        analyticsTrackEvent({
          eventName: AnalyticsEvent.videoCinemaMode,
          eventParams: {
            ...this._analyticsCommonParams,
          },
        }),
      )
    }
    this._firstRun = false
  }

  get timeline() {
    return this._timeline
  }

  @Input() set timeline(config: TimelineConfigDto) {
    this._timeline = config
    if (config) {
      this._timelineLoaded$$.next(true)
    }
  }

  @Input() hideNavigation: boolean
  @Input() markers: Marker[]
  @Input() formatTimeFn: (...args: any[]) => any = formatTimeByPeriods
  @Input() streamType: StreamType
  @Input() tabsTpl: TemplateRef<MatchTabsContainerComponent>
  @Input() loading: boolean
  @Input({ required: true }) lang: string

  @HostBinding('class') clazz = AppTheme.Dark

  @ViewChild('tpl', { static: false, read: TemplateRef })
  tplRef: TemplateRef<any>
  @ViewChild(VideoContainerComponent, { static: false })
  videoContainer: VideoContainerComponent
  @ViewChild(ScrollbarBodyHandlerDirective, { static: false })
  scrollbar: ScrollbarBodyHandlerDirective

  @Output() streamTypeChange = new EventEmitter<StreamType>()
  @Output() navigate = new EventEmitter<StreamMatch>()
  @Output() closeDialog = new EventEmitter<void>()
  @Output() available = new EventEmitter<boolean>()
  @Output() menuClick = new EventEmitter<MenuItemEvent>()

  readonly smallUntil = { [PeriodGroup.Normal]: 0, [PeriodGroup.Extra]: 0 }
  readonly timelineDisableEventFn = timelineDisableEventFn

  cameraList: CameraAsset[]
  withCredentials: boolean
  sidebarCollapsed: boolean
  autoplayMode: 'any' | boolean
  tabs: SegmentOption[] = [...TABS]
  url: string
  readonly lang$ = this._store.select(getSimplifiedLang)

  private get _analyticsCommonParams() {
    return {
      [AnalyticsParam.category]: AnalyticsCategory.streaming,
      [AnalyticsParam.videoName]: this.url,
      [AnalyticsParam.videoTime]: this.videoContainer?.getCurrentTime(),
      [AnalyticsParam.videoFullscreen]: !!this.videoContainer?.isFullScreen(),
      [AnalyticsParam.videoCamera]: this.streamType?.videoType,
      [AnalyticsParam.videoCinema]: this.fullscreen,
    }
  }

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _store: Store,
    private readonly _deviceDetector: DeviceDetectorService,
  ) {}

  @debounce(800)
  private _animateDebounced(theaterMode: boolean): void {
    this.sidebarCollapsed = !theaterMode
    this._cdr.detectChanges()
  }

  private _syncInitialTime() {
    combineLatest([
      this._firstTimeVideoLoaded$$.asObservable().pipe(completeWhen((r) => !!r)),
      this._timelineLoaded$$.asObservable().pipe(completeWhen((r) => !!r)),
    ])
      .pipe(
        filter(
          () =>
            this.streamType.videoType !== VideoType.Live &&
            !this.playlistConfig?.playListTabSelected,
        ),
        take(1),
      )
      .subscribe(([cVideoType]) => this._handleInitialTime(cVideoType))
  }

  private _setupVideo(match: StreamMatch): void {
    this._syncInitialTime()

    this.withCredentials = match?.streaming && match.streaming.withCredentials
    this.autoplayMode =
      (match?.streaming && match.streaming.withCredentials ? 'any' : false) ||
      this._deviceDetector.getDeviceInfo().device === DeviceMobile.Iphone
    const isLiveVideoType =
      match?.streaming?.url && (!match.streamsVoD || Object.keys(match.streamsVoD).length === 0)
    const videoType = isLiveVideoType ? VideoType.Live : getSelectedStream(match.streamsVoD)

    const _asset = (match.streamsVoD || {})[videoType]

    this.url = match?.streaming && match.streaming.finalUrl ? match.streaming.finalUrl : _asset?.url

    this.streamTypeChange.emit({
      videoType,
      id: videoType || '1',
    })
    this.available.emit(!!this.url)

    combineLatest([
      this._videoElementLoaded$$.asObservable().pipe(completeWhen((r) => !!r)),
      this.videoContainer?.playerReady.asObservable().pipe(completeWhen((r) => !!r)) || of(false),
    ]).subscribe(() => {
      this._firstTimeVideoLoaded$$.next(videoType)
    })
  }

  private _setupTabs(_match: StreamMatch): void {
    this.tabs = updateListItemAttributes([...TABS], {})
  }

  private _setupCameraList(match: StreamMatch): void {
    this.cameraList = getCameraAssets(match?.isLive, match?.streamsVoD)
  }

  private _playAt(time: number, label?: string): void {
    if (this.videoContainer && time) {
      this.videoContainer.onMarker({ time })
      this._store.dispatch(
        analyticsTrackEvent({
          eventName: AnalyticsEvent.videoSeek,
          eventParams: {
            ...this._analyticsCommonParams,
            [AnalyticsParam.videoTime]: time,
            [AnalyticsParam.videoSeekEvent]: label,
          },
        }),
      )
    }
  }

  private _enableScrollbar(state: boolean) {
    if (this.scrollbar) {
      this.scrollbar.enableScrollbar(state)
    }
  }

  private _runOnPlayerReady(callback: () => unknown) {
    if (this.videoContainer) {
      this.videoContainer.playerReady
        .asObservable()
        .pipe(take(1))
        .subscribe(() => callback())
    }
  }

  private _handleInitialTime(videoType: VideoType): void {
    if (this.timeline && this.streamType && this.videoContainer) {
      this.videoContainer.setPlayerTime(findInitialSeconds(this.timeline, videoType))
    }
  }

  private _handlePlayerTime(videoType: VideoType) {
    const time = alignTimeByAssetType(
      this.markers,
      this.streamType.videoType,
      videoType,
      this.videoContainer.getCurrentTime() || 0,
    )
    this._runOnPlayerReady(() => this._playAt(time))
  }

  ngAfterViewInit() {
    if (this.videoContainer) {
      this._videoElementLoaded$$.next(true)
    }
  }

  ngOnDestroy() {
    this._store.dispatch(wipeStreamData(StoreToken.stream)())
  }

  playAt(time: number, label?) {
    if (time !== undefined) {
      this._playAt(time, label)
    } else {
      this._handleInitialTime(this.streamType.videoType)
      this.videoContainer.stop()
    }
  }

  playAtOnPlayerReady(time: number, label?) {
    this._runOnPlayerReady(() => this.playAt(time, label))
  }

  onCameraChange(camera: CameraAsset): void {
    const videoType = camera.assetType as VideoType
    if (!this.playlistConfig?.playListTabSelected) {
      this._handlePlayerTime(videoType)
    }
    this.url = camera.streaming?.url
    this.streamTypeChange.emit({ videoType, id: camera.value })
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: AnalyticsEvent.videoCameraChange,
        eventParams: {
          ...this._analyticsCommonParams,
          [AnalyticsParam.videoCamera]: videoType,
        },
      }),
    )
  }

  onQuickTagChange(time: number) {
    this.menuClick.emit({
      item: {
        id: PlaylistContextMenuAction.quickTag,
        label: '',
      },
      context: { time, videoType: this.streamType?.videoType },
    })
  }

  onTimelineEventClick(event: TimelineEvent): void {
    const time =
      (((event as TimelineEventDto).timeItems || {})[this.streamType.videoType] || {})
        .timeInSeconds - getEventOffsetTime(event as TimelineEventDto) || null
    this._playAt(time, event.eventType)
  }

  onTimelinePeriodClick({ period, edge }: PeriodEvent): void {
    this._playAt(
      ((period[`${edge}TimeItems`] || {})[this.streamType.videoType] || {}).timeInSeconds || null,
      period.id,
    )
  }

  onVideoError(error) {
    this._store.dispatch(trackErrorToSmartLook({ error }))
  }

  onVideoAnalytics(params: VideoAnalyticsEvent) {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: VIDEO_EVENT_MAP[params.event],
        eventParams: {
          ...this._analyticsCommonParams,
          ...params?.payload?.eventParams,
        },
      }),
    )
  }
}
