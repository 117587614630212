<div
  class="mcp-widget-table-cell"
  [class.has-interaction]="column.hasInteraction"
  [class.first]="isFirstColumn"
  *ngIf="{
    value:
      (column.roundPipePattern && row[column.key] | isNumber)
        ? (row[column.key] | number: column.roundPipePattern : lang)
        : row[column.key],
    unit: row[column.unitKey] || row.unit,
  } as calculatedCell"
>
  @if (row.showHyphen && column.showHyphenOnFlag) {
    <div class="mcp-widget-table-cell--hyphen">-</div>
  } @else {
    @switch (column.displayType) {
      @case ('image') {
        <img
          class="mcp-widget-table-cell--image"
          [mcuiDefaultImage]="column.placeholderImage"
          [alt]="row[column.imageKey]"
          [srcUrl]="row[column.imageKey]"
          [mcuiTooltip]="row[column.nestedTooltip]"
        />
      }
      @case ('textAndImage') {
        <div class="mcp-widget-table-cell--image-text">
          <img
            [mcuiDefaultImage]="column.placeholderImage"
            [alt]="row | resolveCellValue: column.key"
            [mcuiTooltip]="row[column.nestedTooltip]"
            [srcUrl]="row[column.imageKey]"
          />
          <div class="mcp-widget-table-cell--image-text__value-container">
            <div class="mcp-widget-table-cell--image-text__value">
              <span>{{ calculatedCell.value | translate | invalidValueResolver }}</span>
              <ng-container *ngTemplateOutlet="unit"></ng-container>
            </div>
            @if (column.extraTextKey) {
              <span class="mcp-widget-table-cell--image-text__extra-text">{{
                row[column.extraTextKey] | translate | invalidValueResolver: true
              }}</span>
            }
          </div>
        </div>
      }
      @default {
        <div class="mcp-widget-table-cell--text">
          {{ calculatedCell.value | translate | invalidValueResolver }}
          <ng-container *ngTemplateOutlet="unit"></ng-container>
        </div>
      }
    }
    <ng-template #unit>
      @if (calculatedCell.value && (calculatedCell.unit || column.unit)) {
        <span>{{ calculatedCell.unit || column.unit | translate }}</span>
      }
    </ng-template>
  }
</div>
