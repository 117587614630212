<div class="mcp-video-markers">
  <ng-container *ngFor="let marker of markers">
    <div
      class="mcp-video-markers__item"
      *ngIf="!marker.hideMarker"
      [ngStyle]="marker.style"
      [class.gray-area]="marker.isRange"
      [mcTooltip]="marker.tooltip"
      tooltipPosition="top"
      (click)="onClick(marker, $event)"
    ></div>
  </ng-container>
</div>
