import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import {
  calculateDocumentHeight,
  getElementFixedLeft,
  getElementFixedTop,
  getFirstScrollableParent,
} from '../../utils/dom.utils'
import { DomCoordinates } from '../../models/tour-dom.models'
import { ElementPositioning } from '../../enums/tour.enum'
import { getFirstElementWithoutKeyword } from '../../utils/tour.utils'

@Injectable({ providedIn: 'root' })
export class DomService {
  private _fakeDocument: Document = { body: {}, documentElement: {} } as Document
  private _fakeWindow: Window = { document: this._fakeDocument, navigator: {} } as Window
  private _documentHeight: number

  get documentHeight(): number {
    return this._documentHeight
  }

  get winRef(): Window {
    return isPlatformBrowser(this.platformId) ? window : this._fakeWindow
  }

  get docRef(): Document {
    return isPlatformBrowser(this.platformId) ? document : this._fakeDocument
  }

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.setDocumentHeight()

    if (this.docRef && !this.docRef.elementFromPoint) {
      this.docRef.elementFromPoint = this._elementsFromPoint.bind(this)
    }
  }

  setDocumentHeight(): void {
    this._documentHeight = calculateDocumentHeight(this.docRef)
  }

  getElementAbsoluteTop(element: Element) {
    const { y } = this._getScrollOffsets()
    return getElementFixedTop(element) + y
  }

  getElementAbsoluteLeft(element: Element) {
    const { x } = this._getScrollOffsets()
    return getElementFixedLeft(element) + x
  }

  isElementBeyondOthers(
    element: Element,
    isElementFixed: boolean,
    keywordToDiscard: string,
  ): ElementPositioning {
    const x1 = isElementFixed ? getElementFixedLeft(element) : this.getElementAbsoluteLeft(element)
    const y1 = isElementFixed ? getElementFixedTop(element) : this.getElementAbsoluteTop(element)
    const x2 = x1 + element.getBoundingClientRect().width - 1
    const y2 = y1 + element.getBoundingClientRect().height - 1

    const elements1 = this.docRef.elementsFromPoint(x1, y1)
    const elements2 = this.docRef.elementsFromPoint(x2, y2)

    return elements1.length === 0 && elements2.length === 0
      ? ElementPositioning.beyond
      : getFirstElementWithoutKeyword(elements1, keywordToDiscard) !== element ||
          getFirstElementWithoutKeyword(elements2, keywordToDiscard) !== element
        ? ElementPositioning.beneath
        : ElementPositioning.unset
  }

  isParentScrollable(element: Element): boolean {
    return getFirstScrollableParent(element, this.docRef, this.winRef) !== this.docRef.body
  }

  scrollIntoView(element: Element, isElementFixed: boolean): void {
    const firstScrollableParent = getFirstScrollableParent(element, this.docRef, this.winRef)
    const top = isElementFixed ? getElementFixedTop(element) : this.getElementAbsoluteTop(element)
    if (firstScrollableParent !== this.docRef.body) {
      if (firstScrollableParent.scrollTo) {
        firstScrollableParent.scrollTo(0, top - 150)
      } else {
        // IE 11 - Edge browsers
        firstScrollableParent.scrollTop = top - 150
      }
    } else {
      this.winRef.scrollTo(0, top - 150)
    }
  }

  scrollToTheTop(element: Element): void {
    const firstScrollableParent = getFirstScrollableParent(element, this.docRef, this.winRef)
    if (firstScrollableParent !== this.docRef.body) {
      if (firstScrollableParent.scrollTo) {
        firstScrollableParent.scrollTo(0, 0)
      } else {
        // IE 11 - Edge browsers
        firstScrollableParent.scrollTop = 0
      }
    } else {
      this.winRef.scrollTo(0, 0)
    }
  }

  scrollToTheBottom(element: Element): void {
    const firstScrollableParent = getFirstScrollableParent(element, this.docRef, this.winRef)
    if (firstScrollableParent !== this.docRef.body) {
      if (firstScrollableParent.scrollTo) {
        firstScrollableParent.scrollTo(0, this.docRef.body.scrollHeight)
      } else {
        // IE 11 - Edge browsers
        firstScrollableParent.scrollTop =
          firstScrollableParent.scrollHeight - firstScrollableParent.clientHeight
      }
    } else {
      this.winRef.scrollTo(0, this.docRef.body.scrollHeight)
    }
  }

  private _getScrollOffsets(): DomCoordinates {
    // This works for all browsers except IE versions 8 and before
    if (this.winRef.scrollX != null) {
      return { x: this.winRef.scrollX, y: this.winRef.scrollY }
    }

    // For IE (or any browser) in Standards mode
    if (this.docRef.compatMode === 'CSS1Compat') {
      return {
        x: this.docRef.documentElement.scrollLeft,
        y: this.docRef.documentElement.scrollTop,
      }
    }

    // For browsers in Quirks mode
    return {
      x: this.docRef.body.scrollLeft,
      y: this.docRef.body.scrollTop,
    }
  }

  private _elementsFromPoint(x: number, y: number) {
    const parents = []
    let parent = void 0
    do {
      const elem = this.docRef.elementFromPoint(x, y)
      if (elem && parent !== elem) {
        parent = elem
        parents.push(parent)
        parent.style.pointerEvents = 'none'
      } else {
        parent = false
      }
    } while (parent)
    // eslint-disable-next-line @typescript-eslint/no-shadow
    parents.forEach((parent) => (parent.style.pointerEvents = 'all'))
    return parents
  }
}
