import * as signalR from '@microsoft/signalr'
import { HttpClient as HttpClientSignalR, HubConnection, LogLevel } from '@microsoft/signalr'
import { filter, map, share } from 'rxjs/operators'
import { environment } from '@env'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { RETRY_POLICY } from '../constants/signalr.constants'
import { select, Store } from '@ngrx/store'
import { selectToken } from '@core/state/selectors/user.selectors'

export function SignalrFactory(_store: Store, _http: HttpClient): Observable<HubConnection> {
  let instance: HubConnection
  const options = {
    httpClient: <HttpClientSignalR>{
      getCookieString: (url: string) => '',
      post: (url) =>
        _http
          .post(url, null)
          .toPromise()
          .then(
            (res) => ({
              statusCode: 200,
              statusText: null,
              content: JSON.stringify(res),
            }),
            (err) => err,
          ),
    },
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets,
  }

  return _store.pipe(
    select(selectToken),
    filter((t) => !!t),
    map((token: string) => {
      if (!instance) {
        instance = new signalR.HubConnectionBuilder()
          .withUrl(environment.INTEGRATED_API.WEB_SOCKET(token), options)
          .withAutomaticReconnect(RETRY_POLICY)
          .configureLogging(environment.production ? LogLevel.Information : LogLevel.Debug)
          .build()
      }
      return instance
    }),
    share(),
  )
}
