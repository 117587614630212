<div class="mcp-collaborators-list__share">
  <span>{{ 'MTR_PLAYLIST_DIALOG_PLAYLIST_SHARE' | translate }}</span>
  <mcui-checkbox
    size="small"
    [checked]="allSelected"
    [labelColor]="defaultAvatarColor"
    [reversed]="true"
    [label]="'MONITORING_REPORT_FILTERS_OPTIONS_ALL' | translate"
    (checkedChange)="onCheckChange()"
  />
</div>
<div class="mcp-collaborators-list__list" cdkListbox>
  @for (collaborator of collaborators; track collaborator.id) {
    <div
      class="mcp-collaborators-list__item"
      [cdkOption]="collaborator"
      [class.selected]="selection.isSelected(collaborator)"
      (click)="selection.toggle(collaborator)"
    >
      <mcui-avatar
        [imageSource]="collaborator.avatarPath"
        [text]="collaborator?.name | firstCharacter: collaborator?.surname"
        [backgroundColor]="collaborator?.color || defaultAvatarColor"
        [title]="collaborator?.name || ''"
      />
      <span>{{ collaborator.name }} {{ collaborator.surname }}</span>
      <div class="mcp-collaborators-list__item-check">
        <mcui-icon *ngIf="selection.isSelected(collaborator)" svgIcon="check" />
      </div>
    </div>
    @if (!$last) {
      <div class="mcp-collaborators-list__item-divider"></div>
    }
  }
</div>
