import { Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { Directive, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core'

import { PermittedRoute } from '@core/models/models/permissions.models'
import { PermissionsService } from '@core/services/permissions.service'
import { NAVIGATION_CONFIG } from '@core/constants/navigation.constants'

@Directive({
  selector: '[mcpAllowedRouteNavigation]',
})
export class AllowedRouteNavigationDirective implements OnInit, OnDestroy {
  private subscription: Subscription
  private readonly _routes = NAVIGATION_CONFIG.map(({ route }) => route)
  private readonly _allowedRoutes$: Observable<PermittedRoute[]> =
    this._permissionService.getPermittedRouteUrls$()

  $navEmitter = new EventEmitter()

  constructor(
    private readonly _router: Router,
    private readonly _permissionService: PermissionsService,
  ) {}

  ngOnInit() {
    this.subscription = this.$navEmitter
      .pipe(
        switchMap(() =>
          this._allowedRoutes$.pipe(
            take(1),
            map((routes: PermittedRoute[]) =>
              this._routes.find((r) =>
                routes.some((allowedRoute) => new RegExp(allowedRoute.regex).test(r)),
              ),
            ),
            tap((_route) =>
              this._router.navigate([_route || this._permissionService.notPermittedRoute]),
            ),
          ),
        ),
      )
      .subscribe()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  @HostListener('click', ['$event'])
  navigate() {
    this.$navEmitter.emit()
  }
}
