<div class="mcp-space-selector">
  <div class="mcp-space-selector__competition">
    <mcp-competition-changer
      [competition]="competition"
      (competitionChange)="competitionChange.emit($event)"
    ></mcp-competition-changer>
  </div>
  <div class="mcp-space-selector__teams">
    <ng-container *mcuiLoading="teams.loading || loading">
      <mcp-scrollable-item-selector
        *mcpNoContent="!teams.value; noPadding: true; competition: competition?.value"
        [items]="teams?.value"
        [large]="true"
        [selectedIdx]="selectedTeamIndex"
        [placeholder]="teamPlaceholder"
        (selectItem)="teamChange.emit($event)"
      />
    </ng-container>
  </div>
  <div class="mcp-space-selector__players" *ngIf="displayPlayers">
    <ng-container *mcuiLoading="players?.loading || loading">
      <mcp-scrollable-item-selector
        *mcpNoContent="!players?.value; noPadding: true; competition: competition?.value"
        [items]="players?.value"
        [hasTag]="true"
        [isTooltipDisabled]="true"
        [selectedIdx]="selectedPlayerIndex"
        [placeholder]="playerPlaceholder"
        (selectItem)="playerChange.emit($event)"
      />
    </ng-container>
  </div>
</div>
