import { Pipe, PipeTransform } from '@angular/core'
import { Match } from '@core/models/dto/match.dto'
import { updateScoreOf } from '@core/utils/match.utils'

@Pipe({
  name: 'updateMatchWithSocket',
})
export class UpdateMatchWithSocketPipe implements PipeTransform {
  transform(match: Match, liveMatches: Match[] = []): Match {
    const updatedMatch = liveMatches.find((m) => m.id === match.id)
    if (updatedMatch) {
      return {
        ...match,
        home: updateScoreOf('home', match, updatedMatch),
        away: updateScoreOf('away', match, updatedMatch),
        state: updatedMatch.state,
      }
    }
    return match
  }
}
