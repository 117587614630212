import {
  DistinctItems,
  EventType,
  FlattenDeep,
  Marker,
  Period,
  PERIOD_CONFIG,
  PeriodEdge,
  TEAMS,
  videoJsFormatTime,
  VideoType,
} from '@mediacoach-ui-library/global'
import { TimelineTimeItems } from '@core/models/models/timeline.models'
import { MARKER_PERIOD_EDGES, PERIOD_EDGE_MAPPING } from '@core/constants/matches.constants'
import { TimePeriod } from '@core/models/dto/match.dto'
import {
  EVENT_TRANSLATIONS,
  PERIOD_TRANSLATIONS,
  PERIOD_TRANSLATIONS_END,
} from '@core/constants/timeline.constants'
import { PeriodDto, TimelineConfigDto, TimelineEventDto } from '@core/models/dto/timeline.dto'

export const getEventId = (event: TimelineEventDto): string => `event-${event.id}`

export const getPeriodId = (period: Partial<PeriodDto>, edge: PeriodEdge): string =>
  `${period.id}-${edge}`

export const getEventOffsetTime = (event: TimelineEventDto): number => {
  if (event.edge) {
    // For period offset
    return 2
  } else if (
    event.period !== Period.Penalties &&
    [EventType.Shot, EventType.Goal, EventType.OwnGoal].includes(event.eventType)
  ) {
    return 15
  } else if (
    [EventType.YellowCard, EventType.DoubleYellowCard, EventType.RedCard].includes(event.eventType)
  ) {
    return 12
  }
  return 3
}

export const getTimelineEventSeconds = (
  videoType: VideoType,
  timeItems: TimelineTimeItems,
): number => ((timeItems || {})[videoType || VideoType.Tactical] || {}).timeInSeconds

export const timelineDisableEventFn =
  (videoType: VideoType): ((event: TimelineEventDto) => boolean) =>
  (event) => {
    const timeEventInSeconds = getTimelineEventSeconds(videoType, event.timeItems)
    return timeEventInSeconds == null
  }

export const getTimePeriods = (periods: PeriodDto, videoType: VideoType): TimePeriod[] =>
  Object.values(periods).reduce(
    (list, { defaultEnd, end, startTimeItems, endTimeItems }, i, arr) => {
      const lastPeriod = arr[i - 1]
      const start = getTimelineEventSeconds(videoType, startTimeItems)
      const endP =
        getTimelineEventSeconds(videoType, endTimeItems) || (end || defaultEnd) * 60 + start
      const periodDuration =
        (defaultEnd - (lastPeriod && defaultEnd ? lastPeriod.defaultEnd : 0)) * 60 || endP - start

      if (start && endP && periodDuration) {
        list.push({
          normalizedEnd: defaultEnd,
          end: endP,
          start,
          periodDuration,
        })
      }
      return list
    },
    [],
  )

export const isPeriodHalfTime = (
  timeValue: number,
  currentPeriodStart: number,
  lastPeriod: TimePeriod,
): boolean => (lastPeriod || ({} as TimePeriod)).end < timeValue && timeValue < currentPeriodStart

export const getPeriodTimeWithExtra = (
  timeValue: number,
  currentPeriod: TimePeriod,
  lastPeriod: TimePeriod,
): string => {
  const periodTime = timeValue - currentPeriod.start
  const { normalizedEnd } = lastPeriod || ({} as { normalizedEnd: number })
  const formattedTv = videoJsFormatTime(
    periodTime + (normalizedEnd ? normalizedEnd * 60 : 0),
    0,
    true,
  )
  const extraTime = (time) => (time ? `+${time}` : '')
  return periodTime > currentPeriod.periodDuration + 59
    ? `${currentPeriod.normalizedEnd}${extraTime(Math.floor((periodTime - currentPeriod.periodDuration) / 60))}`
    : formattedTv
}

export const formatTimeByPeriods = (
  periods: PeriodDto,
  videoType: VideoType,
  commonTranslation: string,
): ((currentTime: number) => string) => {
  if (periods && videoType && commonTranslation) {
    const timePeriods: TimePeriod[] = getTimePeriods(periods, videoType)
    return (currentTime) =>
      (timePeriods.reduce(
        (v, { start, end, periodDuration, normalizedEnd }, i) =>
          v != null
            ? v
            : currentTime < start && i === 0
              ? videoJsFormatTime(currentTime - start, 0, true)
              : start <= currentTime && currentTime <= end
                ? getPeriodTimeWithExtra(
                    currentTime,
                    { start, end, periodDuration, normalizedEnd },
                    timePeriods[i - 1],
                  )
                : i === timePeriods.length - 1 ||
                    isPeriodHalfTime(currentTime, start, timePeriods[i - 1])
                  ? commonTranslation
                  : null,
        null,
      ) as string) || videoJsFormatTime(currentTime, 0, true)
  }
}

export const parseToTimelineEvents = (config: TimelineConfigDto): TimelineEventDto[] =>
  FlattenDeep(
    TEAMS.map((team) =>
      PERIOD_CONFIG['PARSED'].map((period) => (config[team] || {})[period] || []),
    ),
  ).sort((a, b) => a.id - b.id)

export const parseToPeriods = (config: TimelineConfigDto): PeriodDto =>
  DistinctItems([...Object.keys(config.periods), ...PERIOD_CONFIG['FIXED'], Period.End])
    .filter((periodKey: Period) => PERIOD_CONFIG['RAW'].includes(periodKey))
    .reduce(
      (obj, periodKey) => ({
        ...obj,
        [periodKey]: {
          ...config.periods[periodKey],
          id: periodKey,
          label: config.periodTranslations[periodKey],
        },
      }),
      {} as PeriodDto,
    )

const mapTimelineEvent = (
  timelineEvent: TimelineEventDto,
  contentType: VideoType,
  formatFn: (...args) => string,
) => {
  const realTime = getTimelineEventSeconds(contentType, timelineEvent.timeItems)
  return {
    id: getEventId(timelineEvent),
    label: EVENT_TRANSLATIONS[timelineEvent.eventType],
    time: realTime - getEventOffsetTime(timelineEvent),
    formattedTime: formatFn(realTime),
    data: timelineEvent,
  }
}

const mapPeriods = (
  periods: PeriodDto,
  contentType: VideoType,
  formatTimeFn: (...args) => string,
) =>
  Object.keys(periods)
    .reduce(
      (arr, periodKey) => [
        ...arr,
        ...MARKER_PERIOD_EDGES.map((edge) => ({
          id: periodKey,
          edge,
          defaultEnd: (periods[periodKey] || ({} as any)).defaultEnd,
          end: (periods[periodKey] || ({} as any)).end,
          timeItems: periods[periodKey][PERIOD_EDGE_MAPPING[edge]],
          startTimeItems:
            periodKey === Period.First &&
            edge === PeriodEdge.End &&
            periods[periodKey][PERIOD_EDGE_MAPPING[PeriodEdge.Start]],
        })),
      ],
      [],
    )
    .map((period) => {
      const { defaultEnd, end } = period
      const start = getTimelineEventSeconds(contentType, period.startTimeItems)
      const realTime =
        getTimelineEventSeconds(contentType, period.timeItems) || (end || defaultEnd) * 60 + start
      const time = realTime - getEventOffsetTime(period)
      const isPeriodStart = period.edge === PeriodEdge.Start
      const formattedTime = formatTimeFn(realTime)

      return {
        id: getPeriodId(period, period.edge),
        label: (isPeriodStart ? PERIOD_TRANSLATIONS : PERIOD_TRANSLATIONS_END)[period.id],
        time,
        formattedTime,
        data: period,
        isGrayAreaStart: !isPeriodStart,
        hideMarker: !isPeriodStart,
        isGrayAreaEnd: period.id !== Period.First && isPeriodStart,
        isAdvancedControl: !!time && isPeriodStart,
      }
    })

export const findInitialSeconds = (
  timelineConfig: TimelineConfigDto,
  contentType: VideoType,
): number =>
  (timelineConfig.periods[Period.First] as PeriodDto)?.startTimeItems[contentType]?.timeInSeconds

export const parseTimelineEventsToMarkers = (
  timelineConfig: TimelineConfigDto,
  streamType: VideoType,
  defaultTranslation,
): Marker[] => {
  const events = parseToTimelineEvents(timelineConfig)
  const periods = parseToPeriods(timelineConfig)
  const formatTimeFn = formatTimeByPeriods(periods, streamType, defaultTranslation)

  return [
    ...events.map((event: TimelineEventDto) => mapTimelineEvent(event, streamType, formatTimeFn)),
    ...mapPeriods(periods, streamType, formatTimeFn),
  ]
}
