import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MatchStreaming } from '@core/models/dto/match.dto'
import { Observable } from 'rxjs'
import { TimelineConfig } from '@mediacoach-ui-library/global'
import { environment } from '@env'
import { ApiMatches } from '@core/models/models/match.models'

@Injectable()
export class MatchApi {
  constructor(private readonly _http: HttpClient) {}

  fetchStreamUrl({ url, withCredentials }: MatchStreaming): Observable<{ url: string }> {
    return this._http.get<{ url: string }>(url, { withCredentials })
  }

  fetchTimelineConfig(matchId: string): Observable<TimelineConfig> {
    return this._http.get<TimelineConfig>(
      environment.INTEGRATED_API.FILTER_MATCHES_TIMELINE(matchId),
    )
  }

  fetchMatch(matchIds: string): Observable<ApiMatches> {
    return this._http.get<ApiMatches>(environment.INTEGRATED_API.MATCHES(), {
      params: { matchIds },
    })
  }

  fetchMatchPlayersMetrics(matchId: string): Observable<any> {
    return this._http.get<any>(environment.INTEGRATED_API.MATCH_PLAYER_LIST_METRICS(matchId))
  }

  fetchMatchTeamMetrics(matchId: string): Observable<any> {
    return this._http.get<any>(environment.INTEGRATED_API.MATCH_TEAM_METRICS(matchId))
  }

  fetchMatchPassMatrix(matchId: string): Observable<any> {
    return this._http.get<any>(environment.INTEGRATED_API.MATCH_PASSMATRIX(matchId))
  }

  fetchMatchHeatMap(matchId: string): Observable<any> {
    return this._http.get<any>(environment.INTEGRATED_API.MATCH_HEATMAP(matchId))
  }

  fetchMatchPlayer(matchId, teamId, id, playerPosition): Observable<any> {
    return this._http.get<any>(environment.INTEGRATED_API.MATCH_PLAYER_BY_ID(matchId, teamId, id), {
      params: { playerPosition },
    })
  }

  fetchMatchPlayerComparison(matchId: string, comparison): Observable<any> {
    return this._http.post<any>(
      environment.INTEGRATED_API.MATCH_PLAYERS_COMPARE(matchId),
      comparison,
    )
  }

  fetchMatchStreamsVod(matchId: string): Observable<any> {
    return this._http.get(environment.INTEGRATED_API.MATCH_STREAMING(matchId))
  }
}
