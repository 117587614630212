// @ts-ignore
import { VideoJsPlayerOptions } from 'video.js'
import { VideoEvent, VIDEOJS_LOCALES } from '@mediacoach-ui-library/global'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import videojs from 'video.js'

export const VIDEOJS_SETTINGS: VideoJsPlayerOptions = {
  controls: true,
  preload: 'auto',
  liveui: true,
  inactivityTimeout: 0,
  html5: {
    nativeTextTracks: false,
    vhs: {
      enableLowInitialPlaylist: true,
      fastQualityChange: true,
      overrideNative: !(videojs.browser.IS_SAFARI || videojs.browser.IS_IOS),
    },
  },
  languages: VIDEOJS_LOCALES,
  plugins: {
    offset: {},
  },
}

export const DEFAULT_STEP_TIME = 15
export const REFRESH_TIME_UPDATE = 2000
export const DIFF_TIME = 15

export const KEY_TIME_MAP = {
  alt: 60,
  shift: 30,
  default: 15,
}

export const VIDEO_EVENT_MAP = {
  [VideoEvent.Play]: AnalyticsEvent.videoPlay,
  [VideoEvent.Ended]: AnalyticsEvent.videoEnded,
  [VideoEvent.Pause]: AnalyticsEvent.videoPause,
  [VideoEvent.Seeked]: AnalyticsEvent.videoSeek,
  [VideoEvent.Fullscreenchange]: AnalyticsEvent.videoFullscreen,
  [VideoEvent.Enterpictureinpicture]: AnalyticsEvent.videoPiP,
  // VideoEvent.Play, VideoEvent.Ended, VideoEvent.Pause, VideoEvent.Seeked
}
