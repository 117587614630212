import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { createAction, props } from '@ngrx/store'
import { TagItem } from '@mediacoach/ui'
import { PlaylistClipSharePayload } from '@features/playlist/dialogs/dialog-playlist-share/models/dialog-playlist-share.models'

export const openDialogEditPlaylistClipComment = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist - Clip] open dialog edit clip comment`,
    props<{ displayMode: string; playlistClip: TagItem }>(),
  )

export const updatePlaylistClip = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist - Clip] update clip`, (payload) => ({ payload }))

export const openConfirmDialogDeletePlaylistClip = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist - Clip] open confirm dialog delete`,
    props<{ displayMode: string; playlistClipToDelete: TagItem & { matchId: string } }>(),
  )

export const sharePlaylistClip = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist - Clip] share clip`,
    (payload: PlaylistClipSharePayload) => ({ payload }),
  )

export const acceptConfirmDialogDeletePlaylistClip = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist - Clip] accept confirm dialog dialog`, (payload) => ({
    payload,
  }))

export const successDeletePlaylistClip = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist - Clip] success delete clip`, (payload) => ({
    payload,
  }))

export const openDialogSharePlaylistClip = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist - Clip] open dialog share clip`,
    props<{ displayMode: string; playlistClipId: string; matchId: string }>(),
  )

export const setIsDeletingTagItem = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist - Clip] set is deleting clip`,
    props<{ isDeletingTagItem: boolean }>(),
  )
