import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent {
  @Input() items: any[]
  @Input() isProgressiveLoad = false

  constructor() {}
}
