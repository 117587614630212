<div class="match-item-list-footer" [class.open]="matches?.length > 0">
  <div
    class="match-item-list-footer__block match-item-list-footer__text match-item-list-footer__select-all"
  >
    <div class="match-item-list-footer__column">
      <mc-checkbox
        [ngModel]="allSelected"
        (ngModelChange)="onClick($event)"
        label="MATCHES_RESULTS_BTN_SELECTALL"
        labelColor="#fff"
      ></mc-checkbox>
    </div>
    <div class="match-item-list-footer__column match-item-list-footer__spacing">
      <span class="match-item-list-footer__text--secondary">
        ({{ total }} {{ 'MATCHES_MAIN_SECTION_TITLE' | translate }} )</span
      >
    </div>
  </div>

  <div
    class="match-item-list-footer__block match-item-list-footer__text match-item-list-footer__selection"
  >
    <span
      [translate]="
        matches.length !== 1
          ? 'MATCHES_RESULTS_LAB_SELECTEDMATCHES'
          : 'MATCHES_RESULTS_LAB_SELECTEDMATCH'
      "
    ></span>
    <span class="match-item-list-footer__number">{{ matches.length }}</span>
  </div>

  <div class="match-item-list-footer__block match-item-list-footer__button">
    <button
      *permissionKey="routeAccess.MatchDetail; isRouteKey: true"
      type="button"
      class="button-primary"
      (click)="goToDetail()"
      [disabled]="!selectedMatches.length"
      [ngClass]="{ disabled: !selectedMatches.length }"
    >
      {{ 'MATCHES_RESULTS_BTN_SHOWDETAIL' | translate }}
    </button>
  </div>
</div>
