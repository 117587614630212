import { Pipe, PipeTransform } from '@angular/core'

const REPORT_ID_PREFIX_RGX = /^.*reports\/\d+\//i
const REPORT_ID_SUFFIX_RGX = /\?.*$/i
const REPORT_FILE_NAME = /^.*&n=/

@Pipe({
  name: 'reportId',
})
export class ReportIdPipe implements PipeTransform {
  transform(path: string, byId: boolean = false): string {
    const _path = path || ''
    return byId
      ? _path.replace(REPORT_ID_PREFIX_RGX, '').replace(REPORT_ID_SUFFIX_RGX, '')
      : _path.replace(REPORT_FILE_NAME, '')
  }
}
