import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { DomSanitizer } from '@angular/platform-browser'
import { Cacheable } from '@shared/decorators/cacheable.decorator'
import { REFRESH_TIME } from '@core/constants/time.constants'
import { mapMacDesktopVersion, parseTextFileToObject } from '@core/utils/assets.utils'
import { Asset } from '@core/models/dto/assets.dto'

@Injectable()
export class AssetsApi {
  constructor(
    private readonly _http: HttpClient,
    private readonly _sanitizer: DomSanitizer,
  ) {}

  getVersionFiles(path: string, isDesktopDMG: boolean): Observable<Record<string, string>> {
    const v = new Date().getTime()
    return isDesktopDMG
      ? this._http
          .get(`${path}?v=${v}`)
          .pipe(map((data: { updates: { [key: string]: unknown } }) => mapMacDesktopVersion(data)))
      : this._http
          .get(`${path}?v=${v}`, { responseType: 'text' })
          .pipe(map((data) => parseTextFileToObject(data)))
  }

  @Cacheable(REFRESH_TIME.THIRTY_MIN * 1000)
  getAssets(): Observable<{ Assets: Asset[] }> {
    return this._http.get<{ Assets: any[] }>(environment.API.GET_URL_DOWNLOAD)
  }
}
