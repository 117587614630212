export enum AnalyticsCategory {
  navigation = 'navigation_portal',
  settings = 'settings_portal',
  matches = 'matches_section_portal',
  players = 'players_section_portal',
  auth = 'auth_portal',
  streaming = 'streaming_portal',
  search = 'search_portal',

  onboarding = 'on',
  assets = 'assets_portal',
  downloads = 'downloads_portal',
}
