import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core'
import { getHTMLElement, isElement } from '../dialog.utils'
import { fromEvent, Observable, Subject } from 'rxjs'
import { getAnimationPlayer } from '@shared/components/dialog/dialog.animations'
import { AnimationBuilder, AnimationPlayer } from '@angular/animations'
import {
  OVERLAY_CLASSNAME,
  OVERLAY_RULES_MAP,
  PARENT_REF_RULES_MAP,
  RULES_TO_BACKUP,
} from '../dialog.constants'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { debounce } from '@mediacoach-ui-library/global'

@UntilDestroy()
@Directive({
  exportAs: 'appendTo',
  selector: '[mcpAppendTo]',
})
export class AppendToDirective implements OnDestroy {
  @Input('mcpAppendTo')
  set appendTo(ref) {
    const reference = getHTMLElement(ref)
    if (reference && isElement(reference) && !this._hasOverlay(reference)) {
      this._setupAppendReference(reference as HTMLElement)
    }
  }

  private _overlay: HTMLElement
  private _reference: HTMLElement
  private _originalRules: Map<string, string>
  private _player: AnimationPlayer
  private _close$$: Subject<void> = new Subject<void>()
  private _overlayEnabled: boolean

  close$: Observable<void> = this._close$$.asObservable()

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _builder: AnimationBuilder,
    private readonly _host: ElementRef,
  ) {}

  private _setupAppendReference(reference: HTMLElement): void {
    this._reference = reference
    this._originalRules = RULES_TO_BACKUP.reduce((map, rule) => {
      map.set(rule, this._reference.style[rule])
      return map
    }, new Map())
    this._buildOverlay()
  }

  private _patchOverflow(enable: boolean): void {
    try {
      this._renderer.setStyle(
        this._reference,
        'overflowY',
        enable ? 'hidden' : this._originalRules.get('overflowY'),
      )
      this._renderer.setStyle(
        this._reference.parentElement,
        'overflowY',
        enable ? 'hidden' : this._originalRules.get('overflowY'),
      )
    } catch (err) {
      console.warn('Unable to patch parent node', err)
    }
  }

  private _buildOverlay(): void {
    this._overlay = this._renderer.createElement('DIV')
    this._renderer.addClass(this._overlay, OVERLAY_CLASSNAME)
    OVERLAY_RULES_MAP.forEach((value: string, rule: string) =>
      this._renderer.setStyle(this._overlay, rule, value),
    )
    fromEvent(this._overlay, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => this._close$$.next())
  }

  private _animate(animateIn: boolean, callback?: () => void): void {
    this._player = getAnimationPlayer(this._builder, this._overlay, animateIn)
    this._player?.onDone(() => {
      if (callback) {
        callback()
      }
      this._player?.destroy()
      this._player = null
    })
    this._player?.play()
  }

  private _hasOverlay(reference: HTMLElement | 'body'): boolean {
    return (
      reference instanceof HTMLElement &&
      reference.querySelectorAll(`.${OVERLAY_CLASSNAME}`).length > 0
    )
  }

  @debounce(0)
  private _setOverlayStatus(visible: boolean) {
    this._overlayEnabled = visible
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target) {
    const clickedInside = this._host.nativeElement.contains(target)
    if (!clickedInside && this._overlayEnabled) {
      this._close$$.next()
    }
  }

  ngOnDestroy(): void {
    this._player?.finish()
    if (this._originalRules) {
      this._originalRules.forEach((value: string, rule: string) =>
        this._renderer.setStyle(this._reference, rule, value),
      )
    }
  }

  appendOverlay(): void {
    if (this._reference) {
      PARENT_REF_RULES_MAP.forEach((value: string, rule: string) =>
        this._renderer.setStyle(this._reference, rule, value),
      )
      this._patchOverflow(true)
      this._reference.scrollTo({ top: 0 })

      this._renderer.appendChild(this._reference, this._overlay)
      this._renderer.setStyle(this._overlay, 'opacity', '1')
      this._setOverlayStatus(true)
    }
  }

  removeOverlay(): void {
    if (this._reference) {
      this._animate(false, () => {
        this._renderer.setStyle(this._overlay, 'opacity', '0')
        this._renderer.removeChild(this._reference, this._overlay)
        this._patchOverflow(false)
        this._originalRules.forEach((value: string, rule: string) =>
          this._renderer.setStyle(this._reference, rule, value),
        )
        this._setOverlayStatus(false)
      })
    }
  }
}
