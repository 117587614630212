import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core'
import { DecimalPipe, LowerCasePipe } from '@angular/common'
import { TranslatePipe, TranslateService } from '@ngx-translate/core'
import { LANG_STANDARD_MAP } from '@core/i18n/constants/i18n.constants'

@Pipe({
  name: 'metric',
})
export class MetricPipe implements PipeTransform {
  private readonly _locale: string
  private readonly _lowerCasePipe: LowerCasePipe
  private readonly _numberPipe: DecimalPipe
  private readonly _translatePipe: TranslatePipe

  constructor(
    private readonly _translate: TranslateService,
    private readonly _cdr: ChangeDetectorRef,
  ) {
    this._locale = LANG_STANDARD_MAP[this._translate.currentLang]
    this._numberPipe = new DecimalPipe(this._locale)
    this._translatePipe = new TranslatePipe(this._translate, this._cdr)
    this._lowerCasePipe = new LowerCasePipe()
  }

  transform(
    cell: { value: number; unit?: string },
    showZeroes: boolean = false,
    digitsInfo: string = '1.0-1',
  ): string {
    const value = this._numberPipe.transform(cell.value, digitsInfo, this._locale) || '-'
    const unit =
      cell.value === 0 || cell.value === null || cell.value === undefined
        ? ''
        : ` ${cell.unit ? this._lowerCasePipe.transform(this._translatePipe.transform(cell.unit)) : ''}`
    return `${showZeroes && cell.value === 0 ? cell.value : value}${unit}`
  }
}
