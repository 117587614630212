<div
  class="mcp-video-container"
  [class.advanced-controls]="showAdvancedControls"
  *ngIf="{
    markers: markers$ | async,
    advancedMarkers: advancedMarkers$ | async,
    totalTime: time$ | async,
    shortcuts: shortcuts$ | async,
    offsetMarkers: offSetMarkers$ | async,
  } as data"
>
  <mcp-video
    #videoPlayer
    *ngIf="source; else notAvailable"
    [mcpAttachMarkers]="videoMarkersElement"
    [source]="source"
    [streamTitle]="streamTitle"
    [lang]="lang"
    [withCredentials]="withCredentials"
    [autoplayMode]="autoplayMode"
    [shortcuts]="data?.shortcuts"
    [formatTimeFn]="!marker ? formatTimeFn : offSetMarketFormatTimeFn"
    (injected)="markersInjected = true"
    (analytics)="sendAnalytics($event)"
    (time)="onTimeUpdate($event)"
    (playerReady)="playerReady.emit($event)"
    (playChange)="playChange.emit($event)"
    (fullscreenChange)="fullscreenChange.emit($event)"
    (playOffsetEnd)="onPlayOffsetEnd()"
    (videoError)="onError($event)"
    (quickTag)="quickTagChange.emit($event)"
  ></mcp-video>
  <ng-template #notAvailable>
    <div class="mcp-video-container__not-available">
      <p>{{ 'MATCHDETAIL_VIDEO_NOT_AVAILABLE' | translate | uppercase }}</p>
    </div>
  </ng-template>

  <mcp-video-advanced-controls
    *ngIf="showAdvancedControls"
    [@fadeInDownOnEnter]
    [advancedMarkers]="data?.advancedMarkers"
    (move)="onMove($event)"
    (marker)="onMarker($event)"
  ></mcp-video-advanced-controls>
  <mcp-video-markers
    #videoMarkers
    *ngIf="data?.totalTime"
    [hidden]="!markersInjected || !!marker"
    [markers]="data?.markers"
    (marker)="onMarker($event)"
  ></mcp-video-markers>
</div>
