import { inject, Injectable } from '@angular/core'
import {
  catchRequestError,
  ConfirmDialogComponent,
  deepEqual,
  DialogService,
  ToastType,
} from '@mediacoach/ui'
import { Actions } from '@ngrx/effects'
import { Action, DefaultProjectorFn, MemoizedSelector, Store } from '@ngrx/store'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import {
  createNewPlaylist,
  deletePlaylist,
  editPlaylist,
  emptyAction,
  fetchPlaylistCollaborators,
  fetchPlayLists,
  setPlaylistCollaborators,
  setSelectedPlaylist,
  shareAllPlaylistClips,
  sharePlaylistFilteredClips,
} from '@core/state/actions/stream-playlist.merged-actions'
import { DialogCreatePlaylistComponent } from '@features/playlist/dialogs/dialog-create-playlist/components/dialog-create-playlist/dialog-create-playlist.component'
import { Observable, OperatorFunction, throwError } from 'rxjs'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { showFullscreenAwareToast, showToast } from '@core/state/actions/toast.actions'
import { concatLatestFrom } from '@ngrx/operators'
import { selectUserId } from '@core/state/selectors/user.selectors'
import { buildPlaylistDialogStyleClass } from '@features/playlist/utils/playlist-dialog.utils'
import { DialogManagePlaylistComponent } from '@features/playlist/dialogs/dialog-manage-playlist/components/dialog-manage-playlist/dialog-manage-playlist.component'
import { MatchTabDisplayMode } from '@features/match-tabs/models/match-tab.models'
import {
  selectCurrentSelectedPlaylist,
  selectDefaultPlaylist,
  selectPlaylistCollaborators,
  selectTags,
} from '@core/state/selectors/stream-playlist.merged-selectors'
import { addUserAsCollaborator } from '@core/state/utils/playlist.utils'
import { DialogPlaylistShareComponent } from '@features/playlist/dialogs/dialog-playlist-share/components/dialog-update-playlist-clip-comment/dialog-playlist-share.component'
import { BaseEffectsHandler } from '@core/models/base-classes/base-effects.handler'
import { getParsedPeriodsByVideoType } from '@core/state/selectors/stream-match.merged-selectors'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { createQuickTag, getCurrentPeriod } from '@features/playlist/utils/playlist.utils'
import { GET_VIDEO_ID_BY_VIDEO_TYPE } from '@features/playlist/constants/playlist.constants'
import { Toast } from '@core/models/models/common.models'
import { VideoType } from '@mediacoach-ui-library/global'

@Injectable({ providedIn: 'root' })
export class PlaylistEffectsHandler extends BaseEffectsHandler {
  protected readonly _actions$ = inject(Actions)
  protected readonly _store = inject(Store)
  protected readonly _api = inject(PlaylistApi)
  protected readonly _dialog = inject(DialogService)

  fetchPlaylistCollaborators(identifier: MergedTokens): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(selectUserId)),
        switchMap(() =>
          this._api.fetchPlaylistCollaborators().pipe(
            map((response) => response?.results),
            catchRequestError(),
          ),
        ),
        map((collaborators) => setPlaylistCollaborators(identifier)({ collaborators })),
      )
  }

  openCreateNewPlaylistDialog(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(({ matchId, displayMode }) =>
          this._translate
            .get('MTR_PLAYLIST_DIALOG_CREATE_NEW')
            .pipe(map((header) => ({ header, matchId, displayMode }))),
        ),
        tap(() => this._store.dispatch(fetchPlaylistCollaborators(identifier)())),
        switchMap(({ header, matchId, displayMode }) =>
          this._dialog
            .open(DialogCreatePlaylistComponent, {
              header,
              styleClass: buildPlaylistDialogStyleClass(displayMode),
              data: { matchId, selector },
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((playlist) => createNewPlaylist(identifier)({ playlist })),
      )
  }

  openEditPlaylistDialog(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(({ playlist, displayMode }) =>
          this._translate
            .get('MTR_PLAYLIST_DIALOG_PLAYLIST_EDIT')
            .pipe(map((header) => ({ header, playlist, displayMode }))),
        ),
        tap(() => this._store.dispatch(fetchPlaylistCollaborators(identifier)())),
        switchMap(({ header, playlist, displayMode }) =>
          this._dialog
            .open(DialogCreatePlaylistComponent, {
              header,
              styleClass: buildPlaylistDialogStyleClass(displayMode),
              data: { playlist, selector },
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((playlist) => editPlaylist(identifier)({ playlist })),
      )
  }

  openManagePlaylistDialog(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(({ matchId, displayMode }) =>
          this._translate
            .get('MTR_PLAYLIST_OPTIONS_MANAGE_PLAYLIST')
            .pipe(map((header) => ({ header, matchId, displayMode }))),
        ),
        switchMap(({ header, matchId, displayMode }) =>
          this._dialog
            .open(DialogManagePlaylistComponent, {
              header,
              styleClass: buildPlaylistDialogStyleClass(displayMode),
              data: { matchId, selector, identifier, displayMode },
              closeOnEscape: false,
            })
            .onClose.pipe(map(() => matchId)),
        ),
      )
  }

  openDeletePlaylistDialog(identifier: MergedTokens): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(({ displayMode, playlist }) =>
          this._openConfirmDialog(displayMode, 'MTR_PLAYLIST_DIALOG_PLAYLIST_DELETE_CONFIRM').pipe(
            map(() => playlist),
          ),
        ),
        map((playlist: any) => deletePlaylist(identifier)({ playlist })),
      )
  }

  createNewPlaylist(identifier: MergedTokens): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(({ playlist }) =>
          this._api
            .createPlaylist(playlist)
            .pipe(
              this._toastAndFetchPlaylists(
                identifier,
                'MTR_PLAYLIST_DIALOG_PLAYLIST_SAVE_OK',
                playlist.matchId,
              ),
            ),
        ),
      )
  }

  editPlaylist(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        concatLatestFrom(() => [
          this._store.select(selectPlaylistCollaborators(selector)),
          this._store.select(selectUserId),
        ]),
        map(([{ playlist }, collaborators, userId]) =>
          addUserAsCollaborator(playlist, collaborators, userId),
        ),
        switchMap((playlist) =>
          this._api
            .updatePlaylist(playlist)
            .pipe(
              this._toastAndFetchPlaylists(
                identifier,
                'MTR_TAGGING_TOAST_UPDATE_PLAYLIST_SUCCESS',
                playlist.matchId,
              ),
            ),
        ),
      )
  }

  leavePlaylist(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        concatLatestFrom(() => [
          this._store.select(selectDefaultPlaylist(selector)),
          this._store.select(selectCurrentSelectedPlaylist(selector)),
        ]),
        switchMap(([{ playlist }, defaultPlaylist, current]) =>
          this._api.unsubscribePlaylist(playlist.id).pipe(
            tap(() => {
              if (playlist.id === current.id) {
                this._store.dispatch(
                  setSelectedPlaylist(identifier)({
                    selectedPlaylist: defaultPlaylist,
                    matchId: playlist.matchId,
                  }),
                )
              }
            }),
            this._toastAndFetchPlaylists(
              identifier,
              'MTR_PLAYLIST_DIALOG_PLAYLIST_LEAVE_CONFIRM_OK',
              playlist.matchId,
            ),
          ),
        ),
      )
  }

  deletePlaylist(
    identifier: MergedTokens,
    selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        concatLatestFrom(() => [
          this._store.select(selectDefaultPlaylist(selector)),
          this._store.select(selectCurrentSelectedPlaylist(selector)),
        ]),
        switchMap(([{ playlist }, defaultPlaylist, current]) =>
          this._api.deletePlaylist(playlist.id).pipe(
            tap(() => {
              if (playlist.id === current.id) {
                this._store.dispatch(
                  setSelectedPlaylist(identifier)({
                    selectedPlaylist: defaultPlaylist,
                    matchId: playlist.matchId,
                  }),
                )
              }
            }),
            this._toastAndFetchPlaylists(
              identifier,
              'MTR_PLAYLIST_DIALOG_PLAYLIST_DELETE_CONFIRM_OK',
              playlist.matchId,
            ),
          ),
        ),
      )
  }

  private _openConfirmDialog(
    displayMode: MatchTabDisplayMode,
    message: string,
    acceptButtonText: string = 'P_COMMONS_CONTRACT_BTN_ACCEPT',
    cancelButtonText: string = 'CONTACT_FORM_BTN_CANCEL',
  ) {
    return this._translate.get([message, acceptButtonText, cancelButtonText]).pipe(
      map((i18n) => ({
        message: i18n[message],
        acceptButtonText: i18n[acceptButtonText],
        cancelButtonText: i18n[cancelButtonText],
        displayMode,
      })),
      switchMap(({ message, acceptButtonText, cancelButtonText, displayMode }) =>
        this._dialog
          .open(ConfirmDialogComponent, {
            styleClass: buildPlaylistDialogStyleClass(displayMode, 'mcp-widget-confirm-dialog'),
            showHeader: false,
            data: { message, acceptButtonText, cancelButtonText },
          })
          .onClose.pipe(filter((accept) => accept === true)),
      ),
    )
  }

  openDialogShareAllPlaylistClips(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(selectCurrentSelectedPlaylist(_selector))),
        switchMap(([{ displayMode, matchId }, { id: playlistId }]) =>
          this._translate
            .get('MTR_PLAYLIST_CLIP_SHARE_ALL_TITLE')
            .pipe(map((header) => ({ header, displayMode, matchId, playlistId }))),
        ),
        switchMap(({ header, displayMode, matchId, playlistId }) =>
          this._dialog
            .open(DialogPlaylistShareComponent, {
              header,
              styleClass: buildPlaylistDialogStyleClass(displayMode, 'mcp-dialog-playlist-share'),
              data: {
                selector: _selector,
                payload: {
                  matchId,
                  playlistId,
                  tags: [],
                  shareAll: true,
                },
              },
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((payload) => shareAllPlaylistClips(_identifier)(payload)),
      )
  }

  shareAllPlaylistClips(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ payload: { matchId, playlistId, ...payloadData } }) =>
          this._api
            .shareAllTags(matchId, playlistId, payloadData)
            .pipe(
              this.handleRequest(
                [emptyAction(_identifier)()],
                'MTR_TAGGING_TOAST_PLAYLIST_CLIP_SHARE_ALL_SUCCESS',
                'MTR_TAGGING_TOAST_PLAYLIST_CLIP_SHARE_ALL_FAILURE',
              ),
            ),
        ),
      )
  }

  openDialogSharePlaylistFilteredClips(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        concatLatestFrom(() => this._store.select(selectTags(_selector))),
        switchMap(([{ displayMode, matchId }, { results }]) =>
          this._translate.get('MTR_PLAYLIST_FILTERED_CLIPS_SHARE_TITLE').pipe(
            map((header) => ({
              header,
              displayMode,
              matchId,
              tags: results?.map(({ id }) => id),
            })),
          ),
        ),
        switchMap(({ header, displayMode, matchId, tags }) =>
          this._dialog
            .open(DialogPlaylistShareComponent, {
              header,
              styleClass: buildPlaylistDialogStyleClass(displayMode, 'mcp-dialog-playlist-share'),
              data: {
                selector: _selector,
                payload: {
                  matchId,
                  tags,
                  shareAll: false,
                },
              },
            })
            .onClose.pipe(filter((result) => !deepEqual(result, { closed: true }))),
        ),
        map((payload) => sharePlaylistFilteredClips(_identifier)(payload)),
      )
  }

  sharePlaylistFilteredClips(_identifier: MergedTokens): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ payload: { matchId, ...payloadData } }) =>
          this._api
            .shareTags(matchId, payloadData)
            .pipe(
              this.handleRequest(
                [emptyAction(_identifier)()],
                'MTR_TAGGING_TOAST_PLAYLIST_FILTERED_CLIPS_SHARE_SUCCESS',
                'MTR_TAGGING_TOAST_PLAYLIST_FILTERED_CLIPS_SHARE_FAILURE',
              ),
            ),
        ),
      )
  }

  quickTag(
    _identifier: MergedTokens,
    _selector: MemoizedSelector<object, any, DefaultProjectorFn<any>>,
  ): OperatorFunction<any, any> {
    return (source): Observable<any> =>
      source.pipe(
        switchMap(({ time, videoType, matchId, isFullscreen }) =>
          this._store.pipe(getParsedPeriodsByVideoType(_selector)).pipe(
            completeWhen((d) => !!d),
            map((periods) => ({
              periodNumber: getCurrentPeriod(time, periods),
              time,
              videoType,
              matchId,
              isFullscreen,
            })),
          ),
        ),
        switchMap(({ time, videoType, periodNumber, matchId, isFullscreen }) =>
          !videoType || !periodNumber
            ? [this._handleFullScreenAwareToast('MTR_TOAST_NEW_TAG_FAILURE', 'error', isFullscreen)]
            : this._saveQuickTag(time, videoType, periodNumber, matchId, isFullscreen, _identifier),
        ),
      )
  }

  private _saveQuickTag(
    time: number,
    videoType: VideoType,
    periodNumber: number,
    matchId: string,
    isFullscreen: boolean,
    identifier: MergedTokens,
  ) {
    const quickTag = createQuickTag({
      periodNumber,
      videosInfo: [{ type: GET_VIDEO_ID_BY_VIDEO_TYPE[videoType], seconds: Math.floor(time) }],
    })

    return this._api.createQuickTag(matchId, quickTag).pipe(
      switchMap(() =>
        this._handleFullScreenAwareToast('MTR_TOAST_NEW_TAG_SUCCESS', 'success', isFullscreen),
      ),
      map(() => fetchPlayLists(identifier)({ matchId })),
      catchError((err) => {
        return this._handleFullScreenAwareToast(
          'MTR_TOAST_NEW_TAG_FAILURE',
          'error',
          isFullscreen,
        ).pipe(map(() => throwError(() => err)))
      }),
    )
  }

  private _toastAndFetchPlaylists(
    identifier: MergedTokens,
    message: string,
    matchId: string,
  ): OperatorFunction<any, Action> {
    return (source: Observable<any>): Observable<Action> =>
      source.pipe(
        map(() => ({
          toast: { message },
          matchId,
        })),
        switchMap(({ toast, matchId }) => [
          showToast({ toast }),
          fetchPlayLists(identifier)({ matchId }),
        ]),
        catchRequestError(),
      )
  }

  private _buildTranslatedToast(i18nKey: string, type: ToastType): Observable<Toast> {
    return this._translate.get(i18nKey).pipe(map((message) => ({ message, type })))
  }

  private _handleFullScreenAwareToast(i18nKey: string, type: ToastType, isFullscreen: boolean) {
    return this._buildTranslatedToast(i18nKey, type).pipe(
      tap((toast) => {
        if (isFullscreen) {
          this._store.dispatch(showFullscreenAwareToast({ toast }))
        }
        this._store.dispatch(showToast({ toast }))
      }),
    )
  }
}
