import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { connectSocket, fetchTopic, setTopic } from '@core/state/actions/socket.actions'
import { map, switchMap, tap } from 'rxjs/operators'
import { Topics } from '@sockets/models/socket.model'
import { Topic } from '@sockets/enums/socket.enum'
import { Store } from '@ngrx/store'
import { getTopic } from '@core/state/selectors/socket.selectors'
import { SocketService } from '@sockets/services/socket.service'
import {
  fetchStreams,
  fetchStreamsWithMultiLive,
} from '@features/playground/state/actions/playground.actions'
import { Match } from '@core/models/dto/match.dto'
import { MatchMultiLive } from '@features/playground/models/playground.state'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class SocketEffects {
  connectSocket$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(connectSocket),
        tap(() => this._socket.connect()),
      ),
    { dispatch: false },
  )

  getTopic$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTopic),
      map(({ socketMessage }) => {
        let msg: string
        try {
          msg = JSON.parse(socketMessage.message)
        } catch {
          msg = JSON.parse(`"${socketMessage.message}"`)
        }
        return {
          socketMessage: { topic: socketMessage.topic, message: msg } as {
            topic: string
            message: unknown
          },
          topic: { [socketMessage.topic]: msg } as Topics,
        }
      }),
      concatLatestFrom(() => this._store.select(getTopic(Topic.MatchIdWithNewAssets))),
      switchMap(([{ socketMessage, topic }, currentMatches]) => {
        const actions = [setTopic({ topic: topic })]
        if (socketMessage.topic === Topic.LiveMatches) {
          const msg = (socketMessage.message || []) as { id: string }[]
          this._store.dispatch(fetchStreams({ streams: socketMessage.message as Match[] }))
          this._store.dispatch(
            fetchStreamsWithMultiLive({
              streamsMultiLive: socketMessage.message as MatchMultiLive[],
            }),
          )
          actions.push(
            setTopic({ topic: { [Topic.IsLive]: msg.length > 0 } }),
            setTopic({
              topic: {
                [Topic.MatchIdWithNewAssets]: Array.from(
                  new Set([...msg.map((m) => m.id), ...((currentMatches || []) as string[])]),
                ),
              },
            }),
          )
        }
        return actions
      }),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _socket: SocketService,
    private readonly _store: Store,
  ) {}
}
