import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core'

import { ErrorInterceptor } from './requests/interceptors/error-interceptor.service'
import { TokenInterceptor } from './requests/interceptors/token.interceptor'
import { LoggedGuard } from '@core/guards/logged.guard'
import { MainModule } from '@core/main/main.module'
import { AuthModule } from '@core/authentication/auth/auth.module'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { AssetsApi } from '@core/requests/api/assets.api'
import { StateModule } from '@core/state/state.module'
import { NgrxRouterStoreModule } from '@core/router/ngrx-router.module'
import { SocketsModule } from '@core/websockets/sockets/sockets.module'
import { AnalyticsModule } from '@core/analytics/analytics.module'
import { Store } from '@ngrx/store'
import { idsFactory } from '@core/authentication/oidc/factories/ids.factory'
import { SeasonApi } from '@core/requests/api/season/season.api'
import { SeasonsResolver } from '@core/resolvers/seasons.resolver'
import { I18nModule } from '@core/i18n/i18n.module'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { OidcModule } from '@core/authentication/oidc/oidc.module'
import { TranslateService } from '@ngx-translate/core'
import { initLanguages } from '@core/i18n/factories/i18n.factory'
import { AssetsResolver } from '@core/resolvers/assets.resolver'
import { TeamApi } from '@core/requests/api/team/team.api'
import { MatchApi } from '@core/requests/api/match/match.api'
import { PlayerApi } from '@core/requests/api/player/player.api'
import { HotkeysService } from '@core/services/hotkeys.service'
import { PowerBIApi } from '@core/requests/api/powerbi/powerbi.api'
import { PlaylistApi } from '@core/requests/api/playlist/playlist.api'
import { DialogService } from '@mediacoach/ui'
import { SpaceModule } from '@core/space/space.module'

const GUARDS = [LoggedGuard]
const RESOLVERS = [SeasonsResolver, AssetsResolver]
const API_SERVICES = [AssetsApi, SeasonApi, TeamApi, MatchApi, PlayerApi, PowerBIApi, PlaylistApi]
const CORE_SERVICES = [HotkeysService, DialogService]
const COMMON_MODULES = [StateModule, NgrxRouterStoreModule, AnalyticsModule]

@NgModule({
  imports: [
    OidcModule,
    AuthModule,
    SocketsModule,
    MainModule,
    I18nModule,
    SpaceModule,
    ...COMMON_MODULES,
  ],
  exports: [...COMMON_MODULES],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: 'notPermittedRoute',
      useValue: '/acceso-restringido',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: idsFactory,
      deps: [OidcService, Store],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLanguages,
      deps: [TranslateService, TranslatorService],
      multi: true,
    },
    ...API_SERVICES,
    ...CORE_SERVICES,
    ...GUARDS,
    ...RESOLVERS,
  ],
})
export class CoreModule {}
