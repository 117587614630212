import { uuidv4 } from '@core/utils/object.utils'
import { asArray } from '@core/utils/collection.utils'
import { ElementRef, QueryList } from '@angular/core'

/*
  Utils extracted from dom-to-image lib
  https://github.com/tsayen/dom-to-image/blob/master/src/dom-to-image.js#L177
 */

const makeImage = (uri) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.onerror = reject
    image.src = uri
  })

const fixSvg = (clone) => {
  if (!(clone instanceof SVGElement)) {
    return
  }
  clone.setAttribute('xmlns', 'http://www.w3.org/2000/svg')

  if (!(clone instanceof SVGRectElement)) {
    return
  }
  ;['width', 'height'].forEach((attribute) => {
    const value = clone.getAttribute(attribute)
    if (!value) {
      return
    }

    clone.style.setProperty(attribute, value)
  })
}

const copyUserInput = (original, clone) => {
  if (original instanceof HTMLTextAreaElement) {
    clone.innerHTML = original.value
  }
  if (original instanceof HTMLInputElement) {
    clone.setAttribute('value', original.value)
  }
  return Promise.resolve(clone)
}

const formatProperty = (style, name) =>
  name +
  ': ' +
  style.getPropertyValue(name) +
  (style.getPropertyPriority(name) ? ' !important' : '')

const formatCssProperties = (style) =>
  asArray(style)
    .map((s) => formatProperty(style, s))
    .join('; ') + ';'

const formatCssText = (style) => {
  const content = style.getPropertyValue('content')
  return style.cssText + ' content: ' + content + ';'
}

const formatPseudoElementStyle = (className, element, style) => {
  const selector = '.' + className + ':' + element
  const cssText = style.cssText ? formatCssText(style) : formatCssProperties(style)
  return document.createTextNode(selector + '{' + cssText + '}')
}

const clonePseudoElement = (element, original, clone) => {
  const style = window.getComputedStyle(original, element)
  const content = style.getPropertyValue('content')

  if (content === '' || content === 'none') {
    return
  }

  const className = uuidv4()
  clone.className = clone.className + ' ' + className
  const styleElement = document.createElement('style')
  styleElement.appendChild(formatPseudoElementStyle(className, element, style))
  clone.appendChild(styleElement)
}

const clonePseudoElements = (original, clone) => {
  ;[':before', ':after'].forEach((element) => {
    clonePseudoElement(element, original, clone)
  })
}

const copyProperties = (source, target) => {
  asArray(source).forEach((name) => {
    target.setProperty(name, source.getPropertyValue(name), source.getPropertyPriority(name))
  })
}

const copyStyle = (source, target) => {
  if (source.cssText) {
    target.cssText = source.cssText
  } else {
    copyProperties(source, target)
  }
}

const cloneStyle = (original, clone) => {
  copyStyle(window.getComputedStyle(original), clone.style)
}

const processClone = (original, clone) =>
  !(clone instanceof Element)
    ? clone
    : Promise.resolve()
        .then(() => cloneStyle(original, clone))
        .then(() => clonePseudoElements(original, clone))
        .then(() => copyUserInput(original, clone))
        .then(() => fixSvg(clone))
        .then(() => clone)

const makeNodeCopy = (node) =>
  node instanceof HTMLCanvasElement ? makeImage(node.toDataURL()) : node.cloneNode(false)

/* eslint-disable prefer-arrow/prefer-arrow-functions, @typescript-eslint/no-shadow */
export function cloneNode(node, filter, root?) {
  if (!root && filter && !filter(node)) {
    return Promise.resolve()
  }

  return Promise.resolve(node)
    .then((clone) => makeNodeCopy(clone))
    .then((clone) => cloneChildren(node, clone, filter))
    .then((clone) => processClone(node, clone))

  function cloneChildren(original, clone, filter) {
    const children = original.childNodes
    if (children.length === 0) {
      return Promise.resolve(clone)
    }

    return cloneChildrenInOrder(clone, asArray(children), filter).then(() => clone)

    function cloneChildrenInOrder(parent, children, filter) {
      let done = Promise.resolve()
      children.forEach((child) => {
        done = done
          .then(() => cloneNode(child, filter))
          .then((childClone) => {
            if (childClone) {
              parent.appendChild(childClone)
            }
          })
      })
      return done
    }
  }
}

export const scrollByElementList = (
  list: QueryList<ElementRef<HTMLDivElement>>,
  styleClass: string,
) => {
  const activeItem = (list?.filter((item) => item.nativeElement.classList.contains(styleClass)) ||
    [])[0]?.nativeElement
  activeItem?.scrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
    inline: 'nearest',
  })
}

export const scrollIntoView = (
  element: HTMLElement,
  options: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' },
): void => {
  element?.scrollIntoView(options)
}

export const removeElements = async (
  element: HTMLElement,
  selector: string,
): Promise<HTMLElement> => {
  const node = (await cloneNode(element, undefined, true)) as HTMLElement
  const imgList = node.querySelectorAll(selector)

  for (let i = 0; i < imgList.length; i++) {
    imgList[i].parentNode.removeChild(imgList[i])
  }
  return node
}
