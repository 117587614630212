import { inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { SpaceApi } from '@core/space/api/space.api'
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators'
import { formatRankedMetrics, initializeSelectedList } from '@core/space/utils/space-metrics.utils'
import {
  exportWidget,
  fetchSpaceDemarcations,
  fetchSpaceDialogTeams,
  fetchSpaceGoalkeeperMetricDefinition,
  fetchSpacePlayerMetricDefinition,
  fetchSpaceTeamMetricDefinition,
  navigateToTeamSpace,
  openSpaceDialogMetricRanking,
  searchSpaceDialogPlayers,
  setSpaceDemarcations,
  setSpaceDialogSearchLoading,
  setSpaceDialogSearchResults,
  setSpaceGoalkeeperMetricDefinition,
  setSpacePlayerMetricDefinition,
  setSpaceTeamMetricDefinition,
} from '@core/space/state/actions/space.actions'
import { buildTeamSpaceUrl, parseSpaceTeam } from '@core/space/utils/space.utils'
import { catchRequestError, DialogService, exists, HighlightPipe } from '@mediacoach/ui'
import { Store } from '@ngrx/store'
import { selectSpaceDialogSearchTextPlayer } from '@core/space/state/selectors/space.selectors'
import {
  mapSpaceSearchPlayers,
  SPACE_MIN_CHAR_TO_FILTER,
} from '@core/space/utils/space-dialog.utils'
import { EMPTY, of, throwError } from 'rxjs'
import { DemarcationPipe } from '@shared/pipes/demarcation/demarcation.pipe'
import { navigateToNewTab } from '@core/router/state/actions/router.actions'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { mapWidgetQuery } from '@features/team-space/utils/team-space.utils'
import {
  buildExportWidgetPayload,
  getWidgetCompareQuery,
} from '@core/space/utils/space-widget.utils'
import { DialogMetricRankingComponent } from '@widgets/dialogs/dialog-ranking-metric/dialog-metric-ranking.component'
import {
  SPACE_PLAYER_RANKED_METRIC_COLUMNS,
  SPACE_TEAM_RANKED_METRIC_COLUMNS,
} from '@core/space/constants/space-grid.constants'
import { MultipleTranslatePipe } from '@shared/pipes/multiple-translate/multiple-translate.pipe'
import { SPACE_RANKING_QUERY } from '@core/space/constants/space.constants'
import { concatLatestFrom } from '@ngrx/operators'
import { ImageExporter } from '@shared/services/exporters/image.exporter'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsExportType } from '@core/analytics/enums/gtag-assets.enum'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { WidgetQuery } from '@widgets/models/widget.models'
import { getSimplifiedLang } from '@core/state/selectors/user.selectors'

@Injectable()
export class SpaceEffects {
  private readonly _actions$ = inject(Actions)
  private readonly _api = inject(SpaceApi)
  private readonly _store = inject(Store)
  private readonly _demarcationPipe = inject(DemarcationPipe<any>)
  private readonly _highlighterPipe = inject(HighlightPipe)
  private readonly _dialog = inject(DialogService)
  private readonly _multiTranslate = inject(MultipleTranslatePipe)
  private readonly _exporter = inject(ImageExporter)

  fetchSpaceTeamMetricDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchSpaceTeamMetricDefinition),
      switchMap(() =>
        this._api.fetchSpaceTeamMetricDefinition().pipe(
          map((metricDefinition) => {
            initializeSelectedList(metricDefinition)
            return metricDefinition
          }),
          map((teamMetricDefinition) => setSpaceTeamMetricDefinition({ teamMetricDefinition })),
        ),
      ),
    ),
  )

  fetchSpacePlayerMetricDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchSpacePlayerMetricDefinition),
      switchMap(() =>
        this._api.fetchSpacePlayerMetricDefinition().pipe(
          map((metricDefinition) => {
            initializeSelectedList(metricDefinition)
            return metricDefinition
          }),
          map((playerMetricDefinition) =>
            setSpacePlayerMetricDefinition({ playerMetricDefinition }),
          ),
        ),
      ),
    ),
  )

  fetchSpaceGoalkeeperMetricDefinition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchSpaceGoalkeeperMetricDefinition),
      switchMap(() =>
        this._api.fetchSpaceGoalkeeperMetricDefinition().pipe(
          map((metricDefinition) => {
            initializeSelectedList(metricDefinition)
            return metricDefinition
          }),
          map((goalkeeperMetricDefinition) =>
            setSpaceGoalkeeperMetricDefinition({ goalkeeperMetricDefinition }),
          ),
        ),
      ),
    ),
  )

  fetchSpaceDemarcations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchSpaceDemarcations),
      switchMap(() =>
        this._api.fetchSpaceDemarcations().pipe(
          map((demarcations) => setSpaceDemarcations({ demarcations })),
          catchRequestError(),
        ),
      ),
    ),
  )

  fetchSpaceDialogTeams$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fetchSpaceDialogTeams),
      tap(() => this._store.dispatch(setSpaceDialogSearchLoading({ loading: true }))),
      switchMap(({ seasonId, competitionId }) =>
        this._api.fetchTeams(seasonId, competitionId).pipe(
          switchMap(({ teams }) => [
            setSpaceDialogSearchResults({
              results: teams.map((team) => parseSpaceTeam(team, seasonId, competitionId)),
            }),
          ]),
          catchError(() => {
            this._store.dispatch(
              setSpaceDialogSearchResults({
                results: null,
              }),
            )
            return EMPTY
          }),
          finalize(() => this._store.dispatch(setSpaceDialogSearchLoading({ loading: false }))),
        ),
      ),
    )
  })

  searchSpaceDialogPlayers$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(searchSpaceDialogPlayers),
      switchMap(({ competitionId, seasonId }) =>
        this._store
          .pipe(selectSpaceDialogSearchTextPlayer())
          .pipe(map((text) => ({ text, competitionId, seasonId }))),
      ),
      tap(() => this._store.dispatch(setSpaceDialogSearchLoading({ loading: true }))),
      switchMap(({ text, competitionId, seasonId }) =>
        !(!text || (exists(text) && text.length < SPACE_MIN_CHAR_TO_FILTER))
          ? this._api.searchPlayers(text, competitionId, seasonId).pipe(
              switchMap((players) => [
                setSpaceDialogSearchResults({
                  results: mapSpaceSearchPlayers(
                    players,
                    text,
                    this._demarcationPipe,
                    this._highlighterPipe,
                  ),
                }),
              ]),
              catchError(() => {
                this._store.dispatch(
                  setSpaceDialogSearchResults({
                    results: null,
                  }),
                )
                return EMPTY
              }),
              finalize(() => this._store.dispatch(setSpaceDialogSearchLoading({ loading: false }))),
            )
          : [
              setSpaceDialogSearchResults({
                results: null,
              }),
              setSpaceDialogSearchLoading({ loading: false }),
            ],
      ),
    )
  })

  openSpaceDialogMetricRanking$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(openSpaceDialogMetricRanking),
        concatLatestFrom(() => [
          this._store.select(getMergedRouteParams),
          this._store.select(getSimplifiedLang),
        ]),
        map(([{ metricKey, widget, isPlayerRanking }, params, lang]) => ({
          ...mapWidgetQuery(
            {
              ...SPACE_RANKING_QUERY,
              metricNames: [metricKey],
              metricType: widget?.query?.metricType ?? SPACE_RANKING_QUERY.metricType,
              ...(isPlayerRanking
                ? {
                    rankingType: widget?.query?.rankingType ?? SPACE_RANKING_QUERY.rankingType,
                    rankingOver: widget?.query?.rankingOver ?? SPACE_RANKING_QUERY.rankingOver,
                    minutesPlayed: widget?.query?.minutesPlayed,
                  }
                : {}),
              ...((getWidgetCompareQuery(widget.query)?.teamId
                ? { compare: { teamId: getWidgetCompareQuery(widget.query)?.teamId } }
                : {}) as any),
            },
            params,
          ),
          widget,
          header: this._multiTranslate.transform(`MTR_WIDGET_RANKING,${metricKey}`, ',', ' '),
          isPlayerRanking,
          lang,
        })),
        map(({ query, widget, teamId, header, isPlayerRanking, lang }) => ({
          header,
          query,
          lang,
          isPlayerRanking,
          minutesPlayed: query?.minutesPlayed,
          threshold: widget?.displayMode?.showLowInvolvementLegendThreshold,
          lowInvolvementLegend: widget?.displayMode?.lowInvolvementLegend,
          metricRanking$: (_query: WidgetQuery) =>
            this._api.fetchSpaceRankingWidget(_query).pipe(
              map((metrics) =>
                formatRankedMetrics({
                  rankings: metrics,
                  itemId: teamId,
                  displayMode: { ...widget.displayMode, rowHighlight: !_query.compare },
                  top: '36px',
                  compareId: getWidgetCompareQuery(_query)?.teamId,
                  againstCompetition: widget?.displayMode?.againstTo === 'competition',
                }),
              ),
              catchError(() => {
                return of([])
              }),
            ),
        })),
        switchMap(
          ({
            header,
            isPlayerRanking,
            minutesPlayed,
            threshold,
            lowInvolvementLegend,
            metricRanking$,
            query,
            lang,
          }) =>
            this._dialog.open(DialogMetricRankingComponent, {
              header,
              styleClass: 'mcp-widget-dialog',
              data: {
                metricRanking$,
                columns: isPlayerRanking
                  ? SPACE_PLAYER_RANKED_METRIC_COLUMNS
                  : SPACE_TEAM_RANKED_METRIC_COLUMNS,
                minutesPlayed,
                threshold,
                lowInvolvementLegend,
                query,
                lang,
              },
            }).onClose,
        ),
      ),
    { dispatch: false },
  )

  navigateToTeamSpace$ = createEffect(() =>
    this._actions$.pipe(
      ofType(navigateToTeamSpace),
      concatLatestFrom(() => this._store.select(getMergedRouteParams)),
      map(([{ team }, params]) => ({
        team,
        competitionId: params['competitionId'],
        seasonId: params['seasonId'],
      })),
      map(({ team, competitionId, seasonId }) =>
        navigateToNewTab({
          path: buildTeamSpaceUrl(team, competitionId, seasonId),
        }),
      ),
    ),
  )

  exportWidget$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportWidget),
      map(({ widget, spaceType }) => buildExportWidgetPayload(widget, spaceType)),
      switchMap(({ element, filename, options }) => {
        if (!element) {
          throw new Error('Element not found!')
        }
        return fromPromise(this._exporter.exportAsPNG(element, filename, options)).pipe(
          map(() => ({
            eventName: AnalyticsEvent.downloadFile,
            eventParams: {
              [AnalyticsParam.category]: AnalyticsCategory.downloads,
              [AnalyticsParam.fileName]: `${filename}.png`,
              [AnalyticsParam.exportType]: AnalyticsExportType.Widget,
            },
          })),
        )
      }),
      map((trackEvent) => analyticsTrackEvent(trackEvent)),
      catchError((err) => {
        console.error(err)
        return throwError(() => err)
      }),
    ),
  )
}
