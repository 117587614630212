import { NavigationState } from '@core/state/models/navigation.state'
import { Action, createReducer, on } from '@ngrx/store'
import { setNavigationItems } from '@core/state/actions/navigation.actions'

const initialState: NavigationState = {
  items: [],
}

const reducer = createReducer(
  initialState,
  on(setNavigationItems, (state, { items }) => ({ ...state, items })),
)

export const navigationReducer = (state: NavigationState | undefined, action: Action) =>
  reducer(state, action)
