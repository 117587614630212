export class TourError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, TourError.prototype)
  }
}

export class TourStepDoesNotExist extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, TourStepDoesNotExist.prototype)
  }
}

export class TourStepOutOfRange extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, TourStepOutOfRange.prototype)
  }
}
