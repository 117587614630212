import { Directive, Renderer2 } from '@angular/core'

@Directive({
  selector: '[mcpScrollbarBodyHandler]',
})
export class ScrollbarBodyHandlerDirective {
  private readonly _body = document.querySelector('body')

  constructor(private readonly _renderer: Renderer2) {}

  enableScrollbar(state: boolean): void {
    this._renderer.setStyle(this._body, 'overflow', state ? 'initial' : 'hidden')
  }
}
