import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { DispatcherCardItem } from './components/dispatcher-card/dispatcher-card.models'

@Component({
  selector: 'mcp-dispatcher-grid',
  templateUrl: './dispatcher-grid.component.html',
  styleUrls: ['./dispatcher-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DispatcherGridComponent {
  @Input() dispatcherItems: DispatcherCardItem[]
  @Output() itemClick: EventEmitter<DispatcherCardItem> = new EventEmitter<DispatcherCardItem>()
}
