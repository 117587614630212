<app-modal
  #modal
  class="match-item-list-modal"
  size="profile"
  [modalHeader]="matchesConfirmHeader"
  [modalBody]="matchesConfirmBody"
  [modalFooter]="matchesConfirmFooter"
>
  <ng-template #matchesConfirmHeader>
    {{ 'MATCHES_DETAIL_CONFIRM_TITLE' | translate }}
  </ng-template>
  <ng-template #matchesConfirmBody>
    <p class="match-item-list-modal__body">
      {{ 'MATCHES_DETAIL_CONFIRM_MESSAGE' | translate: { selectedMatchLimit: limit } }}
    </p>
  </ng-template>
  <ng-template #matchesConfirmFooter>
    <button
      type="button"
      class="match-item-list-modal__footer button-primary"
      (click)="onConfirm()"
    >
      {{ 'COMMONS_CONTINUE' | translate }}
    </button>
  </ng-template>
</app-modal>
