import { createAction, props } from '@ngrx/store'
import { ApplicationProfile, McpProfile } from '@auth/models/auth.dto'

export const fetchUserProfile = createAction('[Profile] Fetch user profile')
export const setProfile = createAction(
  '[Profile] Set profile and trigger side effects',
  props<{ profile: McpProfile }>(),
)
export const setUserProfile = createAction(
  '[Profile] Set user profile to store',
  props<{ profile: McpProfile }>(),
)
export const saveUserProfile = createAction(
  '[Profile] Save user profile',
  props<{ profile: McpProfile }>(),
)

export const saveUserAvatar = createAction('[Profile] Save user avatar', props<{ path: string }>())
export const setUserAvatar = createAction('[Profile] Set user avatar', props<{ avatar: string }>())

export const setUserApplications = createAction(
  '[Profile] Set user applications',
  props<{ applications: ApplicationProfile[] }>(),
)

export const saveUserCompetition = createAction(
  '[Profile] Save user competition',
  props<{ competitionId: string }>(),
)

export const saveUserTeam = createAction('[Profile] Save user team', props<{ teamId: string }>())

export const saveUserTourStep = createAction(
  '[Profile] Save tour step',
  props<{ onBoardingStep: number }>(),
)

export const purgeUserProfile = createAction('[Profile] Purge user profile')
