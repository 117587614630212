export enum AnalyticsEvent {
  pageView = 'page_view',
  openSuiteApplication = 'open_suite_application',
  login = 'login',
  logout = 'logout',
  changeCompetition = 'change_competition',
  changeFavCompetition = 'change_favourite_competition',
  setTab = 'set_tab',
  changeTeam = 'change_team',
  liveMatch = 'click_match_live',
  nextMatch = 'click_match_next',
  prevMatch = 'click_match_previous',
  clickMatch = 'click_match',
  clickPlayer = 'click_player',
  downloadPlayerSeasonOverview = 'download_player_season_overview',
  switchAvg = 'click_switch_avg',
  downloadAsset = 'download_asset',
  downloadFile = 'download_file',
  videoPause = 'video_pause',
  videoPlay = 'video_play',
  videoEnded = 'video_ended',
  videoSeek = 'video_seek',
  videoCameraChange = 'video_camera_change',
  videoFullscreen = 'video_fullscreen',
  videoPiP = 'video_pip',
  videoCinemaMode = 'video_cinema_mode',

  search = 'search',

  onboardingStart = 'onboarding_start',
  onboardingStop = 'onboarding_stop',
  onboardingComplete = 'onboarding_complete',
  unAuthorizedUser = 'unauthorized_user',
  concurrentUser = 'concurrent_user',
}
