import { HttpClient } from '@angular/common/http'
import { Component, ViewChild } from '@angular/core'

import { switchMap } from 'rxjs/operators'

import { ModalComponent } from '../modal/modal.component'

import { environment } from '@env'
import { Store } from '@ngrx/store'
import { selectDistinctSimplifiedLang } from '@core/state/selectors/user.selectors'

@Component({
  selector: 'app-dialog-legal',
  templateUrl: './dialog-legal.component.html',
  styleUrls: ['./dialog-legal.component.scss'],
})
export class DialogLegalComponent {
  @ViewChild('legal', { static: true }) public modal: ModalComponent

  legal$ = this._store.pipe(
    selectDistinctSimplifiedLang(),
    switchMap((lang) =>
      this._http.get(environment.LEGAL_NOTICE_URL(lang), { responseType: 'text' }),
    ),
  )

  constructor(
    private _http: HttpClient,
    private readonly _store: Store,
  ) {}

  openModal() {
    this.modal.openModal()
  }

  closeModal() {
    this.modal.closeModal()
  }
}
