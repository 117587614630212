import {
  EventTranslations,
  EventType,
  Period,
  PeriodTranslations,
} from '@mediacoach-ui-library/global'

export const EVENT_TRANSLATIONS: EventTranslations = {
  [EventType.Shot]: 'MATCH_EVENT_SHOT',
  [EventType.YellowCard]: 'MATCH_EVENT_YELLOW_CARD',
  [EventType.DoubleYellowCard]: 'MATCH_EVENT_DOUBLE_YELLOW_CARD',
  [EventType.RedCard]: 'MATCH_EVENT_RED_CARD',
  [EventType.Goal]: 'MATCH_EVENT_GOAL',
  [EventType.MissedPenal]: 'MATCH_EVENT_MISSED_PENAL',
  [EventType.OwnGoal]: 'MATCH_EVENT_OWN_GOAL',
}

export const PERIOD_TRANSLATIONS: PeriodTranslations = {
  [Period.First]: 'MATCHDETAIL_VIDEO_PLAYER_FIRST_PART',
  [Period.Second]: 'MATCHDETAIL_VIDEO_PLAYER_SECOND_PART',
  [Period.ExtraFirst]: 'MATCHDETAIL_VIDEO_PLAYER_EXTRA_FIRST_PART',
  [Period.ExtraSecond]: 'MATCHDETAIL_VIDEO_PLAYER_EXTRA_SECOND_PART',
  [Period.Penalties]: 'MATCHDETAIL_VIDEO_PLAYER_PENALTIES',
  [Period.End]: 'MATCHDETAIL_VIDEO_PLAYER_FINISHED',
}

export const PERIOD_TRANSLATIONS_END: PeriodTranslations = {
  [Period.First]: 'MATCHDETAIL_VIDEO_PLAYER_FIRST_PART_END',
  [Period.Second]: 'MATCHDETAIL_VIDEO_PLAYER_SECOND_PART_END',
  [Period.ExtraFirst]: 'MATCHDETAIL_VIDEO_PLAYER_EXTRA_FIRST_PART_END',
  [Period.ExtraSecond]: 'MATCHDETAIL_VIDEO_PLAYER_EXTRA_SECOND_PART_END',
  [Period.Penalties]: 'MATCHDETAIL_VIDEO_PLAYER_PENALTIES_END',
  [Period.End]: 'MATCHDETAIL_VIDEO_PLAYER_FINISHED',
}
