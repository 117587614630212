import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'

export const REPORT_ID_RGX = /(^.*reports\/\d+\/)|(\?.*$)/i

export const QUERY_MAP = {
  position: AnalyticsParam.position,
}

export const PARAM_MAP = {
  matchId: AnalyticsParam.matchId,
  teamId: AnalyticsParam.teamId,
  playerId: AnalyticsParam.playerId,
  seasonId: AnalyticsParam.seasonId,
  competitionId: AnalyticsParam.competitionId,
  vsSeasonId: AnalyticsParam.seasonId,
  vsCompetitionId: AnalyticsParam.vsCompetitionIdB,
  vsTeamId: AnalyticsParam.vsTeamIdB,
  vsPlayerId: AnalyticsParam.vsPlayerIdB,
  videoId: AnalyticsParam.mcVideoId,
  dynamicReportName: AnalyticsParam.dynamicReportName,
}

export const DEFAULT_PARAMS_PER_EVENT = {
  [AnalyticsEvent.login]: {
    name: AnalyticsEvent.login,
    params: { [AnalyticsParam.category]: AnalyticsCategory.auth },
  },
  [AnalyticsEvent.logout]: {
    name: AnalyticsEvent.logout,
    params: { [AnalyticsParam.category]: AnalyticsCategory.auth },
  },
  [AnalyticsEvent.nextMatch]: {
    name: AnalyticsEvent.nextMatch,
    params: { [AnalyticsParam.category]: AnalyticsCategory.navigation },
  },
  [AnalyticsEvent.prevMatch]: {
    name: AnalyticsEvent.prevMatch,
    params: { [AnalyticsParam.category]: AnalyticsCategory.navigation },
  },
}
