import { Component, Input, ViewChild } from '@angular/core'

import { Observable } from 'rxjs'

import { Locale } from '@mediacoach-ui-library/global'

import { ModalComponent } from '../modal/modal.component'
import { AssetMatch } from '@core/models/dto/match.dto'
import { Store } from '@ngrx/store'
import { getDistinctSimplifiedLang } from '@core/state/selectors/user.selectors'
import { DEFAULT_BANNER_TEMPLATES } from '@shared/components/match-item/constants/match-item.constants'
import { MatchItemTemplateConfig } from '@shared/components/match-item/models/match-item.model'

const INCIDENCE_MESSAGE_KEYS = {
  [Locale.En]: 'messageENgb',
  [Locale.Es]: 'messageESes',
}

@Component({
  selector: 'app-incidence-modal',
  templateUrl: './incidence-modal.component.html',
  styleUrls: ['./incidence-modal.component.scss'],
})
export class IncidenceModalComponent {
  @ViewChild('modal', { static: true }) modal: ModalComponent
  @Input() match: AssetMatch

  incidenceMessageKeys = INCIDENCE_MESSAGE_KEYS
  language$: Observable<string> = this._store.pipe(getDistinctSimplifiedLang())
  matchTemplates: MatchItemTemplateConfig[] = DEFAULT_BANNER_TEMPLATES

  constructor(private readonly _store: Store) {}
}
