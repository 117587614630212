import { Directive, HostBinding, HostListener, Input, OnInit } from '@angular/core'

@Directive({ selector: '[mcpNavigable]' })
export class NavigableDirective implements OnInit {
  private get _enabled() {
    return !!this.url && !this.navigationDisabled
  }

  @Input('mcpNavigable') url: string
  @Input() navigationDisabled: boolean
  @Input() target: string = '_blank'

  @HostBinding('style.cursor') cursor: 'default' | 'pointer' = 'default'

  @HostListener('click')
  onClick() {
    if (this._enabled) {
      window.open(this.url, this.target)
    }
  }

  ngOnInit() {
    this.cursor = this._enabled ? 'pointer' : 'default'
  }
}
