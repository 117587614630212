import {
  PlayerSpaceCareer,
  PlayerSpaceSeasonData,
  PlayerSpaceTrajectory,
  PlayerSpaceTrajectoryTab,
} from '@features/player-space/models/player-space.models'
import { uuidv4 } from '@core/utils/object.utils'
import { Widget, WidgetQuery, WidgetRow } from '@widgets/models/widget.models'
import { mapSpaceQueryMetricNamesToColumn } from '@core/space/utils/space-metrics.utils'
import { getStaticItem, mediumColorLogoPredicate } from '@core/utils/assets.utils'
import { Season } from '@core/models/dto/season.dto'
import { buildPlayerSpaceSeasonDataRows } from '@features/player-space/utils/player-space-metrics.utils'
import { McpPlayer } from '@core/models/dto/player.dto'
import { SpaceDemarcationCategory } from '@core/space/models/space.models'
import { RadioOption } from '@mediacoach/ui'

export const buildPlayerSpaceNavUrl = (
  playerId: string,
  teamId: string,
  seasonId: string,
  competitionId: string,
): string =>
  `/players/${playerId}/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

const parseTrajectoryRow = (
  trajectory: PlayerSpaceTrajectory,
  hideText: boolean,
  styleClass: string,
  expandableKey?: string,
): WidgetRow<PlayerSpaceTrajectory> => ({
  ...trajectory,
  id: uuidv4(),
  name: trajectory?.team || trajectory?.name,
  hideText,
  expandableKey,
  styleClass,
})

export const buildPlayerSpaceTrajectoryTab = (
  playerCareer: PlayerSpaceCareer,
): PlayerSpaceTrajectoryTab[] => {
  return [
    {
      mode: 'clubs',
      label: 'MTR_WIDGET_TRAJECTORY_CLUBS',
      content: playerCareer.clubs.map((t) => ({
        ...parseTrajectoryRow(t, false, 'parent-row', 'competitions'),
        competitions: t.competitions.map((c) => parseTrajectoryRow(c, true, 'child-row')),
      })),
    },
    {
      mode: 'national',
      label: 'MTR_WIDGET_TRAJECTORY_NATIONAL',
      content: playerCareer.nationalTeams.map((t) => ({
        ...parseTrajectoryRow(t, false, 'parent-row', 'competitions'),
        competitions: t.competitions.map((c) => parseTrajectoryRow(c, true, 'child-row')),
      })),
    },
  ]
}

export const buildPlayerSpaceSeasonData = (
  widget: Widget,
  currentSeason: Season,
  data: PlayerSpaceSeasonData,
) => {
  const playerSpaceSeasonDataRows = buildPlayerSpaceSeasonDataRows(data)
  const tabs = data.competitions.map((item) => ({
    item: item.id,
    title: item.name,
    display: 'competition',
    logo: getStaticItem(item.statics, mediumColorLogoPredicate),
    content: playerSpaceSeasonDataRows[item.id],
  }))

  return {
    data: {
      tabs: [
        {
          item: 'all',
          title: 'MONITORING_REPORT_FILTERS_OPTIONS_ALL',
          subtitle: currentSeason.officialName?.replace(/ - \d{2}/, '/'),
          display: 'text',
          content: playerSpaceSeasonDataRows['all'],
        },
        ...tabs,
      ],
      columns: [
        {
          label: 'MTR_COMMON_MATCH',
          key: 'label',
          width: 'minmax(150px, 1fr)',
          sticky: true,
          styleClass: 'left',
        },
        ...mapSpaceQueryMetricNamesToColumn(widget.query.metricNames, '150px'),
      ],
    },
    widgetUUID: widget.uuid,
  }
}

const findDemarcationLabelByKey = (
  categories: SpaceDemarcationCategory[],
  demarcationKey: string,
): string => {
  return categories.reduce((key, item) => {
    const match = item.positions.find((p: RadioOption) => p.value === demarcationKey) as RadioOption
    if (match) {
      key = match.label
    }
    return key
  }, '')
}

export const resolveDemarcationTranslationKey = (
  player: McpPlayer,
  query: WidgetQuery,
  demarcations: SpaceDemarcationCategory[],
): string => {
  if (query?.positions?.length) {
    const demarcationTranslationKey = query?.positions[0]
    return findDemarcationLabelByKey(demarcations, demarcationTranslationKey)
  }

  return player.playerPositionKey + 'S'
}
