import {
  MatchItemTemplate,
  MatchItemTemplateConfig,
} from '@shared/components/match-item/models/match-item.model'

export const DEFAULT_TEMPLATES: MatchItemTemplateConfig[] = [
  {
    template: MatchItemTemplate.Info,
    wrapperCssClasses: 'u-2/12@lg',
  },
  {
    template: MatchItemTemplate.Result,
    wrapperCssClasses: 'u-7/12@lg u-4/12@xl',
  },
  {
    template: MatchItemTemplate.Round,
    wrapperCssClasses: 'u-1/12@lg',
  },
  {
    template: MatchItemTemplate.Date,
    wrapperCssClasses: 'u-2/12@lg',
  },
  {
    template: MatchItemTemplate.State,
    wrapperCssClasses: 'u-2/12@lg',
  },
  {
    template: MatchItemTemplate.AssetAvailability,
    wrapperCssClasses: 'u-3/12@xl',
  },
]

export const DEFAULT_ROW_TEMPLATES: MatchItemTemplateConfig[] = [
  {
    template: MatchItemTemplate.Info,
    wrapperCssClasses: 'u-2/12@lg pd-top-ml u-hidden--from@lg',
  },
  {
    template: MatchItemTemplate.Result,
    wrapperCssClasses: 'u-6/12@lg u-4/12@xl pd-top-ml',
  },
  {
    template: MatchItemTemplate.Competition,
    wrapperCssClasses: 'u-2/12@lg u-3/12@xl u-hidden--until@lg',
  },
  {
    template: MatchItemTemplate.Round,
    wrapperCssClasses: 'u-2/12@lg u-hidden--until@lg',
  },
  {
    template: MatchItemTemplate.Date,
    wrapperCssClasses: 'u-2/12@lg u-3/12@xl',
  },
]

export const DEFAULT_BANNER_TEMPLATES: MatchItemTemplateConfig[] = [
  {
    template: MatchItemTemplate.Info,
    wrapperCssClasses: 'pd-top-ml',
    templateCssClasses: 'ft-sz-sm',
  },
  { template: MatchItemTemplate.Result, wrapperCssClasses: 'pd-top-sm' },
  { template: MatchItemTemplate.State, wrapperCssClasses: 'pd-top-md' },
  { template: MatchItemTemplate.Date, wrapperCssClasses: 'pd-top-md' },
]
