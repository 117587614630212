import { TeamType } from '@mediacoach-ui-library/global'

export const parsePlayerToPlayerHeader = (player) => {
  const {
    metricPlayerName,
    portraitLogo,
    playerId,
    playerPosition,
    playerPositionKey,
    genericPositionKey,
    specificPositionKey,
    age,
    height,
    nationalityKey,
    preferredFootKey,
    participationKey,
    team,
    headerMetricResults,
  } = player

  return {
    name: metricPlayerName,
    playerPosition,
    playerPositionKey,
    genericPositionKey,
    specificPositionKey,
    age,
    height,
    nationalityKey,
    preferredFootKey,
    participationKey,
    image: portraitLogo,
    id: playerId,
    team,
    headerMetricResults: (headerMetricResults || []).map((metric) => ({
      ...metric,
      unit: metric.unit && `${metric.unit}_SHORT`,
      isNumber: !isNaN(metric.value),
    })),
  }
}

export const getTeamType = (match, team): TeamType =>
  (team.id || team.teamId) === match.home.team.id ? TeamType.Home : TeamType.Away
