import { inject, Pipe, PipeTransform } from '@angular/core'
import { MenuItem } from '@mediacoach/ui'
import { TranslateService } from '@ngx-translate/core'
import { of } from 'rxjs'

@Pipe({
  name: 'translateMenu',
})
export class TranslateMenuPipe implements PipeTransform {
  private readonly _translate = inject(TranslateService)
  transform(value: MenuItem[], _: string): any {
    if (value) {
      return this._translateLabels(value)
    }
    return of(value)
  }

  private _translateLabels(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item) => ({
      ...item,
      label: this._translate.instant(item.label),
      children: item.children ? this._translateLabels(item.children) : undefined,
    }))
  }
}
