import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Competition } from '@mediacoach-ui-library/global'
import { ModalBase } from '@shared/components/modal/modal.base'

@Component({
  selector: 'mcp-competition-dialog',
  templateUrl: './competition-dialog.component.html',
  styleUrls: ['./competition-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionDialogComponent extends ModalBase {
  @Input() competitions: Competition[]

  @Input() set currentCompetitionId(id: string) {
    this.selected = id
  }

  @Output() competitionSelected = new EventEmitter<Competition>()

  selected: string
  competition: Competition

  onCompetitionSelected(competition: Competition): void {
    this.competition = competition
    this.selected = competition.id
  }
}
