import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { PlaylistCollaborator } from '@features/playlist/models/playlist.response.models'

@Component({
  selector: 'mcp-collaborators-avatar-list',
  templateUrl: './collaborators-avatar-list.component.html',
  styleUrl: './collaborators-avatar-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorsAvatarListComponent {
  @Input() collaborators: PlaylistCollaborator[] = []
  readonly defaultAvatarColor = '#a1a1a1'

  get otherCollaborators() {
    return this.collaborators
      ?.filter((_, i) => i > 2)
      .map(({ name, surname }) => `${name} ${surname}`)
      .join(', ')
  }
}
