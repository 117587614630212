import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { ApiTeam, ApiTeams } from '@core/models/models/team.models'
import { ApiMatches } from '@core/models/models/match.models'
import { McpTeam } from '@core/models/dto/team.dto'
import { ApiMetric } from '@core/models/models/metric.models'

@Injectable()
export class TeamApi {
  constructor(private readonly _http: HttpClient) {}

  fetchTeams(seasonId: string, competitionId: string): Observable<ApiTeams> {
    return this._http.get<ApiTeams>(environment.INTEGRATED_API.TEAMS(seasonId, competitionId))
  }

  fetchTeam(seasonId: string, competitionId: string, teamId: string): Observable<ApiTeam> {
    return this._http.get<ApiTeam>(
      environment.INTEGRATED_API.TEAMS(seasonId, competitionId, teamId),
    )
  }

  fetchLastMatches({ seasonId, id, competitionId }: McpTeam): Observable<ApiMatches> {
    return this._http.get<ApiMatches>(
      environment.INTEGRATED_API.LAST_TEAM_MATCHES(seasonId, id, competitionId),
      { params: { competitionId } },
    )
  }

  fetchTeamMetrics({ seasonId, competitionId, id }: McpTeam): Observable<ApiMetric> {
    return this._http.get<ApiMetric>(
      environment.INTEGRATED_API.TEAM_METRIC_RESULTS(seasonId, competitionId, id),
    )
  }
}
