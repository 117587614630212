import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { TagItem } from '../models/tag-item.models'
import {
  COLORED_DIMENSION_TYPES,
  DEFAULT_IMAGE_BY_DIMENSION_TYPE,
  IMAGE_CLASS_BY_DIMENSION_TYPE,
  PATH_TAG_STAR_IMAGE,
} from '../constants/tag-item.constants'
import { TagDimensionType } from '../enums/tag-item.enums'
import { getColoredStyles } from '@mediacoach/ui'

@Component({
  selector: 'mcp-tag-item',
  templateUrl: './tag-item.component.html',
  styleUrls: ['./tag-item.component.theme.scss', './tag-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagItemComponent {
  /**
   * Tag item.
   */
  @Input() item!: TagItem

  /**
   * Tag item selected.
   */
  @Input() selected: boolean | undefined

  /**
   * Callback to invoke when item is clicked.
   * @group Emits
   */
  @Output() itemClick: EventEmitter<TagItem> = new EventEmitter()
  @Output() markAsFavorite = new EventEmitter<TagItem>()

  readonly imageClassByDimensionType = IMAGE_CLASS_BY_DIMENSION_TYPE
  readonly defaultImageByDimensionType = DEFAULT_IMAGE_BY_DIMENSION_TYPE
  readonly pathStar = PATH_TAG_STAR_IMAGE

  get isColored() {
    return COLORED_DIMENSION_TYPES.includes(this.item?.dimensionType)
  }

  get eventStyles(): Partial<CSSStyleDeclaration> {
    return this.isColored ? getColoredStyles(this.item?.eventColor) : {}
  }

  get dimensionType(): TagDimensionType {
    return this.item?.dimensionType
  }
}
