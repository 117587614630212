interface String {
  hash(): string
}

/* eslint-disable no-bitwise */
if (!String.prototype.hasOwnProperty('isSameDay')) {
  String.prototype.hash = function (): string {
    let hash = 0
    let i
    let char

    if (this.length === 0) {
      return hash.toString()
    }
    for (i = 0; i < this.length; i++) {
      char = this.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash = hash & hash // Convert to 32bit integer
    }
    return hash.toString()
  }
}
/* eslint-enable no-bitwise */
