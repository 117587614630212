import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
  @Input() label: string
  @Input() tooltip: string
  @Input() tooltipStyleClass: string

  isIconHover = false

  constructor() {}
}
