import { WidgetIdentifier } from '@widgets/enums/widget-identifier.enum'
import {
  Widget,
  WidgetDisplayMode,
  WidgetQuery,
  WidgetQueryRankingType,
} from '@widgets/models/widget.models'
import { PlayerSpaceSeasonData } from '@features/player-space/models/player-space.models'
import { SpaceMetricResult } from '@core/space/models/space-metrics.models'
import { getStaticItem, smallColorLogoPredicate } from '@core/utils/assets.utils'
import { Competition } from '@core/models/dto/season.dto'
import { McpPlayer } from '@core/models/dto/player.dto'
import { buildRadarChartDataset } from '@widgets/widgets/radar-chart-widget/utils/radar-chart.utils'

export const resolveRankingType = (
  widget: Widget,
  isGoalKeeper: boolean,
): WidgetQueryRankingType => {
  switch (widget.id) {
    case WidgetIdentifier.bottom5Metrics:
      return isGoalKeeper ? 'GoalkeeperBottom' : 'PlayerBottom'
    case WidgetIdentifier.top5Metrics:
      return isGoalKeeper ? 'GoalkeeperTop' : 'PlayerTop'
    default:
      return widget.query?.rankingType
  }
}

const seasonTotalsBase: any = { name: 'MTR_COMMON_TOTAL_SEASON' }

const parseTeam = (team: any) => ({
  ...team,
  img: getStaticItem(team.statics, smallColorLogoPredicate),
})

export const buildPlayerSpaceSeasonDataRows = (data: PlayerSpaceSeasonData) => {
  const allTabTotals = buildMetricTotals(data?.metrics)
  const competitionRowsAndTotals = data?.competitions?.reduce(
    (results, c) => ({
      ...results,
      [c.id]: {
        totals: buildMetricTotals(c.metrics),
        rows: c.matches.map(({ metrics, ...match }) => ({
          match: {
            ...match,
            homeTeam: parseTeam(match.homeTeam),
            awayTeam: parseTeam(match.awayTeam),
          },
          ...(metrics || []).reduce(
            (rData, metric) => ({ ...rData, [metric.key]: metric.value }),
            {},
          ),
        })),
      },
    }),
    {},
  )
  return {
    all: {
      totals: allTabTotals,
      rows: Object.keys(competitionRowsAndTotals).reduce(
        (rData, key) => [...rData, ...competitionRowsAndTotals[key].rows],
        [],
      ),
    },
    ...competitionRowsAndTotals,
  }
}

export const buildMetricTotals = (metrics: SpaceMetricResult[]) =>
  (metrics || []).reduce((results, metric) => ({ ...results, [metric.key]: metric.value }), {
    ...seasonTotalsBase,
  })

export const mapPlayerSpaceRadarChartData = (
  entities: any,
  displayMode: WidgetDisplayMode,
  query: WidgetQuery,
  player: McpPlayer,
  competition: Competition,
  competitionText: string,
): any => {
  return buildRadarChartDataset(entities, displayMode, query, player, competition, competitionText)
}
