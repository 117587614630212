import { Injectable, Injector } from '@angular/core'
import { Subject } from 'rxjs'
import { Step, StepPosition, TourStep } from '../../models/tour-step.models'
import { StepActionType } from '@shared/modules/tour/enums/tour.enum'
import { TourStepOutOfRange } from '@shared/modules/tour/models/tour-step-error.models'
import { getStepIndex, getStepName } from '../../utils/tour.utils'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { DebuggableBaseService } from '@shared/modules/tour/services/debuggable-base/debuggable-base.service'
import { Router } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class TourStepsContainerService extends DebuggableBaseService {
  private _steps: Step[]
  private _tempSteps: TourStep[] = []
  private _currentStepIndex = -2
  private _options: TourOptions

  private get stepsOrder() {
    return this._options?.steps || []
  }

  stepHasBeenModified: Subject<TourStep> = new Subject<TourStep>()

  constructor(
    injector: Injector,
    private readonly _router: Router,
  ) {
    super(injector)
  }

  init() {
    this.logger.info('Initializing the steps array.')
    this._steps = []
    this._currentStepIndex = this._getFirstStepIndex() - 1
    const stepIds = this.stepsOrder
    stepIds.forEach((stepId) => this._steps.push({ id: stepId, step: null }))
  }

  get(action: StepActionType): TourStep {
    if (action === StepActionType.next) {
      this._currentStepIndex++
    } else {
      this._currentStepIndex--
    }

    if (this._currentStepIndex < 0 || this._currentStepIndex >= this._steps.length) {
      throw new TourStepOutOfRange('The first or last step of the tour cannot be found!')
    }

    const stepName = getStepName(this._steps[this._currentStepIndex].id)
    const index = this._tempSteps.findIndex((step) => step.name === stepName)

    const stepFound = this._tempSteps[index]
    this._steps[this._currentStepIndex].step = stepFound

    if (stepFound == null) {
      this.logger.warn(
        `Step ${this._steps[this._currentStepIndex].id} not found in the DOM. Check if it's hidden by *ngIf directive.`,
      )
    }

    return stepFound
  }

  getStepByName(name: string) {
    const stepIndex = this._tempSteps.findIndex((s) => s.name === name)

    if (stepIndex === -1) {
      this.logger.warn(
        `Step ${name} not found in the DOM. Check if it's hidden by *ngIf directive.`,
      )
    }

    this._currentStepIndex = stepIndex
    return this._tempSteps[stepIndex]
  }

  updateStepNavigationPath(stepId: string): void {
    const idx = this._tempSteps.findIndex((step) => step.name === stepId)
    if (this._tempSteps[idx] && !this._tempSteps[idx].navigateTo) {
      this._tempSteps[idx].navigateTo = this._router.url
    }
  }

  updatePosition(stepName: string, position: StepPosition) {
    const index = getStepIndex(this._steps, stepName)
    if (this._steps[index].step) {
      this._steps[index].step.position = position
      this.stepHasBeenModified.next(this._steps[index].step)
    } else {
      this.logger.warn(
        `Trying to modify the position of ${stepName} to ${position}. Step not found!Is this step located in a different route?`,
      )
    }
  }

  getStepNumber(stepName: string): number {
    return getStepIndex(this._steps, stepName) + 1
  }

  getStepsCount(): number {
    return this.stepsOrder?.length
  }

  setup(steps: TourStep[], options: TourOptions) {
    this._options = options
    this._addSteps(steps)
  }

  private _getFirstStepIndex(): number {
    const firstStep = this._options?.startWith
    const stepIds = this.stepsOrder

    let index = stepIds.indexOf(firstStep)
    if (index < 0) {
      index = 0
      if (firstStep !== undefined) {
        this.logger.warn(
          `The step ${firstStep} does not exist. Check in your step list if it's present.`,
        )
      }
    }

    return index
  }

  private _addSteps(stepsToAdd: TourStep[]): void {
    this._tempSteps = []
    for (const tourStep of stepsToAdd) {
      this._addStep(tourStep)
    }
  }

  private _addStep(stepToAdd: TourStep) {
    const stepExist = this._tempSteps.filter((step) => step.name === stepToAdd.name).length > 0
    if (!stepExist) {
      this.logger.info(`Adding step ${stepToAdd.name} to the steps list.`)
      this._tempSteps.push(stepToAdd)
    } else {
      const stepIndexToReplace = this._tempSteps.findIndex((step) => step.name === stepToAdd.name)
      this._tempSteps[stepIndexToReplace] = stepToAdd
    }
  }
}
