import { MergedRoute, MergedRouteReducerState } from '@core/router/models'
import { FEATURE_TOKEN } from '@core/router/constants'
import { createFeatureSelector, createSelector } from '@ngrx/store'
import { Params } from '@angular/router'

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(FEATURE_TOKEN)

export const getMergedRoute = createSelector(
  getRouterReducerState,
  (mergedRouteState): MergedRoute => mergedRouteState && mergedRouteState.state,
)

export const getMergedRouteParams = createSelector(
  getMergedRoute,
  (mergedRoute): Params => mergedRoute && mergedRoute.params,
)

export const getMergedRouteData = createSelector(
  getMergedRoute,
  (mergedRoute): Params => mergedRoute && mergedRoute.data,
)
