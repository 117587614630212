<div class="mcp-downloadable-asset-container" [class.download]="assetGroup.background">
  <div class="mcp-downloadable-asset-container__title-block">
    <span
      class="mcp-downloadable-asset-container__title"
      [title]="assetGroup.title | translate"
      [translate]="assetGroup.title"
    ></span>
  </div>
  <div
    *ngFor="let asset of assetGroup.assets?.length > 0 ? assetGroup.assets : defaultAsset"
    class="mcp-downloadable-asset-container__content"
    [class.no-content]="!(asset && asset?.assetItems)"
  >
    <img
      class="mcp-downloadable-asset-container__logo"
      *ngIf="asset?.logo; else iconTemp"
      [src]="asset?.logo | safe: 'url'"
    />
    <ng-template #iconTemp>
      <i
        *ngIf="asset?.icon"
        class="mcp-downloadable-asset-container__icon fa fa-{{ asset.icon }}"
        aria-hidden="true"
      ></i>
    </ng-template>
    <span class="mcp-downloadable-asset-container__label" [title]="asset?.text | translate">
      {{ asset?.text | translate }}
      <span *ngIf="asset?.version" class="mcp-downloadable-asset-container__version"
        >v{{ asset?.version }}</span
      >
    </span>
    <div class="mcp-downloadable-asset-container__button-wrapper">
      <a
        *ngIf="!(asset && asset?.assetItems); else assetItemsExist"
        class="mcp-downloadable-asset-container__button"
      ></a>

      <ng-template #assetItemsExist>
        <ng-container *ngFor="let item of asset?.assetItems">
          <mcp-downloadable-asset-item
            [item]="item"
            [assetGroup]="assetGroup"
            (streaming)="streaming.emit($event)"
            (analytics)="onAnalytics($event)"
          />
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
