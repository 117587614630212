<div
  class="mcp-tag-item"
  [class.mcp-tag-item--selected]="item?.selected || selected"
  (click)="itemClick.emit(item)"
>
  <div class="mcp-tag-item__container">
    <div class="mcp-tag-item__event-color-container">
      <div class="mcp-tag-item__event-color-item" [ngStyle]="eventStyles"></div>
    </div>
    <div class="mcp-tag-item__main-container">
      <div class="mcp-tag-item__index-subtitle-part-container">
        <span class="mcp-tag-item__index mcp-tag-item__index--{{ item?.teamType }}">{{
          item?.index
        }}</span>
        <span class="mcp-tag-item__subtitle" [title]="item?.subtitle || item?.comment">
          {{ item?.subtitle || item?.comment }}
        </span>
        <span class="mcp-tag-item__period" [title]="item?.period"> {{ item?.period }} </span>
      </div>
      <span class="mcp-tag-item__event-name" [title]="item?.eventName">{{ item?.eventName }}</span>
      <div class="mcp-tag-item__dimension">
        <img
          class="mcp-tag-item__image"
          [ngClass]="imageClassByDimensionType[dimensionType]"
          [srcUrl]="item?.image"
          [mcuiDefaultImage]="defaultImageByDimensionType[dimensionType]"
          [alt]="item?.title || ''"
          [title]="item?.title || ''"
        />
        <span class="mcp-tag-item__title"> {{ item?.title }} </span>
      </div>
    </div>
    <div class="mcp-tag-item__extra-info-container">
      <span class="mcp-tag-item__time">{{ item?.timeDescription }}</span>
      <mcui-avatar
        *ngIf="item?.owner"
        class="mcp-tag-item__owner"
        [backgroundColor]="item?.owner?.color"
        [imageSource]="item?.owner?.avatarPath"
        title="{{ item?.owner?.name }} {{ item?.owner?.surname }}"
        [text]="item?.owner?.name | firstCharacter: item?.owner?.surname"
      ></mcui-avatar>
      <img
        class="mcp-tag-item__star"
        (click)="$event.preventDefault(); markAsFavorite.emit(item)"
        mcuiDefaultImage
        [srcUrl]="pathStar(item?.isHighlighted)"
      />
    </div>
  </div>
</div>
