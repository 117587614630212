import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { HttpStatusCode } from '@core/enums/http-status-code.enum'
import { unauthorizedError } from '@core/state/actions/error.actions'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly _store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => null,
        (error) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case HttpStatusCode.Unauthorized:
                this._store.dispatch(unauthorizedError())
                break
            }
          }
        },
      ),
    )
  }
}
