import { Marker, VideoType } from '@mediacoach-ui-library/global'
import { CameraAsset } from '@core/models/dto/assets.dto'
import { LITERALS_FOR_VIDEO_TITLES } from '@core/constants/assets.constants'
import { getTimelineEventSeconds } from '@core/utils/timeline.utils'
import { VideoTypeMap } from '@core/enums/matches.enum'

export const alignTimeByAssetType = (
  markers: Marker[],
  prevContentType: VideoType,
  currentContentType: VideoType,
  playerTime: number = 0,
): number => {
  if (playerTime) {
    const ev = (markers || [])
      .map(({ time, data }, index) => ({
        index,
        data,
        distance: Math.abs(time - playerTime),
      }))
      .filter(({ distance }) => distance)
      .sort((a, b) => a.distance - b.distance)
    const event = (ev ? ev[0] : ({} as Marker))?.data
    const secondsDiff = !event
      ? 0
      : getTimelineEventSeconds(prevContentType, event.timeItems) -
        getTimelineEventSeconds(currentContentType, event.timeItems)
    return playerTime - secondsDiff
  }
  return playerTime
}

export const getCameraAssets = (
  isLive: boolean,
  streamsVoD: { [key: string]: { url: string } } = {},
): CameraAsset[] =>
  Object.keys(streamsVoD || {}).reduce(
    (list, videoType) => [
      ...list,
      {
        assetType: videoType,
        label: LITERALS_FOR_VIDEO_TITLES[videoType],
        value: videoType,
        disabled: isLive,
        streaming: streamsVoD[videoType],
      },
    ],
    [],
  )

export const getSelectedStream = (streamsVoD, contentType?: VideoType): VideoType => {
  const keys = streamsVoD ? Object.keys(streamsVoD) : []
  const allowedVideoTypes = [VideoType.Tactical, VideoType.Tv, VideoType.Panoramic]
  if (allowedVideoTypes.includes(contentType) && keys.includes(contentType as string)) {
    return VideoTypeMap[contentType] as VideoType
  } else {
    return keys.length === 0 || keys.includes(VideoType.Tactical as string)
      ? VideoType.Tactical
      : (keys[0] as VideoType)
  }
}
