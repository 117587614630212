import { NgModule } from '@angular/core'
import { FACTORY_TOKEN } from './constants/signalr.constants'
import { SignalrFactory } from './factories/signalr.factory'
import { HttpClient } from '@angular/common/http'
import { SignalrService } from './services/signalr.service'
import { Store } from '@ngrx/store'

@NgModule({
  providers: [
    SignalrService,
    {
      provide: FACTORY_TOKEN,
      useFactory: SignalrFactory,
      deps: [Store, HttpClient],
    },
  ],
})
export class SignalrModule {}
