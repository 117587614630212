import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { DateFromString } from '@core/utils/date.utils'

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: string | Date): any {
    const currentYear = new Date().getFullYear()
    const valueYear = DateFromString(value, false).getFullYear()

    return currentYear - valueYear
  }
}
