import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core'

import { DebounceTimeType } from '@mediacoach-ui-library/global'

import { BehaviorSubject, fromEvent, of, Subject, timer } from 'rxjs'
import { debounceTime, filter, map, tap, throttleTime } from 'rxjs/operators'

import { ScrollService } from '../../services/scroll.service'
import { TemplateDirective } from '../../directives/template/template.directive'

import { StorageService, StorageType } from '@shared/services/storage/storage.service'
import { inRange, roundNumber } from '@core/utils/number.utils'

const LOCAL_STORAGE_PREFIX = 'app-carousel__'

export enum Direction {
  Prev = 'prev',
  Next = 'next',
}

export const DIRECTIONS = Object.keys(Direction).map((direction) => Direction[direction])

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.theme.scss', './carousel.component.scss'],
})
export class CarouselComponent implements AfterContentInit {
  private _autoMove = 0
  private _items
  private _carouselItems: Array<ElementRef>
  private _visibleRatio: number
  private _wrapperWidth: number
  private _selectedIndexInner: number
  private _selectedIndex: number
  private _selectIndexDebounce$ = new BehaviorSubject<number>(undefined)
  private _moveThrottled$ = new Subject<Direction | number>()

  itemTemplate
  bottomTemplate
  noItemsTemplate
  previewTemplate
  loadingTemplate

  once
  previewIndex
  indexEdge = {}
  carouselEdgeX = 0
  enableCarousel = false
  navigationButtons = []
  carouselTranslateX = 0
  activeButtonState = {}
  autoMove$
  canSelect$ = of(true)
  onResize$ = fromEvent(window, 'resize').pipe(
    debounceTime(DebounceTimeType.Long),
    tap(() => {
      this._updateVisibleItems()
      this.move(this.selectedIndex)
    }),
  )

  move$ = this._moveThrottled$.pipe(
    throttleTime(DebounceTimeType.Long),
    tap((option) => {
      this._moveInner(option)
    }),
  )

  selectIndex$ = this._selectIndexDebounce$.pipe(
    filter(() => !!(this.items || []).length),
    tap((selectedIndex) => {
      selectedIndex = inRange(
        selectedIndex != null ? Number(selectedIndex) : this._storageSelectedIndex || 0,
        (this.items || []).length - 1,
      )
      const firstLoad = this._selectedIndex == null
      if (this._selectedIndex !== selectedIndex) {
        this._selectedIndex = selectedIndex
        this._storageSelectedIndex = this._selectedIndex

        this.selectedIndexChange.next(this._selectedIndex)
        if (!firstLoad) {
          this.onSelect.next(this.items[this._selectedIndex])
        }
        if (this.focusOnSelect || this._visibleRatio <= 1 || firstLoad) {
          this._moveInner()
        }
      }
    }),
  )

  CarouselDirection = Direction

  @Input() loop = true
  @Input() step
  @Input() storageKey
  @Input() extraClasses = ''
  @Input() isCentered = false
  @Input() boundToEdge = false
  @Input() focusOnSelect = true
  @Input() enableForceSelect: boolean
  @Input() disableSelect: boolean
  @Input() set selected(idx: number) {
    this._selectedIndex = idx
    this._selectedIndexInner = idx
    this.previewIndex = idx
    this._updateCarouselPosition()
  }

  get autoMove() {
    return this._autoMove
  }
  @Input() set autoMove(_autoMove) {
    this._autoMove = _autoMove
    this._resetAutoMove()
  }

  get items() {
    return this._items
  }
  @Input() set items(_items) {
    if (this._items !== _items) {
      this._items = _items
      this._saveStorageConfig()
      if (this._items) {
        this.selectedIndex = this._selectedIndexInner
      }
      this._ref.detectChanges()
      this._updateCarouselPosition()
    }
  }

  get selectedIndex() {
    return this._selectedIndex
  }
  @Input() set selectedIndex(_selectedIndex) {
    this._selectedIndexInner = _selectedIndex
    this._selectIndexDebounce$.next(_selectedIndex)
  }

  @Output() selectedIndexChange = new EventEmitter<number>()
  @Output() onMove = new EventEmitter()
  @Output() onSelect = new EventEmitter()

  @ViewChild('carouselViewport') carouselViewport: ElementRef
  @ViewChildren('carouselItem') set carouselItems(_carouselItems) {
    this._carouselItems = Array.from(_carouselItems)
    if (this._carouselItems.length > 0) {
      this.el.nativeElement
        .querySelectorAll('img')
        .forEach((el) => el.addEventListener('dragstart', (event) => event.preventDefault()))

      if (!this.once) {
        this.once = true
        // Avoid image default drag event, for swipe to work when image is the starting point
        this._updateCarouselPosition()
      }
    }
  }
  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  constructor(
    private el: ElementRef,
    private _ref: ChangeDetectorRef,
    private scrollService: ScrollService,
    private _storageService: StorageService,
  ) {}

  private _getTotalItemsSize(until?) {
    return this._carouselItems
      .filter((v, index) => until == null || index <= until)
      .reduce((sum, item) => sum + item.nativeElement.getBoundingClientRect().width, 0)
  }

  private _getItemSize(index) {
    return (
      this._carouselItems &&
      this._carouselItems[index] &&
      this._carouselItems[index].nativeElement.getBoundingClientRect().width
    )
  }

  private _getKey(modifier = '') {
    return (
      this.storageKey &&
      `${LOCAL_STORAGE_PREFIX}${this.storageKey}${modifier ? `--${modifier}` : ''}`
    )
  }

  private _getItemsHash(items = this.items) {
    return (items || []).map(({ id }) => id || '').join('_')
  }

  private get _storageSelectedIndex(): number {
    const key = this._getKey()
    return key && this._storageService.get(StorageType.Local, key)
  }

  private set _storageSelectedIndex(value) {
    const key = this._getKey()
    if (key) {
      this._storageService.set(StorageType.Local, key, value)
    }
  }

  private _saveStorageConfig() {
    const key = this._getKey('config')
    const itemsHash = this._getItemsHash()
    if (key && itemsHash) {
      // If new data is different from stored one, init selected item to first item
      if (this._storageService.get(StorageType.Local, key) !== itemsHash) {
        this._storageSelectedIndex = 0
      }
      this._storageService.set(StorageType.Local, key, itemsHash)
    }
  }

  private _updateVisibleItems() {
    const itemWidth = this._getItemSize(0)
    this._wrapperWidth =
      itemWidth && this.carouselViewport.nativeElement.getBoundingClientRect().width

    if (this._wrapperWidth && itemWidth) {
      const itemsLength = (this.items || []).length
      this._visibleRatio = roundNumber(this._wrapperWidth / itemWidth, 3)
      this.navigationButtons = new Array(itemsLength || 0).fill(1)
      if (this.boundToEdge) {
        this.carouselEdgeX = (this._getTotalItemsSize() / this._wrapperWidth - 1) * -100
      }
      this.indexEdge[Direction.Prev] = 0
      this.indexEdge[Direction.Next] = itemsLength - 1
    }

    return this._wrapperWidth && itemWidth && this._visibleRatio
  }

  private _updateCarouselPositionInner() {
    this.enableCarousel = (this.items || []).length > this._visibleRatio
    if (this.enableCarousel) {
      this.previewIndex = inRange(
        this.previewIndex || 0,
        this.indexEdge[Direction.Next],
        this.indexEdge[Direction.Prev],
      )
      const carouselTranslateX = this.boundToEdge
        ? ((this._getTotalItemsSize(this.previewIndex - 1) -
            (this._wrapperWidth - this._getItemSize(this.previewIndex)) / 2) *
            100) /
          -this._wrapperWidth
        : (100 / this._visibleRatio) * (0.5 * (this._visibleRatio - 1) - this.previewIndex)
      this.carouselTranslateX = this.boundToEdge
        ? inRange(carouselTranslateX, 0, this.carouselEdgeX)
        : carouselTranslateX
    } else {
      this.carouselEdgeX = 0
      this.carouselTranslateX = 0
    }
    this._ref.detectChanges()
  }

  private _resetAutoMove() {
    this.autoMove$ = this.autoMove
      ? timer(this.autoMove).pipe(tap(() => this.move(Direction.Next)))
      : null
  }

  private _updateCarouselPosition() {
    if (this._updateVisibleItems()) {
      this._updateCarouselPositionInner()
    }
  }

  private _moveInner(option: Direction | number = this.selectedIndex) {
    this._resetAutoMove()
    const itemsLength = (this.items || []).length
    if (itemsLength && option != null) {
      const step = this.step || (this._visibleRatio > 1 ? roundNumber(this._visibleRatio / 2) : 1)
      let tempIndex = isNaN(Number(option))
        ? this.previewIndex + (option === Direction.Prev ? -1 : 1) * step
        : option
      tempIndex = !this.loop
        ? inRange(tempIndex, itemsLength - 1)
        : tempIndex < 0
          ? itemsLength - 1
          : tempIndex <= itemsLength - 1
            ? tempIndex
            : 0

      if (itemsLength) {
        const hasChanged = this.previewIndex !== tempIndex
        this.previewIndex = tempIndex
        if (
          (this._visibleRatio <= 1 || this.autoMove) &&
          this.previewIndex !== this.selectedIndex
        ) {
          this.selectedIndex = this.previewIndex
        }
        this._updateCarouselPositionInner()
        if (hasChanged) {
          this.onMove.next(this.previewIndex)
        }
      }
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template
          break
        case 'preview':
          this.previewTemplate = item.template
          break
        case 'bottom':
          this.bottomTemplate = item.template
          break
        case 'no-items':
          this.noItemsTemplate = item.template
          break
        case 'loading':
          this.loadingTemplate = item.template
          break
        default:
          this.itemTemplate = item.template
      }
    })
  }

  resetButton(direction) {
    this.activeButtonState[direction] = false
  }

  move(option: Direction | number = this.selectedIndex) {
    this._moveThrottled$.next(option)
  }

  onClickCarouselButton(direction: Direction) {
    if (!this.scrollService.hasTouch() || this.activeButtonState[direction]) {
      this.move(direction)
      if (this.scrollService.hasTouch()) {
        this.resetButton(direction)
      }
    } else {
      this.activeButtonState[direction] = true
    }
  }

  setActiveButton(direction: Direction, isActive = true) {
    if (!this.scrollService.hasTouch()) {
      this.activeButtonState[direction] = isActive
    }
  }

  selectItem(index: number) {
    DIRECTIONS.forEach((direction) => this.resetButton(direction))
    if (
      (this.enableForceSelect || (this.selectedIndex !== index && this._visibleRatio > 1)) &&
      !this.disableSelect
    ) {
      this.selectedIndex = index
    }
  }

  onSlide(event) {
    this.canSelect$ = event.isFinal ? timer(DebounceTimeType.Long).pipe(map(() => true)) : of(false)
  }

  trackByFn(index, item) {
    return item.id
  }
}
