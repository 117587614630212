import { NgModule } from '@angular/core'
import { DialogComponent } from './dialog.component'
import { CommonModule } from '@angular/common'
import { AppendToDirective } from './directives/append-to.directive'
import { DialogModule, IconModule } from '@mediacoach/ui'

@NgModule({
  declarations: [DialogComponent, AppendToDirective],
  imports: [DialogModule, CommonModule, IconModule],
  exports: [DialogComponent, AppendToDirective],
})
export class McpDialogModule {}
