import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { Loadable } from '@core/models/models/loadable.models'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { deepEqual, DialogService } from '@mediacoach/ui'
import { TranslateService } from '@ngx-translate/core'
import { Subject, switchMap } from 'rxjs'
import { filter, tap } from 'rxjs/operators'
import { DialogSeasonCompetitionSelectorComponent } from '@shared/components/dialog-comparison-selector/components/dialog-season-competition-selector/dialog-season-competition-selector.component'

@Component({
  selector: 'mcp-competition-changer',
  templateUrl: './competition-changer.component.html',
  styleUrls: ['./competition-changer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionChangerComponent {
  private readonly _dialog = inject(DialogService)
  private readonly _translate = inject(TranslateService)
  private openSeasonCompetitionDialogSubject$$: Subject<void> = new Subject<void>()
  openSeasonCompetitionDialogSubject$ = this.openSeasonCompetitionDialogSubject$$.pipe(
    switchMap(() =>
      this._translate.get(
        this.hideSeasons
          ? 'MTR_COMMON_COMPETITION_SELECT'
          : 'MTR_WIDGET_MENU_SELECT_SEASON_COMPETITION',
      ),
    ),
    switchMap(
      (header) =>
        this._dialog.open(DialogSeasonCompetitionSelectorComponent, {
          header,
          styleClass: 'mcp-widget-dialog',
          data: {
            seasonId: this.competition?.value?.seasonId,
            competitionId: this.competition?.value?.id,
            hideSeasons: this.hideSeasons,
          },
        }).onClose,
    ),
    filter((a) => !deepEqual(a, { closed: true })),
    tap((competition) => this.competitionChange.emit(competition)),
  )
  readonly competitionPlaceholder: string = PLACEHOLDER_IMAGES.COMPETITION
  readonly ga4Event = AnalyticsEvent.changeCompetition
  readonly ga4Category = AnalyticsCategory.settings

  @Input() competition: Loadable<Competition>
  @Input() competitions: Competition[]
  @Input() hideSeasons = false
  @Output() competitionChange = new EventEmitter<Competition>()

  openSeasonCompetitionDialog() {
    this.openSeasonCompetitionDialogSubject$$.next()
  }
}
