import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ModalComponent } from '@shared/components/modal/modal.component'

@Component({
  selector: 'mcp-match-item-limit-reached-modal',
  templateUrl: './match-item-limit-reached-modal.component.html',
  styleUrls: ['./match-item-limit-reached-modal.component.scss'],
})
export class MatchItemLimitReachedModalComponent {
  @ViewChild('modal') modal: ModalComponent

  @Input() limit: number
  @Output() close = new EventEmitter<void>()

  onConfirm(): void {
    if (this.modal) {
      this.modal.closeModal()
    }
    this.close.emit()
  }

  open(): void {
    if (this.modal) {
      this.modal.openModal()
    }
  }
}
