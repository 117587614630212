import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'
import {
  APP_DATA,
  FORM_FIELDS,
  LANGUAGES,
  VALIDATION_MESSAGES,
} from './constants/profile-dialog.constants'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { environment } from '@env'
import {
  buildProfileForm,
  fileToArrayBuffer,
} from '@core/main/components/profile-dialog/utils/profile-dialog.utils'
import { McpProfile } from '@auth/models/auth.dto'
import { ModalBase } from '@shared/components/modal/modal.base'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AppInfo } from '@core/models/dto/app.dto'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'

@UntilDestroy()
@Component({
  selector: 'mcp-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent extends ModalBase implements OnInit {
  readonly languages: { value: string; label: string }[] = LANGUAGES
  readonly teamPlaceholder: string = PLACEHOLDER_IMAGES.TEAM
  readonly appData: AppInfo = APP_DATA
  readonly privacyPolicyUrl = environment.PRIVACY_POLICY
  readonly formFields = FORM_FIELDS

  form: UntypedFormGroup = buildProfileForm()
  picErrors = []
  validationMessages = {}
  profile: McpProfile

  @Input() set user(profile: McpProfile) {
    this.profile = profile
    if (this.profile) {
      this._updateFormValue(this.profile)
    }
  }

  @Input() competitions: Competition[]

  @Output() conditions = new EventEmitter<void>()
  @Output() save = new EventEmitter()
  @Output() analytics = new EventEmitter()

  private _setupFormValidationMessages(): void {
    Object.keys(this.form.value).forEach((value) => {
      const control = this.form.get(value)
      control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this._setValidationMessage(control, value)
      })
    })
  }

  private _setValidationMessage(control: AbstractControl, name: string): void {
    if ((control.touched || control.dirty) && control.errors) {
      this.validationMessages = {
        ...this.validationMessages,
        [name]: Object.keys(control.errors).map((key) => VALIDATION_MESSAGES[name][key])[0],
      }
    } else {
      delete this.validationMessages[name]
    }
  }

  private _updateFormValue(profile: McpProfile): void {
    if (this.form) {
      this.form.reset(profile || {})
    }
  }

  async onFileSelected(files) {
    if (files.length > 0) {
      const buffer = await fileToArrayBuffer(files[0])
      this.form.get('photoPath').setValue(buffer)
      this.form.get('photoPath').markAsDirty()
    }
  }

  onCompetitionSelected(competition: Competition): void {
    this.form.get('favourites').get('competitionId').setValue(competition.id)
    this.form.markAsDirty()
    this.analytics.emit({
      eventName: AnalyticsEvent.changeFavCompetition,
      eventParams: {
        [AnalyticsParam.category]: AnalyticsCategory.settings,
        [AnalyticsParam.favCompetitionId]: competition.id,
        [AnalyticsParam.favCompetitionName]: competition.name,
      },
    })
  }

  ngOnInit(): void {
    this._setupFormValidationMessages()
  }
}
