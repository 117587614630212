import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DialogBase } from '@shared/components/dialog/dialog.base'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationModeSelectable } from '@core/models/models/metric.models'

@Component({
  selector: 'app-aggregation-selector-dialog',
  templateUrl: './aggregation-selector-dialog.component.html',
  styleUrls: [
    './aggregation-selector-dialog.component.theme.scss',
    './aggregation-selector-dialog.component.scss',
  ],
})
export class AggregationSelectorDialogComponent extends DialogBase {
  private _selection: MetricAggregation
  @Input() inMatchContext: boolean
  @Input() againstCompetition: boolean
  @Input() set selection(_selection: MetricAggregation) {
    this.aggregationSelected = _selection
    this._selection = _selection
  }
  get selection() {
    return this._selection
  }
  @Input() extraClasses: string = ''
  @Input() aggregationModes: MetricAggregationModeSelectable[]

  @Output() selectionChange = new EventEmitter<MetricAggregation>()

  aggregationSelected: MetricAggregation

  openDialog(scrollableRef: string = '.match-details__metrics') {
    document.querySelector(scrollableRef).scrollIntoView()

    setTimeout(() => {
      super.open()
    }, 100)
  }
}
