import { createAction, props } from '@ngrx/store'
import { User } from 'oidc-client'
import { environment } from '@env'

export const setOidcUser = createAction('[OIDC] Set user', props<{ oidc: User }>())
export const removeUser = createAction('[OIDC] Remove user', (uri?: string) => ({ uri }))
export const setRedirectUri = createAction(
  '[OIDC] Set redirect uri',
  props<{ redirectUri: string }>(),
)
export const signInRedirect = createAction('[OIDC] SignIn redirect', (requestedRoute?: string) => ({
  state: requestedRoute,
}))
export const signInRedirectCallback = createAction('[OIDC] SignIn redirect callback')
export const signInSilent = createAction('[OIDC] SignIn silent')
export const signInSilentCallback = createAction(
  '[OIDC] SignIn silent callback',
  (uri?: string) => ({ uri }),
)
export const logOut = createAction(
  '[OIDC] Logout',
  (uri: string = environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI) => ({ uri }),
)

export const authFailed = createAction('[OIDC] Auth failed')
