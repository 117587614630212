import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { User } from 'oidc-client'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { UserState } from '@core/state/models/user.state'
import { parseISOLangToSimplified } from '@core/utils/i18n.utils'
import { pipe } from 'rxjs'
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import {
  findDefaultSeasonAndCompetition,
  getSeasonAndCompetition,
  getSeasonIdByCompetition,
} from '@core/utils/season.utils'
import { ApplicationProfile, Favourite, McpProfile } from '@auth/models/auth.dto'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { AppPermissionType, PermissionType } from '@core/enums/permissions.enum'
import { WidgetPermission } from '@widgets/enums/widget-permission.enum'
import { StorageParams } from '@core/models/models/storage.models'

export const selectUserFeature = createFeatureSelector<UserState>(StoreToken.user)

export const selectUser = createSelector(selectUserFeature, (state: UserState): User => state.oidc)
export const selectToken = createSelector(
  selectUser,
  (user: User): string => user && user.access_token,
)
export const getFormattedToken = createSelector(
  selectUser,
  (user: User): string => user && `${user.token_type} ${user.access_token}`,
)
export const selectTokenId = createSelector(
  selectUser,
  (user: User): string => user && user.id_token,
)

export const selectProfile = createSelector(
  selectUserFeature,
  (state: UserState): McpProfile => state.profile,
)

export const selectUserId = createSelector(selectProfile, (profile) => profile?.userId)

export const selectWidgetPermissions = createSelector(
  selectProfile,
  (profile: McpProfile): WidgetPermission[] => {
    if (profile) {
      return profile[PermissionType.ApplicationPermissions][
        AppPermissionType.AppContent
      ] as WidgetPermission[]
    }
  },
)

export const selectApplications = createSelector(
  selectUserFeature,
  (state: UserState): ApplicationProfile[] => state.applications,
)
export const selectFavourites = createSelector(
  selectProfile,
  (profile: McpProfile): Favourite => profile && profile.favourites,
)

export const selectISOLang = createSelector(
  selectProfile,
  (profile: McpProfile): string => profile && profile.language,
)
export const selectSimplifiedLang = createSelector(
  selectProfile,
  (profile: McpProfile): string => profile && parseISOLangToSimplified(profile.language),
)

export const selectDistinctSimplifiedLang = () =>
  pipe(
    select(selectSimplifiedLang),
    distinctUntilChanged(),
    filter((lang) => !!lang),
  )

export const selectIfRouteParamsAreValid = createSelector(
  getSeasons,
  getMergedRouteParams,
  (seasons, params) => {
    if (!params['seasonId'] && !params['competitionId']) {
      return false
    } else if (seasons?.length) {
      const season = seasons.find((s) => s.id === params['seasonId'])
      const competition = ((season || {}).competitions || []).find(
        (s) => s.id === params['competitionId'],
      )
      return !!season && !!competition
    }
  },
)

export const selectFavCompetitionIdsAllowed = (storedQuery?: StorageParams) =>
  pipe(
    select(
      createSelector(selectFavourites, getSeasons, (favourites, seasons) => ({
        favourites,
        seasons,
      })),
    ),
    filter(({ favourites, seasons }) => !!favourites && !!seasons?.length),
    map(({ favourites, seasons }) => {
      const storedSeason = seasons.find((s) => s.id === storedQuery?.seasonId)
      const storedCompetition = ((storedSeason || {})?.competitions || []).find(
        (c) => c.id === storedQuery?.competitionId,
      )
      const favSeasonAndCompetition = getSeasonIdByCompetition(favourites?.competitionId, seasons)
      const defaultSeasonAndCompetition = findDefaultSeasonAndCompetition(seasons)

      const resolvedFavCompetition = favSeasonAndCompetition
        ? {
            ...favSeasonAndCompetition,
            teamId: favourites?.teamId,
          }
        : { ...defaultSeasonAndCompetition }

      return (storedCompetition ? { ...storedQuery } : resolvedFavCompetition) as StorageParams
    }),
    takeWhile((fav) => !fav || (!!fav && (!fav?.competitionId || !fav?.seasonId)), true),
    filter((fav) => !!fav && !!fav?.competitionId && !!fav?.seasonId),
  )

export const selectFavCompetitionIds = () =>
  pipe(
    select(
      createSelector(selectFavourites, getSeasons, (favourites, seasons) => ({
        favourites,
        seasons,
      })),
    ),
    filter(({ favourites, seasons }) => !!favourites && !!seasons?.length),
    map(({ favourites, seasons }) => ({
      ...getSeasonIdByCompetition(favourites?.competitionId, seasons),
      teamId: favourites?.teamId,
    })),
    takeWhile((fav) => !fav || (!!fav && (!fav?.competitionId || !fav?.seasonId)), true),
    filter((fav) => !!fav && !!fav?.competitionId && !!fav?.seasonId),
  )

export const selectFavCompetition = () =>
  pipe(
    select(
      createSelector(selectFavourites, getSeasons, (favourites, seasons) => ({
        favourites,
        seasons,
      })),
    ),
    filter(({ favourites, seasons }) => !!favourites && !!seasons && !!seasons?.length),
    map(({ favourites, seasons }) => getSeasonAndCompetition(favourites?.competitionId, seasons)),
    takeWhile((fav) => !fav || (!!fav && (!fav?.competition || !fav?.season)), true),
    filter((fav) => !!fav && !!fav?.competition && !!fav?.season),
  )

export const selectUserHeaderData = createSelector(
  selectToken,
  selectISOLang,
  (token, language) => ({
    token,
    language,
  }),
)
