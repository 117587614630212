import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { DEFAULT_IMAGE_BY_DIMENSION_TYPE, MenuItem, MenuItemEvent } from '@mediacoach/ui'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { TagItem } from '@shared/components/tag-item/models/tag-item.models'
import { PLAYLIST_ITEM_INFO_MENU_OPTIONS } from '@shared/components/playlist-item-info/constants/playlist-item-info.constants'

@Component({
  selector: 'mcp-playlist-item-info',
  templateUrl: './playlist-item-info.component.html',
  styleUrls: ['./playlist-item-info.component.theme.scss', './playlist-item-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistItemInfoComponent {
  private _item: TagItem
  @Input({ required: true }) lang: string
  @Input() isModal: boolean

  @Input() set item(_item: TagItem) {
    this._item = _item
    if (_item) {
      this._updateMenuItems()
    }
  }

  get item(): TagItem {
    return this._item
  }

  @Output() menuClick = new EventEmitter<MenuItemEvent>()

  readonly placeholder = PLACEHOLDER_IMAGES
  playlistItemInfoMenuItems: MenuItem[] = []

  get placeholderImage() {
    return DEFAULT_IMAGE_BY_DIMENSION_TYPE[this.item?.dimensionType]
  }

  private _updateMenuItems() {
    this.playlistItemInfoMenuItems = PLAYLIST_ITEM_INFO_MENU_OPTIONS(this._item.isHighlighted)
  }
}
