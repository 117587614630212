import { ChangeDetectionStrategy, Component } from '@angular/core'
import { QueryMultiselect } from '@shared/components/query/models/query-multiselect.model'
import { QueryableField } from '@shared/components/query/models/query-base.model'

@Component({
  selector: 'mcp-query-multiselect',
  templateUrl: './query-multiselect.component.html',
  styleUrls: ['./query-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryMultiselectComponent extends QueryableField<QueryMultiselect> {}
