import { Component, EventEmitter, Input, NgZone, Output, TemplateRef } from '@angular/core'
import { deepCloneArray } from '@core/utils/collection.utils'
import { debounce, SafeObjectData } from '@mediacoach-ui-library/global'
import { Column } from '@shared/components/grid/models/grid.models'
import { defaultTrackBy, getNewDirection } from '@shared/components/grid/utils/grid.utils'
import { existsAsNumber } from '@core/utils/number.utils'
import { Subject } from 'rxjs'

@Component({
  selector: 'mcp-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.theme.scss', './grid.component.scss'],
})
export class GridComponent<T = unknown> {
  private _defaultSortableColumnIndex: number
  private _rows: T[]
  private _rowsBackup: T[]
  private _columns: Column[]
  private _columnsBackup: Column[]
  private _ready$$ = new Subject<boolean>()

  get rows(): T[] {
    return this._rows
  }

  @Input() set rows(_rows: T[]) {
    this._rows = _rows
    if (_rows) {
      this._rowsBackup = deepCloneArray<T>(_rows)
      this.resetColumnsSortDirection()
      this.lastColumnIndex = this._defaultSortableColumnIndex
    }
  }

  get columns(): Column[] {
    return this._columns
  }

  @Input() set columns(_columns: Column[]) {
    this._columns = _columns
    if (_columns) {
      this._columnsBackup = []
      _columns.forEach((c, i) => {
        this._columnsBackup.push({ ...c })
        if (c.sortDirection) {
          this._defaultSortableColumnIndex = i
          this.lastColumnIndex = i
        }
      })
    }
  }

  @Input() headerHeight: string
  @Input() loading: boolean
  @Input() stickyHeader = true
  @Input() cellTpl: TemplateRef<unknown>
  @Input() headerTpl: TemplateRef<unknown>
  @Input() footerTpl: TemplateRef<unknown>
  @Input() rowStyles: Partial<CSSStyleDeclaration>
  @Input() sortable: boolean
  @Input() trackByFn: (...args) => unknown = defaultTrackBy

  @Output() cellClick = new EventEmitter()
  @Output() ready = new EventEmitter()

  lastColumnIndex: number
  ready$ = this._ready$$.asObservable()

  constructor(private readonly _zn: NgZone) {}

  sort(index: number): void {
    if (this.columns[index].sortable !== false) {
      this._zn.runOutsideAngular(() => {
        if (existsAsNumber(this.lastColumnIndex) && this.lastColumnIndex !== index) {
          this.columns[this.lastColumnIndex].sortDirection = 'def'
        }
        this.columns[index].sortDirection = getNewDirection(this.columns[index])
        this.lastColumnIndex = index

        if (this.columns[index].sortFn) {
          this._rows =
            this.columns[index].sortDirection && this.columns[index].sortDirection !== 'def'
              ? [...this._rowsBackup].sort((a, b) =>
                  this.columns[index].sortFn(
                    a,
                    b,
                    this.columns[index].sortDirection,
                    this.columns[index].sortableKey,
                    this.columns[index].key,
                    this.columns[index].label,
                  ),
                )
              : [...this._rowsBackup]
        } else if (this.sortable && this.columns[index].sortable !== false) {
          this._rows =
            this.columns[index].sortDirection && this.columns[index].sortDirection !== 'def'
              ? [...this._rowsBackup].sort((a, b) => {
                  const _a = this._getComparableValue(a, index)
                  const _b = this._getComparableValue(b, index)

                  return this.columns[index].sortDirection === 'desc' ? _b - _a : _a - _b
                })
              : [...this._rowsBackup]
        }
      })
    }
  }

  resetColumnsSortDirection(): void {
    this._zn.runOutsideAngular(() => {
      this._columns = deepCloneArray<Column>(this._columnsBackup)
    })
  }

  @debounce(0)
  setReadyState(): void {
    this.ready.emit()
  }

  private _getComparableValue(item: T, columnIndex: number): number {
    return (
      +SafeObjectData(
        item,
        this.columns[columnIndex].sortableKey ||
          this.columns[columnIndex].key ||
          this.columns[columnIndex].label,
        0,
      ) || 0
    )
  }
}
