import { ApplicationName, DeviceOs } from '@mediacoach-ui-library/global'

import { environment } from '@env'

import { DialogType } from './dialog-application.models'
import { DialogApplicationComponent } from '@shared/components/dialog-application/dialog-application.component'

export const IMAGE_COMMON_BASE_PATH = environment.STATICS_URL_ASSETS + 'img/commons/'
export const IMAGE_BASE_PATH = environment.STATICS_URL_ASSETS + 'img/applications/'
const IMAGE_BASE_CLASS = 'c-dialog-application__img-'

export const GetBackgroundImage = (type: DialogType, app: ApplicationName | string) =>
  `${IMAGE_BASE_PATH}header__${type}--${app}.jpg`
export const GetHeader = (type: DialogType, app: ApplicationName | string) => [
  {
    image: `${IMAGE_BASE_PATH}logo--${app}.svg`,
    class: `${IMAGE_BASE_CLASS}logo ${IMAGE_BASE_CLASS}logo--${app}`,
  },
  {
    image: `${IMAGE_BASE_PATH}platform--${app}.png`,
    class: `${IMAGE_BASE_CLASS}platform ${IMAGE_BASE_CLASS}platform--${app}`,
  },
]

const PORTABLE_DEVICE_INSTALLER_CONFIG = [
  {
    os: DeviceOs.Ios,
    type: 'primary',
    extension: 'IOS',
  },
  {
    os: DeviceOs.Android,
    type: 'primary',
    extension: 'APK',
  },
]

export const INSTALLER_CONFIG_BY_APPLICATION = {
  [ApplicationName.Desktop]: [
    {
      os: DeviceOs.Windows,
      type: 'primary',
      extension: 'EXE',
    },
    {
      os: DeviceOs.Macos,
      type: 'primary',
      extension: 'DMG',
    },
  ],
  [ApplicationName.Mobile]: PORTABLE_DEVICE_INSTALLER_CONFIG,
  [ApplicationName.LivePro]: PORTABLE_DEVICE_INSTALLER_CONFIG,
}

export const SHOW_OS_ICON_APPLICATIONS = [
  ApplicationName.Mobile,
  ApplicationName.Desktop,
  ApplicationName.LivePro,
]

export const NOT_INSTALLATION_ALLOWED = {
  [ApplicationName.Mobile]: (dialogInstance: DialogApplicationComponent) =>
    dialogInstance.isTablet || dialogInstance.isDesktop,
  [ApplicationName.Desktop]: (dialogInstance: DialogApplicationComponent) =>
    dialogInstance.isTablet || dialogInstance.isMobile,
  [ApplicationName.LivePro]: (dialogInstance: DialogApplicationComponent) =>
    dialogInstance.isMobile || dialogInstance.isDesktop,
}
