import { MenuItem } from '@mediacoach/ui'
import { ManagePlaylistAction } from '@features/playlist/dialogs/dialog-manage-playlist/enums/manage-playlist.enums'

export const MANAGE_PLAYLIST_MENU: MenuItem[] = [
  {
    id: ManagePlaylistAction.leavePlaylist,
    label: 'MTR_PLAYLIST_DIALOG_PLAYLIST_LEAVE',
  },
  {
    id: ManagePlaylistAction.editPlaylist,
    label: 'MTR_PLAYLIST_DIALOG_PLAYLIST_EDIT',
  },
  {
    id: ManagePlaylistAction.deletePlaylist,
    label: 'MTR_PLAYLIST_DIALOG_PLAYLIST_DELETE',
  },
]
