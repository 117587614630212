import { AfterContentInit, Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { signInRedirectCallback } from '@core/state/actions/oidc.actions'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'

@Component({
  selector: 'app-callback',
  template: ' <app-loading extraClasses="c-loading--fullscreen"></app-loading>',
})
export class CallbackComponent implements OnInit, AfterContentInit {
  constructor(private readonly _store: Store) {}

  ngOnInit() {
    this._store.dispatch(
      analyticsTrackEvent({
        // eventName: AnalyticsEvent.login,
        // eventParams: {
        //   [AnalyticsParam.category]: AnalyticsCategory.auth,
        // },
        eventName: AnalyticsEvent.login,
        eventParams: {
          [AnalyticsParam.category]: AnalyticsCategory.auth,
        },
      }),
    )
  }

  ngAfterContentInit(): void {
    this._store.dispatch(signInRedirectCallback())
  }
}
