import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'mcp-tour-arrow',
  templateUrl: './tour-arrow.component.html',
  styleUrls: ['./tour-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourArrowComponent {
  @Input() position = 'top'
}
