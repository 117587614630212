<div [ngClass]="'root-file-upload'" [ngStyle]="style" [class]="styleClass">
  <div class="root-file-upload__container">
    <span
      class="root-file-upload__choose"
      [ngClass]="{
        'button-primary--focus': focus,
        'button-primary--disabled': disabled || isChooseDisabled()
      }"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (click)="choose()"
      (keydown.enter)="choose()"
      tabindex="0"
    >
      <input
        #fileInput
        type="file"
        (change)="onFileSelect($event)"
        [multiple]="multiple"
        [accept]="accept"
        [disabled]="disabled || isChooseDisabled()"
        [attr.title]="''"
      />
      <span class="root-file-upload__label">{{ chooseButtonLabel }}</span>
    </span>
  </div>
  <div
    #content
    class="root-file-upload-content"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
  >
    <ng-content></ng-content>
  </div>
</div>
