import { BackOffPolicy } from '@shared/decorators/retryable/enums/retryable.enums'

export class MaxAttemptsError extends Error {
  code = '429'
  /* if target is ES5, need the 'new.target.prototype'
  constructor(msg?: string) {
      super(msg)
      Object.setPrototypeOf(this, new.target.prototype)
    } */
}

export interface RetryOptions {
  maxAttempts: number
  backOffPolicy?: BackOffPolicy
  backOff?: number
  doRetry?: (e: any) => boolean
  value?: ErrorConstructor[]
  exponentialOption?: { maxInterval: number; multiplier: number }
}
