export const REFRESH_TIME = {
  PER_MIN: 60,
  FIVE_MIN: 5 * 60,
  THIRTY_MIN: 30 * 60,
  HALF_DAY: 12 * 60 * 60,
  PER_DAY: 24 * 60 * 60,
}

export const EXPIRATION_FAIL_SAFE = {
  TEN_SEC: 60 * 10,
}
