import { createAction, props } from '@ngrx/store'
import { AssetDownload } from '@core/models/dto/assets.dto'
import { AssetFile } from '@core/enums/assets.enum'

export const fetchVersions = createAction('[Assets] Fetch versions')
export const setVersions = createAction(
  '[Assets] Set versions',
  props<{ versions: Record<AssetFile, string> }>(),
)

export const fetchAssets = createAction('[Assets] Fetch assets')
export const setAssets = createAction('[Assets] Set assets', props<{ assets: AssetDownload }>())
