<div class="c-textarea__container" [ngClass]="{ 'is-disabled': disabled }">
  <app-label
    [label]="label"
    [tooltip]="tooltip"
    [tooltipStyleClass]="tooltipStyleClass"
  ></app-label>
  <textarea
    class="c-textarea__textarea"
    [id]="id"
    [placeholder]="placeholder || '' | translate"
    [maxlength]="maxLength"
    [(ngModel)]="value"
    [disabled]="disabled"
    (input)="onChange($event?.target?.value)"
    (blur)="onTouched()"
    (keyup)="onKeyUp.emit()"
    (focusin)="onFocusIn.emit()"
    (focusout)="onFocusOut.emit()"
  >
  </textarea>
</div>
