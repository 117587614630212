@if ({ playlists: playlists$ | async }; as data) {
  <div class="mcp-dialog-playlist-share">
    <div class="mcp-dialog-playlist-share__content">
      <mcp-playlists-share [playlists]="data?.playlists" #list />
    </div>
    <div class="mcp-dialog-playlist-share__footer">
      <button
        class="mcp-dialog-playlist-share__footer-button button-primary"
        [disabled]="!list.selected.length"
        (click)="close(list.selected)"
      >
        {{ 'P_COMMONS_CONTRACT_BTN_ACCEPT' | translate }}
      </button>
    </div>
  </div>
}
