import { NgModule } from '@angular/core'
import { SharedModule } from '@shared/shared.module'
import { NavigationBarContainerComponent } from './navigation-bar-container.component'
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component'
import { NavigableDropdownComponent } from './components/navigable-dropdown/navigable-dropdown.component'
import { NavService } from '@core/main/containers/navigation-bar/services/side-nav.service'
import { NavigableDropdownItemComponent } from './components/navigable-dropdown-item/navigable-dropdown-item.component'

@NgModule({
  declarations: [
    NavigationBarContainerComponent,
    SideNavigationComponent,
    NavigableDropdownComponent,
    NavigableDropdownItemComponent,
  ],
  imports: [SharedModule],
  exports: [NavigationBarContainerComponent, NavigableDropdownComponent],
  providers: [NavService],
})
export class NavigationBarModule {}
