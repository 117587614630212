import { inject, Injectable } from '@angular/core'
import { createEffect, ofType } from '@ngrx/effects'
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import { catchRequestError, completeWhen } from '@mediacoach/ui'
import { getMergedRouteParams } from '@core/router/state/selectors/router.selectors'
import { saveUserTeam } from '@core/state/actions/profile.actions'
import { SpaceApi } from '../../api/space.api'
import {
  changeSpaceSelectorCompetition,
  changeSpaceSelectorPlayer,
  changeSpaceSelectorTeam,
  fetchSpaceSelectorPlayers,
  fetchSpaceSelectorTeams,
  findSpaceSelectorCompetition,
  findSpaceSelectorPlayer,
  findSpaceSelectorTeam,
  setSpaceSelectorCompetition,
  setSpaceSelectorLoading,
  setSpaceSelectorPlayer,
  setSpaceSelectorPlayers,
  setSpaceSelectorTeam,
  setSpaceSelectorTeams,
} from '@core/space/state/actions/space-selector.actions'
import { setSpaceHeaderLoading, spaceNavigate } from '@core/space/state/actions/space.actions'
import { parseSpaceTeam } from '@core/space/utils/space.utils'
import { mapPlayers } from '@core/utils/player.utils'
import { selectSpaceSelectorTeam } from '@core/space/state/selectors/space-selector.selectors'
import { EMPTY, Observable, of, OperatorFunction, throwError } from 'rxjs'
import { ApiTeamTeams } from '@core/models/models/team.models'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { getSeasonAndCompetitionById } from '@core/utils/season.utils'
import { getStaticItem, smallColorLandscapeLogoPredicate } from '@core/utils/assets.utils'
import { concatLatestFrom } from '@ngrx/operators'
import { SpaceEffectBase } from '@core/space/base-clases/space-effect.base'

@Injectable()
export class SpaceSelectorEffects extends SpaceEffectBase {
  private readonly _api = inject(SpaceApi)

  fetchSpaceTeams$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fetchSpaceSelectorTeams),
      tap(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: true }))),
      switchMap(({ payload: { seasonId, competitionId } }) =>
        this._api.fetchTeams(seasonId, competitionId).pipe(
          switchMap(({ teams }) => [
            setSpaceSelectorTeams({
              selectorTeams: teams.map((team) => parseSpaceTeam(team, seasonId, competitionId)),
            }),
            findSpaceSelectorTeam({ teams }),
          ]),
          catchError((err) => {
            console.error(err)
            this._store.dispatch(setSpaceSelectorTeams({ selectorTeams: [] }))
            this._store.dispatch(setSpaceSelectorPlayers({ selectorPlayers: [] }))
            return throwError(() => err)
          }),
          finalize(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: false }))),
        ),
      ),
    )
  })

  findSpaceSelectorTeam$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(findSpaceSelectorTeam),
      concatLatestFrom(() => this._store.select(getMergedRouteParams)),
      filter(([{ teams }, params]) => !!teams?.length && params['teamId']),
      map(([{ teams }, params]) => teams.find((t) => t.id === params['teamId'])),
      map((selectorTeam) => setSpaceSelectorTeam({ selectorTeam: parseSpaceTeam(selectorTeam) })),
    )
  })

  fetchSpacePlayers$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(fetchSpaceSelectorPlayers),
      tap(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: true }))),
      switchMap(({ payload: { seasonId, competitionId, teamId } }) =>
        this._api.fetchPlayers(seasonId, competitionId, teamId).pipe(
          map(({ squad }) => mapPlayers(squad, seasonId, competitionId, teamId)),
          switchMap((players) => [
            setSpaceSelectorPlayers({
              selectorPlayers: players,
            }),
            findSpaceSelectorPlayer({ players }),
          ]),
          catchRequestError(),
          finalize(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: false }))),
        ),
      ),
    )
  })

  findSpaceSelectorPlayer$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(findSpaceSelectorPlayer),
      concatLatestFrom(() => this._store.select(getMergedRouteParams)),
      filter(([{ players }, params]) => !!players?.length && params['playerId']),
      map(([{ players }, params]) => players.find((p) => p.id === params['playerId'])),
      map((selectorPlayer) => setSpaceSelectorPlayer({ selectorPlayer })),
    )
  })

  changeSpaceCompetition$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeSpaceSelectorCompetition),
      concatLatestFrom(() => [
        this._store.select(selectSpaceSelectorTeam),
        this._store.select(getMergedRouteParams),
      ]),
      switchMap(([{ competition }, selectorTeam, params]) =>
        params['teamId']
          ? this._api.fetchTeams(competition.seasonId, competition.id).pipe(
              map(({ teams }) => ({
                teamId: (teams.find((t) => t.id === selectorTeam?.id) || teams[0])?.id,
                teams,
                competitionId: competition.id,
                seasonId: competition.seasonId,
                playerId: params['playerId'],
              })),
              catchError((err) => {
                console.error(err)
                this._store.dispatch(setSpaceHeaderLoading({ headerLoading: false }))
                this._store.dispatch(setSpaceSelectorTeams({ selectorTeams: [] }))
                this._store.dispatch(
                  spaceNavigate({
                    seasonId: competition.seasonId,
                    competitionId: competition.id,
                    teamId: 'undefined',
                    playerId: params['playerId'],
                  }),
                )
                return EMPTY
              }),
            )
          : of({ competitionId: competition.id, seasonId: competition.seasonId, teams: [] }),
      ),
      this._checkPlayer(),
      switchMap(({ teams, teamId, seasonId, competitionId, playerId }) => [
        setSpaceSelectorTeams({
          selectorTeams: teams.map((team: ApiTeamTeams) =>
            parseSpaceTeam(team, seasonId, competitionId),
          ),
        }),
        spaceNavigate({
          seasonId,
          competitionId,
          teamId,
          playerId,
        }),
      ]),
    )
  })

  changeSpaceTeam$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeSpaceSelectorTeam),
      tap(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: true }))),
      concatLatestFrom(() => this._store.select(getMergedRouteParams)),
      map(([{ team }, params]) => ({
        teamId: team.id,
        competitionId: params['competitionId'] as string,
        seasonId: params['seasonId'] as string,
        playerId: params['playerId'] as string,
      })),
      this._checkPlayer(),
      switchMap(({ teamId, competitionId, seasonId, playerId }) => [
        spaceNavigate({
          seasonId,
          competitionId,
          teamId,
          playerId,
        }),
        saveUserTeam({ teamId }),
      ]),
    )
  })

  changeSpacePlayer$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(changeSpaceSelectorPlayer),
      tap(() => this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: true }))),
      concatLatestFrom(() => this._store.select(getMergedRouteParams)),
      map(([{ player }, params]) => ({
        id: player.id,
        teamId: params['teamId'],
        competitionId: params['competitionId'],
        seasonId: params['seasonId'],
      })),
      switchMap(({ id, teamId, competitionId, seasonId }) => [
        spaceNavigate({
          seasonId,
          competitionId,
          teamId,
          playerId: id,
        }),
      ]),
    )
  })

  findSpaceSelectorCompetition$ = createEffect(() =>
    this._actions$.pipe(
      ofType(findSpaceSelectorCompetition),
      switchMap(({ payload: { competitionId, seasonId } }) =>
        this._store.select(getSeasons).pipe(
          completeWhen((seasons) => !!seasons?.length),
          map((seasons) => ({ competitionId, seasonId, seasons })),
        ),
      ),
      map(({ seasonId, competitionId, seasons }) => {
        const { competition, season } = getSeasonAndCompetitionById(
          seasonId,
          competitionId,
          seasons,
          true,
        )
        return { ...competition, seasonPill: season.name.replace(/ - \d{2}/, '/') }
      }),
      filter((competition) => !!competition),
      map((competition) => {
        return {
          ...competition,
          landscapeLogo: getStaticItem(competition.statics, smallColorLandscapeLogoPredicate),
        }
      }),
      map((selectorCompetition) => setSpaceSelectorCompetition({ selectorCompetition })),
    ),
  )

  private _checkPlayer<T>(): OperatorFunction<T, any> {
    return (source: Observable<any>) =>
      source.pipe(
        switchMap(({ teamId, competitionId, seasonId, playerId, teams }) =>
          playerId
            ? this._api.fetchPlayers(seasonId, competitionId, teamId).pipe(
                map(({ squad }) => squad?.players),
                map((players) => ({
                  seasonId,
                  competitionId,
                  teamId,
                  playerId: players?.find((p) => p.id === playerId)?.id || (players || [])[0]?.id,
                  teams,
                })),
                catchError((err) => {
                  console.error(err)
                  this._store.dispatch(setSpaceHeaderLoading({ headerLoading: false }))
                  this._store.dispatch(setSpaceSelectorLoading({ selectorLoading: false }))
                  return throwError(() => err)
                }),
              )
            : of({ teamId, competitionId, seasonId, playerId, teams }),
        ),
      )
  }
}
