import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MorphButtonComponent } from '@shared/components/morph-button/morph-button.component'

@NgModule({
  declarations: [MorphButtonComponent],
  exports: [MorphButtonComponent],
  imports: [CommonModule],
})
export class MorphButtonModule {}
