import { GET_PLAYERS_FORMATTED, GetTeamProp, TEAMS } from '@mediacoach-ui-library/global'

export function parseMatch(match) {
  return {
    ...match,
    playerList: TEAMS.reduce((obj, teamKey) => {
      const team = match[GetTeamProp(teamKey)]
      const lineup = team.lineup.players.length > 0 ? team.lineup : team.squad
      return {
        ...obj,
        [teamKey]: GET_PLAYERS_FORMATTED(lineup.players, () => true),
      }
    }, {}),
  }
}
