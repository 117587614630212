import { AfterContentInit, Component, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { debounceTime, tap } from 'rxjs/operators'

import { ErrorType, TranslatorService } from '@mediacoach-ui-library/global'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-error-feedback-page',
  templateUrl: './error-feedback-page.component.html',
  styleUrls: ['./error-feedback-page.component.scss'],
})
export class ErrorFeedbackPageComponent implements AfterContentInit {
  errorType: ErrorType = ErrorType.Default

  routeDataChanges$ = this._route.data.pipe(
    tap((data) => {
      this.errorType = (data || {})['errorType']
    }),
    debounceTime(500),
    tap(() => {
      const lang =
        this.errorType !== ErrorType.Forbidden
          ? this._translateService.getDefaultLang()
          : this._translateService.currentLang
      this._translateService.use(lang)
      this._translatorService.use(lang)
    }),
  )

  lang: string

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly ngZone: NgZone,
    private readonly _translateService: TranslateService,
    private readonly _translatorService: TranslatorService,
  ) {
    this.lang = _translateService.defaultLang
  }

  ngAfterContentInit() {}

  onErrorFeedBackButtonClick() {
    this._router.navigate([''])
  }
}
