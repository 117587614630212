import { Component, Input } from '@angular/core'

import { TitleSize } from './title.constants'

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent {
  @Input() title: string
  @Input() titleParams: { [key: string]: string | number }
  @Input() size: TitleSize = TitleSize.L
  @Input() extraClasses = ''

  constructor() {}
}
