import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import {
  EMAIL_REGEX,
  MAX_LENGTH_FORM,
  TEXT_REGEX,
} from '@core/main/components/profile-dialog/constants/profile-dialog.constants'

const _arrayBufferToBase64 = (buffer): string => {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return window.btoa(binary)
}

export const fileToArrayBuffer = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => resolve(_arrayBufferToBase64(reader.result))
    reader.onerror = (err) => reject(err)

    reader.readAsArrayBuffer(file)
  })

export const buildProfileForm = (): UntypedFormGroup =>
  new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(TEXT_REGEX),
      Validators.max(MAX_LENGTH_FORM),
    ]),
    surname: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(TEXT_REGEX),
      Validators.max(MAX_LENGTH_FORM),
    ]),
    email: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.pattern(EMAIL_REGEX),
      Validators.max(MAX_LENGTH_FORM),
    ]),
    language: new UntypedFormControl('', [Validators.required]),
    photoPath: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
    profiles: new UntypedFormControl({ value: [], disabled: true }),
    favourites: new UntypedFormGroup({
      competitionId: new UntypedFormControl('', [Validators.required]),
      teamId: new UntypedFormControl(''),
    }),
  })
