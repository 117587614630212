import { inject, Injectable } from '@angular/core'
import { saveUserTourStep } from '@core/state/actions/profile.actions'
import { StepActionType } from '@shared/modules/tour/enums/tour.enum'
import { TourStep, TourStepInfo, TourStepTexts } from '@shared/modules/tour/models/tour-step.models'
import { sortBy } from '@mediacoach-ui-library/global'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { PermissionsService } from '@core/services/permissions.service'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { Observable, of } from 'rxjs'
import { existsAsNumber } from '@core/utils/number.utils'
import { getProfile } from '@core/state/selectors/user.selectors'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { TourService } from '@shared/modules/tour/services/tour/tour.service'
import { TOUR_BUTTONS_TRANSLATIONS, TOUR_STEPS } from '../constants/tour.constants'

@Injectable({ providedIn: 'root' })
export class TourHelper {
  private readonly _router = inject(Router)
  private readonly _store = inject(Store)
  private readonly _translate = inject(TranslateService)
  private readonly _permissions = inject(PermissionsService)
  private readonly _tour = inject(TourService)

  startTour(userStoredStep?: number): void {
    const currentPath = this._router.url
    const { order, parsedSteps } = this._parseSteps()

    if (parsedSteps?.length) {
      this._startTour(
        parsedSteps,
        { steps: order, customTexts: this._translateTourButtons() },
        userStoredStep,
      ).subscribe(({ number, actionType }) => {
        this._store.dispatch(
          saveUserTourStep({
            onBoardingStep: actionType === StepActionType.done ? 1 : number,
          }),
        )
        this._router.navigateByUrl(currentPath)
      })
    }
  }

  stopTour(): void {
    this._tour.closeTour()
  }

  private _translateTourButtons(): TourStepTexts {
    return Object.keys(TOUR_BUTTONS_TRANSLATIONS).reduce(
      (stepTexts, key) => ({
        ...stepTexts,
        [key]: this._translate.get(TOUR_BUTTONS_TRANSLATIONS[key]),
      }),
      {} as TourStepTexts,
    )
  }

  private _parseSteps(): { order: string[]; parsedSteps: TourStep[] } {
    const order = []
    const parsedSteps = sortBy(TOUR_STEPS).reduce((steps, step) => {
      if (this._hasPermissions(step)) {
        order.push(step.name)
        steps.push({
          ...step,
          asyncText: this._translate.get(step.translationKey),
        })
      }
      return steps
    }, [])
    return { order, parsedSteps }
  }

  private _hasPermissions(step: TourStep) {
    return (
      (!step.navigateTo && !step.permissionUrl) ||
      (step.navigateTo && this._permissions.isRouteUrlPermitted(step.navigateTo, false)) ||
      (step.permissionUrl && this._permissions.isRouteUrlPermitted(step.permissionUrl, false))
    )
  }

  private _startTour(
    steps: TourStep[],
    options: Partial<TourOptions>,
    userStoredStep?: number,
  ): Observable<TourStepInfo> {
    const _source = existsAsNumber(userStoredStep)
      ? of(userStoredStep)
      : this._store.select(getProfile).pipe(
          completeWhen((p) => !!p),
          map((s) => s.onBoardingStep),
        )

    return _source.pipe(
      map((stepNumber) => steps.find((s) => s.order === stepNumber) || steps[0]),
      tap((initialStep: TourStep) =>
        this._store.dispatch(
          analyticsTrackEvent({
            eventName: AnalyticsEvent.onboardingStart,
            eventParams: {
              [AnalyticsParam.category]: AnalyticsCategory.onboarding,
              [AnalyticsParam.onboardingStepIndex]: initialStep.order,
              [AnalyticsParam.onboardingStepName]: initialStep.gtagTitle,
            },
          }),
        ),
      ),
      switchMap((initialStep: TourStep) =>
        this._tour
          .startTour(steps, {
            ...options,
            startWith: initialStep?.dependsOn ?? initialStep?.name,
          })
          .pipe(
            filter(
              ({ actionType }) =>
                actionType === StepActionType.close || actionType === StepActionType.done,
            ),
            tap(({ actionType, step }) => {
              this._store.dispatch(
                analyticsTrackEvent({
                  eventName:
                    AnalyticsEvent[
                      actionType === StepActionType.close ? 'onboardingStop' : 'onboardingComplete'
                    ],
                  eventParams: {
                    [AnalyticsParam.category]: AnalyticsCategory.onboarding,
                    [AnalyticsParam.onboardingStepIndex]: step.order,
                    [AnalyticsParam.onboardingStepName]: step.gtagTitle,
                  },
                }),
              )
            }),
          ),
      ),
    )
  }
}
