export const calculateDocumentHeight = (docRef: Document): number =>
  Math.max(
    docRef.body.scrollHeight,
    docRef.documentElement.scrollHeight,
    docRef.body.offsetHeight,
    docRef.documentElement.offsetHeight,
    docRef.body.clientHeight,
    docRef.documentElement.clientHeight,
  )

export const getElementFixedTop = (element: Element): number => element.getBoundingClientRect().top

export const getElementFixedLeft = (element: Element): number =>
  element.getBoundingClientRect().left

export const getStyleValue = (node: any, prop: any, winRef: Window) =>
  winRef.getComputedStyle(node, null).getPropertyValue(prop)

export const hasScroll = (node: any, winRef: Window): boolean =>
  /(auto|scroll|overlay)/.test(
    getStyleValue(node, 'overflow', winRef) +
      getStyleValue(node, 'overflow-y', winRef) +
      getStyleValue(node, 'overflow-x', winRef),
  )

export const scrollParent = (node: any, docRef: Document, winRef: Window): any =>
  !node || node === docRef.body
    ? docRef.body
    : hasScroll(node, winRef)
      ? node
      : scrollParent(node.parentNode, docRef, winRef)

export const getFirstScrollableParent = (node: any, docRef: Document, winRef: Window): Element =>
  scrollParent(node, docRef, winRef)
