import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatchItemTemplateBase } from '@shared/components/match-item/models/match-item-template.base'

@Component({
  selector: 'mcp-match-item-date-template',
  templateUrl: './match-item-date-template.component.html',
  styleUrls: ['./match-item-date-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemDateTemplateComponent extends MatchItemTemplateBase {}
