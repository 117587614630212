import videojs from 'video.js'

const component = videojs.getComponent('Component')

export class VideoJsToastComponent extends component {
  textEl: Element

  constructor(player, options) {
    super(player, options)

    this.el_ = videojs.dom.createEl('div', {
      className: 'vjs-toast',
    })

    const wrapper = videojs.dom.createEl('div', {
      className: 'vjs-toast__wrapper',
    })

    this.textEl = videojs.dom.createEl('span', {
      className: 'vjs-toast__text',
      innerHTML: '', // Initially empty
    })

    const closeButtonEl = videojs.dom.createEl('span', {
      className: 'vjs-toast__close',
    })

    closeButtonEl.addEventListener('click', () => {
      this.hide()
    })

    wrapper.appendChild(this.textEl)
    wrapper.appendChild(closeButtonEl)
    this.el_.appendChild(wrapper)

    // Initially hidden
    this.hide()
  }

  /**
   * Sets up the toast
   * @param {Toast} toast
   */
  setupToast(toast) {
    this.el_.classList.add(toast.type)
    this.textEl.innerHTML = toast.message
  }

  override show() {
    this.el_.style.display = 'block'
  }

  override hide() {
    this.el_.style.display = 'none'
  }
}

videojs.registerComponent('VideoJsToastComponent', VideoJsToastComponent)
