import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { map } from 'rxjs/operators'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { getSelectedMatchList } from '@core/state/selectors/selection.selectors'
import {
  selectMatch,
  setSelectedMatches,
  unselectMatch,
} from '@core/state/actions/selection.actions'
import { concatLatestFrom } from '@ngrx/operators'

@Injectable()
export class SelectionEffects {
  selectMatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(selectMatch),
      concatLatestFrom(() => this._store.select(getSelectedMatchList)),
      map(([{ match }, matches]) => {
        const values = [...matches]
        values.push(match)
        return setSelectedMatches({ matches: values })
      }),
    ),
  )

  unselectMatch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(unselectMatch),
      concatLatestFrom(() => this._store.select(getSelectedMatchList)),
      map(([{ match }, matches]) => {
        const values = [...matches]
        const idx = values.findIndex((i) => i.id === match.id)
        values.splice(idx, 1)
        return setSelectedMatches({ matches: values })
      }),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
  ) {}
}
