import { Pipe, PipeTransform } from '@angular/core'

import { isObservable, Observable, of } from 'rxjs'
import { catchError, map, startWith } from 'rxjs/operators'

@Pipe({
  name: 'loading',
})
export class LoadingPipe<T = any> implements PipeTransform {
  transform(val): Observable<T> {
    return !isObservable(val)
      ? val
      : val.pipe(
          map((value: any) => ({ loading: false, value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error })),
        )
  }
}
