import { MatchTabDisplayMode } from '@features/match-tabs/models/match-tab.models'

export const buildPlaylistDialogStyleClass = (
  displayMode: MatchTabDisplayMode,
  ...styles: string[]
): string => {
  return [`mcp-playlist-dialog${displayMode === 'modal' ? ' m-app--dark' : ''}`, ...styles].join(
    ' ',
  )
}
