<div class="mcp-dialog-update-playlist-clip-comment">
  <div class="mcp-dialog-update-playlist-clip-comment__content">
    <mcp-tag-info
      [item]="playlistClip"
      [isFormMode]="true"
      (commentChange)="onCommentChange($event)"
    />
  </div>
  <div class="mcp-dialog-update-playlist-clip-comment__footer">
    <button
      class="mcp-dialog-update-playlist-clip-comment__footer-button button-primary"
      (click)="savePlaylistClip()"
      [disabled]="!playlistClip?.comment?.trim()?.length"
    >
      {{ 'P_COMMONS_PROFILE_BTN_SAVE' | translate }}
    </button>
  </div>
</div>
