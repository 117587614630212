import { StoreToken } from '@core/state/enums/store-token.enum'
import { createAction, props } from '@ngrx/store'

export const fetchStreamData = createAction(
  `[${StoreToken.stream}] fetch stream data`,
  props<{ matchId: string }>(),
)
export const fetchStreamPlayerMetrics = createAction(
  `[${StoreToken.stream}] fetch stream player metrics`,
  props<{ matchId: string }>(),
)
export const setStreamPlayerMetrics = createAction(
  `[${StoreToken.stream}] set stream player metrics`,
  props<{ playerMetrics: any }>(),
)
