import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'

import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'
import { Topic } from '@sockets/enums/socket.enum'
import { NavService } from '../../services/side-nav.service'
import { getDistinctTopic } from '@core/state/selectors/socket.selectors'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'

@Component({
  selector: 'mcp-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
})
export class SideNavigationComponent {
  @Input() items: NavigableItem[] = []

  @Output() itemClick: EventEmitter<NavigableItem> = new EventEmitter<NavigableItem>()

  currentUrl: string
  activeIndex = 0

  currentUrl$: Observable<string> = this._navService.currentUrl.pipe(
    tap((url) => (this.currentUrl = url)),
    tap((url) => {
      this.activeIndex = this.items.findIndex((item) => {
        const urlParsed = this._getRouteChunks(url)
        return (
          url === `/${item.route}` ||
          (item.items || []).findIndex((SubItem) => {
            const routeParsed = this._getRouteChunks(`/${SubItem.route}`)
            return routeParsed.every((x) => urlParsed.includes(x))
          }) !== -1
        )
      })
    }),
    tap((url) => this._ref.detectChanges()),
  )
  hasLiveMatches$ = this._store.pipe(getDistinctTopic(Topic.IsLive))

  constructor(
    private readonly _navService: NavService,
    private readonly _store: Store,
    private readonly _ref: ChangeDetectorRef,
  ) {}

  private _getRouteChunks = (route) => route.replace('/', '').split('/')
}
