import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatchItemTemplateBase } from '@shared/components/match-item/models/match-item-template.base'
import { Match, MatchTeam } from '@core/models/dto/match.dto'
import {
  MATCH_STATE_CONFIG,
  MatchItemData,
  MatchItemModifier,
  MatchState,
  TeamInfo,
} from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-match-item-result-template',
  templateUrl: './match-item-result-template.component.html',
  styleUrls: ['./match-item-result-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemResultTemplateComponent extends MatchItemTemplateBase {
  @Input() override set match(m: Match) {
    this.matchItemData = this._transformMatch(m)
  }

  @Input() modifiers: MatchItemModifier[]
  @Input() isBanner: boolean

  matchItemData: MatchItemData
  MatchState = MatchState
  MATCH_STATE_CONFIG = MATCH_STATE_CONFIG

  private _mapMatchItemTeam({ score, team }: MatchTeam): MatchTeam {
    return {
      score,
      team: {
        ...team,
        name: team.shortName || team.name,
      },
    }
  }

  private _transformMatch(m: Match): MatchItemData | undefined {
    if (m) {
      return {
        home: this._mapMatchItemTeam(m.home) as unknown as TeamInfo,
        away: this._mapMatchItemTeam(m.away) as unknown as TeamInfo,
        state: m.state,
      }
    }
    return undefined
  }
}
