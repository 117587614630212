import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { BreakpointObserver } from '@angular/cdk/layout'
import { map } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Pipe({
  name: 'responsiveCompetitionLogo',
})
export class ResponsiveCompetitionLogoPipe implements PipeTransform {
  constructor(private readonly _breakpointObs: BreakpointObserver) {}

  transform(
    defaultImageUrl: string,
    smallImageUrl?: string,
    matcher: string | string[] = '(min-width: 600px)',
  ): Observable<string> {
    return this._breakpointObs.observe(matcher).pipe(
      map((state) => (state.matches ? defaultImageUrl : smallImageUrl)),
      untilDestroyed(this),
    )
  }
}
