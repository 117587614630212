import { ConnectionCoordinateType } from '@mediacoach-ui-library/global'
import { SegmentOption } from '@core/models/models/common.models'

export const FIELD_DIAGRAM_SEGMENT_OPTIONS: SegmentOption[] = [
  {
    id: ConnectionCoordinateType.Target,
    label: 'MTR_FIELD_DIAGRAM_TARGET',
  },
  {
    id: ConnectionCoordinateType.Origin,
    label: 'MTR_FIELD_DIAGRAM_ORIGIN',
  },
]
