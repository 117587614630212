import { TourStep } from '@shared/modules/tour/models/tour-step.models'
import { runDebouncedPromise } from '@core/utils/common.utils'
import { waitUntilIsRendered } from '@shared/modules/tour/utils/tour.utils'
import { RoutesEnum } from '@core/enums/routes.enums'

export const TOUR_STEPS: TourStep[] = [
  {
    order: 1,
    selector: '#tour-step1',
    name: 'navBarStep',
    preventNavigation: true,
    gtagTitle: 'Navigation bar',
    translationKey: 'MTR_ONBOARDING_NAVBAR_STEP',
  },
  {
    order: 2,
    selector: 'mcui-application-menu .mcui-overlay-panel',
    name: 'menuStep',
    gtagTitle: 'Application launcher',
    translationKey: 'MTR_ONBOARDING_APPMENU_STEP',
    position: 'left',
    preventNavigation: true,
    beforeShow: () =>
      runDebouncedPromise(
        () => (document.querySelector('.mcui-application-menu') as HTMLElement)?.click(),
        0,
      ),
  },
  {
    order: 3,
    selector: '.mcp-open-widget-selector-trigger',
    name: 'addNewWidget',
    navigateTo: RoutesEnum.Competitions,
    preventNavigation: true,
    gtagTitle: 'Add New Widget',
    translationKey: 'MTR_ONBOARDING_ADD_NEW_WIDGET',
  },
  {
    order: 4,
    selector: '.c-matches-list',
    position: 'bottom|right',
    name: 'matchList',
    gtagTitle: 'Match list',
    translationKey: 'MTR_ONBOARDING_MATCHLIST_STEP',
    navigateTo: RoutesEnum.Matches,
    hideBackdrop: true,
    waitUntilRendered: ['.c-matches-list .c-list-view'],
    afterShow: () =>
      waitUntilIsRendered('.c-matches-list .c-list-view')
        .then(() =>
          runDebouncedPromise(
            () => document.querySelector('html')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' }),
            0,
          ),
        )
        .then(() =>
          runDebouncedPromise(
            () => (document.querySelector('.c-matches-list__item') as HTMLElement)?.click(),
            700,
          ),
        ),
  },
  {
    order: 5,
    selector: '.match-details__header',
    position: 'bottom',
    name: 'matchHeader',
    permissionUrl: `${RoutesEnum.MatchDetail}/match-id`,
    dependsOn: 'matchList',
    gtagTitle: 'Match timeline',
    translationKey: 'MTR_ONBOARDING_MATCH_HEADER_STEP',
    waitUntilRendered: ['mc-timeline'],
    beforeShow: () =>
      /matches$/.test(location.pathname)
        ? runDebouncedPromise(() => {
            const nodeList = document.querySelectorAll('.c-matches-list__item')
            if (nodeList.length > 3) {
              ;[1, 2].forEach((idx) => (nodeList.item(idx) as HTMLElement)?.click())
            }
          }, 0).then(() =>
            runDebouncedPromise(
              () =>
                (
                  document.querySelector('.match-item-list-footer__button button') as HTMLElement
                )?.click(),
              600,
            ),
          )
        : Promise.resolve(),
  },
  {
    order: 6,
    selector: '.mcp-match-video',
    position: 'top',
    name: 'matchVideo',
    permissionUrl: `${RoutesEnum.MatchDetail}/match-id`,
    dependsOn: 'matchList',
    gtagTitle: 'Match video',
    translationKey: 'MTR_ONBOARDING_MATCH_VIDEO_STEP',
  },
  {
    order: 7,
    selector: '.match-details__metrics',
    position: 'left',
    name: 'matchMetrics',
    permissionUrl: `${RoutesEnum.MatchDetail}/match-id`,
    dependsOn: 'matchList',
    gtagTitle: 'Match tabs',
    translationKey: 'MTR_ONBOARDING_MATCH_METRICS_STEP',
  },
  {
    order: 8,
    selector: '.mcp-team-space',
    position: 'bottom|right',
    name: 'teams',
    gtagTitle: 'Team Space',
    translationKey: 'MTR_ONBOARDING_TEAMS_STEP',
    navigateTo: RoutesEnum.Teams,
    hideBackdrop: true,
  },
  {
    order: 9,
    selector: '.mcp-player-space',
    position: 'bottom|right',
    name: 'players',
    gtagTitle: 'Player Space',
    translationKey: 'MTR_ONBOARDING_PLAYERS_STEP',
    navigateTo: RoutesEnum.Players,
    hideBackdrop: true,
  },
  {
    order: 10,
    selector: '.mcp-calendar-container',
    position: 'bottom|right',
    name: 'calendar',
    gtagTitle: 'Calendar',
    translationKey: 'MTR_ONBOARDING_CALENDAR_STEP',
    navigateTo: RoutesEnum.Calendar,
    hideBackdrop: true,
  },
  {
    order: 11,
    selector: '.c-page.onboarding_more_portal',
    position: 'bottom|right',
    name: 'more',
    gtagTitle: 'More',
    translationKey: 'MTR_ONBOARDING_MORE_STEP',
    navigateTo: RoutesEnum.More,
    hideBackdrop: true,
  },
]

export const TOUR_BUTTONS_TRANSLATIONS = {
  next: 'MTR_ONBOARDING_STEP_NEXT',
  prev: 'MTR_ONBOARDING_STEP_PREV',
  done: 'MTR_ONBOARDING_STEP_DONE',
  close: 'MTR_ONBOARDING_STEP_CLOSE',
  progress: 'MTR_ONBOARDING_STEP',
}
