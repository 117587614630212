import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { fetchSeasons } from '@core/state/actions/season.actions'
import { getSeasonRefreshFlag } from '@core/state/selectors/seasons.selectors'
import { map } from 'rxjs/operators'

@Injectable()
export class SeasonsResolver {
  _shouldRefresh$ = this._store.pipe(getSeasonRefreshFlag())

  constructor(private readonly _store: Store) {}

  resolve(): Observable<void> | Promise<void> | void {
    return this._shouldRefresh$.pipe(
      map((refresh) => {
        if (refresh) {
          this._store.dispatch(fetchSeasons())
        }
        return
      }),
    )
  }
}
