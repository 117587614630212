import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core'
import { CdkListbox } from '@angular/cdk/listbox'
import { SelectionModel } from '@angular/cdk/collections'
import { PlaylistCollaborator } from '@features/playlist/models/playlist.response.models'
import { scrollIntoView } from '@core/utils/dom.utils'
import { debounce } from '@mediacoach/ui'
import { combineLatest, Subject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'mcp-collaborators-list',
  templateUrl: './collaborators-list.component.html',
  styleUrl: './collaborators-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollaboratorsListComponent {
  private readonly _collaborators$$ = new Subject<PlaylistCollaborator[]>()
  private readonly _selectedCollaborators$$ = new Subject<PlaylistCollaborator[]>()

  private _collaborators: PlaylistCollaborator[]

  readonly defaultAvatarColor = '#a1a1a1'
  readonly selection = new SelectionModel<PlaylistCollaborator>(true, [])

  @ViewChild(CdkListbox) list: CdkListbox

  @Input() set collaborators(clb: PlaylistCollaborator[]) {
    this._collaborators = clb
    this._collaborators$$.next(clb)
  }

  @Input() set selectedCollaborators(clb: PlaylistCollaborator[]) {
    this._selectedCollaborators$$.next(clb)
  }

  get allSelected(): boolean {
    return this.selection.selected.length === this.collaborators?.length
  }

  get selected() {
    return this.selection.selected
  }

  get collaborators() {
    return this._collaborators
  }

  constructor() {
    combineLatest([this._collaborators$$, this._selectedCollaborators$$])
      .pipe(takeUntilDestroyed())
      .subscribe(([collaborators, selectedCollaborators]) =>
        this._handlePreselectedValues(collaborators, selectedCollaborators),
      )
  }

  onCheckChange(): void {
    if (this.allSelected) {
      this.selection.clear()
    } else {
      this.selection.select(...this.collaborators)
    }
  }

  private _handlePreselectedValues(
    collaborators: PlaylistCollaborator[],
    selectedCollaborators: PlaylistCollaborator[],
  ) {
    if (collaborators?.length && selectedCollaborators?.length) {
      collaborators.forEach((clb) => {
        if (selectedCollaborators.find((c) => c.id === clb.id)) {
          this.selection.select(clb)
        }
      })

      this._scrollToFirstSelected()
    }
  }

  @debounce()
  private _scrollToFirstSelected() {
    const _selectedItem = document.querySelector(
      '.mcp-collaborators-list__item.selected',
    ) as HTMLElement

    if (_selectedItem) {
      scrollIntoView(_selectedItem, { behavior: 'smooth', block: 'start' })
    }
  }
}
