import { Directive, ElementRef, Input, TemplateRef, Type, ViewChild } from '@angular/core'
import { DialogComponent } from './dialog.component'

@Directive()
export abstract class DialogBase {
  @ViewChild(DialogComponent) dialog: DialogComponent
  @Input() ref: Element | ElementRef | TemplateRef<Type<unknown>> | unknown

  isOpen: boolean

  open(ref?: Element | ElementRef | TemplateRef<Type<unknown>> | unknown): void {
    if (ref) {
      this.ref = ref
    }

    this.dialog.modal = !this.ref
    this.dialog.inLine = !!this.ref
    this.dialog.keepUnderToolbar = !!this.ref

    if (this.dialog) {
      this.isOpen = true
      this.dialog.open()
    }
  }

  close(): void {
    if (this.dialog) {
      this.isOpen = false
      this.dialog.close()
    }
  }

  onShow() {
    if (!this.ref && this.dialog) {
      this.dialog.center()
    }
  }
}
