import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { QueryBase, Selectable } from '@shared/components/query/models/query-base.model'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { debounceTime } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mcp-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueriesComponent {
  private _fields: QueryBase<any>[]
  get fields() {
    return this._fields
  }

  @Input() set fields(f) {
    if (f) {
      this._setOptions(f)
      this._fields = (f || []).sort((a, b) => a.order - b.order)
      this._initialize(f)
    }
  }

  form: UntypedFormGroup

  @Output() queryChange = new EventEmitter<unknown>()

  handleOptions({ value, field }) {
    if (field.updates) {
      const target = this.fields.find((f) => f.key === field.updates.targetName) as Selectable
      if (target) {
        target.options = value[field.updates.fieldKey]
      }

      const newValue = field.updates.updatesFn
        ? field.updates.updatesFn(value, target)
        : (
            value[field.updates.fieldKey].find(
              (opt) =>
                opt.value[field.updates.matchKey] ===
                (this.form.get(field.updates.targetName).value || {})[field.updates.matchKey],
            ) || {}
          ).value

      this.form
        .get(field.updates.targetName)
        .setValue(newValue || value[field.updates.fieldKey][0].value)
    }
  }

  private _initialize(fields) {
    const controls = fields.reduce(
      (ctrl, field) => ({
        ...ctrl,
        [field.key]: new UntypedFormControl(field.value),
      }),
      {},
    )
    this.form = new UntypedFormGroup(controls)
    this.form.valueChanges.pipe(debounceTime(300), untilDestroyed(this)).subscribe((value) => {
      this.queryChange.emit(value)
    })
  }

  private _setOptions(fields) {
    fields.forEach((field) => {
      if (field.updates) {
        const target = fields.find((f) => f.key === field.updates.targetName)
        target.options = field.value[field.updates.fieldKey]
      }
    })
  }
}
