import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import {
  DownloadableAssetItemStreamEvent,
  DownloadableBlock,
} from './models/downloadable-block.models'

@Component({
  selector: 'mcp-downloadable-block-container',
  templateUrl: './downloadable-block-container.component.html',
  styleUrl: './downloadable-block-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadableBlockContainerComponent {
  @Input() downloads: DownloadableBlock[]

  onStreaming(streamEvent: DownloadableAssetItemStreamEvent) {}
}
