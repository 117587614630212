<div
  [mcpGridTpl]="{ columns: columns, rows: rows, headerHeight: headerHeight }"
  class="mcp-grid"
  id="mcp-grid"
>
  <ng-container
    *rxFor="let column of columns; index as cIdx; first as isFirstColumn; last as isLastColumn"
  >
    <div
      class="mcp-grid__header toooltip"
      [ngClass]="column.styleClass"
      [ngStyle]="column.styles"
      [class.sticky]="stickyHeader"
      [class.top]="stickyHeader"
      [class.left]="stickyHeader && isFirstColumn"
      [class.on-top]="stickyHeader && isFirstColumn"
      [class.sortable]="
        ((column.sortFn || sortable) && column.sortable !== false) || column.sortable
      "
      [style.gridRow]="1"
      [style.gridColumn]="1 + cIdx"
      (click)="sort(cIdx)"
    >
      <div *ngIf="headerTpl; else defaultHeaderTpl">
        <ng-container
          [ngTemplateOutlet]="headerTpl"
          [ngTemplateOutletContext]="{
            column: column,
            index: cIdx,
            isFirstColumn: isFirstColumn,
            isLastColumn: isLastColumn
          }"
        ></ng-container>
      </div>
      <ng-template #defaultHeaderTpl>
        <div mcTooltip="{{ column.tooltipLabel | translate }}">
          {{ column.label | translate }}
        </div>
      </ng-template>
      <span
        class="mcp-grid__header-sort"
        [ngClass]="column.sortDirection"
        *ngIf="column.sortFn || column.sortable || sortable"
      ></span>
    </div>

    <div
      class="mcp-grid__row"
      [ngStyle]="rowStyles"
      *rxFor="let row of rows; index as rIdx; first as isFirstRow; last as isLastRow"
      [class.sticky]="column.sticky"
      [class.left]="column.sticky"
      [style.gridColumn]="1 + cIdx"
      [style.gridRow]="2 + rIdx"
      (click)="cellClick.emit({ row: row, column: column, rowIdx: rIdx, columnIdx: cIdx })"
    >
      <ng-container *ngIf="cellTpl; else defaultRowTpl">
        <ng-container
          [ngTemplateOutlet]="cellTpl"
          [ngTemplateOutletContext]="{
            row: row,
            rowIndex: rIdx,
            isFirstRow: isFirstRow,
            isLastRow: isLastRow,
            column: column,
            columnIndex: cIdx,
            isFirstColumn: isFirstColumn,
            isLastColumn: isLastColumn
          }"
        >
        </ng-container>
      </ng-container>
      <ng-template #defaultRowTpl>
        <div class="mcp-grid__row--default">{{ row[column.key] }}</div>
      </ng-template>
    </div>

    <div class="mcp-grid__footer sticky bottom" [class.left]="isFirstColumn" *ngIf="footerTpl">
      <ng-container
        [ngTemplateOutlet]="footerTpl"
        [ngTemplateOutletContext]="{
          column: column,
          index: cIdx,
          isFirstColumn: isFirstColumn,
          isLastColumn: isLastColumn
        }"
      ></ng-container>
    </div>
  </ng-container>
</div>
