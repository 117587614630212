<div class="c-stats__container">
  <div class="c-stats__player-container">
    <img
      class="c-stats__player-container-image"
      [appDefaultImage]="placeholder.PLAYER"
      [srcUrl]="playerImage?.loading ? null : playerImage?.portraitLogo"
      [title]="playerImage?.metricPlayerName"
    />
    <div class="c-stats__player-container-background"></div>
  </div>
  <div *ngFor="let stat of statistics" class="c-stats__item">
    <span class="c-stats__item--label">{{ stat.label | translate }}</span>
    <span class="c-stats__item--value">
      <i
        *ngIf="stat.icon"
        class="c-stats__item--icon u-icon"
        [style.color]="stat?.icon?.color"
        [ngClass]="'u-icon--' + stat?.icon?.value"
      ></i>
      {{
        stat.value != null
          ? (stat.isNumber
              ? (stat.value | number: '' : (lang$ | async))
              : (stat.value | translate)) + ((stat.unit | translate) || '')
          : '-'
      }}
    </span>
  </div>
</div>
