<div
  [ngClass]="{
    'c-metric-dialog--player': type === MetricCategory.Player,
    'c-metric-dialog--team': type === MetricCategory.Team,
  }"
>
  <app-dialog
    [closable]="false"
    [styleClass]="extraClasses"
    [mcpAppendTo]="ref"
    [inlineWrapperSelector]="$any(ref)"
    [keepUnderToolbar]="!!ref"
    (showEvent)="onShow()"
  >
    <div class="dialog-header c-metric-dialog__header" [ngSwitch]="type">
      <ng-container *ngSwitchCase="MetricCategory.Player">
        <ng-container *ngTemplateOutlet="playerHeader; context: { $implicit: data }"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="MetricCategory.Team">
        <ng-container *ngTemplateOutlet="teamHeader; context: { $implicit: data }"></ng-container>
      </ng-container>
    </div>
    <div class="c-metric-dialog__body">
      <div class="c-metric-dialog__data" [class.is-dual]="isDual">
        <span class="c-metric-dialog__data-key">{{ data?.metricLabel | translate }}</span>
        <ng-container *ngIf="!isDual; else dualMode">
          <span class="c-metric-dialog__data-value">
            {{ (data?.metricValue | number: '1.0-2' : (language$ | async)) || '-'
            }}{{ (data?.metricUnit | translate) || '' }}
          </span>
        </ng-container>
      </div>
      <div class="c-metric-dialog__button-container">
        <button class="c-metric-dialog__button" (click)="close()">
          {{ 'DEMO_FORM_BTN_CLOSE_DIALOG' | translate }}
        </button>
      </div>
    </div>
  </app-dialog>
</div>

<ng-template #dualMode>
  <mcui-dual-header
    [leftText]="data?.dualData?.header?.leftContent?.title"
    [leftImage]="data?.dualData?.header?.leftContent?.img"
    [leftSubtitleImage]="data?.competition?.logo"
    [leftImagePlaceholder]="playerPlaceholder"
    [leftSubtitleImagePlaceholder]="competitionPlaceholder"
    [leftPills]="[data?.dualData?.header?.leftContent?.subtitle | translate]"
    [rightPills]="[data?.dualData?.header?.rightContent?.subtitle | translate]"
    [rightText]="
      data?.dualData?.header?.rightContent?.title === competitionIdentifier
        ? data?.competition.officialName
        : data?.dualData?.header?.rightContent?.title
    "
    [rightImage]="data?.dualData?.header?.rightContent?.img"
    [rightSubtitleImage]="data?.competition?.logo"
    [rightImagePlaceholder]="
      data?.dualData?.header?.rightContent?.isPlayer ? playerPlaceholder : competitionPlaceholder
    "
    [rightSubtitleImagePlaceholder]="competitionPlaceholder"
  />
  <div class="c-metric-dialog__metric">
    <span class="c-metric-dialog__metric-left"
      >{{ (data?.dualData?.metric?.leftValue | number: '1.0-2' : (language$ | async)) || '-'
      }}{{ (data?.dualData?.metric?.leftUnit | translate) || '' }}</span
    >
    <span class="c-metric-dialog__metric-right"
      >{{ (data?.dualData?.metric?.rightValue | number: '1.0-2' : (language$ | async)) || '-'
      }}{{ (data?.dualData?.metric?.rightUnit | translate) || '' }}</span
    >
  </div>
</ng-template>

<ng-template #playerHeader let-data>
  <div class="c-metric-dialog__header-content">
    <img
      class="c-metric-dialog__header-content-logo"
      slot="start"
      [appDefaultImage]="playerPlaceholder"
      [srcUrl]="data?.imgUrl"
      [alt]="data?.name"
    />
    <div class="c-metric-dialog__header-content-info">
      <span class="c-metric-dialog-info__name">
        {{ data?.name }}
      </span>
      <span class="c-metric-dialog-info__position">
        {{ data?.position | translate }}
      </span>
      <div class="c-metric-dialog-info__team">
        <img
          [appDefaultImage]="teamPlaceholder"
          [srcUrl]="data?.team?.portraitLogo"
          [alt]="data?.team?.name"
        />
        <span class="c-metric-dialog-info__team-name">
          {{ data?.team?.name }}
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #teamHeader let-data>
  <div class="c-metric-dialog__header-content">
    <img
      class="c-metric-dialog__header-content-logo"
      slot="start"
      [appDefaultImage]="teamPlaceholder"
      [srcUrl]="data?.imgUrl"
      [alt]="data?.name"
    />
    <div class="c-metric-dialog__header-content-info">
      <span class="c-metric-dialog-info__name">
        {{ data?.name }}
      </span>
    </div>
  </div>
</ng-template>
<ng-container *ngIf="open$$ | async"></ng-container>
