<ng-container *ngIf="field?.options | optionsResolver as options">
  <app-loader [loading]="options.length <= 0" [noHeight]="true">
    <mcui-select
      panelStyleClass="mcui-select-panel--common"
      label="{{ field?.placeholder | translate }}"
      [disable]="options.length <= 0 || !!options.disabled"
      [options]="options"
      [formControl]="control"
      [clearable]="field?.clearable"
      [optionSelectedTemplate]="selectedItem"
      [optionTemplate]="itemTemplate"
    >
      <ng-template let-item="item" #selectedItem>
        <mcp-query-dropdown-item [item]="item" [config]="field"></mcp-query-dropdown-item>
      </ng-template>
      <ng-template let-item="item" #itemTemplate>
        <mcp-query-dropdown-item
          [item]="item"
          [isListItem]="true"
          [config]="field"
        ></mcp-query-dropdown-item>
      </ng-template>
    </mcui-select>
  </app-loader>
</ng-container>
