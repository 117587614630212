<app-dialog
  [mcpAppendTo]="ref"
  [inlineWrapperSelector]="$any(ref)"
  class="c-player-comparison"
  styleClass="c-dialog--attached"
  [keepUnderToolbar]="!!ref"
  (showEvent)="onShow()"
>
  <div class="dialog-header c-player-comparison__dialog--header-title">
    <span> {{ 'MTR_TITLE_PLAYER_VS' | translate: { player: initialPlayer?.nameAndDorsal } }}</span>
  </div>

  <div class="c-player-comparison__dialog--content">
    <mcui-segmented-group
      class="c-player-comparison__lineup-team segmented-group"
      [ngModel]="selectedTeam"
      (ngModelChange)="onTeamChange($event)"
    >
      @for (opt of teamSegmentOptions; track opt.id) {
        <mcui-segmented-control [value]="opt.id">
          <div class="c-player-comparison__team-segment segment">
            <div
              class="c-player-comparison__team-segment-img segment-image"
              [mcpBackgroundImage]="opt.image"
            ></div>
            <span class="c-player-comparison__team-segment-label segment-label">{{
              opt.label
            }}</span>
          </div>
        </mcui-segmented-control>
      }
    </mcui-segmented-group>
    <div class="c-player-comparison__dialog--content-list">
      @for (
        player of match?.playerList[selectedTeam] | excludePlayer: initialPlayer?.id;
        track player.id
      ) {
        <mcp-player-list-item
          class="c-player-comparison__list-item"
          (click)="playerSelect.emit({ playerA: initialPlayer, playerB: player })"
          [data]="$any(player)"
          extraClasses="mcp-player-list-item__hover"
          [isNavigationActive]="true"
        />
      }
    </div>
  </div>
</app-dialog>
