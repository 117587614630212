import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'

import { ContentType, ITableConfig } from './table.model'
import { DOCUMENT } from '@angular/common'
import { getScrollParent } from '@core/utils/application.utils'

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  private _items: any[]
  private _selectedItems: any[]
  private _scrollableParent: HTMLElement

  @Input() contentConfig: ITableConfig[]
  @Input() isSelectable: boolean
  @Input() isTypeGrid: boolean
  @Input() justify = 'center'
  @Input() size: string
  @Input() headerExtraClasses: string
  @Input() stickyHeader = true
  @Input() blockParentOverflow = false

  get items() {
    return this._items
  }
  @Input() set items(_items) {
    this._items = _items
    this._updateSelected()
  }

  @Input() set selectedItems(_selectedItems) {
    this._selectedItems = _selectedItems
    this._updateSelected()
  }

  get selectedItems() {
    return this._selectedItems
  }

  ContentType = ContentType
  sizeClass = ''

  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _el: ElementRef,
  ) {}

  private _updateSelected() {
    if (this.isSelectable && this._selectedItems) {
      this._items = this._items.map((match) => ({
        ...match,
        selected: this._selectedItems.some((s) => s.id === match.id),
      }))
    }
  }

  ngOnInit() {
    if (this.size) {
      this.sizeClass = `c-table--${this.size}`
    }
  }

  ngAfterViewInit() {
    if (this.blockParentOverflow) {
      this._scrollableParent = getScrollParent(this._document, this._el.nativeElement)
      this._scrollableParent.style.overflowY = 'hidden'
    }
  }

  ngOnDestroy() {
    if (this.blockParentOverflow && this._scrollableParent) {
      this._scrollableParent.style.overflowY = 'initial'
    }
  }

  trackByFn(index, item) {
    return item?.id
  }
}
