import { DeviceOs } from '@mediacoach-ui-library/global'

export enum DialogType {
  Contact = 'contact',
  Download = 'download',
}

export interface DownloadItem {
  os: DeviceOs
  type: string
  text: string
  url: string | object
}
