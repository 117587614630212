import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { pipe } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { DeepEqual } from '@mediacoach-ui-library/global'
import { HubConnectionState } from '@microsoft/signalr'
import { SocketState } from '../models/socket.state'
import { Topics } from '@sockets/models/socket.model'
import { Topic } from '@sockets/enums/socket.enum'
import { selectSimplifiedLang } from './user.selectors'
import { getSeasons } from './seasons.selectors'
import { lang } from 'moment'
import { parseMatch } from '@core/utils/match.utils'
import { sortByMatchdayDescDateDesc } from '@features/matches/utils/matches.utils'
import { Match } from '@core/models/dto/match.dto'
import { exists } from '@core/utils/object.utils'

export const selectSocket = createFeatureSelector<SocketState>(StoreToken.socket)

/**
 * Gets all stored topics as a Map
 *
 * @see Topics
 * @return {Observable<Topics>}
 */
export const getTopics = createSelector(selectSocket, ({ topics }): Topics => topics)

/**
 * Get a topic value or a set of topics as a Map
 * If a single topic is received, it will return its content. Otherwise it will return a subset of all topics as a Map
 *
 * @param topics
 * @see Topics
 * @return
 */
// export const getTopic = createSelector(
//   selectSocket, (state, topics: Topic | Topic[]): Topics | unknown => Array.isArray(topics) ?
//     topics.reduce((obj, topic) => ({
//       ...obj,
//       [topic]: exists(state.topics) ? state.topics[topic] : null
//     }), {})
//     : exists(state.topics) ? state.topics[topics] : null
// )

export const getTopic = (topics: Topic | Topic[]) =>
  createSelector(selectSocket, (state) =>
    Array.isArray(topics)
      ? topics.reduce(
          (obj, topic) => ({
            ...obj,
            [topic]: exists(state.topics) ? state.topics[topic] : null,
          }),
          {},
        )
      : exists(state.topics)
        ? state.topics[topics]
        : null,
  )

export const getDistinctTopic = (topics: Topic | Topic[]) =>
  pipe(select(getTopic(topics)), distinctUntilChanged(DeepEqual))

export const getSocketStatus = createSelector(selectSocket, (state) => state.status)

export const getOnConnect = pipe(
  select(getSocketStatus),
  filter((status) => status === HubConnectionState.Connected),
)

export const getTokenExpired = pipe(
  select(getTopic(Topic.TokenExpired)),
  filter((expired) => !!expired),
  map(() => true),
)

export const getMatchTopic = (topic: Topic | Topic[]) =>
  createSelector(
    getTopic(topic),
    selectSimplifiedLang,
    getSeasons,
    (liveMatches: Match[], language, seasons) => {
      if (!!liveMatches && !!language && !!seasons) {
        return sortByMatchdayDescDateDesc(
          liveMatches.map((match) => parseMatch(match, lang, seasons)),
        )
      }
    },
  )

export const getLiveMatches = createSelector(
  getTopic(Topic.LiveMatches),
  selectSimplifiedLang,
  getSeasons,
  (liveMatches: Match[], language, seasons) => {
    if (!!liveMatches && !!language && !!seasons) {
      return sortByMatchdayDescDateDesc(
        liveMatches.map((match) => parseMatch(match, lang, seasons)),
      )
    }
  },
)
