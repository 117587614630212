<ng-container
  *ngIf="{
    profile: profile$ | async,
    competitions: competitions$ | async,
    profileMenu: profileMenu$ | async,
  } as data"
>
  <nav [attr.data-cy]="'header'" class="c-menu-horizontal {{ darkMode }}">
    <div [attr.data-cy]="'header-logo'" class="c-menu-horizontal__logo" mcpAllowedRouteNavigation>
      <img
        class="c-menu-horizontal__logo-img"
        [src]="urlStatics + logoData?.lg | safe: 'url'"
        [alt]="logoData.alt"
      />
    </div>
    <div class="c-menu-horizontal__content">
      <mcp-navigation-bar-container
        (itemClick)="onItemClick($event)"
        [profile]="data?.profile"
        [profileMenuConfig]="data?.profileMenu"
      >
        <div class="c-menu-horizontal__options">
          <div [attr.data-cy]="'apps-launcher'" class="c-menu-horizontal__app-menu">
            <mcui-application-menu
              [config]="appMenuConfig"
              label="APPS"
              [applicationUserProfileTypes]="applications$ | async"
              (itemClick)="onLauncherClick($event)"
              (deepLinkChange)="
                appDialog.openModal(applicationDialogType.Download, $event, data?.profile)
              "
            >
            </mcui-application-menu>
          </div>
        </div>
      </mcp-navigation-bar-container>
    </div>
  </nav>

  <!-- Pop-up Profile -->
  <mcp-profile-dialog
    #profile
    [competitions]="data?.competitions"
    [user]="data?.profile"
    (conditions)="conditions.openModal()"
    (save)="onSubmit($event)"
    (analytics)="onAnalytics($event)"
  ></mcp-profile-dialog>

  <!-- Pop-up Competition -->
  <mcp-competition-dialog
    #competition
    [competitions]="data?.competitions"
    [currentCompetitionId]="data?.profile?.favourites?.competitionId"
    (competitionSelected)="onCompetitionSelected($event)"
  ></mcp-competition-dialog>

  <app-update-password-modal #updatePassword></app-update-password-modal>
  <app-dialog-application #appDialog></app-dialog-application>
  <app-dialog-legal #conditions></app-dialog-legal>
</ng-container>
