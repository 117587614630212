<div class="mcp-dialog-playlist-filter" *mcuiLoading="filterLoader$ | async">
  <div
    class="mcp-dialog-playlist-filter__content"
    *ngIf="{
      items: config$ | async,
      filterActive: filterActive$ | async,
    } as data"
  >
    <mcui-chips
      [items]="data?.items?.all"
      multiple="true"
      dataKey="value"
      [ngModel]="filterActive.all"
      (itemClick)="itemAllClick($event, filterActive)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.items?.team?.length"
      [title]="'MTR_PLAYLIST_FILTER_TEAM' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="filterActive.team"
      [items]="data?.items?.team"
      (selectionChange)="selectionChange(filterType.Team, $event)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.items?.event?.length"
      [title]="'MTR_PLAYLIST_FILTER_EVENT' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="filterActive.event"
      [items]="data?.items?.event"
      (selectionChange)="selectionChange(filterType.Event, $event)"
    >
    </mcui-chips>
    <mcui-chips
      *ngIf="data?.items?.player?.length"
      [title]="'MTR_PLAYLIST_FILTER_PLAYER' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="filterActive.player"
      [items]="data?.items?.player"
      (selectionChange)="selectionChange(filterType.Player, $event)"
    >
    </mcui-chips>
    <mcui-chips
      [title]="'MTR_PLAYLIST_FILTER_OTHER' | translate"
      dataKey="value"
      multiple="true"
      [ngModel]="filterActive?.other"
      [items]="data?.items?.other"
      (selectionChange)="selectionChange(filterType.Other, $event)"
    >
    </mcui-chips>
  </div>
  <div class="mcp-dialog-playlist-filter__footer">
    <button class="mcp-dialog-playlist-filter__footer-button button-primary" (click)="close()">
      {{ 'MTR_PLAYLIST_FILTERS_APPLY' | translate }}
    </button>
  </div>
</div>
