<div class="mcp-stream-content" #tpl [class.fullscreen]="fullscreen" mcpScrollbarBodyHandler>
  <div class="mcp-stream-content__header" [hidden]="!fullscreen" [@fadeInDown]="fullscreen">
    <mcp-stream-header
      [ref]="tplRef"
      [hideNavigation]="hideNavigation"
      [match]="match"
      [cameraList]="cameraList"
      [selectedCamera]="streamType?.id"
      (navigate)="navigate.emit(match)"
      (cameraChange)="onCameraChange($event)"
      (close)="closeDialog.emit()"
    ></mcp-stream-header>
  </div>
  <div class="mcp-stream-content__body">
    <div class="mcp-stream-content__body-player">
      <mcp-video-container
        [hidden]="playlistConfig?.hideVideoSource"
        [source]="url"
        [showAdvancedControls]="fullscreen"
        [withCredentials]="withCredentials"
        [autoplayMode]="autoplayMode"
        [markers]="markers"
        [hasQuickTagButton]="true"
        [formatTimeFn]="
          formatTimeFn(
            timeline?.periods,
            streamType?.videoType,
            'MTR_COMMON_MATCH_STATE_HALFTIME' | translate
          )
        "
        [lang]="lang$ | async"
        (quickTagChange)="onQuickTagChange($event)"
        (videoError)="onVideoError($event)"
        (analytics)="onVideoAnalytics($event)"
      ></mcp-video-container>
      <span
        class="mcp-stream-content__body-playlist-no-results"
        *ngIf="playlistConfig?.hideVideoSource && playlistConfig?.notHasTags"
      >
        {{ 'MTR_PLAYLIST_NO_TAGS_RESULTS' | translate }}
      </span>
    </div>
    <div class="mcp-stream-content__body-timeline" *ngIf="fullscreen" [@fadeInUp]="fullscreen">
      <mc-timeline
        *ngIf="timeline && !playlistConfig?.playListTabSelected"
        [data]="timeline"
        [smallUntil]="smallUntil"
        [disableConfig]="timelineDisableEventFn(streamType?.videoType)"
        [eventTranslations]="timeline?.eventTranslations"
        [periodTranslations]="timeline?.periodTranslations"
        (onEventClick)="onTimelineEventClick($event)"
        (onPeriodClick)="onTimelinePeriodClick($event)"
      ></mc-timeline>
      <div
        [hidden]="!playlistConfig?.playListTabSelected"
        class="mcp-stream-content__body-playlist-info"
        [attr.data-cy]="'stream-content__body-playlist-info'"
        [class.loading]="loading"
      >
        <mcp-loader [loading]="loading">
          <mcp-playlist-item-info
            *ngIf="playlistConfig?.selectedTagItem"
            [item]="playlistConfig?.selectedTagItem"
            [lang]="lang"
            [isModal]="true"
            (menuClick)="menuClick.emit($event)"
          >
          </mcp-playlist-item-info>
        </mcp-loader>
      </div>
    </div>
    <mcp-sidebar [hidden]="!fullscreen" [collapsed]="sidebarCollapsed">
      <ng-container
        *ngTemplateOutlet="$any(tabsTpl); context: { tabs: tabs, displayMode: 'modal' }"
      ></ng-container>
    </mcp-sidebar>
  </div>
</div>
