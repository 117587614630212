import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { MatchItemTemplateBase } from '@shared/components/match-item/models/match-item-template.base'
import { Match } from '@core/models/dto/match.dto'
import {
  MATCH_STATE_CONFIG,
  MatchItemData,
  MatchItemModifier,
  MatchState,
} from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-match-item-result-template',
  templateUrl: './match-item-result-template.component.html',
  styleUrls: ['./match-item-result-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemResultTemplateComponent extends MatchItemTemplateBase {
  @Input() override set match(m: Match) {
    this.matchItemData = m
      ? {
          home: m.home as any,
          away: m.away as any,
          state: m.state,
        }
      : null
  }

  @Input() modifiers: MatchItemModifier[]
  @Input() isBanner: boolean

  matchItemData: MatchItemData
  MatchState = MatchState
  MATCH_STATE_CONFIG = MATCH_STATE_CONFIG
}
