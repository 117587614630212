export const isGoalKeeper = (player: any): boolean =>
  player.genericPosition === 'MTR_PLAYERPOSITION_GOALKEEPER' ||
  (player as any).genericPositionKey === 'MTR_PLAYERPOSITION_GOALKEEPER' ||
  (player as any).playerPosition === 'Goalkeeper'

export const resolveGenericPosition = (player: any): string =>
  player.playerPositionKey ||
  player.genericPositionKey ||
  player.genericPosition ||
  player.playerPosition
