import { TranslateService } from '@ngx-translate/core'

export const translateKey = (_translateService: TranslateService, key: string, lang?: string) => {
  const translation = _translateService.instant(key)
  const multiTranslation =
    lang && lang === _translateService.currentLang
      ? _translateService.translations[lang][key]
      : translation

  return multiTranslation ?? translation
}
