import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map } from 'rxjs/operators'
import { fetchNavigationItems, setNavigationItems } from '@core/state/actions/navigation.actions'
import { NAVIGATION_CONFIG } from '@core/constants/navigation.constants'
import { Router } from '@angular/router'
import { PermissionsService } from '@core/services/permissions.service'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'

@Injectable()
export class NavigationEffects {
  fetchNavigationItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchNavigationItems),
      map(() => setNavigationItems({ items: this._getFilteredItems(NAVIGATION_CONFIG) })),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _permissionService: PermissionsService,
  ) {}

  private _getFilteredItems = (items: NavigableItem[]) =>
    items
      .map((item: NavigableItem) => ({
        ...item,
        items: item.items?.filter((child) =>
          this._permissionService.isRouteUrlPermitted(child.route, false),
        ),
      }))
      .filter((item) => this._permissionService.isRouteUrlPermitted(item.route, false))
}
