import { Store } from '@ngrx/store'
import { startupApplication } from '@core/state/actions/startup.actions'
import { OidcService } from '@core/authentication/oidc/services/oidc.service'
import { purgeUserProfile } from '@core/state/actions/profile.actions'
import { navigateExternal } from '@core/router/state/actions/router.actions'
import { logOut } from '@core/state/actions/oidc.actions'
import { environment } from '@env'
import { clearStorageByPrefix } from '@core/utils/application.utils'
import { lastValueFrom } from 'rxjs'

export const idsFactory = (_oidc: OidcService, _store: Store) => () => {
  const _logoutRedirect = () => {
    clearStorageByPrefix(localStorage, 'oidc.')
    void lastValueFrom(_oidc.removeUser())
    _store.dispatch(
      navigateExternal({
        path: environment.IDENTITY_SERVER.POST_LOGOUT_REDIRECT_URI,
      }),
    )
  }
  _oidc.manager.events.addUserSignedOut(() => _store.dispatch(logOut()))
  _oidc.manager.events.addUserLoaded((oidc) => _store.dispatch(startupApplication({ oidc })))
  _oidc.manager.events.addUserUnloaded(() => _store.dispatch(purgeUserProfile()))
  _oidc.manager.events.addAccessTokenExpired(() => _logoutRedirect())
  _oidc.manager.events.addSilentRenewError(() => _logoutRedirect())
}
