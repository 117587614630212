import { Injectable } from '@angular/core'

/* eslint-disable no-console */

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private _enabled: boolean
  private _color: string

  enable(enable: boolean, color: string = '#bf81ff') {
    this._enabled = enable
    this._color = `color:${color}`
  }

  debug(message?: string, data: any = '') {
    if (this._enabled) {
      console.debug('%c[OnBoarding|DEBUG] ' + message, this._color, data)
    }
  }

  info(message?: string, data: any = '') {
    if (this._enabled) {
      console.info('%c[OnBoarding|INFO] ' + message, this._color, data)
    }
  }

  warn(message?: string, data: any = '') {
    if (this._enabled) {
      console.warn('[OnBoarding|WARN] ' + message, data)
    }
  }

  error(message?: string, data: any = '') {
    if (this._enabled) {
      console.error('[OnBoarding|ERROR] ' + message, data)
    }
  }

  /* eslint-enable no-console */
}
