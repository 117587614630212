import { Store } from '@ngrx/store'
import { DomSanitizer } from '@angular/platform-browser'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core'

import { environment } from '@env'
import { DOCUMENT } from '@angular/common'
import { McpProfile } from '@auth/models/auth.dto'
import { NavService } from './services/side-nav.service'
import { getNavigationItems } from '@core/state/selectors/navigation.selectors'
import { NAVIGATION_LOGO } from '@core/main/containers/navigation-bar/constants/navigation-bar.constants'
import { Observable } from 'rxjs'
import { getDistinctTopic } from '@core/state/selectors/socket.selectors'
import { Topic } from '@sockets/enums/socket.enum'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'
import { BreakpointObserver } from '@angular/cdk/layout'
import { DESKTOP_BREAKPOINT } from '@core/constants/responsive.constants'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'

@UntilDestroy()
@Component({
  selector: 'mcp-navigation-bar-container',
  templateUrl: './navigation-bar-container.component.html',
  styleUrls: ['./navigation-bar-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarContainerComponent implements OnInit {
  @Output() itemClick = new EventEmitter<NavigableItem>()
  @Input() profileMenuConfig: NavigableItem
  @Input() profile: McpProfile
  logoData = NAVIGATION_LOGO
  urlStatics: string = environment.STATICS_URL_ASSETS
  logoPortal
  isOpen: boolean
  isProfileCollapseOpen: boolean
  PLACEHOLDER_IMAGES = PLACEHOLDER_IMAGES

  currentUrl$: Observable<string> = this._navService.currentUrl
  hasLiveMatches$: Observable<boolean> = this._store.pipe(getDistinctTopic(Topic.IsLive))

  readonly navigationData$ = this._store.select(getNavigationItems)
  readonly defaultAvatarColor = '#a1a1a1'

  constructor(
    private readonly _renderer: Renderer2,
    private readonly _navService: NavService,
    private readonly _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _store: Store,
    private readonly _breakpointObs: BreakpointObserver,
  ) {
    this.logoPortal = this._sanitizer.bypassSecurityTrustUrl(this.urlStatics + this.logoData.lg)

    this._breakpointObs
      .observe([DESKTOP_BREAKPOINT])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this._navService.isOpen) {
          this._navService.toggle()
        }
      })
  }

  ngOnInit() {
    this._navService.isOpenUpdated.subscribe((isOpen: boolean) => {
      this.isOpen = isOpen
      this._renderer[`${isOpen ? 'add' : 'remove'}Class`](this._document.body, 'overflow-hidden')
    })
  }

  toggleSidenav(): void {
    this.isProfileCollapseOpen = false
    this._navService.toggle()
  }

  onNavigationItemClick(item: NavigableItem) {
    if (this._navService.isOpen && !item.items) {
      this._navService.toggle()
    }
    this.googleAnalyticsDownload(item.analytics)
  }

  googleAnalyticsDownload(itemAnalytics) {
    const { action, label } = itemAnalytics
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: null,
        eventParams: {
          eventCategory: 'menu_portal',
          eventAction: action,
          eventLabel: label,
        },
      }),
    )
  }
}
