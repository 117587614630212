import { inject, Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
  name: 'translateStringList',
})
export class TranslateStringListPipe implements PipeTransform {
  private readonly translate: TranslateService = inject(TranslateService)
  transform(list: string[], _: string): any {
    return (list || []).map((text) => this.translate.instant(text))
  }
}
