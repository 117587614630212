<a
  *ngIf="(!item.url && !item.urlFn) || item.disabled; else enabledAsset"
  class="mcp-downloadable-asset-item__button"
  [attr.title]="'MATCHES_TEMPORALITY_DOC_UNAVAILABLE' | translate"
  disabled
  >{{ item.extension }}</a
>

<ng-template #enabledAsset>
  <button
    *ngIf="ALLOW_ASSET_STREAMING && VIDEO_EXTENSIONS.includes(item.extension)"
    class="mcp-downloadable-asset-item__button mcp-downloadable-asset-item__button--primary"
    (click)="emitStreaming(item)"
  >
    {{ 'COMMONS_PLAY' | translate }}
  </button>
  <a
    *ngIf="!item.disabled"
    class="mcp-downloadable-asset-item__button"
    [ngClass]="item.extraClasses"
    [href]="item.url || item.urlFn() | safe: 'url'"
    (click)="gaTrack(item)"
    [extension]="item.extension"
    [ga4Params]="{
      name: item?.ga4Event,
      event_category: item?.ga4Category,
      installer_name: item?.installerName,
    }"
    download
    >{{ item.extension }}</a
  >
</ng-template>
