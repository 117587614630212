import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { AssetGroupConfig, AssetItem } from '@core/models/dto/assets.dto'
import { Store } from '@ngrx/store'
import { AnalyticsTrackEvent } from '@core/analytics/models/analytics.models'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { DownloadableAssetItemStreamEvent } from '../../models/downloadable-block.models'

@Component({
  selector: 'mcp-downloadable-asset-container',
  templateUrl: './downloadable-asset-container.component.html',
  styleUrl: './downloadable-asset-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadableAssetContainerComponent {
  readonly defaultAsset: Record<string, string>[] = [{ text: 'MATCHDETAIL_MEDIA_LAB_NOTAVAILABLE' }]

  @Input() assetGroup: AssetGroupConfig<AssetItem>
  @Output() streaming = new EventEmitter<DownloadableAssetItemStreamEvent>()

  constructor(private readonly _store: Store) {}

  onAnalytics(analyticsEvent: AnalyticsTrackEvent) {
    this._store.dispatch(analyticsTrackEvent(analyticsEvent))
  }
}
