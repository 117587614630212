import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { from, Observable, ReplaySubject } from 'rxjs'
import { flatMap } from 'rxjs/operators'

import { environment } from '@env'

const GRECAPTCHA = 'grecaptcha'
const ONLOAD_CALLBACK = 'recaptchaOnSuccess'

@Injectable()
export class RecaptchaService {
  public ready = new ReplaySubject<any>()

  constructor(private http: HttpClient) {
    this.registerCaptchaScript()
  }

  private getScriptUrl = (): string => {
    return `https://www.google.com/recaptcha/api.js?onload=${ONLOAD_CALLBACK}&render=${environment.RECAPTCHA_SITE_KEY}`
  }

  private registerCaptchaScript(): void {
    // If ReCaptcha is loaded, just call the callback, else binds callback globally so it can be accessible
    if (window[GRECAPTCHA]) {
      this.ready.next(window[GRECAPTCHA])
    } else {
      window[ONLOAD_CALLBACK] = () => this.ready.next(window[GRECAPTCHA])

      const scriptElem = document.createElement('script')
      scriptElem.innerHTML = ''
      scriptElem.src = this.getScriptUrl()
      scriptElem.async = true
      scriptElem.defer = true

      // add script to header
      document.getElementsByTagName('head')[0].appendChild(scriptElem)
    }
  }

  execute(action: string): Observable<{ score: number }> {
    return this.ready.pipe(
      flatMap((grecaptcha) =>
        from(grecaptcha.execute(environment.RECAPTCHA_SITE_KEY, { action: action })).pipe(
          flatMap((response: string) => {
            return this.http.get<any>(environment.API.COMMERCIAL.RECAPTCHA_VERIFY, {
              params: { response },
            })
          }),
        ),
      ),
    )
  }
}
