import { AssetsState } from '@core/state/models/assets.state'
import { Action, createReducer, on } from '@ngrx/store'
import { setAssets, setVersions } from '@core/state/actions/assets.actions'
import { REFRESH_TIME } from '@core/constants/time.constants'

const initialState: AssetsState = {
  versions: undefined,
  assets: undefined,
  requestTimestamp: undefined,
  ttl: REFRESH_TIME.PER_DAY * 1000,
}

const reducer = createReducer(
  initialState,
  on(setVersions, (state, { versions }) => ({
    ...state,
    versions,
    requestTimestamp: new Date().getTime(),
  })),
  on(setAssets, (state, { assets }) => ({
    ...state,
    assets,
    requestTimestamp: new Date().getTime(),
  })),
)

export function assetsReducer(state: AssetsState | undefined, action: Action) {
  return reducer(state, action)
}
