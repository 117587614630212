<section
  *ngIf="downloads"
  class="mcp-downloadable-block"
  [class.fixed-width]="downloads.length <= 2"
  [attr.data-cy]="'downloads'"
>
  <div class="mcp-downloadable-block__block" *ngFor="let download of downloads">
    <img
      *ngIf="download.src"
      class="mcp-downloadable-block__sample"
      [src]="download.src | safe: 'resourceUrl'"
      [alt]="download.title | translate"
    />
    <div class="mcp-downloadable-block__header">
      <img
        class="mcp-downloadable-block__product"
        [src]="download.logo | safe: 'resourceUrl'"
        [alt]="download.title | translate"
      />
    </div>

    <div class="mcp-downloadable-block__asset-container" *ngFor="let asset of download.assetGroup">
      <mcp-downloadable-asset-container
        *ngIf="asset"
        [assetGroup]="asset"
        (streaming)="onStreaming($event)"
      />
    </div>
  </div>
</section>
