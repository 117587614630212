import { NgModule } from '@angular/core'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { I18nApi } from '@core/i18n/api/i18n.api'
import { EffectsModule } from '@ngrx/effects'
import { I18nEffects } from '@core/i18n/state/effects/i18n.effects'
import { StoreModule } from '@ngrx/store'
import { FEATURE_TOKEN } from '@core/i18n/constants/i18n.constants'
import { i18nReducer } from '@core/i18n/state/reducers/i18n.reducer'

@NgModule({
  imports: [
    StoreModule.forFeature(FEATURE_TOKEN, i18nReducer),
    EffectsModule.forFeature([I18nEffects]),
  ],
  providers: [I18nApi, TranslatorService],
})
export class I18nModule {}
