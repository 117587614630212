<div class="mcp-metric-list" [class.is-match-data]="isMatchData">
  <div class="mcp-metric-list__header">
    <mcui-dual-header
      [leftText]="metrics?.header?.leftContent?.title"
      [leftImage]="metrics?.header?.leftContent?.img"
      [leftSubtitleImage]="competition?.logo"
      [leftImagePlaceholder]="
        metrics?.header?.leftContent?.isPlayer ? playerPlaceholder : teamPlaceholder
      "
      [leftSubtitleImagePlaceholder]="competitionPlaceholder"
      [leftPills]="
        metrics?.header?.leftContent?.subtitle && [
          metrics?.header?.leftContent?.subtitle | translate,
        ]
      "
      [rightPills]="
        metrics?.header?.rightContent?.subtitle && [
          metrics?.header?.rightContent?.subtitle | translate,
        ]
      "
      [rightText]="
        metrics?.header?.rightContent?.title === competitionIdentifier
          ? competition.officialName
          : metrics?.header?.rightContent?.title
      "
      [rightImage]="metrics?.header?.rightContent?.img"
      [rightSubtitleImage]="competition?.logo"
      [rightImagePlaceholder]="
        metrics?.header?.rightContent?.isPlayer
          ? playerPlaceholder
          : metrics?.header?.rightContent?.title === competitionIdentifier
            ? competitionPlaceholder
            : teamPlaceholder
      "
      [rightSubtitleImagePlaceholder]="competitionPlaceholder"
      [rightTriggerIcon]="rightTriggerIcon"
      [centerText]="centerText"
      (rightTriggerClick)="aggregationModeClick.emit()"
      (centerTriggerClick)="aggregationModeClick.emit()"
    />
  </div>
  <div class="mcp-metric-list__content">
    <div
      class="mcp-metric-list__metric grid"
      *ngFor="let metric of metrics?.list; last as isLast"
      [class.last]="isLast"
    >
      <mcui-metric-item
        [title]="metric?.text | translate"
        [leftValue]="$any(metric?.homeValue | invalidValueResolver)"
        [rightValue]="$any(metric?.awayValue || {} | invalidValueResolver)"
        [leftTextValue]="$any(metric?.homeValue | number: '1.0-2' : lang | invalidValueResolver)"
        [rightTextValue]="$any(metric?.awayValue | number: '1.0-2' : lang | invalidValueResolver)"
      />
    </div>
  </div>
</div>
