import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostListener,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core'

import { debounceTime, tap } from 'rxjs/operators'

import { DefaultImageDirective, NoAbsoluteUrl } from '@mediacoach-ui-library/global'
import { PermissionsService } from '@core/services/permissions.service'

import { CardItem } from './card.models'
import { ScrollService } from '../../services/scroll.service'
import { TemplateDirective } from '../../directives/template/template.directive'
import { BaseCardComponent } from '@shared/components/base-card/base-card.component'
import {
  PLAYER_DEMARCATION_FALLBACK_KEY,
  PLAYER_DEMARCATION_KEYS,
} from '@core/constants/player.constants'

@Component({
  selector: 'mcp-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent extends BaseCardComponent<CardItem> implements AfterContentInit {
  @HostListener('mouseenter')
  onHoverOn() {
    this.isHovered = true
  }

  @HostListener('mouseleave')
  onHoverOff() {
    this.isHovered = false
  }

  private _cardImage: DefaultImageDirective // FIXME remove when fixed in library

  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY

  isHovered: boolean
  noAbsoluteUrl
  isRouteUrlPermitted
  isRouteUrlPermitted$

  contentTemplate: TemplateRef<any>
  footerTemplate: TemplateRef<any>

  @Input() isPlayer: boolean

  override get data(): CardItem {
    return this._data
  }

  @Input() override set data(_data) {
    this._data = _data

    if (this._data) {
      this.noAbsoluteUrl = NoAbsoluteUrl(_data.url)
      this.isRouteUrlPermitted$ = this._permissionsService
        .isRouteUrlPermitted$(this._data.url)
        .pipe(
          debounceTime(0),
          tap((isRouteUrlPermitted) => (this.isRouteUrlPermitted = isRouteUrlPermitted)),
        )
    }
  }

  // FIXME remove cardImage when fixed in library
  get cardImage() {
    return this._cardImage
  }

  @ViewChild(DefaultImageDirective, { static: true }) set cardImage(_cardImage) {
    this._cardImage = _cardImage
    if (_cardImage) {
      _cardImage.isImgLoaded = false
    }
  }

  @ContentChildren(TemplateDirective) templates: QueryList<TemplateDirective>

  constructor(
    private readonly scrollService: ScrollService,
    private readonly _permissionsService: PermissionsService,
  ) {
    super(scrollService)
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template
          break
        case 'footer':
          this.footerTemplate = item.template
          break
        default:
          this.contentTemplate = item.template
      }
    })
  }
}
