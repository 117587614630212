import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { DEFAULT_CAMERA_ASSET, LITERALS_FOR_VIDEO_TYPES } from '@core/constants/assets.constants'
import { CameraAsset } from '@core/models/dto/assets.dto'

@Component({
  selector: 'mcp-camera-selector',
  templateUrl: './camera-selector.component.html',
  styleUrls: ['./camera-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CameraSelectorComponent {
  private _cameraList: CameraAsset[]
  get cameraList() {
    return this._cameraList
  }

  @Input() set cameraList(list: CameraAsset[]) {
    let _list = list
    if (list) {
      this.isAllCameraDisabled = list.every(({ disabled }) => disabled)
      if (this.isLive || this.disabled || this.isAllCameraDisabled) {
        _list = [...DEFAULT_CAMERA_ASSET]
      }
    }
    this._cameraList = (_list || []).map((c) => ({
      ...c,
      translation: LITERALS_FOR_VIDEO_TYPES[c?.assetType],
    }))
  }

  @Input() selected: string
  @Input() disabled: boolean
  @Input() isLive: boolean
  @Input() extraClasses: string
  @Input() ref: any

  @Output() cameraChange = new EventEmitter()

  isAllCameraDisabled = false

  onCameraChange(value: unknown): void {
    this.cameraChange.emit(value)
  }
}
