import { SegmentOption } from '@core/models/models/common.models'
import { HeatMapType } from '@shared/components/heat-map/heat-map.models'

export const HEAT_MAP_DEFAULT_OPTIONS: SegmentOption[] = [
  {
    id: HeatMapType.Global,
    label: 'MTR_HEAT_MAP_GLOBAL',
  },
  {
    id: HeatMapType.Offensive,
    label: 'MTR_HEAT_MAP_OFFENSIVE',
  },
  {
    id: HeatMapType.Defensive,
    label: 'MTR_HEAT_MAP_DEFENSIVE',
  },
]
