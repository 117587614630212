export enum PlayerPosition {
  Goalkeeper = 'Goalkeeper',
  Defender = 'Defender',
  Midfielder = 'Midfielder',
  Forward = 'Forward',
}

export enum GroupWeight {
  Starter = 30,
  Separator = 20,
  Substitute = 10,
}
