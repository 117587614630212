import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router'
import { RouterStateSerializer } from '@ngrx/router-store'
import { MergedRoute } from '@core/router/models'
import { Injectable } from '@angular/core'

@Injectable()
export class MergedRouterStateSerializer implements RouterStateSerializer<MergedRoute> {
  private mergeRouteParams(
    route: ActivatedRouteSnapshot,
    getter: (r: ActivatedRouteSnapshot) => Params,
  ): Params {
    if (!route) {
      return {}
    }
    const currentParams = getter(route)
    const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild
    return { ...currentParams, ...this.mergeRouteParams(primaryChild, getter) }
  }

  private mergeRouteData(route: ActivatedRouteSnapshot): Data {
    if (!route) {
      return {}
    }
    const currentData = route.data
    const primaryChild = route.children.find((c) => c.outlet === 'primary') || route.firstChild
    return { ...currentData, ...this.mergeRouteData(primaryChild) }
  }
  serialize(routerState: RouterStateSnapshot): MergedRoute {
    const route = routerState.root
    return {
      url: routerState.url,
      params: this.mergeRouteParams(route, (r) => r.params),
      queryParams: this.mergeRouteParams(route, (r) => r.queryParams),
      data: this.mergeRouteData(route),
    }
  }
}
