import {
  ArrowDirection,
  Step,
  StepPosition,
  TourStepTexts,
} from '@shared/modules/tour/models/tour-step.models'
import {
  ASPECT_RATIO,
  DEFAULT_CLOSE_TEXT,
  DEFAULT_DONE_TEXT,
  DEFAULT_NEXT_TEXT,
  DEFAULT_PREV_TEXT,
  DEFAULT_PROGRESS_TEXT,
  ROUTE_SEPARATOR,
  STEP_MAX_WIDTH,
  STEP_MIN_WIDTH,
} from '@shared/modules/tour/constants/tour.constants'
import { TourError } from '@shared/modules/tour/models/tour-step-error.models'
import { isObservable, Observable, of } from 'rxjs'
import { ObservableCustomTexts } from '@shared/modules/tour/models/tour-options.models'
import { Size } from '@shared/modules/tour/models/tour-dom.models'

export const getFirstElementWithoutKeyword = (elements: Element[], keyword: string): Element => {
  while (elements[0] && elements[0].classList.toString().includes(keyword)) {
    elements.shift()
  }
  return elements[0]
}

export const getStepIndex = (steps: Step[], stepName: string): number => {
  const index = steps
    .map((step) =>
      step.id.includes(ROUTE_SEPARATOR) ? step.id.split(ROUTE_SEPARATOR)[0] : step.id,
    )
    .findIndex((name) => stepName === name)
  if (index === -1) {
    throw new TourError(`The step with name: ${stepName} does not exist in the step list.`)
  }
  return index
}

export const getStepName = (stepId: string): string =>
  stepId && stepId.includes(ROUTE_SEPARATOR) ? stepId.split(ROUTE_SEPARATOR)[0] : stepId

export const toObservable = (value: string | Observable<string>): Observable<string> =>
  value instanceof Observable ? value : of(value)

export const hexToRgb = (hex: string): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : null
}

export const getDimensionsByAspectRatio = (
  width: number,
  height: number,
  aspectRatio: number,
): Size => {
  const calcHeight = (width + height) / (1 + aspectRatio)
  const calcWidth = calcHeight * aspectRatio
  return {
    width: calcWidth,
    height: calcHeight,
  }
}

export const adjustDimensions = ({ width, height }: Size): Size => {
  const area = width * height
  let newWidth = width
  let newHeight = height
  if (width > STEP_MAX_WIDTH) {
    newWidth = STEP_MAX_WIDTH
    newHeight = area / newWidth
  } else if (width < STEP_MIN_WIDTH) {
    newWidth = STEP_MIN_WIDTH
    newHeight = STEP_MIN_WIDTH / ASPECT_RATIO
  }
  return {
    width: newWidth,
    height: newHeight,
  }
}

export const buildObservableCustomTexts = (texts: TourStepTexts): ObservableCustomTexts =>
  ({
    prev$: isObservable(texts?.prev) ? texts?.prev : of(texts?.prev || DEFAULT_PREV_TEXT),
    next$: isObservable(texts?.next) ? texts?.next : of(texts?.next || DEFAULT_NEXT_TEXT),
    done$: isObservable(texts?.done) ? texts?.done : of(texts?.done || DEFAULT_DONE_TEXT),
    close$: isObservable(texts?.close) ? texts?.close : of(texts?.close || DEFAULT_CLOSE_TEXT),
    progress$: isObservable(texts?.progress)
      ? texts?.progress
      : of(texts?.progress || DEFAULT_PROGRESS_TEXT),
  }) as ObservableCustomTexts

export const waitUntilIsRendered = (selector: string): Promise<void> =>
  new Promise((resolve) => {
    if (document.querySelector(selector) || !selector) {
      resolve()
    } else {
      let observer = new MutationObserver(() => {
        if (document.querySelector(selector)) {
          observer.disconnect()
          observer = null
          resolve()
        }
      })
      observer?.observe(document, { subtree: true, childList: true })
    }
  })

export const getArrowDirection = (position: StepPosition): ArrowDirection => {
  switch (position) {
    case 'top':
      return 'bottom'
    case 'left':
      return 'right'
    case 'right':
      return 'left'
    case 'bottom':
      return 'top'
    default:
      return null
  }
}
