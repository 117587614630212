import { ChangeDetectionStrategy, Component } from '@angular/core'
import { DialogConfig } from '@mediacoach/ui'
import { DialogSeasonCompetitionSelectorBase } from '../../base-classes/dialog-season-competition-selector.base'

@Component({
  selector: 'mcp-dialog-season-competition-selector',
  templateUrl: './dialog-season-competition-selector.component.html',
  styleUrl: './dialog-season-competition-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSeasonCompetitionSelectorComponent extends DialogSeasonCompetitionSelectorBase {
  constructor(_dialogConfig: DialogConfig) {
    super(_dialogConfig)
  }

  get hideSeasons() {
    return this._dialogConfig?.data?.hideSeasons
  }

  applySelection() {
    this._dialogRef.close({ ...this.selectedCompetition, seasonId: this.selectedSeason?.id })
  }
}
