import { Component } from '@angular/core'

import { ApplicationMenuConfig, ApplicationName, ApplicationUserProfileType } from '@mediacoach/ui'

import { Observable } from 'rxjs'

import { environment } from '@env'
import { DialogType } from '../dialog-application/dialog-application.models'
import { Store } from '@ngrx/store'
import { getApplications, getProfile } from '@core/state/selectors/user.selectors'
import { McpProfile } from '@auth/models/auth.dto'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ApplicationsComponent {
  userProfile$: Observable<McpProfile> = this._store.select(getProfile)
  applications$: Observable<ApplicationUserProfileType[]> = this._store
    .select(getApplications)
    .pipe(map((data) => data as ApplicationUserProfileType[]))

  applicationDialogType = DialogType
  userProfileDefault = {} as McpProfile
  appMenuConfig: ApplicationMenuConfig = {
    environment: environment.envType,
    application: ApplicationName.Portal,
    blacklist: environment.PROFILE_TYPE_BLACKLIST,
  }

  constructor(private readonly _store: Store) {}
}
