export enum FieldName {
  Name = 'name',
  Email = 'email',
  Customer = 'customer',
  Product = 'product',
  IssueType = 'issueType',
  OtherIssueType = 'otherIssueType',
  AppVersion = 'appVersion',
  Platform = 'platform',
  Browser = 'browser',
  OtherBrowser = 'otherBrowser',
  OSVersion = 'osVersion',
  Description = 'description',
  IssueFiles = 'issueFiles',
}

export enum Product {
  Desktop = 'Desktop',
  Live = 'Live',
  Mobile = 'Mobile',
  Portal = 'Portal',
  StaticReports = 'StaticReports',
  DynamicReports = 'DynamicReports',
  Vision = 'Vision',
  Wimu = 'Wimu',
  LivePro = 'LivePro',
}

export enum InputType {
  Text = 'Text',
  Textarea = 'Textarea',
  Dropdown = 'Dropdown',
  FileUpload = 'FileUpload',
  Divider = 'Divider',
}
