import { inject, Injectable } from '@angular/core'
import { ToastService } from '@mediacoach/ui'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { showToast } from '@core/state/actions/toast.actions'
import { map, switchMap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, of } from 'rxjs'

@Injectable()
export class ToastEffects {
  private readonly _toast = inject(ToastService)
  private readonly _translate = inject(TranslateService)
  private readonly _actions = inject(Actions)

  showToast$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(showToast),
        switchMap(({ toast }) => {
          return forkJoin(
            [toast.title, toast.message].map((text) =>
              text ? this._translate.get(text) : of(undefined),
            ),
          ).pipe(map(([title, message]) => ({ ...toast, title, message })))
        }),
        map(({ title, message, type }) =>
          this._toast.show({ title, message }, { type: type || 'success' }),
        ),
      ),
    { dispatch: false },
  )
}
