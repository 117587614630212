// In case of using more codes, draw them from org.springframework.security.oauth2.core OAuth2ErrorCodes
export enum OAuthErrorCode {
  AccessDenied = 'access_denied',
}

export enum RoutePermissionKey {
  MatchDetail = 'match-detail/.+',
  MonitoringHistory = 'monitoring/history',
  MonitoringHistoryDetail = 'monitoring/history/.+/.+',
  MediacoachVideo = 'videos',
  MediacoachVideoDetail = 'videos/.+',
  PlayerDetail = 'players/.+/teams/.+/competitions/.+/seasons/.+',
}

export enum ContentPermissionKey {
  // REPORTS
  PreMatchReport = 'MPM_CONTENT_REPORT_PREMATCH',
  PhysicalReport = 'MPM_CONTENT_REPORT_PHY',
  PostMatchTeamsReport = 'MPM_CONTENT_REPORT_POSTMATCH_TEAMS',
  PostMatchPlayersReport = 'MPM_CONTENT_REPORT_POSTMATCH_PLAYERS',
  AccumulatedTeamsReport = 'MPM_CONTENT_REPORT_ACCUMULATED_TEAMS',
  MediaReport = 'MPM_CONTENT_REPORT_MEDIA',
  AdvancePreMatchReport = 'MPM_CONTENT_REPORT_ADVANCED_PREMATCH',
  AccumulatedPlayersReport = 'MPM_CONTENT_REPORT_ACCUMULATED_PLAYERS',
  ReportMaxExigency = 'MPM_CONTENT_REPORT_MAXIMUM_EXIGENCY',
  BeyondStats = 'MPM_CONTENT_REPORT_BEYOND_STATS',

  // VIDEO
  LiveVideo = 'MPM_CONTENT_VIDEO_TAC',
  TacDownloadable = 'MPM_CONTENT_VIDEO_DOWNLOADABLE_TAC',
  TvDownloadable = 'MPM_CONTENT_VIDEO_DOWNLOADABLE_TV',
  PanDownloadable = 'MPM_CONTENT_VIDEO_DOWNLOADABLE_PAN',
  WarmUp = 'MPM_CONTENT_VIDEO_DOWNLOADABLE_WARMUP',
}
