import { AssetMatch, Match } from '@core/models/dto/match.dto'
import { TimelineConfigDto } from '@core/models/dto/timeline.dto'
import {
  ConnectionCoordinateType,
  Marker,
  StatTableDataMode,
  VideoType,
} from '@mediacoach-ui-library/global'
import { StreamType } from '@core/models/dto/assets.dto'
import { PlaylistState } from '@core/state/models/playlist.state'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import {
  DEFAULT_ALL_FILTERS,
  DEFAULT_OTHER_FILTERS,
} from '@features/playlist/constants/playlist.constants'
import { ComparablePlayer, ComparedPlayers } from '@core/models/dto/player.dto'
import { MetricAggregation } from '@core/enums/metric.enums'
import { MetricAggregationModeSelectable } from '@core/models/models/metric.models'
import { MATCH_CONTEXT_AGGREGATION_MODES } from '@core/constants/metric-aggregation.constants'
import { TabsSelectedData } from '@core/models/models/match.models'
import { LineUpMode } from '@features/matches/enums/matches.enum'
import { TeamType } from '@mediacoach/ui'
import { HeatMapType } from '@shared/components/heat-map/heat-map.models'

export interface MarkersGroup {
  [VideoType.Tactical]: Marker[]
  [VideoType.Tv]: Marker[]
  [VideoType.Panoramic]: Marker[]
}

export interface StreamState {
  currentMatch: AssetMatch
  stream: Match
  matchLoader: boolean
  playerMetrics: any
  teamMetrics: any
  teamMetricsLoader: boolean
  timelineConfig: TimelineConfigDto
  timelineLoader: boolean
  markers: MarkersGroup
  passMatrix: any
  passMatrixLoader: boolean
  heatMap: any
  heatMapLoader: boolean
  selectedPlayer: ComparablePlayer
  playerLoader: boolean
  comparison: ComparedPlayers
  comparisonLoader: boolean
  playerMetricsLoader: boolean
  streamType: StreamType
  pdfLoader: boolean
  isPlaylistTabSelected?: boolean
  playlist: PlaylistState
  aggregationMode: MetricAggregation
  aggregationModes: MetricAggregationModeSelectable[]
  tabsSelectedData: TabsSelectedData
  playerQuery?: any
  playerComparisonQuery?: any
}

const playlistInitialState: PlaylistState = {
  playlists: null,
  tags: null,
  codes: null,
  dimensions: null,
  collaborators: undefined,
  playlistLoader: true,
  playlistFilterLoader: false,
  isDeletingTagItem: false,
  isChangingPlaylist: false,
  filterConfig: {
    [FilterType.All]: DEFAULT_ALL_FILTERS,
    [FilterType.Other]: DEFAULT_OTHER_FILTERS,
  },
}

export const initialStreamState: StreamState = {
  currentMatch: undefined,
  matchLoader: true,
  playerMetrics: undefined,
  teamMetrics: undefined,
  teamMetricsLoader: true,
  timelineConfig: undefined,
  markers: undefined,
  timelineLoader: true,
  passMatrix: undefined,
  passMatrixLoader: true,
  heatMap: undefined,
  heatMapLoader: true,
  selectedPlayer: undefined,
  playerLoader: true,
  comparison: undefined,
  comparisonLoader: false,
  playerMetricsLoader: true,
  stream: undefined,
  streamType: undefined,
  pdfLoader: false,
  isPlaylistTabSelected: false,
  playlist: playlistInitialState,
  aggregationMode: MetricAggregation.MatchValues,
  aggregationModes: MATCH_CONTEXT_AGGREGATION_MODES,
  tabsSelectedData: {
    lineUpMode: LineUpMode.Field,
    teamType: TeamType.Home,
    playerMetricsTableAggregation: StatTableDataMode.Match,
    passMatrixSegmentValue: ConnectionCoordinateType.Target,
    heatMapSegmentValue: HeatMapType.Global,
    playerPassMatrixSegmentValue: ConnectionCoordinateType.Target,
    playerHeatMapSegmentValue: HeatMapType.Global,
    passPlayerNumber: null,
  },
}
