import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { LANG_STANDARD_MAP } from '@core/i18n/constants/i18n.constants'
import { Cacheable } from '@shared/decorators/cacheable.decorator'
import { REFRESH_TIME } from '@core/constants/time.constants'
import { Translations } from '@core/i18n/models/i18n.models'

declare const I18N_HASH: string

@Injectable()
export class I18nApi {
  constructor(private readonly _http: HttpClient) {}

  @Cacheable(REFRESH_TIME.THIRTY_MIN * 1000)
  fetchTranslations(lang: string): Observable<Translations> {
    return this._http.get<Translations>(
      environment.INTEGRATED_API.TRANSLATIONS(LANG_STANDARD_MAP[lang]) + '?v=' + I18N_HASH,
    )
  }
}
