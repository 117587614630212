import { BackOffPolicy } from './enums/retryable.enums'
import {
  MaxAttemptsError,
  RetryOptions,
} from '@shared/decorators/retryable/models/retryable.models'
import { sleep } from '@shared/decorators/retryable/utils/retryable.utils'

/**
 * Original source: https://github.com/vcfvct/typescript-retry-decorator
 *
 * @param options the 'RetryOptions'
 */
export function retryable(options: RetryOptions): MethodDecorator {
  /**
   * target: The prototype of the class (Object)
   * propertyKey: The name of the method (string | symbol).
   * descriptor: A TypedPropertyDescriptor — see the type, leveraging the Object.defineProperty under the hood.
   *
   * NOTE: It's very important here we do not use arrow function otherwise 'this' will be messed up due
   * to the nature how arrow function defines this inside.
   *
   */
  return function (
    target: Record<string, any>,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>,
  ) {
    const originalFn: Function = descriptor.value
    // set default value for ExponentialBackOffPolicy
    if (options.backOffPolicy === BackOffPolicy.ExponentialBackOffPolicy) {
      setExponentialBackOffPolicyDefault()
    }
    descriptor.value = async function (...args: any[]) {
      try {
        return await retryAsync.apply(this, [
          originalFn,
          args,
          options.maxAttempts,
          options.backOff,
        ])
      } catch (e) {
        if (e instanceof MaxAttemptsError) {
          const msgPrefix = `Failed for '${propertyKey}' for ${options.maxAttempts} times.`
          e.message = e.message ? `${msgPrefix} Original Error: ${e.message}` : msgPrefix
        }
        throw e
      }
    }
    return descriptor
  }

  async function retryAsync(
    fn: Function,
    args: any[],
    maxAttempts: number,
    backOff?: number,
  ): Promise<any> {
    try {
      return await fn.apply(this, args)
    } catch (e) {
      if (--maxAttempts < 0 && e.message) {
        console.error(e.message)
        throw new MaxAttemptsError(e.message)
      }
      if (!canRetry(e)) {
        throw e
      }
      if (backOff) {
        await sleep(backOff)
      }
      if (options.backOffPolicy === BackOffPolicy.ExponentialBackOffPolicy) {
        const newBackOff: number = backOff * options.exponentialOption.multiplier
        backOff =
          newBackOff > options.exponentialOption.maxInterval
            ? options.exponentialOption.maxInterval
            : newBackOff
      }
      return retryAsync.apply(this, [fn, args, maxAttempts, backOff])
    }
  }

  function canRetry(e: Error): boolean {
    if (options.doRetry && !options.doRetry(e)) {
      return false
    }
    return !(
      options.value &&
      options.value.length &&
      !options.value.some((errorType) => e instanceof errorType)
    )
  }

  function setExponentialBackOffPolicyDefault(): void {
    if (!options.backOff) {
      options.backOff = 1000
    }

    options.exponentialOption = {
      ...{ maxInterval: 2000, multiplier: 2 },
      ...options.exponentialOption,
    }
  }
}
