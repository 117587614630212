export enum TableType {
  Default = 'default',
  DefaultNoAssets = 'defaultNoAssets',
  WithCompetition = 'withCompetition',
  WithCompetitionNoAssets = 'withCompetitionNoAssets',
}

export enum LiveClipStatus {
  CREATING = 'CREATING',
  CREATED = 'CREATED',
  RUNNING = 'RUNNING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  DELETING = 'DELETING',
}

export enum TabType {
  Alignment = 'alignment',
  Data = 'data',
  Files = 'files',
  PlayList = 'playlist',
}

export enum TabTypeIndex {
  Alignment,
  Data,
  Playlist,
  Files,
}

export enum LineUpMode {
  Field = 'field',
  List = 'list',
}

export enum MatchMode {
  PlayerDetail = 'playerDetail',
  PlayerCompare = 'playerCompare',
}
