import { createFeatureSelector, createSelector } from '@ngrx/store'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { SelectionState } from '@core/state/models/selection.state'
import { Match } from '@core/models/dto/match.dto'

export const selectSelection = createFeatureSelector<SelectionState>(StoreToken.selection)

export const getSelectedMatchList = createSelector(
  selectSelection,
  (state: SelectionState): Match[] => state.matches,
)
