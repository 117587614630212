<app-modal
  size="incidence"
  #modal
  [modalHeader]="incidenceHeader"
  [modalBody]="incidenceBodyTemplate"
>
  <ng-template #incidenceHeader>
    {{ 'MATCHES_INCIDENCE_TITLE' | translate }}
  </ng-template>
  <ng-template #incidenceBodyTemplate>
    <div class="c-incidence-modal__body" *ngIf="match">
      <mcp-match-item
        [match]="match"
        direction="vertical"
        [templates]="matchTemplates"
      ></mcp-match-item>
      <div class="c-incidence-modal__body-detail" *ngIf="match.incidence">
        <div class="c-incidence-modal">
          <div class="c-incidence-modal__item">
            <span class="c-incidence-modal__icon">!</span>
          </div>
        </div>
        <div class="c-incidence-modal__body-detail-title">
          {{ 'MATCHES_INCIDENCE_DETAIL' | translate }}
        </div>
        <p
          class="c-incidence-modal__body-detail-text"
          [innerText]="match.incidence[incidenceMessageKeys[language$ | async]]"
        ></p>
      </div>
    </div>
  </ng-template>
</app-modal>
