import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { Scroll } from '../../models/tour-dom.models'
import { TourStep } from '../../models/tour-step.models'
import {
  BACKDROP_CONTENT_STYLES,
  BACKDROP_MIDDLE_CONTENT_STYLES,
  CURRENT_BACKDROP_STYLES,
  TOP_MIDDLE_BACKDROP_STYLES,
} from '@shared/modules/tour/constants/backdrop.constants'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { DomService } from '@shared/modules/tour/services/dom/dom.service'
import { getElementFixedLeft, getElementFixedTop } from '../../utils/dom.utils'

@Injectable({ providedIn: 'root' })
export class TourBackdropService {
  private readonly _renderer: Renderer2
  private _lastBackdropContainer: Element
  private _backdropTop: Element
  private _leftBackdrop: Element
  private _backdropMiddleContainer: Element
  private _targetBackdrop: Element
  private _targetAbsoluteTop: number
  private _targetAbsoluteLeft: number
  private _lastXScroll = 0
  private _lastYScroll = 0
  private _element: HTMLElement
  private _options: TourOptions

  constructor(
    private readonly _dom: DomService,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer = this._rendererFactory.createRenderer(null, null)
  }

  setup(options: TourOptions): void {
    this._options = options
  }

  draw(step: TourStep) {
    this._element = document.querySelector(step.selector)
    this._targetAbsoluteTop = this._getTargetTotalTop(step)
    this._targetAbsoluteLeft = this._getTargetTotalLeft(step)

    const { content, container } = this._buildMainBackdrop(step)
    this._renderer.appendChild(container, content)

    const { top, bottom } = this._buildVerticalSurroundingBackdrop(step)
    const { middle, target, left } = this._buildMiddleBackdrop(step)

    this._backdropTop = top
    this._leftBackdrop = left
    this._backdropMiddleContainer = middle
    this._targetBackdrop = target

    this._renderer.appendChild(content, top)
    this._renderer.appendChild(content, middle)
    this._renderer.appendChild(content, bottom)

    this._removeLastBackdrop()
    this._drawCurrentBackdrop(container)
    this._lastBackdropContainer = container
  }

  remove() {
    this._removeLastBackdrop()
  }

  redrawTarget(step: TourStep) {
    this._targetAbsoluteLeft = this._getTargetTotalLeft(step)
    this._targetAbsoluteTop = this._getTargetTotalTop(step)
    this._handleVerticalScroll(step)
    this._handleHorizontalScroll(step)
  }

  redraw(step: TourStep, scroll: Scroll) {
    if (this._lastYScroll !== scroll.scrollY) {
      this._lastYScroll = scroll.scrollY
      if (this._element) {
        this._handleVerticalScroll(step)
      }
    }
    if (this._lastXScroll !== scroll.scrollX) {
      this._lastXScroll = scroll.scrollX
      if (this._element) {
        this._handleHorizontalScroll(step)
      }
    }
  }

  private _buildMainBackdrop(step): { content: Element; container: Element } {
    const container = this._renderer.createElement('div')
    this._renderer.addClass(container, 'backdrop-container')
    this._renderer.setAttribute(container, 'id', 'backdrop-' + step.name)
    this._handleBackdropStaticStyles(container, CURRENT_BACKDROP_STYLES)

    const content = this._renderer.createElement('div')
    this._renderer.addClass(content, 'backdrop-content')
    this._handleBackdropStaticStyles(content, BACKDROP_CONTENT_STYLES)
    return { content, container }
  }

  private _buildVerticalSurroundingBackdrop(step: TourStep): { top: Element; bottom: Element } {
    const top = this._renderer.createElement('div')
    this._renderer.addClass(top, 'mcp-tour-backdrop')
    this._renderer.addClass(top, 'backdrop-top')
    this._handleBackdropStaticStyles(top, TOP_MIDDLE_BACKDROP_STYLES)
    this._renderer.setStyle(top, 'height', this._targetAbsoluteTop - this._lastYScroll + 'px')
    this._renderer.setStyle(
      top,
      'background-color',
      step.hideBackdrop ? 'transparent' : `rgba(${this._options.backdropColor}, 0.7)`,
    )
    this._renderer.setStyle(
      top,
      'backdrop-filter',
      step.hideBackdrop ? 'none' : `blur(${this._options.blurIntensity})`,
    )

    const bottom = this._renderer.createElement('div')
    this._renderer.addClass(bottom, 'mcp-tour-backdrop')
    this._renderer.addClass(bottom, 'backdrop-bottom')
    this._renderer.setStyle(bottom, 'width', '100%')
    this._renderer.setStyle(bottom, 'height', '100%')
    this._renderer.setStyle(
      bottom,
      'background-color',
      step.hideBackdrop ? 'transparent' : `rgba(${this._options.backdropColor}, 0.7)`,
    )
    this._renderer.setStyle(
      bottom,
      'backdrop-filter',
      step.hideBackdrop ? 'none' : `blur(${this._options.blurIntensity})`,
    )

    return { top, bottom }
  }

  private _buildMiddleBackdrop(step: TourStep): {
    middle: Element
    left: Element
    target: Element
  } {
    const middle = this._renderer.createElement('div')
    this._renderer.addClass(middle, 'backdrop-middle-container')
    this._handleBackdropStaticStyles(middle, TOP_MIDDLE_BACKDROP_STYLES)
    this._renderer.setStyle(middle, 'height', this._element.offsetHeight + 'px')

    const middleContent = this._renderer.createElement('div')
    this._renderer.addClass(middleContent, 'backdrop-middle-content')
    this._handleBackdropStaticStyles(middleContent, BACKDROP_MIDDLE_CONTENT_STYLES)
    this._renderer.appendChild(middle, middleContent)

    const left = this._renderer.createElement('div')
    this._renderer.addClass(left, 'mcp-tour-backdrop')
    this._renderer.addClass(left, 'backdrop-left')
    this._renderer.setStyle(left, 'flex-shrink', '0')
    this._renderer.setStyle(left, 'width', this._targetAbsoluteLeft - this._lastXScroll + 'px')
    this._renderer.setStyle(
      left,
      'background-color',
      step.hideBackdrop ? 'transparent' : `rgba(${this._options.backdropColor}, 0.7)`,
    )
    this._renderer.setStyle(
      left,
      'backdrop-filter',
      step.hideBackdrop ? 'none' : `blur(${this._options.blurIntensity})`,
    )
    this._renderer.appendChild(middleContent, left)

    const target = this._renderer.createElement('div')
    this._renderer.addClass(target, 'backdrop-target')
    this._renderer.setStyle(target, 'flex-shrink', '0')
    this._renderer.setStyle(target, 'width', this._element.offsetWidth + 'px')
    this._renderer.appendChild(middleContent, target)

    const right = this._renderer.createElement('div')
    this._renderer.addClass(right, 'mcp-tour-backdrop')
    this._renderer.addClass(right, 'backdrop-right')
    this._renderer.setStyle(right, 'width', '100%')
    this._renderer.setStyle(
      right,
      'background-color',
      step.hideBackdrop ? 'transparent' : `rgba(${this._options.backdropColor}, 0.7)`,
    )
    this._renderer.setStyle(
      right,
      'backdrop-filter',
      step.hideBackdrop ? 'none' : `blur(${this._options.blurIntensity})`,
    )
    this._renderer.appendChild(middleContent, right)

    return { middle, left, target }
  }

  private _getTargetTotalTop(step: TourStep) {
    return step.isElementOrAncestorFixed
      ? getElementFixedTop(this._element)
      : this._dom.getElementAbsoluteTop(this._element)
  }

  private _getTargetTotalLeft(step: TourStep) {
    return step.isElementOrAncestorFixed
      ? getElementFixedLeft(this._element)
      : this._dom.getElementAbsoluteLeft(this._element)
  }

  private _handleHorizontalScroll(step: TourStep) {
    const newBackdropLeftWidth = step.isElementOrAncestorFixed
      ? this._targetAbsoluteLeft
      : this._targetAbsoluteLeft - this._lastXScroll

    if (newBackdropLeftWidth >= 0) {
      this._renderer.setStyle(this._leftBackdrop, 'width', newBackdropLeftWidth + 'px')
      this._renderer.setStyle(this._targetBackdrop, 'width', this._element.offsetWidth + 'px')
    } else {
      this._handleTargetPartialWidth(newBackdropLeftWidth)
    }
  }

  private _handleVerticalScroll(step: TourStep) {
    const newBackdropTopHeight = step.isElementOrAncestorFixed
      ? this._targetAbsoluteTop
      : this._targetAbsoluteTop - this._lastYScroll

    if (newBackdropTopHeight >= 0) {
      this._renderer.setStyle(this._backdropTop, 'height', newBackdropTopHeight + 'px')
      this._renderer.setStyle(
        this._backdropMiddleContainer,
        'height',
        this._element.offsetHeight + 'px',
      )
    } else {
      this._handleTargetPartialHeight(newBackdropTopHeight)
    }
  }

  private _handleTargetPartialWidth(newBackdropLeftWidth: number) {
    this._renderer.setStyle(this._leftBackdrop, 'width', 0 + 'px')
    const visibleTargetWidth = this._element.offsetWidth + newBackdropLeftWidth
    if (visibleTargetWidth >= 0) {
      this._renderer.setStyle(this._targetBackdrop, 'width', visibleTargetWidth + 'px')
    } else {
      this._renderer.setStyle(this._targetBackdrop, 'width', 0 + 'px')
    }
  }

  private _handleTargetPartialHeight(newBackdropTopHeight: number) {
    this._renderer.setStyle(this._backdropTop, 'height', 0 + 'px')
    const visibleTargetHeight = this._element.offsetHeight + newBackdropTopHeight
    if (visibleTargetHeight >= 0) {
      this._renderer.setStyle(this._backdropMiddleContainer, 'height', visibleTargetHeight + 'px')
    } else {
      this._renderer.setStyle(this._backdropMiddleContainer, 'height', 0 + 'px')
    }
  }

  private _removeLastBackdrop() {
    this._renderer.removeClass(document.body, 'no-scroll-y')
    if (this._lastBackdropContainer) {
      this._renderer.removeChild(document.body, this._lastBackdropContainer)
      this._lastBackdropContainer = undefined
    }
  }

  private _drawCurrentBackdrop(backdrop: Element) {
    this._renderer.listen(backdrop, 'click', (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
    })
    this._renderer.addClass(document.body, 'no-scroll-y')
    this._renderer.appendChild(document.body, backdrop)
  }

  private _handleBackdropStaticStyles(
    backdropEl: Element,
    styles: Partial<CSSStyleDeclaration>,
  ): void {
    // eslint-disable-next-line guard-for-in
    for (const cssRule in styles) {
      this._renderer.setStyle(backdropEl, cssRule, styles[cssRule])
    }
  }
}
