import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { Observable, of } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { TourStep, TourStepInfo } from '@shared/modules/tour/models/tour-step.models'
import { TourStepService } from '@shared/modules/tour/services/tour-step/tour-step.service'
import { TourStepsContainerService } from '@shared/modules/tour/services/tour-steps-container/tour-steps-container.service'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TourService {
  private _tourInProgress = false
  private _tour$: Observable<TourStepInfo>
  private _options: TourOptions

  constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    private readonly _stepContainer: TourStepsContainerService,
    private readonly _stepService: TourStepService,
  ) {}

  startTour(steps: TourStep[], options?: Partial<TourOptions>): Observable<TourStepInfo> {
    if (!isPlatformBrowser(this._platformId)) {
      return of(new TourStepInfo())
    }
    if (!this._tourInProgress) {
      this._options = new TourOptions(options)
      this._stepContainer.setup(steps, this._options)
      this._tourInProgress = true

      this._tour$ = this._stepService.startTour(this._options).pipe(
        finalize(() => {
          this._stepService.cleanUp()
          this._tourInProgress = false
        }),
      )
      this._tour$.pipe(untilDestroyed(this)).subscribe()
    }
    return this._tour$
  }

  closeTour(): void {
    if (this.isTourInProgress()) {
      this._stepService.close()
    }
  }

  isTourInProgress(): boolean {
    return this._tourInProgress
  }
}
