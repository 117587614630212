import { SafeObjectData, sortBy, Static, StaticOrientation } from '@mediacoach-ui-library/global'
import { Asset, AssetDownload, AssetDownloadItem } from '@core/models/dto/assets.dto'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { ASSETS_TO_SANITIZE } from '@core/constants/assets.constants'

import { ApiMatchAsset } from '@core/models/models/match.models'

export const mapMacDesktopVersion = ({
  updates,
}: {
  updates: Record<string, unknown>
}): { Version: string } => ({
  Version: SafeObjectData(updates, 'osx.latest-version', ''),
})

export const getSanitizedUrl = (asset: Asset, sanitizer: DomSanitizer): SafeUrl =>
  ASSETS_TO_SANITIZE.includes(asset.friendlyName)
    ? sanitizer.bypassSecurityTrustUrl(asset.SUR as string)
    : asset.SUR

export const mapAssetsDictionary = (
  dictionaryAssets: Record<string, Asset[]>,
  sanitizer: DomSanitizer,
): AssetDownload =>
  Object.keys(dictionaryAssets).reduce((prev, key) => {
    const dictionaryAssetsArr = sortBy(dictionaryAssets[key])
    return {
      ...prev,
      [key]: dictionaryAssetsArr.reduce((prevAsset, current) => {
        const asset = { ...current, SUR: getSanitizedUrl(current, sanitizer) }
        const languages = asset.lang.split('_')
        return {
          ...prevAsset,
          ...languages.reduce(
            (obj, lang) => ({
              ...obj,
              [lang]: [...(prevAsset[lang] || []), asset],
            }),
            {},
          ),
        }
      }, {} as AssetDownloadItem),
    }
  }, {} as AssetDownload)

export const parseTextFileToObject = (
  text: string,
  keyValueSeparator = '=',
  lineSeparator = '\n',
): Record<string, string> =>
  text.split(lineSeparator).reduce((obj, line) => {
    const [key, value] = line.split(keyValueSeparator).map((val) => val.trim())
    return {
      ...obj,
      ...(key != null && value != null ? { [key]: value } : {}),
    }
  }, {})

export const getAssetByQualifier = (
  assetList: ApiMatchAsset[],
  qualifierValue: string,
): ApiMatchAsset | null => {
  for (const asset of assetList) {
    if (asset.qualifiers.find((q) => q.value === qualifierValue)) {
      return asset
    }
  }
  return null
}

export const getStaticItem = (statics: Static[], predicate: (staticItem: Static) => boolean) =>
  ((statics || []).find((s) => predicate(s)) as any)?.url

export const whiteLogoPredicate = (staticItem: Static): boolean =>
  staticItem.orientation === StaticOrientation.Portrait &&
  staticItem.size === 'High' &&
  !staticItem.isColor

export const whiteLogoLandscapePredicate = (staticItem: Static): boolean =>
  staticItem.orientation === StaticOrientation.Landscape &&
  staticItem.size === 'High2x' &&
  !staticItem.isColor

export const smallColorLogoPredicate = (staticItem: Static): boolean =>
  staticItem.orientation === StaticOrientation.Portrait &&
  staticItem.size === 'Small' &&
  staticItem.isColor

export const mediumColorLogoPredicate = (staticItem: Static): boolean =>
  staticItem.orientation === StaticOrientation.Portrait &&
  staticItem.size === 'Medium' &&
  staticItem.isColor

export const smallColorLandscapeLogoPredicate = (staticItem: Static): boolean =>
  staticItem.orientation === StaticOrientation.Landscape &&
  staticItem.size === 'Small' &&
  staticItem.isColor
