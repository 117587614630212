<div *ngIf="query.placeholder && query.controlType === 'text'" class="c-filter__title">
  {{ query.placeholder | translate }}
</div>
<ng-container [ngSwitch]="query.controlType">
  <mcp-query-dropdown
    *ngSwitchCase="'dropdown'"
    [query]="query"
    [control]="control"
    (queryChange)="queryChange.emit($event)"
  ></mcp-query-dropdown>
  <mcp-query-multiselect
    *ngSwitchCase="'multiselect'"
    [query]="query"
    [control]="control"
    (queryChange)="queryChange.emit($event)"
  ></mcp-query-multiselect>
  <mcp-query-text
    *ngSwitchCase="'text'"
    [query]="query"
    [control]="control"
    (queryChange)="queryChange.emit($event)"
  ></mcp-query-text>
  <!--    other field types-->
</ng-container>
