import { Component, EventEmitter, Output } from '@angular/core'

import { DispatcherCardItem } from './dispatcher-card.models'
import { ScrollService } from '@shared/services/scroll.service'
import { BaseCardComponent } from '@shared/components/base-card/base-card.component'

@Component({
  selector: 'mcp-dispatcher-card',
  templateUrl: './dispatcher-card.component.html',
  styleUrls: ['./dispatcher-card.component.scss'],
})
export class DispatcherCardComponent extends BaseCardComponent<DispatcherCardItem> {
  @Output() onclick: EventEmitter<DispatcherCardItem> = new EventEmitter<DispatcherCardItem>()

  constructor(private readonly scrollService: ScrollService) {
    super(scrollService)
  }
}
