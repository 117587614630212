import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { Season } from '@core/models/dto/season.dto'

@Injectable()
export class SeasonApi {
  constructor(private readonly _http: HttpClient) {}

  fetchSeasons(competitionOrderCriteriaId?): Observable<{ seasons: Season[] }> {
    return this._http.get<{ seasons: Season[] }>(
      environment.INTEGRATED_API.SEASONS() +
        `${
          competitionOrderCriteriaId
            ? `?competitionOrderCriteriaId=${competitionOrderCriteriaId}`
            : ''
        }`,
    )
  }
}
