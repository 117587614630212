import { UrlDeepLength } from '@mediacoach-ui-library/global'

export const checkReplaceUrlMatchers = (urlMatchers: string[], replaceUrl: string) =>
  urlMatchers
    ?.map((routeUrl: string) => ({
      regex: new RegExp(`^(?!${routeUrl}/)${routeUrl}(\\?.+)?$`),
      deepLength: UrlDeepLength(routeUrl),
    }))
    .some(
      ({ deepLength, regex }) => regex.test(replaceUrl) && deepLength === UrlDeepLength(replaceUrl),
    )
