import { MenuItem } from '@mediacoach/ui'

const findImmediateParent = (menu: MenuItem[], targetId: string): MenuItem | null => {
  for (const item of menu) {
    if (item.children) {
      const childWithTargetId = item.children.find((child) => child.id === targetId)
      if (childWithTargetId) {
        return item
      } else {
        const parent = findImmediateParent(item.children, targetId)
        if (parent) {
          return parent
        }
      }
    }
  }
  return null
}

export const markAsActive = (menu: MenuItem[], targetId: string): MenuItem[] => {
  if (menu?.length) {
    const updatedMenu = menu.map((item) => ({ ...item }))
    const immediateParent = findImmediateParent(updatedMenu, targetId)

    if (!immediateParent || !immediateParent.children) {
      return updatedMenu
    }

    const parentIndex = updatedMenu.findIndex((item) => item.id === immediateParent.id)

    const children = updatedMenu[parentIndex].children
    if (children) {
      updatedMenu[parentIndex].children = children.map((child) => ({
        ...child,
        active: child.id === targetId,
      }))
    }

    return updatedMenu
  }

  return menu
}
