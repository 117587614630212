import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { I18nApi } from '@core/i18n/api/i18n.api'
import { Store } from '@ngrx/store'
import {
  fetchTranslations,
  setTranslations,
  setTranslationsLoaded,
  useLanguage,
} from '@core/i18n/state/actions/i18n.actions'
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import { TranslatorService } from '@mediacoach-ui-library/global'
import { getDistinctSimplifiedLang } from '@core/state/selectors/user.selectors'

@Injectable()
export class I18nEffects {
  fetchTranslations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fetchTranslations),
      exhaustMap(({ lang }) =>
        this._api.fetchTranslations(lang).pipe(
          map((translations) => setTranslations(translations || { translations: {} })),
          catchError((err) => {
            console.error(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

  setTranslations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setTranslations),
      switchMap(({ translations }) =>
        this._store.pipe(
          getDistinctSimplifiedLang(),
          map((lang) => ({ lang, translations })),
          take(1),
        ),
      ),
      switchMap(({ translations, lang }) =>
        this._translate.getTranslation(lang).pipe(
          map((prevTranslations) => ({
            prevTranslations: {
              ...prevTranslations,
              ...(this._translator.translations[lang] || {}),
            },
            translations,
            lang,
          })),
          take(1),
        ),
      ),
      tap(({ lang }) => this._translate.resetLang(lang)),
      map(({ prevTranslations, translations, lang }) => {
        this._translate.setTranslation(lang, { ...prevTranslations, ...translations }, true)
        return useLanguage({ lang })
      }),
    ),
  )

  useLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(useLanguage),
      tap(() => this._store.dispatch(setTranslationsLoaded(false))),
      switchMap(({ lang }) => this._translator.use(lang).pipe(map(() => lang))),
      switchMap((lang) => this._translate.use(lang)),
      map(() => setTranslationsLoaded(true)),
    ),
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _api: I18nApi,
    private readonly _store: Store,
    private readonly _translate: TranslateService,
    private readonly _translator: TranslatorService,
  ) {}
}
