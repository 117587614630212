import { InjectionToken, NgModule } from '@angular/core'
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router'
import { LoggedGuard } from '@core/guards/logged.guard'
import { ErrorType } from '@mediacoach-ui-library/global'
import { PermissionsGuard } from '@core/guards/permissions.guard'
import { ApplicationsComponent } from '@shared/components/applications/applications.component'
import { CallbackComponent } from '@shared/components/callback/callback.component'
import { SilentComponent } from '@shared/components/silent/silent.component'
import { ExternalRedirectComponent } from '@shared/components/external-redirect/external-redirect.component'
import { ErrorFeedbackPageComponent } from '@shared/components/error-feedback-page/error-feedback-page.component'
import { MainContainerComponent } from '@core/main/main-container.component'
import { SeasonsResolver } from '@core/resolvers/seasons.resolver'
import { AssetsResolver } from '@core/resolvers/assets.resolver'
import { AnalyticsRouteTitle } from '@core/analytics/enums/gtag-route-titles.enum'
import { RoutesEnum } from '@core/enums/routes.enums'

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver')

const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    canActivate: [LoggedGuard],
    canActivateChild: [PermissionsGuard],
    data: {
      description: 'COMMONS_TITLE_DESCRIPTION',
    },
    resolve: {
      seasons: SeasonsResolver,
      assets: AssetsResolver,
    },
    children: [
      {
        path: RoutesEnum.Matches,
        loadChildren: () =>
          import('./features/matches/matches.module').then((m) => m.MatchesModule),
        data: {
          title: 'COMMONS_TITLE_MATCHES',
          gtagTitle: AnalyticsRouteTitle.matches,
          key: 'MATCHES',
        },
      },
      {
        path: RoutesEnum.MatchDetail,
        loadChildren: () =>
          import('./features/matches/containers/match-details/match-details.module').then(
            (m) => m.MatchDetailsModule,
          ),
        data: {
          title: 'COMMONS_TITLE_MATCH_DETAIL',
          gtagTitle: AnalyticsRouteTitle.matchDetail,
        },
      },
      {
        path: RoutesEnum.Calendar,
        loadChildren: () =>
          import('./features/calendar/calendar.module').then((m) => m.CalendarModule),
        data: {
          title: 'COMMONS_TITLE_CALENDAR',
          gtagTitle: AnalyticsRouteTitle.calendar,
          key: 'CALENDAR',
        },
      },
      {
        path: RoutesEnum.Teams,
        loadChildren: () =>
          import('./features/team-space/team-space.module').then((m) => m.TeamSpaceModule),
        data: {
          title: 'COMMONS_TITLE_TEAMS',
          gtagTitle: AnalyticsRouteTitle.teams,
          key: 'TEAMS',
        },
      },
      {
        path: RoutesEnum.Players,
        loadChildren: () =>
          import('./features/player-space/player-space.module').then((m) => m.PlayerSpaceModule),
        data: {
          title: 'COMMONS_TITLE_PLAYERS',
          gtagTitle: AnalyticsRouteTitle.players,
          key: 'PLAYERS',
        },
      },
      {
        path: RoutesEnum.Competitions,
        loadChildren: () =>
          import('./features/competition-space/competition-space.module').then(
            (m) => m.CompetitionSpaceModule,
          ),
        data: {
          title: 'COMMONS_TITLE_COMPETITIONS',
          gtagTitle: AnalyticsRouteTitle.competitions,
          key: 'COMPETITIONS',
        },
      },
      {
        path: RoutesEnum.Monitoring,
        data: {
          title: 'COMMONS_TITLE_MONITORING_REPORT',
          gtagTitle: AnalyticsRouteTitle.monitoring,
          key: 'MONITORING',
          gtmCategory: 'monitoring_portal', // TODO: Double check
        },
        loadChildren: () =>
          import('./features/monitoring/monitoring.module').then((m) => m.MonitoringModule),
      },
      {
        path: RoutesEnum.BeyondStats,
        data: {
          title: 'COMMONS_TITLE_BEYOND_STATS',
          gtagTitle: AnalyticsRouteTitle.beyondStats,
          key: 'BEYOND_STATS',
          gtmCategory: 'beyond-stats_portal', // TODO: Double check
        },
        loadChildren: () =>
          import('@features/beyond-stats/beyond-stats.module').then((m) => m.BeyondStatsModule),
      },
      {
        path: RoutesEnum.More,
        data: {
          title: 'COMMONS_TITLE_MORE',
          gtagTitle: AnalyticsRouteTitle.more,
          key: 'MORE',
          gtmCategory: 'more_portal', // TODO: Double check
        },
        loadChildren: () => import('./features/more/more.module').then((m) => m.MoreModule),
      },
      {
        path: RoutesEnum.Applications,
        component: ApplicationsComponent,
        data: {
          title: 'COMMONS_TITLE_APPLICATIONS',
          gtagTitle: AnalyticsRouteTitle.apps,
          key: 'APPLICATIONS',
        },
      },
      {
        path: RoutesEnum.Forbidden,
        component: ErrorFeedbackPageComponent,
        data: {
          errorType: ErrorType.Forbidden,
          gtagTitle: AnalyticsRouteTitle.logout,
        },
      },
      {
        path: RoutesEnum.Portal,
        redirectTo: '',
      },
      {
        path: RoutesEnum.Login,
        redirectTo: '',
      },
      {
        path: '',
        redirectTo: `/${RoutesEnum.Competitions}`,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: RoutesEnum.Auth,
    component: CallbackComponent,
    data: {
      title: AnalyticsRouteTitle.login,
      gtagTitle: AnalyticsRouteTitle.login,
    },
  },
  {
    path: RoutesEnum.Silent,
    component: SilentComponent,
    canActivate: [LoggedGuard],
    data: {
      title: AnalyticsRouteTitle.login,
      gtagTitle: AnalyticsRouteTitle.login,
    },
  },
  {
    path: RoutesEnum.ExternalRedirect,
    canActivate: [externalUrlProvider],
    component: ExternalRedirectComponent,
  },
  {
    path: RoutesEnum.UserConcurrency,
    component: ErrorFeedbackPageComponent,
    data: {
      errorType: ErrorType.UserConcurrence,
      title: AnalyticsRouteTitle.logout,
      gtagTitle: AnalyticsRouteTitle.logout,
    },
  },
  {
    path: '**',
    component: ErrorFeedbackPageComponent,
    data: {
      errorType: ErrorType.Default,
      title: AnalyticsRouteTitle.logout,
      gtagTitle: AnalyticsRouteTitle.logout,
    },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const { externalUrl, target } = route.params
        window.open(externalUrl, target)
      },
    },
  ],
})
export class AppRoutingModule {}
