import { Component, EventEmitter, Input, Output } from '@angular/core'
import { environment } from '@env'
import { DialogBase } from '@shared/components/dialog/dialog.base'
import { PlayerPosition } from '@core/enums/player.enums'
import { PLAYER_POSITION_KEYS } from '@core/constants/player.constants'

@Component({
  selector: 'app-position-selector-modal',
  templateUrl: './position-selector-modal.component.html',
  styleUrls: [
    './position-selector-modal.component.theme.scss',
    './position-selector-modal.component.scss',
  ],
})
export class PositionSelectorModalComponent extends DialogBase {
  @Output() positionSelect = new EventEmitter<{ id: string; text: string }>()
  @Input() extraClasses: string = ''

  envType = environment.envType
  positions = Object.keys(PLAYER_POSITION_KEYS)
    .filter((playerKey) => playerKey !== PlayerPosition.Goalkeeper)
    .map((key) => ({ id: key, text: PLAYER_POSITION_KEYS[key] }))

  openDialog(scrollableRef: string = '.match-details__metrics') {
    document.querySelector(scrollableRef).scrollIntoView()

    setTimeout(() => {
      super.open()
    }, 100)
  }
}
