import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core'

@Directive({
  selector: '[mcpAttachMarkers]',
})
export class AttachMarkersDirective {
  @Output() injected = new EventEmitter<void>()

  @Input('mcpAttachMarkers') set videoMarker(videoMarkerElementRef: ElementRef) {
    if (videoMarkerElementRef && this._elementRef) {
      const progressBar = this._elementRef.nativeElement.querySelector(
        '.vjs-progress-control .vjs-slider',
      )
      progressBar?.insertBefore(
        videoMarkerElementRef.nativeElement,
        progressBar.querySelector('.vjs-mouse-display'),
      )
      this.injected.emit()
    }
  }

  constructor(private readonly _elementRef: ElementRef) {}
}
