import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

import { iif, Observable, of } from 'rxjs'
import { filter, map, mergeMap, share, tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { AppTheme, Competition, debounce, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

import { ApplicationMenuConfig, ApplicationName, ApplicationUserProfileType } from '@mediacoach/ui'

import { environment } from '@env'
import { UpdatePasswordModalComponent } from '@shared/components/update-password-modal/update-password-modal.component'
import { DialogType } from '@shared/components/dialog-application/dialog-application.models'
import { Action, Store } from '@ngrx/store'
import { getApplications, getProfile } from '@core/state/selectors/user.selectors'
import { logOut } from '@core/state/actions/oidc.actions'
import {
  saveUserAvatar,
  saveUserCompetition,
  saveUserProfile,
  setProfile,
} from '@core/state/actions/profile.actions'
import { Actions, ofType } from '@ngrx/effects'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { McpProfile } from '@auth/models/auth.dto'
import { ProfileDialogComponent } from '@core/main/components/profile-dialog/profile-dialog.component'
import { CompetitionDialogComponent } from '@core/main/components/competition-dialog/competition-dialog.component'
import { NAVIGATION_LOGO } from '@core/main/containers/navigation-bar/constants/navigation-bar.constants'
import {
  HEADER_MENU_ENTRIES,
  ONBOARDING_MENU_ENTRY,
} from '@core/main/components/header/constants/header.constants'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { XL_LEGACY_BREAKPOINT } from '@core/constants/responsive.constants'
import { getCompetitions } from '@core/state/selectors/seasons.selectors'
import { existsAsNumber } from '@core/utils/number.utils'
import { HeaderMenuAction } from '@core/main/components/header/enum/header-menu-actions.enum'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'
import { analyticsTrackEvent } from '@core/analytics/state/actions/analytics.actions'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { EventParams } from '@core/analytics/models/gtag.models'
import { TourHelper } from '@core/main/components/header/helper/tour.helper'

@UntilDestroy()
@Component({
  selector: 'mcp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header-modal.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild('profile') profileDialog: ProfileDialogComponent
  @ViewChild('updatePassword') updatePassword: UpdatePasswordModalComponent
  @ViewChild('competition') competition: CompetitionDialogComponent

  private _ranOnce: boolean

  competitions$: Observable<Competition[]> = this._store.pipe(getCompetitions(), share())
  applications$: Observable<ApplicationUserProfileType[]> = this._store
    .select(getApplications)
    .pipe(map((data) => data as ApplicationUserProfileType[]))
  profile$: Observable<McpProfile> = this._store.select(getProfile)

  profileMenu$: Observable<NavigableItem> = this._breakpointObs
    .observe([XL_LEGACY_BREAKPOINT])
    .pipe(
      untilDestroyed(this),
      tap(({ matches }) => {
        if (!matches) {
          this._tourHelper.stopTour()
        }
      }),
      map(({ matches }: BreakpointState) => {
        const _menuItems = [...HEADER_MENU_ENTRIES]

        if (matches) {
          _menuItems.splice(1, 0, ONBOARDING_MENU_ENTRY)
        }

        return {
          label: '',
          items: [..._menuItems],
        }
      }),
    )

  placeholder = PLACEHOLDER_IMAGES
  logoData = NAVIGATION_LOGO
  urlStatics: string = environment.STATICS_URL_ASSETS
  darkMode = AppTheme.Dark

  appMenuConfig: ApplicationMenuConfig = {
    environment: environment.envType,
    application: ApplicationName.Portal,
    blacklist: environment.PROFILE_TYPE_BLACKLIST,
  } as any
  applicationDialogType = DialogType

  constructor(
    private readonly _store: Store,
    private readonly _actions$: Actions,
    private readonly _breakpointObs: BreakpointObserver,
    private readonly _tourHelper: TourHelper,
  ) {}

  private _updateProfile(action: Action, callback: () => void): void {
    this._actions$
      .pipe(
        ofType(setProfile),
        completeWhen(({ profile }) => !!profile),
      )
      .subscribe(() => {
        callback()
      })
    this._store.dispatch(action)
  }

  ngAfterViewInit(): void {
    this._store
      .select(getProfile)
      .pipe(
        filter((p) => !!p?.favourites),
        mergeMap((profile) =>
          iif(
            () => !profile.favourites.competitionId,
            of(''),
            this.competitions$.pipe(
              map((competitions) => ({
                competitions,
                favCompetitionId: profile.favourites.competitionId,
              })),
              tap(({ competitions, favCompetitionId }) => {
                if (competitions.some((c) => c.id === favCompetitionId)) {
                  this._handleOnBoardingOnStartup(profile)
                }
              }),
              filter(
                ({ competitions, favCompetitionId }) =>
                  !!this.profileDialog &&
                  !!competitions &&
                  !competitions.some((c) => c.id === favCompetitionId),
              ),
            ),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.competition.open()
      })
  }

  logOut() {
    this._store.dispatch(logOut())
  }

  showChangePassword() {
    this.updatePassword.modal.openModal()
  }

  onSubmit(form: UntypedFormGroup) {
    const photoPathFc = form.get('photoPath')
    if (photoPathFc.dirty) {
      this._store.dispatch(saveUserAvatar({ path: photoPathFc.value }))
    }
    const profile = form.getRawValue()
    delete profile.photoPath
    this._updateProfile(saveUserProfile({ profile }), () => this.profileDialog.close())
  }

  onCompetitionSelected(competition: Competition): void {
    this._updateProfile(saveUserCompetition({ competitionId: competition.id }), () => {
      this.competition.close()
      this._store.dispatch(
        analyticsTrackEvent({
          eventName: AnalyticsEvent.changeFavCompetition,
          eventParams: {
            [AnalyticsParam.category]: AnalyticsCategory.settings,
            [AnalyticsParam.favCompetitionId]: competition.id,
            [AnalyticsParam.favCompetitionName]: competition.name,
          },
        }),
      )
    })
  }

  onItemClick(item: NavigableItem) {
    switch (item.action) {
      case HeaderMenuAction.startTour:
        this._tourHelper.startTour()
        break
      case HeaderMenuAction.logout:
        this.logOut()
        break
      case HeaderMenuAction.changePassword:
        this.showChangePassword()
        break
      case HeaderMenuAction.openProfile:
        this.profileDialog.open()
        break
    }
  }

  onLauncherClick(app) {
    this._store.dispatch(
      analyticsTrackEvent({
        eventName: AnalyticsEvent.openSuiteApplication,
        eventParams: {
          [AnalyticsParam.category]: AnalyticsCategory.navigation,
          [AnalyticsParam.suiteApp]: app.name,
        },
      }),
    )
  }

  onAnalytics(params: { eventName: AnalyticsEvent; eventParams: EventParams }): void {
    this._store.dispatch(analyticsTrackEvent(params))
  }

  @debounce(2000)
  private _handleOnBoardingOnStartup(profile: McpProfile): void {
    if (
      existsAsNumber(profile?.onBoardingStep) &&
      profile.onBoardingStep === 0 &&
      !this._ranOnce &&
      this._breakpointObs.isMatched(XL_LEGACY_BREAKPOINT)
    ) {
      this._ranOnce = true
      this._tourHelper.startTour(profile.onBoardingStep)
    }
  }
}
