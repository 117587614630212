import { createAction, props } from '@ngrx/store'
import { Translations } from '@core/i18n/models/i18n.models'

export const useLanguage = createAction('[i18n] use language', props<{ lang: string }>())
export const fetchTranslations = createAction(
  '[i18n] fetch translations',
  props<{ lang: string }>(),
)
export const setTranslations = createAction(
  '[i18n] set translations',
  (translations: Translations) => translations,
)
export const setTranslationsLoaded = createAction(
  '[i18n] translations loaded',
  (loaded: boolean) => ({ loaded }),
)

export const setLanguage = createAction('[i18n] set language', props<{ lang: string }>())
