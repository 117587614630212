import { Inject, Injectable, isDevMode } from '@angular/core'
import { FACTORY_TOKEN } from '../constants'
import { Log, User, UserManager } from 'oidc-client'
import { from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable()
export class OidcService {
  constructor(@Inject(FACTORY_TOKEN) public readonly manager: UserManager) {
    if (isDevMode()) {
      Log.logger = console
      Log.level = Log.DEBUG
    }
  }

  removeUser(): Observable<boolean> {
    return from(this.manager.removeUser()).pipe(map(() => true))
  }

  getUser(): Observable<User> {
    return from(this.manager.getUser())
  }

  signInRedirectCallback(url?: string): Observable<User> {
    return from(this.manager.signinRedirectCallback(url))
  }

  signInSilentCallback(url?: string): Observable<User> {
    return from(this.manager.signinSilentCallback(url))
  }

  signInRedirect(args?): Observable<void> {
    return from(this.manager.signinRedirect(args))
  }

  signInSilent(args?): Observable<User> {
    return from(this.manager.signinSilent(args))
  }
}
