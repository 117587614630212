import { TeamSpaceHeader } from '@features/team-space/models/team-space.models'
import { WidgetQuery } from '@widgets/models/widget.models'
import { Params } from '@angular/router'
import { SpaceMatch, SpaceTeam } from '@core/space/models/space.models'
import * as _ from 'lodash'

export const buildTeamSpaceNavUrl = (
  seasonId: string,
  competitionId: string,
  teamId: string,
): string => `/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

export const findHomeAwayTeam = (
  header: TeamSpaceHeader,
  match: SpaceMatch,
): { home: SpaceTeam; away: SpaceTeam } => {
  let home: SpaceTeam
  let away: SpaceTeam
  for (const team of header.teams) {
    if (match.homeTeamId === team.id) {
      home = team
    }
    if (match.awayTeamId === team.id) {
      away = team
    }
    if (home && away) {
      break
    }
  }
  return { home, away }
}

export const mapWidgetQuery = (
  query: WidgetQuery,
  params: Params,
): { query: WidgetQuery; teamId: string } => ({
  query: {
    teamId: params['teamId'],
    competitionId: params['competitionId'],
    seasonId: params['seasonId'],
    playerId: params['playerId'],
    ..._.omit(query, ['competitionId']),
  },
  teamId: params['teamId'],
})
