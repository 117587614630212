import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'app-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputContainerComponent {
  @Input() label: string
  @Input() hasError: boolean

  constructor() {}
}
