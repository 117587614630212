import { environment } from '@env'

declare const ga: any
declare const smartlook: any

export const updateListItemAttributes = (list, attributes) =>
  list.map((item: any) => ({
    ...item,
    attributes: { ...item.attributes, ...attributes },
  }))

// export const initGoogleAnalytics = (trackingCode) => ga('create', trackingCode, 'auto')

export const initSmartLook = (trackingCode) => {
  smartlook('init', trackingCode)
  smartlook('properties', { environment: environment.envType })
}
