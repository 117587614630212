<app-loader [loading]="!data || loading">
  <ng-container *ngFor="let groupItem of data">
    <ng-container
      *ngTemplateOutlet="titleTemplate; context: { $implicit: groupItem.title }"
    ></ng-container>
    <div class="c-card-block">
      <mcp-card *ngFor="let item of groupItem.group" [data]="item" [isPlayer]="isPlayer">
        <ng-template appTemplate="content">
          <ng-container
            *ngTemplateOutlet="contentTemplate; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
        <ng-template appTemplate="footer">
          <ng-container
            *ngTemplateOutlet="footerTemplate; context: { $implicit: item }"
          ></ng-container>
        </ng-template>
      </mcp-card>
    </div>
  </ng-container>
</app-loader>
