import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DeferLoadDirective } from './defer-load.directive'

@NgModule({
  declarations: [DeferLoadDirective],
  exports: [DeferLoadDirective],
  imports: [CommonModule],
})
export class DeferLoadModule {}
