import { Injectable } from '@angular/core'
import { McpProfile } from '@auth/models/auth.dto'
import { ApiProfile } from '@auth/models/auth.model'
import { Observable } from 'rxjs'
import { HttpClient, HttpResponse } from '@angular/common/http'
import { environment } from '@env'

@Injectable()
export class AuthApi {
  constructor(private readonly _http: HttpClient) {}

  fetchUserProfile(): Observable<HttpResponse<ApiProfile>> {
    return this._http.get<ApiProfile>(environment.INTEGRATED_API.USER_PROFILE, {
      observe: 'response',
    })
  }

  saveUserAvatar(avatar: { encodedImage: string }): Observable<any> {
    return this._http.put(environment.INTEGRATED_API.UPDATE_AVATAR, JSON.stringify(avatar))
  }

  saveUserProfile(profile: McpProfile): Observable<void> {
    return this._http.put<void>(environment.INTEGRATED_API.USER_PROFILE, profile)
  }
}
