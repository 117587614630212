<div class="c-team-stats">
  <div class="c-team-stats__row">
    <div class="c-team-stats__item c-team-stats__item-label" *ngFor="let stat of statsData">
      {{ stat?.label || '-' | translate }}
    </div>
  </div>
  <div class="c-team-stats__row c-team-stats__row--accent">
    <div class="c-team-stats__item c-team-stats__item-value" *ngFor="let stat of statsData">
      {{ stat?.value || '-' }}
    </div>
  </div>
</div>
