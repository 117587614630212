<div class="mcp-dialog-create-playlist">
  <input
    class="mcp-input"
    [placeholder]="'MTR_PLAYLIST_DIALOG_PLAYLIST_NAME' | translate"
    [(ngModel)]="playlistName"
    [required]="true"
  />
</div>
<mcp-collaborators-list
  [collaborators]="collaborators$ | async"
  [selectedCollaborators]="playlist?.collaborators"
  #list
/>
<div class="mcp-dialog-create-playlist__footer">
  <mcui-button
    [disabled]="!playlistName"
    (click)="close(list.selected)"
    text="{{ 'P_COMMONS_PROFILE_BTN_SAVE' | translate }}"
  />
</div>
