import {
  TeamSpaceCoachCompetitionMetric,
  TeamSpaceCoachGrid,
  TeamSpaceCoachMetric,
  TeamSpaceHeader,
} from '@features/team-space/models/team-space.models'
import { WidgetColumn, WidgetQuery, WidgetRow } from '@widgets/models/widget.models'
import { Params } from '@angular/router'
import { SpaceMatch, SpaceTeam } from '@core/space/models/space.models'
import * as _ from 'lodash'
import { TEAM_SPACE_MANAGERS_COLUMNS } from '@features/team-space/constants/team-space-grid.constants'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'

export const buildTeamSpaceNavUrl = (
  seasonId: string,
  competitionId: string,
  teamId: string,
): string => `/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

export const findHomeAwayTeam = (
  header: TeamSpaceHeader,
  match: SpaceMatch,
): { home: SpaceTeam; away: SpaceTeam } => {
  let home: SpaceTeam
  let away: SpaceTeam
  for (const team of header.teams) {
    if (match.homeTeamId === team.id) {
      home = team
    }
    if (match.awayTeamId === team.id) {
      away = team
    }
    if (home && away) {
      break
    }
  }
  return { home, away }
}

export const mapWidgetQuery = (
  query: WidgetQuery,
  params: Params,
): { query: WidgetQuery; teamId: string } => ({
  query: {
    teamId: params['teamId'],
    competitionId: params['competitionId'],
    seasonId: params['seasonId'],
    playerId: params['playerId'],
    ..._.omit(query, ['competitionId']),
  },
  teamId: params['teamId'],
})

const buildManagerColumns = (managers: TeamSpaceCoachMetric[]): WidgetColumn[] => [
  ...TEAM_SPACE_MANAGERS_COLUMNS,
  ...managers[0].metricResults.map(
    (metric) =>
      ({
        key: metric.key,
        alignment: 'center',
        label: `${metric.key}_SHORT`,
        tooltip: metric.key,
        width: 'minmax(60px, 100px)',
        sortable: true,
      }) as WidgetColumn,
  ),
]

const buildManagerRows = (manager: TeamSpaceCoachMetric) => {
  const expandableRow: WidgetRow<TeamSpaceCoachMetric> = { ...manager }

  expandableRow.formattedName = manager.alias

  // Map metricResults from the manager level
  manager.metricResults.forEach((metric) => {
    expandableRow[metric.key] = metric.value
  })

  expandableRow.formattedName = `${expandableRow.name} ${expandableRow.surname}`
  expandableRow.depth = 0
  expandableRow.expandableKey = 'competitions'
  expandableRow.expanded = true

  // Optionally, map competition-level metrics
  expandableRow.competitions = manager.competitions.map((competition) => {
    const row: WidgetRow<TeamSpaceCoachCompetitionMetric> = { ...competition }

    competition.metricResults.forEach((metric) => {
      row[metric.key] = metric.value
    })

    row.imagePlaceholder = PLACEHOLDER_IMAGES.COMPETITION

    row.depth = 1
    return row
  })
  return expandableRow
}

export const mapManagersMetrics = (managers: TeamSpaceCoachMetric[]): TeamSpaceCoachGrid => {
  if (managers?.length) {
    return {
      columns: buildManagerColumns(managers),
      rows: managers.map(buildManagerRows),
    }
  }

  return { columns: [], rows: [] }
}
