import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { tap } from 'rxjs/operators'
import { notAvailablePowerBiForSeason } from '@core/state/actions/power-bi.actions'
// import { ToastSeverity } from '@core/toast/enums/toast.enums'
// import { ToastService } from '@core/toast/services/toast.service'
import { TranslateService } from '@ngx-translate/core'
import { ToastService } from '@mediacoach/ui'

@Injectable()
export class PowerBiEffects {
  notAvailableReportForSeason$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(notAvailablePowerBiForSeason),
        tap(() =>
          this._toast.show(
            {
              message: this._translateService.instant('MTR_POWER_BI_NOT_AVAILABLE_MESSAGE'),
            },
            { type: 'warning' },
          ),
        ),
      ),
    {
      dispatch: false,
    },
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _toast: ToastService,
    private readonly _translateService: TranslateService,
  ) {}
}
