import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { Store } from '@ngrx/store'
import { getToken } from '@core/state/selectors/user.selectors'
import { map, switchMap } from 'rxjs/operators'
import { completeWhen } from '@shared/operators/complete-when.operator'

const AUTH_DOMAINS = [
  environment.API.MAIN_PATH,
  environment.INTEGRATED_API.MAIN_PATH,
  environment.APIM_API.MAIN_PATH,
]

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _token$ = this._store.select(getToken).pipe(completeWhen((t) => !!t))

  constructor(private readonly _store: Store) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AUTH_DOMAINS.some((domain) => request.url.indexOf(domain) === 0)) {
      return this._token$.pipe(
        map((token: string) => {
          let headers = request.headers.set(
            'Authorization',
            `${environment.API_KEY_SECURE} ${token}`,
          )

          if (request.detectContentTypeHeader() === 'text/plain') {
            headers = headers.set('Content-Type', 'application/json')
          }

          return request.clone({ headers })
        }),
        switchMap((req: HttpRequest<any>) => next.handle(req)),
      )
    }
    return next.handle(request)
  }
}
