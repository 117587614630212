import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'mcp-space-header-cell',
  template: `
    {{
      String(value)?.indexOf('STAT') !== -1
        ? (value | translate)
        : (value | number: '1.0-2' : lang | invalidValueResolver)
    }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceHeaderCellComponent {
  @Input() value: any
  @Input() lang: string
  protected readonly String = String
}
