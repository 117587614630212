<ng-container [ngSwitch]="true">
  <div *ngSwitchCase="!!items?.length && items?.length <= config.maxLabelsAllowed">
    <span *ngFor="let item of items; let i = index">
      <ng-container
        *ngTemplateOutlet="dropdownLabel; context: { item: { label: item.label } }"
      ></ng-container>
      {{ i < items?.length - 1 ? ',&nbsp;' : '' }}
    </span>
  </div>
  <div *ngSwitchCase="items?.length > config.maxLabelsAllowed">
    {{ items?.length + ' ' + ('FILTERS_LAB_PLURAL' | translate) }}
  </div>
  <ng-container *ngSwitchCase="!!item">
    <ng-container *ngTemplateOutlet="dropdownLabel; context: { item: item }"></ng-container>
  </ng-container>
  <div *ngSwitchDefault>{{ 'FILTERS_LAB_DEFAULT' | translate }}</div>
</ng-container>

<ng-template let-item="item" #dropdownLabel>
  <span *ngIf="item?.label">{{ item?.label | translate }}</span>
</ng-template>
