import { Observable, of } from 'rxjs'
import { Injectable } from '@angular/core'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

import { PermissionsService } from '../services/permissions.service'

import { PermittedRoute } from '@core/models/models/permissions.models'
import { NAVIGATION_CONFIG } from '@core/constants/navigation.constants'

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard {
  private readonly _routes = NAVIGATION_CONFIG.map(({ route }) => route)

  private readonly _allowedRoutes$: Observable<PermittedRoute[]> =
    this._permissionService.getPermittedRouteUrls$()

  constructor(
    private readonly _permissionService: PermissionsService,
    private readonly _router: Router,
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._permissionService.isRouteUrlPermitted$(state.url).pipe(
      switchMap((isPermitted) => {
        isPermitted = state.url === '/' || isPermitted

        return state.url !== this._permissionService.notPermittedRoute && !isPermitted
          ? this._allowedRoutes$.pipe(
              take(1),
              map((routes: PermittedRoute[]) =>
                this._routes.find((r) =>
                  routes.some((allowedRoute) => new RegExp(allowedRoute.regex).test(r)),
                ),
              ),
              tap((_route) =>
                this._router.navigate([_route || this._permissionService.notPermittedRoute]),
              ),
              map(() => false),
            )
          : of(true)
      }),
    )
  }
}
