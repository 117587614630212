import { ChangeDetectionStrategy, Component } from '@angular/core'
import { QueryText } from '@shared/components/query/models/query-text.model'
import { QueryableField } from '@shared/components/query/models/query-base.model'

@Component({
  selector: 'mcp-query-text',
  templateUrl: './query-text.component.html',
  styleUrls: ['./query-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryTextComponent extends QueryableField<QueryText> {}
