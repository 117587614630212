import { FilterItem, TagCode } from '@features/playlist/models/playlist.models'
import {
  PlaylistCollaborator,
  PlaylistResponse,
  PlaylistsResponse,
  TagListResponse,
  TagResponse,
} from '@features/playlist/models/playlist.response.models'
import { FilterType } from '@features/playlist/enums/playlist.enums'
import { PartialRecord } from '@core/types/record.types'
import { createAction, props } from '@ngrx/store'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { TagItem } from '@shared/components/tag-item/models/tag-item.models'
import { MatchTabDisplayMode } from '@features/match-tabs/models/match-tab.models'
import { PlaylistClipSharePayload } from '@features/playlist/dialogs/dialog-playlist-share/models/dialog-playlist-share.models'
import { VideoType } from '@mediacoach-ui-library/global'

export const emptyAction = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] empty action`)

export const fetchPlayLists = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch playlists`, props<{ matchId: string }>())

export const setPlaylists = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set playlists`,
    props<{ playlists: PlaylistsResponse }>(),
  )

export const setSelectedPlaylist = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set selected playlist index`,
    props<{ matchId: string; selectedPlaylist: PlaylistResponse }>(),
  )

export const fetchPlayListData = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] fetch playlist data`,
    props<{ matchId: string; omitClean?: boolean }>(),
  )
export const fetchFilterData = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch filter data`, props<{ matchId: string }>())

export const fetchTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] fetch tags`, props<{ matchId: string }>())

export const setTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set tags`, props<{ tags: TagListResponse }>())

export const parseTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] parse tags`, props<{ results: TagResponse[] }>())

export const setParsedTagItems = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set parsed items`,
    props<{ parsedTagItems: TagItem[] }>(),
  )

export const fetchDimensions = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] fetch dimensions`,
    props<{ matchId: string; playlistId: string }>(),
  )

export const setDimensions = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set dimensions`, props<{ dimensions: string[] }>())

export const fetchCodes = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] fetch codes`,
    props<{ matchId: string; playlistId: string }>(),
  )

export const setCodes = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set codes`, props<{ codes: TagCode[] }>())

export const prepareDialogPlaylistFilter = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] prepare dialog filter`, props<{ displayMode: string }>())

export const openDialogPlaylistFilter = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] open dialog filter`, props<{ displayMode: string }>())

export const setPlaylistLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set playlist loader`, (playlistLoader) => ({
    playlistLoader,
  }))

export const setPlaylistFilterLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set playlist filter loader`, (playlistFilterLoader) => ({
    playlistFilterLoader,
  }))

export const downloadXml = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] Download xml`)

export const setFilterConfigByType = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filter config by type`,
    props<{ filterType: FilterType; items?: FilterItem[] }>(),
  )

export const resetFilterActive = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] reset filter active`)

export const applyFilter = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] apply filters`,
    props<{ filterActive: PartialRecord<FilterType, FilterItem[]> }>(),
  )

export const setFilterActive = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set filters active`,
    props<{ matchId: string; filterActive: PartialRecord<FilterType, FilterItem[]> }>(),
  )

export const setSortFilter = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set sort filter`,
    props<{ matchId: string; sortFilter: FilterItem }>(),
  )

export const setSelectedTagItem = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] set selected tag item`,
    props<{ selectedTagItem: TagItem }>(),
  )

export const reFetchTags = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] re fetch tags`)

export const openCreateNewPlaylistDialog = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] open create playlist dialog`,
    props<{ matchId: string; displayMode: MatchTabDisplayMode }>(),
  )

export const createNewPlaylist = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] create new playlist`,
    props<{ playlist: Partial<PlaylistResponse> }>(),
  )

export const fetchPlaylistCollaborators = (identifier: MergedTokens) =>
  createAction(`[${identifier}][Playlist] fetch playlist collaborators`)

export const setPlaylistCollaborators = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] set playlist collaborators`,
    props<{ collaborators: PlaylistCollaborator[] }>(),
  )

export const setIsChangingPlaylist = (identifier: MergedTokens) =>
  createAction(`[${identifier} - Playlist] set is changing playlist`, (isChangingPlaylist) => ({
    isChangingPlaylist,
  }))

export const openManagePlaylistDialog = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] open manage playlist dialog`,
    props<{ matchId: string; displayMode: MatchTabDisplayMode }>(),
  )

export const openEditPlaylistDialog = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] open edit playlist dialog`,
    props<{ playlist: PlaylistResponse; displayMode: MatchTabDisplayMode }>(),
  )

export const openDeletePlaylistDialog = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}][Playlist] open delete playlist dialog`,
    props<{ playlist: PlaylistResponse; displayMode: MatchTabDisplayMode }>(),
  )

export const leavePlaylist = (identifier: MergedTokens) =>
  createAction(`[${identifier}][Playlist] leave playlist`, props<{ playlist: PlaylistResponse }>())

export const editPlaylist = (identifier: MergedTokens) =>
  createAction(`[${identifier}][Playlist] edit playlist`, props<{ playlist: PlaylistResponse }>())

export const deletePlaylist = (identifier: MergedTokens) =>
  createAction(`[${identifier}][Playlist] delete playlist`, props<{ playlist: PlaylistResponse }>())

export const openDialogShareAllPlaylistClips = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] open dialog share all clips`,
    props<{ displayMode: string; matchId: string }>(),
  )

export const shareAllPlaylistClips = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] share all clips`,
    (payload: PlaylistClipSharePayload) => ({ payload }),
  )

export const openDialogSharePlaylistFilteredClips = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] open dialog share filtered clips`,
    props<{ displayMode: string; matchId: string }>(),
  )

export const sharePlaylistFilteredClips = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] share filtered clips`,
    (payload: PlaylistClipSharePayload) => ({ payload }),
  )

export const quickTag = (identifier: MergedTokens) =>
  createAction(
    `[${identifier} - Playlist] quick tag`,
    props<{ time: number; videoType: VideoType; matchId: string }>(),
  )
