export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const encodeBase64 = (object: any): string => btoa(JSON.stringify(object))
export const decodeBase64 = (encoded: string): string => atob(encoded)

export const splitEveryNCaracter = (
  str: string,
  occurrences: number = 2,
  caracter: RegExp = /\s/,
): string[] => {
  const words = str.split(caracter)
  const result: string[] = []

  for (let i = 0; i < words.length; i += occurrences) {
    const text = words
      .slice(i, i + occurrences)
      .join(' ')
      .replace(/\(%\)/g, ' (%)')

    result.push(text)
  }
  return result
}
