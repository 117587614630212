import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { StorageService } from '@shared/services/storage/storage.service'
import { storeData, storeRawData } from '@core/state/actions/storage.actions'
import { tap } from 'rxjs/operators'

@Injectable()
export class StorageEffects {
  storeData$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(storeData),
        tap(({ storageKey, data, storageType }) => {
          this._storage.set(storageType, storageKey, data)
        }),
      ),
    { dispatch: false },
  )

  storeRawData$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(storeRawData),
        tap(({ storageKey, data, storageType }) => {
          this._storage.setRaw(storageType, storageKey, data)
        }),
      ),
    { dispatch: false },
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _storage: StorageService,
  ) {}
}
