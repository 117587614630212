import { Directive, ViewChild } from '@angular/core'
import { ModalComponent } from '@shared/components/modal/modal.component'

@Directive()
export abstract class ModalBase {
  @ViewChild('modal') modal: ModalComponent

  open() {
    if (this.modal) {
      this.modal.openModal()
    }
  }

  close() {
    if (this.modal) {
      this.modal.closeModal()
    }
  }
}
