import { debounceTime, startWith, tap } from 'rxjs/operators'
import { DebounceTimeType } from '@mediacoach-ui-library/global'
import { Directive, ElementRef, Input, ViewChild } from '@angular/core'
import { ScrollService } from '@shared/services/scroll.service'
import { IMAGES_PATH } from './base-card.constants'

@Directive()
export abstract class BaseCardComponent<T> {
  protected _data: T

  public footerHeight = 48
  public onWindowResize$ = this._scrollService.resizeObs.pipe(
    startWith(() => null),
    debounceTime(DebounceTimeType.InputChanges),
    tap(() => this._setFooterHeight()),
  )

  IMAGES_PATH = IMAGES_PATH

  get data(): T {
    return this._data
  }

  @Input() set data(_data: T) {
    this._data = _data
  }

  @ViewChild('footerBottom', { static: true }) public footerBottom: ElementRef<HTMLDivElement>

  protected constructor(private readonly _scrollService: ScrollService) {}

  private _setFooterHeight() {
    this.footerHeight =
      (this.footerBottom.nativeElement &&
        this.footerBottom.nativeElement.getBoundingClientRect().height) ||
      0
  }
}
