import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import {
  DragNScrollDirection,
  DragNScrollPosition,
} from '@shared/directives/drag-n-scroll/drag-n-scroll.models'
import { Platform } from '@angular/cdk/platform'
import { fromEvent } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Directive({
  selector: '[mcpDragNScroll]',
})
export class DragNScrollDirective {
  @Input({ alias: 'mcpDragNScroll' }) direction: DragNScrollDirection = 'horizontal'

  private _startPos: DragNScrollPosition | null = null
  private _isDragging: boolean = false

  private get _isMobile(): boolean {
    return this._platform.IOS || this._platform.ANDROID
  }

  private get _element(): HTMLElement {
    return this._host?.nativeElement
  }

  private get _hasScroll(): boolean {
    return this._element.scrollWidth > this._element.clientWidth
  }

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _platform: Platform,
  ) {
    if (!this._isMobile) {
      this._updateCursor(this._element, 'grab')

      fromEvent(this._element, 'mousedown')
        .pipe(takeUntilDestroyed())
        .subscribe((event: MouseEvent) => this._onMouseDown(event))

      fromEvent(this._element, 'touchstart')
        .pipe(takeUntilDestroyed())
        .subscribe((event: TouchEvent) => this._onTouchStart(event))
    }
  }

  private _onMouseDown(event: MouseEvent): void {
    this._startPos = {
      left: this._element.scrollLeft,
      top: this._element.scrollTop,
      x: event.clientX,
      y: event.clientY,
    }
    this._isDragging = false
    this._updateCursor(this._element, 'grabbing')
    this._renderer.setStyle(this._element, 'user-select', 'none')

    this._renderer.listen(this._element, 'mousemove', this._onMove.bind(this))
    this._renderer.listen(this._element, 'mouseup', this._onEventEnd.bind(this))
  }

  private _onTouchStart(event: TouchEvent): void {
    const touch = event.touches[0]
    this._onEventStart(touch)
    this._renderer.listen(this._element, 'touchmove', (event: TouchEvent) =>
      this._onMove(event.touches[0]),
    )
    this._renderer.listen(this._element, 'touchend', this._onEventEnd.bind(this))
  }

  private _onEventStart(event: MouseEvent | Touch): void {
    if (this.direction === 'horizontal') {
      this._startPos = {
        left: this._element.scrollLeft,
        x: event.clientX,
      }
    } else if (this.direction === 'vertical') {
      this._startPos = {
        top: this._element.scrollTop,
        y: event.clientY,
      }
    } else {
      this._startPos = {
        left: this._element.scrollLeft,
        top: this._element.scrollTop,
        x: event.clientX,
        y: event.clientY,
      }
    }

    this._isDragging = false
    this._updateCursor(this._element, 'grabbing')
    this._renderer.setStyle(this._element, 'user-select', 'none')
  }

  private _onEventEnd() {
    this._updateCursor(this._element, 'grab')
    this._renderer.removeStyle(this._element, 'user-select')

    if (this._isDragging && this._hasScroll) {
      this._preventClick()
    }

    this._startPos = null
  }

  private _onMove(event: MouseEvent | Touch): void {
    if (!this._startPos) {
      return
    }

    if (this.direction === 'horizontal') {
      const dx = event.clientX - this._startPos.x

      if (Math.abs(dx) > 5) {
        this._isDragging = true
      }
      this._element.scrollLeft = this._startPos.left - dx
    } else if (this.direction === 'vertical') {
      const dy = event.clientY - this._startPos.y
      if (Math.abs(dy) > 5) {
        this._isDragging = true
      }
      this._element.scrollTop = this._startPos.top - dy
    } else {
      const dx = event.clientX - this._startPos.x
      const dy = event.clientY - this._startPos.y

      if (Math.abs(dx) > 5 || Math.abs(dy) > 5) {
        this._isDragging = true
      }

      this._element.scrollTop = this._startPos.top - dy
      this._element.scrollLeft = this._startPos.left - dx
    }
  }

  private _preventClick(): void {
    const clickPreventer = (event: MouseEvent) => {
      event.stopImmediatePropagation()
      this._host.nativeElement.removeEventListener('click', clickPreventer, true)
    }
    this._host.nativeElement.addEventListener('click', clickPreventer, true)
  }

  private _updateCursor(ele: HTMLElement, cursorStyle: string): void {
    this._renderer.setStyle(ele, 'cursor', cursorStyle)
  }
}
