import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { SmartLookService } from '@core/analytics/services/smart-look.service'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { environment } from '@env'
import { throwError } from 'rxjs'
import {
  trackErrorToSmartLook,
  trackUserToSmartLook,
} from '@core/analytics/state/actions/smart-look.actions'
import { getSeasons } from '@core/state/selectors/seasons.selectors'
import { Store } from '@ngrx/store'
import { completeWhen } from '@shared/operators/complete-when.operator'
import { getSeasonAndCompetition } from '@core/utils/season.utils'

@Injectable()
export class SmartLookEffects {
  trackUserToSmartLook$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(trackUserToSmartLook),
        filter(() => environment.enableSmartLook),
        switchMap(({ profile }) =>
          this._store.select(getSeasons).pipe(
            completeWhen((s) => !!s && s.length > 0),
            map((seasons) => ({ seasons, profile })),
          ),
        ),
        filter(
          ({ profile }) => !!profile?.favourites?.competitionId && !!profile?.favourites?.teamId,
        ),
        map(({ seasons, profile }) => ({
          profile,
          competition: getSeasonAndCompetition(profile?.favourites?.competitionId, seasons)
            ?.competition,
        })),
        filter((competition) => !!competition),
        map(({ competition, profile }) => ({
          id: profile.id,
          name: `${profile.name.trim()} ${profile.surname.trim()}`,
          email: profile.email,
          competitionId: competition.id,
          competitionName: competition.name,
          teamId: profile.favourites.teamId,
          language: profile.language,
          environment: environment.envType,
        })),
        tap(({ id, ...userData }) => {
          this._smartLook.trackUser(id, userData)
        }),
        catchError((err) => throwError(err)),
      ),
    { dispatch: false },
  )

  trackErrorToSmartLook$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(trackErrorToSmartLook),
        filter(() => environment.enableSmartLook),
        tap(({ error }) => this._smartLook.trackError(error)),
      ),
    { dispatch: false },
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _smartLook: SmartLookService,
    private readonly _store: Store,
  ) {}
}
