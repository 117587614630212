import { Inject, Injectable, Optional } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators'
import { DeepEqual, EnvType, NoAbsoluteUrl, UrlDeepLength } from '@mediacoach-ui-library/global'
import { Permissions } from '../models/models/permissions.models'
import { AppPermissionType, PermissionType } from '../enums/permissions.enum'
import { environment } from '@env'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private _ROUTE_WHITELIST = [EnvType.Dv, EnvType.Ts].includes(environment.envType) ? [] : []

  private _routeAliasDictionary: { [key: string]: string[] }
  private _permissionSubject = new BehaviorSubject<Permissions>(null)
  private _permissions$ = this._permissionSubject.pipe(
    filter((permissions) => !!permissions),
    distinctUntilChanged(DeepEqual),
  )
  private _permittedRouteUrls$: Observable<string[]>
  private _contentPermissions$: Observable<string[]>
  private _permittedRouteUrls: string[]

  constructor(@Inject('notPermittedRoute') @Optional() public notPermittedRoute?: string) {
    this.notPermittedRoute = notPermittedRoute || '/not-permitted'
  }

  private _isSomePermittedRouteUrl(routeUrls, routeUrl) {
    if (this._ROUTE_WHITELIST.some((url) => new RegExp(url).test(routeUrl))) {
      return true
    }
    return routeUrls?.some(
      ({ deepLength, regex }) => regex.test(routeUrl) && deepLength === UrlDeepLength(routeUrl),
    )
  }

  setPermissions(permissions, _routeAliasDictionary?: { [key: string]: string[] }) {
    this._routeAliasDictionary = _routeAliasDictionary
    this._permissionSubject.next(permissions)
  }

  getPermittedRouteUrls$() {
    return (
      this._permittedRouteUrls$ ||
      (this._permittedRouteUrls$ = this._permissions$.pipe(
        filter((permissions) => !!permissions[PermissionType.ApplicationPermissions]),
        map(
          (permissions) =>
            permissions[PermissionType.ApplicationPermissions][AppPermissionType.Route],
        ),
        map((routeUrlPermissions) =>
          (routeUrlPermissions || []).reduce(
            (arr, routeUrl) => [...arr, ...((this._routeAliasDictionary || {})[routeUrl] || [])],
            routeUrlPermissions,
          ),
        ),
        map((routeUrlPermissions) =>
          (routeUrlPermissions || []).map((routeUrl) => ({
            regex: new RegExp(`^(?!${routeUrl}/)${routeUrl}(\\?.+)?$`),
            deepLength: UrlDeepLength(routeUrl),
          })),
        ),
        tap((_routeUrls) => (this._permittedRouteUrls = _routeUrls)),
        shareReplay(1),
      ))
    )
  }

  getContentPermissionKeys$() {
    return (
      this._contentPermissions$ ||
      (this._contentPermissions$ = this._permissions$.pipe(
        filter(
          (permissions) =>
            !!(
              permissions[PermissionType.CrossContentPermissions] &&
              (permissions[PermissionType.ApplicationPermissions] || {})[
                AppPermissionType.AppContent
              ]
            ),
        ),
        map((permissions) => [
          ...permissions[PermissionType.CrossContentPermissions],
          ...permissions[PermissionType.ApplicationPermissions][AppPermissionType.AppContent],
        ]),
        shareReplay(1),
      ))
    )
  }

  isRouteUrlPermitted$(routeUrl, isAbsolute = true): Observable<boolean> {
    routeUrl = isAbsolute ? NoAbsoluteUrl(routeUrl) : routeUrl
    return this.getPermittedRouteUrls$().pipe(
      map((routeUrls) => this._isSomePermittedRouteUrl(routeUrls, routeUrl)),
      catchError(() => of(false)),
    )
  }

  isRouteUrlPermitted(routeUrl, isAbsolute = true): boolean {
    routeUrl = isAbsolute ? NoAbsoluteUrl(routeUrl) : routeUrl
    return this._isSomePermittedRouteUrl(this._permittedRouteUrls, routeUrl)
  }

  filterPermittedRouteUrls<T extends { routeUrl: string }>(
    routes: T[],
    isAbsolute = true,
  ): Observable<T[]> {
    return this.getPermittedRouteUrls$().pipe(
      map((_routeUrls) =>
        routes.filter(({ routeUrl }) =>
          this._isSomePermittedRouteUrl(
            _routeUrls,
            isAbsolute ? NoAbsoluteUrl(routeUrl) : routeUrl,
          ),
        ),
      ),
    )
  }

  isPermissionKeyPermitted$(permissionKey): Observable<boolean> {
    return this.getContentPermissionKeys$().pipe(
      map((permittedKeys) => (permittedKeys || []).includes(permissionKey)),
    )
  }
}
