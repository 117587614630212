import { ChangeDetectionStrategy, Component } from '@angular/core'
import { DialogConfig, SegmentedControlChange } from '@mediacoach/ui'
import { BehaviorSubject, Observable, switchMap } from 'rxjs'
import { SORT_ICON_CONFIG } from '../../constants/widget.constants'
import { fadeInSlideAnimation } from '@widgets/animations/fade-in-slide.animation'
import { WidgetQuery, WidgetQueryMetricType } from '@widgets/models/widget.models'
import { SpaceRankedMetric } from '@core/space/models/space-metrics.models'
import { SegmentOption } from '@core/models/models/common.models'
import { AGGREGATION_OPTIONS } from '@widgets/dialogs/dialog-ranking-metric/constants/dialog-ranking-metric.constants'

@Component({
  selector: 'mcp-dialog-metric-ranking',
  templateUrl: './dialog-metric-ranking.component.html',
  styleUrl: './dialog-metric-ranking.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInSlideAnimation],
})
export class DialogMetricRankingComponent {
  private _metricRankingSubject$$: BehaviorSubject<WidgetQuery>
  metricRanking$: Observable<SpaceRankedMetric[]>

  readonly sortIconConfig = SORT_ICON_CONFIG
  readonly aggregationOptions: SegmentOption[] = AGGREGATION_OPTIONS
  selectedAggregationValue: WidgetQueryMetricType = 'Average'

  get dialogData() {
    return this._dialogConfig?.data
  }

  constructor(private readonly _dialogConfig: DialogConfig) {
    this.selectedAggregationValue = this.dialogData?.query?.metricType
    this._metricRankingSubject$$ = new BehaviorSubject<WidgetQuery>(this.dialogData.query)
    this.metricRanking$ = this._metricRankingSubject$$.pipe(
      switchMap(
        (query) => this.dialogData?.metricRanking$(query) as Observable<SpaceRankedMetric[]>,
      ),
    )
  }

  onAggregationChange({ value: metricType }: SegmentedControlChange) {
    this._metricRankingSubject$$.next({
      ...this._metricRankingSubject$$.value,
      metricType,
    })
  }
}
