import {
  StepPosition,
  TourCustomTemplates,
  TourStepTexts,
} from '@shared/modules/tour/models/tour-step.models'
import { Observable } from 'rxjs'
import { buildObservableCustomTexts, hexToRgb } from '@shared/modules/tour/utils/tour.utils'
import {
  DEFAULT_STEP_BLUR,
  DEFAULT_THEME_COLOR,
  DEFAULT_TIMEOUT_BETWEEN_STEPS,
  STEP_DEFAULT_POSITION,
} from '@shared/modules/tour/constants/tour.constants'

export class ObservableCustomTexts {
  prev$: Observable<string>
  next$: Observable<string>
  done$: Observable<string>
  close$: Observable<string>
  progress$: Observable<string>
}

export class TourOptions extends TourCustomTemplates {
  steps: string[]
  startWith?: string
  waitingTime?: number
  stepDefaultPosition?: StepPosition
  themeColor?: string
  blurIntensity?: string
  showPrevButton?: boolean
  customTexts?: TourStepTexts
  buttonsText?: ObservableCustomTexts
  showProgress?: boolean

  get backdropColor(): string {
    return hexToRgb(this.themeColor)
  }

  constructor(options?: Partial<TourOptions>) {
    super()
    this.steps = options?.steps || []
    this.startWith = options?.startWith
    this.themeColor = options?.startWith || DEFAULT_THEME_COLOR
    this.stepDefaultPosition = options?.stepDefaultPosition || STEP_DEFAULT_POSITION
    this.showPrevButton = options?.showPrevButton ?? true
    this.showProgress = options?.showProgress ?? true
    this.blurIntensity = options?.blurIntensity || DEFAULT_STEP_BLUR
    this.buttonsText = buildObservableCustomTexts(options?.customTexts)
    this.waitingTime = options?.waitingTime ?? DEFAULT_TIMEOUT_BETWEEN_STEPS
    this.prevButtonTpl = options?.prevButtonTpl
    this.doneButtonTpl = options?.doneButtonTpl
    this.nextButtonTpl = options?.nextButtonTpl
    this.closeButtonTpl = options?.closeButtonTpl
    this.progressTpl = options?.progressTpl
  }
}

export interface TourModuleOptions {
  debug: boolean
}
