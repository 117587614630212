import { NgModule } from '@angular/core'
import { FACTORY_TOKEN } from './constants'
import { OidcFactory } from './factories/oidc.factory'
import { OidcService } from './services/oidc.service'

@NgModule({
  providers: [
    OidcService,
    {
      provide: FACTORY_TOKEN,
      useFactory: OidcFactory,
    },
  ],
})
export class OidcModule {}
