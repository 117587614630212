import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import {
  QueryDropdown,
  QueryDropdownOption,
} from '@shared/components/query/models/query-dropdown.model'
import { QueryMultiselect } from '@shared/components/query/models/query-multiselect.model'

@Component({
  selector: 'mcp-query-dropdown-item',
  templateUrl: './query-dropdown-item.component.html',
  styleUrls: ['./query-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryDropdownItemComponent {
  @Input() item: QueryDropdownOption<any>
  @Input() config: QueryDropdown | QueryMultiselect
  @Input() isListItem: boolean
}
