export enum FileUploadType {
  Avatar = 'avatar',
  Common = 'common',
}

export enum FileType {
  Pdf = 'pdf',
  Gif = 'gif',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
  Log = 'log',
  Txt = 'txt',
}
