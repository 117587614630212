import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TourButtonComponent } from './components/tour-button/tour-button.component'
import { TourArrowComponent } from './components/tour-arrow/tour-arrow.component'
import { TourCloseComponent } from './components/tour-close/tour-close.component'
import { TourStepComponent } from './components/tour-step/tour-step.component'
import { TourProgressComponent } from './components/tour-progress/tour-progress.component'
import { StepPositionDirective } from './directives/step-position.directive'
import { TourModuleOptions } from '@shared/modules/tour/models/tour-options.models'
import { TOUR_LOGGER_ENABLED } from '@shared/modules/tour/tokens/tour.tokens'

@NgModule({
  declarations: [
    TourButtonComponent,
    TourArrowComponent,
    TourCloseComponent,
    TourStepComponent,
    TourProgressComponent,
    StepPositionDirective,
  ],
  imports: [CommonModule],
})
export class TourModule {
  static forRoot(opt?: TourModuleOptions) {
    return {
      ngModule: TourModule,
      providers: [{ provide: TOUR_LOGGER_ENABLED, useValue: opt?.debug }],
    }
  }
}
