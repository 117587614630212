import { Component, EventEmitter, Input, Output } from '@angular/core'

import { environment } from '@env'

import { TitleSize } from '@shared/components/title/title.constants'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { PlayerPosition } from '@core/enums/player.enums'
import { MetricAggregationHeader } from '@core/models/models/metric.models'
import { MetricAggregation } from '@core/enums/metric.enums'

@Component({
  selector: 'app-player-chart-metrics',
  templateUrl: './player-chart-metrics.component.html',
  styleUrls: [
    './player-chart-metrics.component.theme.scss',
    './player-chart-metrics.component.scss',
  ],
})
export class PlayerChartMetricsComponent {
  private _metricData

  @Input() data
  @Input() competition: Competition
  @Input() currentPlayer = {} as any
  @Input() showTeamMarksInList = true
  @Input() isCollapsibleList = true
  @Input() isFromMatch = false
  @Input() isSwitchable = false
  @Input() hasLineup = false
  @Input() aggregationHeader: MetricAggregationHeader
  @Input() aggregationMode: MetricAggregation
  @Input() showAggregationIcon: boolean
  @Input() lang: string

  get metricsData() {
    return this._metricData
  }

  @Input() set metricsData(_metricData) {
    this._metricData = _metricData
  }

  @Output() chartMetricChange = new EventEmitter<string>()
  @Output() changePosition = new EventEmitter<void>()
  @Output() aggregationModeClick = new EventEmitter<any>()

  envType = environment.envType

  TitleSize = TitleSize
  MetricAggregation = MetricAggregation
  readonly competitionIdentifier = 'LALIGA'
  readonly playerPlaceholder = PLACEHOLDER_IMAGES.PLAYER
  readonly competitionPlaceholder = PLACEHOLDER_IMAGES.COMPETITION

  constructor() {}

  canChangePlayerPosition() {
    return this.currentPlayer.metricPlayerPosition !== PlayerPosition.Goalkeeper
  }

  changePlayerPosition() {
    if (this.canChangePlayerPosition()) {
      this.changePosition.emit()
    }
  }
}
