import { EnvType, firstLetterToLowerCase } from '@mediacoach-ui-library/global'
import packageJson from '../../package.json'

/* eslint-disable @typescript-eslint/naming-convention, max-len */
const MAIN_APP_NAME = 'portal'
const MAIN_APP_API = 'portal-api'

const GetEnvironmentInfo = (env: EnvType) => {
  const {
    COMMERCIAL_DOMAIN,
    APP_DOMAIN,
    IDENTITY_SERVER,
    API_PORTAL_URL,
    API_INTEGRATED_DOMAIN,
    API_TV_DOMAIN,
  } = GetDomainInfo(env)
  const STATICS_URL_ROOT = GetUrlByEnv('https://festatics.mediacoach.es/', env)
  const STATICS_FULL_URL = (path: string) => STATICS_URL_ROOT + firstLetterToLowerCase(path)

  const ORIGIN_DOMAIN = window.location.origin

  return {
    production: false,
    serverMocks: false,

    DEFAULT_ROUTE: '',
    API_KEY_SECURE: 'Bearer',

    APP_DOMAIN,

    ORIGIN_DOMAIN,

    STATICS_URL_ROOT,

    STATICS_URL_ASSETS: 'assets/',

    LEGAL_NOTICE_URL: (lang) =>
      STATICS_FULL_URL(`Licenser/LicenseAgreement/License-Agreement_${lang}.html`),

    DOWNLOADS_VERSION_FILE_PATH: {
      live: {
        ios: STATICS_FULL_URL('Live/iOS/updatesMCLive.txt'),
        android: STATICS_FULL_URL('Live/Android/updatesMCLive.txt'),
      },
      desktop: {
        win: STATICS_FULL_URL('Desktop/Win/Installer/updatesMCDesktop.txt'),
        mac: STATICS_FULL_URL('Desktop/MacOS/Installer/updates.json'),
      },
      mobile: {
        ios: STATICS_FULL_URL('Mobile/iOS/updatesMCMobile.txt'),
        android: STATICS_FULL_URL('Mobile/Android/updatesMCMobile.txt'),
      },
      livePro: {
        ios: STATICS_FULL_URL('livepro/iOS/updatesMCLivePRO.txt'),
      },
    },

    PRIVACY_POLICY: 'https://policies.mediacoach.es/privacy',

    RECAPTCHA_SITE_KEY: '6Ld7xZ8UAAAAABoGqReo4tz4uMkK8oj0MrMIv1HS',

    DEBOUNCE_TIME: {
      FOR_CRASHES: 10,
      NORMAL: 100,
      INPUT_CHANGES: 200,
      CURSOR_CHANGES: 1000,
      LONG: 200,
    },

    TOAST_TIME: {
      DEFAULT: 3000,
      LONG: 5000,
    },

    ALLOW_ASSET_STREAMING: true,

    I18N_LANGS: [
      {
        id: 'es',
        locale: 'es-ES',
        i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_ES',
      },
      {
        id: 'en',
        locale: 'en-GB',
        i18nLabel: 'COMMONS_HEADER_OP_MENU_LANG_EN',
      },
    ],
    I18N_DEFAULT_LANG: 'es',

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-108719301-1',

    SMART_LOOK_API_KEY: '9ccdb4ab02c65eb0b614fbace6eaf9a9755046b3',

    POWER_BI_BASE_URL: 'https://app.powerbi.com/reportEmbed',

    POWER_BI_WORKSPACE: 'b4ce5693-889b-42ed-8865-e24e98f57816',

    // POWER_BI_CURRENT_SEASON: '2023-2024',
    POWER_BI_CURRENT_SEASON: '2024-2025',

    VIMEO_API_USER: '/110927286',

    PROFILE_TYPE_BLACKLIST: [],

    DOWNLOAD_PRODUCTS_BLACKLIST: [],

    API: {
      VERSION: '1.0',
      MAIN_PATH: `${API_PORTAL_URL}/`,
      GET_URL_DOWNLOAD: `${API_PORTAL_URL}/downloads`,
      GET_POWER_BI_REPORTS: `${API_PORTAL_URL}/powerbireports`,
      GET_VIMEO_ASSETS_BY_CONNECTION: `${API_PORTAL_URL}/vimeo`,
      GET_VIMEO_ALBUMS_BY_USER: `${API_PORTAL_URL}/vimeo/albums`,
      ENABLE_PERMISSION_REQUEST: `${API_PORTAL_URL}/enablePermissionRequest`,
      ISSUES_CRUD: `${API_PORTAL_URL}/issues`,
      ISSUES_CONFIG: `${API_PORTAL_URL}/issues/config`,
      ISSUES_ATTACHMENTS: `${API_PORTAL_URL}/issues/attachments`,
      DELETE_TAG: (tagId) => `${API_PORTAL_URL}/match/tags/${tagId}`,
      PLAYLISTS: `${API_PORTAL_URL}/playlists`,
      PLAYLIST: (id: string) => `${API_PORTAL_URL}/playlists/${id}`,
      PLAYLISTS_COLLABORATORS: `${API_PORTAL_URL}/playlists/collaborators?offset=0&limit=1000`,
      UNSUBSCRIBE_PLAYLIST: (id: string) => `${API_PORTAL_URL}/playlists/${id}/unsubscribe`,
      GET_PLAYLIST_TAGS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags`,
      GET_PLAYLIST_DIMENSIONS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/dimensions`,
      GET_PLAYLIST_CODES: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/codes`,
      DELETE_PLAYLIST_TAG: (id, tagId) => `${API_PORTAL_URL}/playlists/${id}/tags/${tagId}`,
      EXPORT_PLAYLIST_TAGS: (id) => `${API_PORTAL_URL}/playlists/${id}/tags/export`,
      UPDATE_TAG: (matchId: string, tagId: string) =>
        `${API_PORTAL_URL}/match/${matchId}/tags/${tagId}`,
      SHARE_TAGS: (matchId: string, playlistId?: string) =>
        `${API_PORTAL_URL}/match/${matchId}/tags/share${playlistId ? `?playlistId=${playlistId}` : ''}`,
      QUICK_TAG: (matchId: string) => `${API_PORTAL_URL}/match/${matchId}/tags`,
    },

    INTEGRATED_API: GetIntegratedServicesApi(API_INTEGRATED_DOMAIN, API_TV_DOMAIN),
    APIM_API: getApimApi(env),

    IDENTITY_SERVER: {
      AUTHORITY_URL: IDENTITY_SERVER,
      REDIRECT_URI: `${ORIGIN_DOMAIN}/auth`,
      API_LOGOUT: `${IDENTITY_SERVER}/connect/endsession`,
      POST_LOGOUT_REDIRECT_URI: COMMERCIAL_DOMAIN,
      TAG_POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
      SILENT_REDIRECT_URI: `${ORIGIN_DOMAIN}/silent`,
      CLIENT_ID: '39e03e15-7240-8199-8068-ae6c344d8161',
      ACCESS_TOKEN_EXPIRING_NOTIFICATIONTIME: 4,
      LOAD_USER_INFO: true,
      RESPONSE_TYPE: 'token id_token',
      SCOPE:
        'openid profile portal portal-backend integratedservices-api integratednotifications-api tagmanager-api presetsmanager-api',
    },
  }
}

const GetDomainInfo = (env: EnvType) => ({
  COMMERCIAL_DOMAIN: GetUrlByEnv('https://www.mediacoach.es', env),
  APP_DOMAIN: GetUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
  IDENTITY_SERVER: GetUrlByEnv('https://id.mediacoach.es', env),
  API_PORTAL_URL: GetUrlByEnv(`https://${MAIN_APP_API}.mediacoach.es`, env),
  API_INTEGRATED_DOMAIN: GetUrlByEnv('https://integratedservices-api.mediacoach.es', env),
  API_TV_DOMAIN: GetUrlByEnv('https://portal-ws.mediacoach.es', env),
})

const GetUrlByEnv = (domain, env) => {
  const chunks = domain.split('.')
  chunks[0] += env === EnvType.Pr ? '' : `-${env}`
  return chunks.join('.')
}

export const GetEnvironment = (env: EnvType): any => ({
  appVersion: packageJson?.version,
  appBuild: '1374',
  appRelease: '12122024.1710',
  appBranch: 'develop',
  envType: env,
  ...GetEnvironmentInfo(env),
  // ...VARIABLES_BY_ENVIRONMENT[env],
})

export const getApimApi = (env: EnvType) => {
  const baseUrl = GetDomainInfo(env).API_PORTAL_URL
  const playerHeader = (
    teamId: string,
    playerId: string,
    seasonId: string,
    competitionId: string,
  ) =>
    `${baseUrl}/teams/${teamId}/players/${playerId}/seasons/${seasonId}/competitions/${competitionId}`

  return {
    MAIN_PATH: baseUrl,
    TEAM_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/team`,
    TEAM_TEMPLATES: `${baseUrl}/layoutconfiguration/team/templates`,
    TEAM_HEADER: (seasonId: string, competitionId: string, teamId: string) =>
      `${baseUrl}/seasons/${seasonId}/competitions/${competitionId}/teams/${teamId}`,
    COMPETITIONS_WIDGET: `${baseUrl}/widgets/competition`,
    DEMARCATIONS: `${baseUrl}/metricdefinitions/playerPositions`,
    TEAM_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/team`,
    WIDGETS: `${baseUrl}/widgets`,
    SPIDER_CHART: `${baseUrl}/widgets/spiderchart`,
    RANKING_WIDGETS: `${baseUrl}/widgets/ranking`,
    PLAYER_CAREER: `${baseUrl}/widgets/player-career`,
    SEASON_DATA: `${baseUrl}/widgets/matches`,
    GOALKEEPER_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/goalkeeper`,
    GOALKEEPER_TEMPLATES: `${baseUrl}/layoutconfiguration/goalkeeper/templates`,
    PLAYER_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/player`,
    PLAYER_TEMPLATES: `${baseUrl}/layoutconfiguration/player/templates`,
    PLAYER_HEADER: playerHeader,
    PLAYER_HEADER_METRICS: (
      teamId: string,
      playerId: string,
      seasonId: string,
      competitionId: string,
    ) => `${playerHeader(teamId, playerId, seasonId, competitionId)}/competitionsInfo`,
    GOALKEEPER_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/goalkeeper`,
    PLAYER_METRIC_DEFINITION: `${baseUrl}/metricdefinitions/player`,
    SEARCH_PLAYERS: `${baseUrl}/players/search`,
    STANDINGS: (matchdayId: string) => `${baseUrl}/matchdays/${matchdayId}/standings`,
    COMPETITION_ACTIVE_LAYOUT: `${baseUrl}/layoutconfiguration/competition`,
    COMPETITION_HEADER: (seasonId: string, competitionId: string) =>
      `${baseUrl}/season/${seasonId}/competition/${competitionId}`,
    MATCHES_BY_MATCH_DAY: (matchDayId: string) => `${baseUrl}/matchdays/${matchDayId}/matches`,
  }
}

export const GetIntegratedServicesApi = (appDomain, websocketDomain) => {
  const apiPath = `${appDomain}/api/`

  const MATCHES = (matchId?) => `${apiPath}matches${matchId ? `/${matchId}` : ''}`

  const SEASONS = (id?) => `${apiPath}seasons${id ? `/${id}` : ''}`
  const COMPETITIONS = (seasonId, competitionId?) =>
    `${SEASONS(seasonId)}/competitions${competitionId ? `/${competitionId}` : ''}`
  const MATCHES_BY_SEASON_COMPETITION = (seasonId, competitionId) =>
    `${COMPETITIONS(seasonId, competitionId)}/matches`
  const TEAMS = (seasonId, competitionId, teamId?) =>
    `${COMPETITIONS(seasonId, competitionId)}/teams${teamId ? `/${teamId}` : ''}`
  const PLAYERS = (seasonId, competitionId, teamId, id?) =>
    `${TEAMS(seasonId, competitionId, teamId)}/players${id ? `/${id}` : ''}`
  const USER_PROFILE = `${apiPath}userprofile`
  return {
    MAIN_PATH: apiPath,
    USER_PROFILE,
    UPDATE_AVATAR: `${USER_PROFILE}/avatar`,
    SEASONS,
    SETTINGS: `${apiPath}settings`,
    TRANSLATIONS: (language) => `${apiPath}translations/${language}`,
    TEAMS,
    TEAM_OVERVIEW_MATCHES: (seasonId, teamId) =>
      `${apiPath}seasons/${seasonId}/teams/${teamId}/overviewmatches`,
    LAST_TEAM_MATCHES: (seasonId, teamId) =>
      `${apiPath}seasons/${seasonId}/teams/${teamId}/lastmatches`,
    TEAM_METRIC_RESULTS: (seasonId, competitionId, teamId) =>
      `${TEAMS(seasonId, competitionId, teamId)}/metricresults`,
    TEAMS_COMPARE: `${apiPath}teams/compare`,
    MATCHDAYS: `${apiPath}matchdays`,
    PLAYERS,
    PLAYERS_METRIC_RESULTS: (seasonId, competitionId, teamId, id) =>
      `${PLAYERS(seasonId, competitionId, teamId, id)}/metricresults`,
    PLAYERS_SEARCH: `${apiPath}players/search`,
    PLAYERS_COMPARE: `${apiPath}players/compare`,
    MATCH_PLAYERS_COMPARE: (matchId) => `${apiPath}matches/${matchId}/players/compare`,
    PLAYER_LAST_PLAYED_MATCHES: (seasonId, id) =>
      `${SEASONS(seasonId)}/players/${id}/lastplayedmatches`,
    PLAYER_LAST_PLAYED_MATCH: (seasonId, id) =>
      `${SEASONS(seasonId)}/players/${id}/lastplayedmatch`,
    STANDINGS_BY_MATCHDAY_ID: (matchDayId) => `${apiPath}matchdays/${matchDayId}/standings`,
    MATCH_PLAYER_BY_ID: (matchId, teamId, id) =>
      `${apiPath}matches/${matchId}/teams/${teamId}/players/${id}`,
    MATCHES,
    LAST_MATCHES: (matchId) => `${MATCHES(matchId)}/lastnmatches`,

    MATCH_STREAMING: (matchId) => `${MATCHES(matchId)}/streaming`,
    MATCH_PASSMATRIX: (matchId) => `${MATCHES(matchId)}/passmatrix`,
    MATCH_HEATMAP: (matchId) => `${MATCHES(matchId)}/heatmap`,
    MATCH_TEAM_METRICS: (matchId) => `${MATCHES(matchId)}/teammetricresults`,
    MATCH_PLAYER_LIST_METRICS: (matchId) => `${MATCHES(matchId)}/playerlistmetricresults`,
    MATCHES_BY_SEASON_COMPETITION,
    METRIC_DEFINITIONS: `${apiPath}metricdefinitions`,
    WEB_SOCKET: (accessToken) => `${websocketDomain}?access_token=${accessToken}`,
    FILTER_MATCHES_TIMELINE: (id) => `${apiPath}matches/${id}/streamevents?videoMetadataType=ALL`,
    MATCH_REFEREES: (matchId: string) => `${MATCHES(matchId)}/referees`,
    MATCH_KIT_SELECTOR: (matchId: string) => `${MATCHES(matchId)}/kitselector`,
    MATCH_GREEN_KEEPER: (matchId: string) => `${MATCHES(matchId)}/greenkeeper`,
    MATCH_SUNLIGHT: (matchId: string) => `${MATCHES(matchId)}/sunlight`,
    CALENDAR_COMPETITION_MATCHES: ({ seasonId, itemId, from, to }) =>
      `${MATCHES_BY_SEASON_COMPETITION(
        seasonId,
        itemId,
      )}betweendates?dateFrom=${from}&dateTo=${to}`,
    CALENDAR_TEAM_MATCHES: ({ seasonId, itemId, from, to }) =>
      `${SEASONS(seasonId)}/teams/${itemId}/matchesbetweendates?dateFrom=${from}&dateTo=${to}`,
  }
}
/* eslint-enable @typescript-eslint/naming-convention, max-len */
