import { PeriodEdge } from '@mediacoach-ui-library/global'

export const MIN_CHAR_TO_FILTER = 2

export const REPORTS_WITH_TWO_VERSIONS = ['ReportTeamPostMatch', 'ReportPlayersPostMatch']

export const VIDEO_EXTENSIONS = ['mp4']

export const REPORTS_WITH_TWO_TEAMS = [
  'ReportTeamAdvancePreMatch',
  'ReportTeamPreMatch',
  'ReportPhy',
  'WarmUp',
  'ReportMaxExigency',
]

export const TWO_TEAM_CODES = {
  H: 'home',
  A: 'away',
}

export const MARKER_PERIOD_EDGES = [PeriodEdge.Start, PeriodEdge.End] // [PeriodEdge.Start, PeriodEdge.End]

export const PERIOD_EDGE_MAPPING = {
  [PeriodEdge.Start]: 'startTimeItems',
  [PeriodEdge.End]: 'endTimeItems',
}
