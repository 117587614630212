import { FlattenDeep } from '@mediacoach-ui-library/global'

export const getSelectedItems = (items) => (items || []).filter((item) => item.selected)

export const areItemsSelected = (items) => !!getSelectedItems(items).length

export const areAllItemsSelected = (items, selectedItems = getSelectedItems(items)) => {
  if (!selectedItems.length) {
    return false
  }
  return selectedItems.length === items.length
}

export const toggleItem = (item) => {
  item.selected = !item.selected
}

export const selectAllItems = (items) => {
  items.forEach((match) => (match.selected = true))
}

export const unselectAllItems = (selectedItems) => {
  getSelectedItems(selectedItems).forEach((item) => (item.selected = false))
}

export const toggleItems = (items, selectedItems = getSelectedItems(items)) => {
  if (areAllItemsSelected(items, selectedItems)) {
    unselectAllItems(selectedItems)
    return
  }

  selectAllItems(items)
}

export const groupBy = (items, key, mapFn?) => {
  const itemsObj = items.reduce(
    (obj, item) => ({ ...obj, [item[key]]: [...(obj[item[key]] || []), item] }),
    {},
  )
  return Object.keys(itemsObj).map((_key) => mapFn(_key, itemsObj[_key]))
}

export const getUniqueValuesByKey = (arr: any[], key: string): any[] =>
  Array.from(new Set(arr.filter((item) => item).map((item) => item[key])))

export const createUniqueArrayByKey = (arr: any[], key: string) => [
  ...new Map([...arr].map((item) => [item[key], item])).values(),
]

export const split = (arr: any[], index: number): any[] => [arr.slice(0, index), arr.slice(index)]
export const distinctItems = (arr: any[]): any[] => Array.from(new Set(arr))
export const isSomeItemInArr = (arr1, arr2) => arr1.some((item) => arr2.includes(item))

export const sortSpecific = (arr: any[], key: string, orderArr) => {
  const order = Array.from(orderArr).reverse()
  return Array.from(arr || [])
    .sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]))
    .reverse()
}
export const areArrayValuesEmpty = (arr) =>
  arr.length < 1 || FlattenDeep(arr).every(({ value }) => value == null)

export const moveMemberFromTo = <T>(arr: T[], from: number, to: number, on: number = 1): T[] => {
  const _arr = arr.slice()
  _arr.splice(to, 0, ..._arr.splice(from, on))
  return _arr
}

export const asArray = (arrayLike) => {
  const array = []
  const length = arrayLike.length
  for (let i = 0; i < length; i++) {
    array.push(arrayLike[i])
  }
  return array
}

export const deepCloneArray = <T = unknown>(array: T[] = []): T[] =>
  array.map((item) => ({ ...item }))

export const filterByList = (items, predicateList: string[], key = 'id') =>
  (items || []).filter((item) => predicateList.includes(item[key]))
