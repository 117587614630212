import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { AnalyticsEffects } from '@core/analytics/state/effects/analytics.effects'
import { SmartLookService } from '@core/analytics/services/smart-look.service'
import { SmartLookEffects } from '@core/analytics/state/effects/smart-look.effects'
import { GtagService } from '@core/analytics/services/gtag.service'
import { AnalyticsFacade } from '@core/analytics/analytics.facade'

@NgModule({
  imports: [EffectsModule.forFeature([AnalyticsEffects, SmartLookEffects])],
  providers: [SmartLookService, GtagService, AnalyticsFacade],
})
export class AnalyticsModule {}
