import { Component, Input } from '@angular/core'

@Component({
  selector: 'mcp-morph-button',
  templateUrl: './morph-button.component.html',
  styleUrls: ['./morph-button.component.scss'],
})
export class MorphButtonComponent {
  @Input() state: 'close' | ''
  @Input() color: 'white' | 'gray' | ''

  constructor() {}
}
