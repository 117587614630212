import { WidgetColumn } from '@shared/modules/widgets/models/widget.models'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { Column } from '@mediacoach/ui'

export const TOP_BOTTOM_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_WIDGET_METRIC',
    key: 'metricKey',
    hasInteraction: true,
  },
  {
    label: 'MTR_WIDGET_VALUE',
    key: 'valueFormatted',
    width: '100px',
    alignment: 'center',
    sortable: true,
    roundPipePattern: '1.0-2',
  },
  {
    label: 'MTR_WIDGET_RANKING',
    key: 'ranked',
    width: '100px',
    alignment: 'center',
    sortable: true,
    sortableKey: 'rank',
  },
]

export const SPACE_PLAYER_RANKED_METRIC_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_WIDGET_POS',
    key: 'position',
    width: '70px',
    displayType: 'text',
    alignment: 'center',
    sortable: true,
  },
  {
    label: 'MTR_TITLE_PLAYER',
    key: 'playerFormatted',
    displayType: 'textAndImage',
    placeholderImage: PLACEHOLDER_IMAGES.PLAYER,
    showAsterisk: true,
    imageKey: 'playerImage',
    sortable: true,
    navigableKey: 'route',
    extraTextKey: 'specificPositionKey',
  },
  {
    label: 'MTR_TITLE_TEAM',
    key: 'name',
    displayType: 'image',
    placeholderImage: PLACEHOLDER_IMAGES.TEAM,
    imageKey: 'teamImage',
    alignment: 'center',
    width: '70px',
    showHyphenOnFlag: true,
    navigableKey: 'teamRoute',
  },
  {
    label: 'MTR_WIDGET_VALUE',
    key: 'valueFormatted',
    width: '70px',
    alignment: 'center',
    roundPipePattern: '1.0-2',
    sortable: true,
  },
]

export const SPACE_TEAM_RANKED_METRIC_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_WIDGET_POS',
    key: 'position',
    width: '70px',
    displayType: 'text',
    alignment: 'center',
    sortable: true,
  },
  {
    label: 'MTR_TITLE_TEAM',
    key: 'name',
    displayType: 'textAndImage',
    placeholderImage: PLACEHOLDER_IMAGES.TEAM,
    imageKey: 'teamImage',
    sortable: true,
    navigableKey: 'route',
  },
  {
    label: 'MTR_WIDGET_VALUE',
    key: 'valueFormatted',
    width: '70px',
    alignment: 'center',
    sortable: true,
    roundPipePattern: '1.0-2',
  },
]

export const SPACE_COMMON_STANDING_COLUMNS: Column[] = [
  {
    key: 'position',
    label: 'MTR_TITLE_POSITION',
    sticky: true,
    sortDirection: 'asc',
    sortable: true,
    width: '247px',
    styleClass: 'left',
  },
  {
    label: 'MTR_HEADER_POINTS_SHORT',
    sortable: true,
    key: 'points',
    tooltip: 'MTR_HEADER_POINTS',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_PLAYEDMATCHES_SHORT',
    sortable: true,
    key: 'played',
    tooltip: 'MTR_HEADER_PLAYEDMATCHES',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_WONMATCHES_SHORT',
    sortable: true,
    key: 'won',
    tooltip: 'MTR_HEADER_WONMATCHES',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_DRAWMATCHES_SHORT',
    sortable: true,
    key: 'drawn',
    tooltip: 'MTR_HEADER_DRAWMATCHES',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_LOSTMATCHES_SHORT',
    sortable: true,
    key: 'lost',
    tooltip: 'MTR_HEADER_LOSTMATCHES',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_GOALSFOR_SHORT',
    sortable: true,
    key: 'for',
    tooltip: 'MTR_HEADER_GOALSFOR',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_GOALSAGAINST_SHORT',
    sortable: true,
    key: 'against',
    tooltip: 'MTR_HEADER_GOALSAGAINST',
    alignment: 'center',
  },
  {
    label: 'MTR_HEADER_GOALSDIFFERENCE_SHORT',
    sortable: true,
    key: 'goalDifference',
    tooltip: 'MTR_HEADER_GOALSDIFFERENCE',
    alignment: 'center',
  },
]

export const SPACE_TOP_PLAYERS_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_TITLE_PLAYER',
    key: 'playerFormatted',
    displayType: 'textAndImage',
    placeholderImage: PLACEHOLDER_IMAGES.PLAYER,
    showAsterisk: true,
    imageKey: 'playerImage',
    sortable: true,
    navigableKey: 'route',
    extraTextKey: 'specificPositionKey',
  },
  {
    label: 'MTR_TITLE_TEAM',
    key: 'teamImage',
    width: '70px',
    displayType: 'image',
    placeholderImage: PLACEHOLDER_IMAGES.TEAM,
    imageKey: 'teamImage',
    alignment: 'center',
    showHyphenOnFlag: true,
    navigableKey: 'teamRoute',
  },
  {
    label: 'MTR_WIDGET_METRIC',
    key: 'metricKey',
    hasInteraction: true,
  },
  {
    label: 'MTR_WIDGET_VALUE',
    key: 'valueFormatted',
    width: '70px',
    alignment: 'center',
    sortable: true,
    roundPipePattern: '1.0-2',
  },
]

export const SPACE_TOP_TEAMS_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_TITLE_TEAM',
    key: 'name',
    displayType: 'textAndImage',
    placeholderImage: PLACEHOLDER_IMAGES.TEAM,
    imageKey: 'teamImage',
    navigableKey: 'route',
    sortable: true,
  },
  {
    label: 'MTR_WIDGET_METRIC',
    key: 'metricKey',
    hasInteraction: true,
    sortable: true,
  },
  {
    label: 'MTR_WIDGET_VALUE',
    key: 'valueFormatted',
    width: '70px',
    alignment: 'center',
    sortable: true,
    roundPipePattern: '1.0-2',
  },
]
