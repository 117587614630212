import { McpTeam } from '@core/models/dto/team.dto'
import { Competition } from '@core/models/dto/season.dto'
import { McpPlayer } from '@core/models/dto/player.dto'
import { CHART_COLORS, generateRandomHexColor } from '@mediacoach/ui'
import { getStaticItem, smallColorLandscapeLogoPredicate } from '@core/utils/assets.utils'
import { WidgetDisplayMode, WidgetQuery, WidgetQueryCompare } from '@widgets/models/widget.models'
import { COLOR_TRANSPARENT } from '@widgets/widgets/radar-chart-widget/constants/radar-chart-widget.constants'

const findComparedEntityMetadata = (displayMode: WidgetDisplayMode, id: string) => {
  return displayMode?.comparison.find((c) => {
    if (c.playerId || c.teamId) {
      return c.playerId === id || c.teamId === id
    }
    return c.seasonCompetitionId === id
  })
}

const resolveComplexLegendTopBlock = (entity: any, competitionText: string) => {
  if (entity.id) {
    return {
      topText: (entity as McpTeam).abbreviation ?? entity.metricPlayerName,
      topAltImage: (entity as McpTeam).shortName ?? entity.metricPlayerName,
      topImage: (entity as McpTeam).portraitLogo,
    }
  } else if (entity.playerId) {
    return {
      topText: entity.playerMetricName,
      topAltImage: entity.playerMetricName,
      topImage: entity.playerPortraitLogo,
    }
  } else if (entity.teamId) {
    return {
      topText: entity.teamAbbreviation,
      topAltImage: entity.teamName,
      topImage: entity.teamPortraitLogo,
    }
  } else {
    return {
      topText: competitionText,
      topAltImage: competitionText,
      topImage: null,
    }
  }
}
const resolveComplexLegendMiddleBlock = (entity: any, competition: Competition) => {
  return {
    middleImage: entity?.id
      ? getStaticItem(competition.statics, smallColorLandscapeLogoPredicate)
      : entity?.competitionLandscapeLogo,

    middleAltImage: entity?.id ? competition.officialName : entity?.competitionName,
  }
}
const buildComplexLegendItem = (
  entity: any,
  competition: Competition,
  competitionText: string,
  idx: number,
) => {
  return {
    removable: idx !== 0,
    ...resolveComplexLegendTopBlock(entity, competitionText),
    ...resolveComplexLegendMiddleBlock(entity, competition),
    bottomText: entity?.id ? competition.seasonPill : entity?.seasonName.replace(/\s.{4}/, '/'),
    context: {
      ...entity,
      seasonId: entity?.id ? competition.seasonId : entity.seasonId,
      competitionId: entity?.id ? competition.id : entity.competitionId,
    },
  }
}

const buildSimpleLegendItem = (
  entity: McpPlayer | McpTeam | Competition | WidgetQueryCompare,
  competition: Competition,
  competitionText: string,
  isCompetition: boolean,
) => {
  return {
    topImage: isCompetition ? null : (entity as any).portraitLogo,
    topAltImage: isCompetition
      ? competitionText
      : ((entity as McpTeam).shortName ?? (entity as McpPlayer).metricPlayerName),
    topText: isCompetition
      ? competitionText
      : ((entity as McpTeam).abbreviation ?? (entity as McpPlayer).metricPlayerName),
    middleImage: getStaticItem(competition.statics, smallColorLandscapeLogoPredicate),
    middleAltImage: competition.officialName,
    bottomText: competition.seasonPill,
    context: {
      ...entity,
      seasonId: competition.seasonId,
      competitionId: competition.id,
    },
  }
}

const resolveEntity = (
  datasetId: string,
  competition: Competition,
  displayMode: WidgetDisplayMode,
  isComparison: boolean,
): any => {
  return isComparison ? findComparedEntityMetadata(displayMode, datasetId) : competition
}

const resolveLabel = (
  entity: McpTeam | McpPlayer | Competition | WidgetQueryCompare,
  competitionText: string,
): string => {
  return (
    (entity as WidgetQueryCompare).playerMetricName ??
    (entity as WidgetQueryCompare).teamAbbreviation ??
    (entity as McpPlayer).metricPlayerName ??
    (entity as McpTeam).abbreviation ??
    competitionText
  )
}

const resolveLegend = (
  entity: McpTeam | McpPlayer | Competition | WidgetQueryCompare,
  competition: Competition,
  competitionText: string,
  isCompetition: boolean,
  isComparison: boolean,
  idx: number,
) => {
  return isComparison
    ? buildComplexLegendItem(entity, competition, competitionText, idx)
    : buildSimpleLegendItem(entity, competition, competitionText, isCompetition)
}

export const buildRadarChartDataset = (
  entities: any,
  displayMode: WidgetDisplayMode,
  query: WidgetQuery,
  reference: McpTeam | McpPlayer,
  competition: Competition,
  competitionText: string,
) => {
  const isComparison = !!query?.compare?.length
  return entities.map((d: any, idx: number) => {
    const entity =
      idx === 0 ? reference : resolveEntity(d.id, competition, displayMode, isComparison)
    const color = idx < CHART_COLORS.length ? CHART_COLORS[idx] : generateRandomHexColor()
    const label = resolveLabel(entity, competitionText)
    const isCompetition = !entity.playerId && !entity.teamId && entity.seasonCompetitionId

    const legend = resolveLegend(
      entity,
      competition,
      competitionText,
      isCompetition,
      isComparison,
      idx,
    )

    return {
      uuid: entity.uuid ?? crypto.randomUUID(),
      entity,
      isCompetition,
      competition,
      legend,
      label,
      backgroundColor: `${color}33`,
      borderColor: color,
      data: d.metricResults,
      pointBorderColor: COLOR_TRANSPARENT,
      pointBackgroundColor: COLOR_TRANSPARENT,
      hoverBorderColor: color,
      hoverBackgroundColor: color,
      legendAccentColor: color,
    }
  })
}
