import { AssetType, ReportType, VideoType } from '@mediacoach-ui-library/global'

export const ASSET_ORDER: AssetType[] = [
  VideoType.Tactical,
  VideoType.Tv,
  VideoType.Panoramic,
  ReportType.Reports,
]

export const ASSET_TYPE_TRANSLATIONS = {
  [VideoType.Panoramic]: 'MATCHDETAIL_MEDIA_SUB_PANA',
  [VideoType.Tv]: 'MATCHDETAIL_MEDIA_SUB_TV',
  [VideoType.Tactical]: 'MATCHDETAIL_MEDIA_SUB_TACTICALCAMERA',
  [ReportType.Reports]: 'MATCHES_CONTENT_LAB_REPORTS',
}
