<div
  class="c-card"
  [class.hover]="isHovered"
  [class.c-card--no-route]="!isRouteUrlPermitted"
  [routerLink]="isRouteUrlPermitted ? data.url : null"
>
  <div class="c-card__main">
    <img
      [appDefaultImage]="data.placeholderImage"
      [style.background-image]="'url(' + IMAGES_PATH.CARD_BACKGROUND + ')'"
      class="c-card__image"
      [srcUrl]="data.image"
    />
    <div class="c-card__main-content" [style.padding-bottom.px]="footerHeight">
      <ng-container
        *ngTemplateOutlet="contentTemplate; context: { $implicit: data }"
      ></ng-container>
    </div>
  </div>
  <div
    class="c-card__footer"
    [style.margin-bottom.px]="-footerHeight"
    [style.transform]="'translateY(' + -footerHeight + 'px)'"
  >
    <div class="c-card__footer-top">
      <img [src]="IMAGES_PATH.TRIANGLE_GRADIENT" [hidden]="!cardImage?.isImgLoaded" />
      <img [src]="IMAGES_PATH.TRIANGLE_BLACK" />
      <div class="c-card__footer-wrapper">
        <p *ngIf="data.extra != null" class="c-card__footer-extra">{{ data.extra | translate }}</p>
        <span class="c-card__footer-content">
          <h1 [title]="(data.title | translate) || '&nbsp;'">
            {{ (data.title | translate) || '&nbsp;' }}
          </h1>
          <h2
            [mcuiTooltip]="
              data | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' '
            "
            [tooltipDisabled]="!isPlayer"
          >
            {{
              isPlayer
                ? (data | demarcation: playerDemarcationKeys : playerDemarcationFallbackKey : ' ')
                : (data.subtitle | translate) || '&nbsp;'
            }}
          </h2>
        </span>
      </div>
      <span *permissionKey="noAbsoluteUrl; isRouteKey: true" class="c-card__footer-icon">
        <i class="u-icon--dart-right-squared mc-translate-gradient-animation"></i>
      </span>
    </div>
    <div class="c-card__footer-bottom" (click)="$event.stopPropagation()" #footerBottom>
      <div>
        <ng-container
          *ngTemplateOutlet="footerTemplate; context: { $implicit: data }"
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template [ngIf]="isRouteUrlPermitted$ | async"></ng-template>
<ng-template [ngIf]="onWindowResize$ | async"></ng-template>
