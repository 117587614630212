import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { PLACEHOLDER_IMAGES } from '@mediacoach/ui'
import { PlayerListItem } from '@mediacoach-ui-library/global'
import {
  PLAYER_DEMARCATION_FALLBACK_KEY,
  PLAYER_DEMARCATION_KEYS,
} from '@core/constants/player.constants'

@Component({
  selector: 'mcp-player-list-item',
  templateUrl: './player-list-item.component.html',
  styleUrls: ['./player-list-item.component.theme.scss', './player-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerListItemComponent {
  @Input() public data: PlayerListItem
  @Input() public extraClasses: 'mcp-player-list-item__hover' | undefined
  @Input() public isNavigationActive: boolean

  readonly teamPlaceholder = PLACEHOLDER_IMAGES.TEAM
  readonly playerPlaceholder = PLACEHOLDER_IMAGES.PLAYER
  readonly playerDemarcationKeys = PLAYER_DEMARCATION_KEYS
  readonly playerDemarcationFallbackKey = PLAYER_DEMARCATION_FALLBACK_KEY
}
