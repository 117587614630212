export enum RoutesEnum {
  Matches = 'matches',
  MatchDetail = 'match-detail',
  Calendar = 'calendar',
  Teams = 'teams',
  Players = 'players',
  Competitions = 'competitions',
  Monitoring = 'monitoring',
  BeyondStats = 'beyond-stats',
  More = 'more',
  Applications = 'applications',
  Forbidden = 'forbidden',
  Portal = 'portal',
  Login = 'login',
  Auth = 'auth',
  Silent = 'silent',
  ExternalRedirect = 'externalRedirect',
  UserConcurrency = 'user-concurrency',
}
