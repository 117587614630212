import { createAction, props } from '@ngrx/store'
import {
  Competition,
  Injury,
  MatchMultiLive,
  MatchStream,
  Membership,
} from '@features/playground/models/playground.state'
import { Match } from '@core/models/dto/match.dto'
import { McpTeam } from '@core/models/dto/team.dto'
import { McpPlayer } from '@core/models/dto/player.dto'

export const fetchStreams = createAction(
  '[Playground] fetch live streams',
  props<{ streams: Match[] }>(),
)
export const setStreams = createAction(
  '[Playground] set streams',
  props<{ streams: MatchStream[] }>(),
)
export const fetchStreamsWithMultiLive = createAction(
  '[Playground] fetch live streams with multi live',
  props<{ streamsMultiLive: MatchMultiLive[] }>(),
)
export const setStreamsWithMultiLive = createAction(
  '[Playground] set streams with multi live',
  props<{ streamsMultiLive: any[] }>(),
)

export const resolveStream = createAction('[Playground] resolve stream', props<{ stream: any }>())

export const setStreamResolved = createAction(
  '[Playground] set stream resolved',
  props<{ streamResolved: any }>(),
)

export const fetchVOD = createAction('[Playground] fetch VOD', props<{ matchId: string }>())
export const setVODAsset = createAction('[Playground] set VOD', props<{ vod: string }>())

export const fetchPlayerCareerTeams = createAction(
  '[Playground - Player Career] fetch teams',
  props<{ seasonId: string; competitionId: string }>(),
)
export const setPlayerCareerTeams = createAction(
  '[Playground - Player Career] set teams',
  props<{ teams: McpTeam[] }>(),
)
export const setPlayerCareerSelectedTeam = createAction(
  '[Playground - Player Career] select team',
  props<{ selectedTeam: McpTeam }>(),
)
export const initializePlayerCareerSelectedTeam = createAction(
  '[Playground - Player Career] initialize selected team',
)
export const fetchPlayerCareerPlayers = createAction(
  '[Playground - Player Career] Fetch players',
  props<{ seasonId: string; competitionId: string; teamId?: string; isCoach?: boolean }>(),
)
export const setPlayerCareerPlayers = createAction(
  '[Playground - Player Career] Set Players',
  props<{ players: McpPlayer[] }>(),
)
export const setPlayerCareerSelectedPlayer = createAction(
  '[Playground - Player Career] Select player',
  props<{ selectedPlayer: McpPlayer }>(),
)
export const fetchPlayerCareerInfo = createAction(
  '[Playground - Player Career] fetch player career Info',
  props<{ optdaId: string }>(),
)
export const setPlayerCareerInfo = createAction(
  '[Playground - Player Career] Set player career Info',
  props<{ info: any; error?: unknown }>(),
)
export const fetchPlayerCareerBio = createAction(
  '[Playground - Player Career] fetch player career Bio',
  props<{ optdaId: string }>(),
)
export const setPlayerCareerBio = createAction(
  '[Playground - Player Career] Set player career Bio',
  props<{ bio: string[]; error?: unknown }>(),
)
export const fetchPlayerCareerInjuries = createAction(
  '[Playground - Player Career] fetch player career Injuries',
  props<{ optdaId: string }>(),
)
export const setPlayerCareerInjuries = createAction(
  '[Playground - Player Career] Set player career Injuries',
  props<{ injuries: Injury[]; error?: unknown }>(),
)
export const fetchPlayerCareerTransfers = createAction(
  '[Playground - Player Career] fetch player career Transfers',
  props<{ optdaId: string }>(),
)
export const setPlayerCareerTransfers = createAction(
  '[Playground - Player Career] Set player career Transfers',
  props<{ transfers: Membership[]; error?: unknown }>(),
)
export const fetchPlayerCareerTrophies = createAction(
  '[Playground - Player Career] fetch player career Trophies',
  props<{ optdaId: string }>(),
)
export const setPlayerCareerTrophies = createAction(
  '[Playground - Player Career] Set player career Trophies',
  props<{ trophies: Competition[]; error?: unknown }>(),
)
