import { StreamState } from '@core/state/models/stream.state'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { commonReducer } from '@core/state/reducers/common.reducer'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { Action } from '@ngrx/store'

export function streamReducer(
  state: StreamState | undefined,
  action: Action,
  identifier: MergedTokens = StoreToken.stream,
) {
  return commonReducer(identifier)(state, action)
}
