import {
  Competition,
  ParseStatics,
  Season,
  sortBy,
  StaticOrientation,
} from '@mediacoach-ui-library/global'
import { MatchDay } from '@core/models/dto/match.dto'
import {
  CompetitionQuery,
  CompetitionQueryValue,
  MatchDayQueryValue,
  SeasonQuery,
} from '@core/models/dto/season.dto'
import { QueryDropdownOption } from '@shared/components/query/models/query-dropdown.model'
import { MatchDayType } from '@core/enums/matches.enum'
import {
  getStaticItem,
  smallColorLandscapeLogoPredicate,
  whiteLogoLandscapePredicate,
  whiteLogoPredicate,
} from './assets.utils'

export const mapSeasons = ({ seasons }: { seasons: Season[] }) =>
  sortBy(
    seasons.map((season) => ({
      ...season,
      name: season.officialName,
      order: -parseInt(season.officialName, 10),
      competitions: season.competitions
        .map((competition) => ({
          ...competition,
          name: competition.officialName,
          seasonId: season.id,
          currentMatchDayNumber: (
            competition.matchdays?.items?.find(({ selected }) => selected) || ({} as MatchDay)
          )?.number,
          matchdays: sortBy(
            competition.matchdays?.items?.map((matchDay) => ({
              ...matchDay,
              name: matchDay.number,
              order: -matchDay.number,
            })) || [],
          ),
          ...ParseStatics(competition.statics, [
            StaticOrientation.Landscape,
            StaticOrientation.Portrait,
          ]),
        }))
        .map((competition) => ({
          ...competition,
          imageSrc: competition.logo,
        })),
    })),
  )

export const getSeasonAndCompetition = (
  competitionId: string,
  seasons: Season[],
): {
  season: Season
  competition: Competition
} => {
  for (const season of seasons) {
    for (const competition of season.competitions) {
      if (competition.id === competitionId) {
        return { season, competition }
      }
    }
  }
}

export const getSeasonAndCompetitionBySeasonIdAndCompetitionId = (
  seasonId: string,
  competitionId: string,
  seasons: Season[],
): { season: Season; competition: Competition } => {
  const season = seasons.find((s) => s.id === seasonId) || seasons[0]
  const competition =
    (season?.competitions || []).find((c) => c.id === competitionId) || season?.competitions[0]
  return { season, competition }
}

export const getSeasonIdByCompetition = (
  competitionId: string,
  seasons: Season[],
  storeMode = false,
): {
  competitionId?: string
  seasonId?: string
  season?: string
  competition?: string
  playerId?: string
} => {
  if (competitionId) {
    const { season, competition } = getSeasonAndCompetition(competitionId, seasons)
    if (season) {
      return storeMode
        ? {
            competition: competition.id,
            season: season.id,
          }
        : {
            competitionId: competition.id,
            seasonId: season.id,
          }
    }
  }
}

export const mapSeasonAndCompetition = (
  competitionId,
  season,
): { season: Season; competition: Competition } => {
  const competition = season.competitions.find((c) => c.id === competitionId)
  return {
    season: {
      ...season,
      formattedName: season.name.split(' - '),
    },
    competition: {
      ...competition,
      ...getCompetitionWithLogos(competition),
    },
  }
}

export const getSeasonAndCompetitionById = (
  seasonId: string,
  competitionId: string,
  seasons: Season[],
  setDefault?: boolean,
) => {
  const season = seasons.find((s) => s.id === seasonId)
  let competition = ((season || ({} as Season)).competitions || []).find(
    (c) => c.id === competitionId,
  )
  if (!competition && setDefault) {
    competition = (season ? season : seasons[0]).competitions[0]
  }
  return { season, competition }
}

export const mapCompetition = (
  competition: Competition,
): Competition & Pick<Competition, 'logo'> => ({
  ...competition,
  logo: getStaticItem(competition.statics, smallColorLandscapeLogoPredicate),
})

export const cleanCurrentSeasonMatchDays = (
  matchDays: MatchDay[],
  isCurrentSeason?: boolean,
): MatchDay[] => {
  const idx = isCurrentSeason ? matchDays.findIndex(({ selected }) => selected) : 0
  return matchDays.slice(
    idx !== -1 && idx - MatchDayType.CurrentPlusTwo > 0 ? idx - MatchDayType.CurrentPlusTwo : 0,
    matchDays.length,
  )
}

export const mapMatchDays = (
  { number, id, selected }: MatchDay,
  competitionId: string,
  seasonId: string,
): QueryDropdownOption<MatchDayQueryValue> => ({
  label: number.toString(),
  value: { id, selected, competitionId, seasonId },
})

export const mapQueryableCompetition = (
  { id, imageSrc, matchdays, name, seasonId }: Competition,
  addMatchDays?: boolean,
  isCurrentSeason?: boolean,
): CompetitionQuery => {
  let value = { id, imageSrc, name, seasonId }
  if (addMatchDays) {
    value = {
      ...value,
      matchDays: cleanCurrentSeasonMatchDays(matchdays, isCurrentSeason).map((m) =>
        mapMatchDays(m, id, seasonId),
      ),
    } as CompetitionQueryValue
  }
  return {
    label: name,
    value,
  }
}

export const getQueryableSeasonAndCompetition = (
  seasonId: string,
  competitionId: string,
  seasons: SeasonQuery[],
): { season: SeasonQuery; competition: CompetitionQuery } => {
  const season = seasons.find((s) => s.value.id === seasonId) || seasons[0]
  const competition =
    (season?.value?.competitions || []).find((c) => c.value.id === competitionId) ||
    season?.value?.competitions[0]
  return { season, competition }
}

export const getCompetitionWithLogos = (competition: Competition) => ({
  logo: getStaticItem(competition.statics, smallColorLandscapeLogoPredicate),
  whiteCompetitionLogo: getStaticItem(competition.statics, whiteLogoPredicate),
  whiteCompetitionLogoLandscape: getStaticItem(competition.statics, whiteLogoLandscapePredicate),
})

export const findDefaultSeasonAndCompetition = (
  seasons: Season[] = [],
): { competitionId: string; seasonId: string } | undefined => {
  const currentSeason = seasons.find((s) => s.current)
  const currentCompetition = currentSeason.competitions[0]

  return currentSeason && currentCompetition
    ? { seasonId: currentSeason.id, competitionId: currentCompetition.id }
    : undefined
}
