import { UserState } from '@core/state/models/user.state'
import { Action, createReducer, on } from '@ngrx/store'
import { setOidcUser } from '@core/state/actions/oidc.actions'
import {
  purgeUserProfile,
  setUserApplications,
  setUserAvatar,
  setUserProfile,
} from '@core/state/actions/profile.actions'

const initialState: UserState = {
  oidc: null,
  profile: null,
  applications: null,
}

const reducer = createReducer(
  initialState,
  on(setOidcUser, (state, { oidc }) => ({ ...state, oidc })),
  on(setUserProfile, (state, { profile }) => ({
    ...state,
    profile: {
      ...state.profile,
      ...profile,
      ...(profile && profile.favourites
        ? {
            favouriteTeamId: profile.favourites.teamId,
            favouriteCompetitionId: profile.favourites.competitionId,
          }
        : {}),
      ...(profile?.avatarPath ? { avatar: profile.avatarPath } : {}),
    },
  })),
  on(setUserAvatar, (state, { avatar }) => ({ ...state, profile: { ...state.profile, avatar } })),
  on(setUserApplications, (state, { applications }) => ({ ...state, applications })),
  on(purgeUserProfile, () => ({ ...initialState })),
)

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action)
}
