import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { ApiTeams } from '../../models/models/team.models'
import { environment } from '@env'
import { ApiPlayers } from '@core/models/models/player.models'
import { SpaceMetricDefinition, SpaceRankedMetric } from '@core/space/models/space-metrics.models'
import { WidgetQuery } from '@widgets/models/widget.models'
import { TeamSpaceCompetitionWidgetData } from '@features/team-space/models/team-space.models'
import {
  SpaceDemarcationCategory,
  SpaceMatchDayLeaderboard,
  SpaceTemplate,
  SpaceTemplateWrapper,
} from '@core/space/models/space.models'

@Injectable()
export class SpaceApi {
  private readonly _http: HttpClient = inject(HttpClient)

  fetchTeams(seasonId: string, competitionId: string): Observable<ApiTeams> {
    return this._http.get<ApiTeams>(environment.INTEGRATED_API.TEAMS(seasonId, competitionId))
  }

  fetchPlayers(seasonId: string, competitionId: string, teamId: string): Observable<ApiPlayers> {
    return this._http.get<ApiPlayers>(
      environment.INTEGRATED_API.PLAYERS(seasonId, competitionId, teamId),
      { params: { showInscriptionExpired: 'true' } },
    )
  }

  searchPlayers(searchParams: string, competitionId: string, seasonId: string): Observable<any> {
    return this._http.get<any>(environment.APIM_API.SEARCH_PLAYERS, {
      params: { value: searchParams, competitionId, seasonId },
    })
  }

  fetchSpaceWidgets(layoutUrl: string): Observable<string> {
    return this._http.get(layoutUrl, { responseType: 'text' })
  }

  saveSpaceWidgets(layoutUrl: string, widgets: string) {
    return this._http.put(layoutUrl, { body: widgets })
  }

  fetchSpaceTemplates(layoutUrl: string): Observable<SpaceTemplateWrapper> {
    return this._http.get<SpaceTemplateWrapper>(`${layoutUrl}/templates`)
  }

  saveSpaceTemplate(layoutUrl: string, template: SpaceTemplate) {
    return this._http.post(`${layoutUrl}/templates`, template)
  }

  updateSpaceTemplate(layoutUrl: string, oldName: string, template: SpaceTemplate) {
    return this._http.put(`${layoutUrl}/templates/${oldName}`, template)
  }

  deleteSpaceTemplate(layoutUrl: string, templateName: string) {
    return this._http.delete(`${layoutUrl}/templates/${templateName}`)
  }

  fetchSpaceTeamMetricDefinition(): Observable<SpaceMetricDefinition[]> {
    return this._http.get<SpaceMetricDefinition[]>(environment.APIM_API.TEAM_METRIC_DEFINITION)
  }

  fetchSpacePlayerMetricDefinition(): Observable<SpaceMetricDefinition[]> {
    return this._http.get<SpaceMetricDefinition[]>(environment.APIM_API.PLAYER_METRIC_DEFINITION)
  }

  fetchSpaceGoalkeeperMetricDefinition(): Observable<SpaceMetricDefinition[]> {
    return this._http.get<SpaceMetricDefinition[]>(
      environment.APIM_API.GOALKEEPER_METRIC_DEFINITION,
    )
  }

  fetchSpaceCompetitionsWidget(query: WidgetQuery): Observable<TeamSpaceCompetitionWidgetData> {
    return this._http.post<TeamSpaceCompetitionWidgetData>(
      environment.APIM_API.COMPETITIONS_WIDGET,
      query,
    )
  }

  fetchSpaceStandingsWidget(matchdayId: string): Observable<SpaceMatchDayLeaderboard> {
    return this._http.get<SpaceMatchDayLeaderboard>(environment.APIM_API.STANDINGS(matchdayId))
  }

  fetchSpaceRankingWidget(query: WidgetQuery): Observable<SpaceRankedMetric[]> {
    return this._http.post<SpaceRankedMetric[]>(environment.APIM_API.RANKING_WIDGETS, query)
  }

  fetchSpaceDemarcations(): Observable<SpaceDemarcationCategory[]> {
    return this._http.get<SpaceDemarcationCategory[]>(environment.APIM_API.DEMARCATIONS)
  }
}
