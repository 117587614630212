import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { EventManager } from '@angular/platform-browser'
import { Observable } from 'rxjs'

interface Options {
  element: any
  keys: string
}

@Injectable()
export class HotkeysService {
  defaults: Partial<Options> = {
    element: this.document,
  }

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  addShortcut(options: Partial<Options>): Observable<any> {
    const merged = { ...this.defaults, ...(options?.element ? options : {}) }
    const event = `keydown.${merged.keys}`

    return new Observable((observer) => {
      const handler = (e: any): void => {
        e.preventDefault()
        observer.next(e)
      }

      const dispose = this.eventManager?.addEventListener(merged.element, event, handler)

      return (): void => {
        dispose()
      }
    })
  }
}
