import videojs from 'video.js'
import { VideoJsToastComponent } from './videojs-toast.component'

const Plugin = videojs.getPlugin('plugin')

class ToastPlugin extends Plugin {
  constructor(player, options) {
    super(player)

    this.options = videojs.obj.merge({}, options)
    this.player = player

    this.toastComponent = new VideoJsToastComponent(this.player, this.options)
    this.player.addChild(this.toastComponent)
  }

  /**
   * Show a toast message
   * @param {Toast} toast - The message to display
   */
  showToast(toast) {
    this.toastComponent.setupToast(toast)
    this.toastComponent.show()

    // Auto-hide after a delay
    setTimeout(() => this.toastComponent.hide(), toast.duration ?? this.options.duration ?? 3000)
  }
}
// Register the plugin with Video.js
videojs.registerPlugin('toastPlugin', ToastPlugin)
export default ToastPlugin
