import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Match } from '@core/models/dto/match.dto'
import {
  MatchItemDisplayDirection,
  MatchItemDisplayMode,
  MatchItemTemplate,
  MatchItemTemplateConfig,
} from '@shared/components/match-item/models/match-item.model'
import { DEFAULT_TEMPLATES } from '@shared/components/match-item/constants/match-item.constants'
import {
  MatchItemModifier,
  MatchStateSize,
  PLACEHOLDER_IMAGES,
} from '@mediacoach-ui-library/global'
import { LiveClipStatus } from '@features/matches/enums/matches.enum'
import { ContentPermissionKey } from '@auth/enums/auth.enum'
import { environment } from '@env'
import { transformMatch } from './utils/match-item.utils'

@Component({
  selector: 'mcp-match-item',
  templateUrl: './match-item.component.html',
  styleUrls: [
    './match-item.component.scss',
    './styles/match-item--banner.component.scss',
    './styles/match-item--small.component.scss',
    './styles/match-item--row.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemComponent {
  private _modifiers: MatchItemModifier[] = []
  private _match: Match
  get modifiers() {
    return this._modifiers
  }
  @Input() set modifiers(_modifiers) {
    this._modifiers = _modifiers
    this.size = (this.modifiers || []).find((i) => i === MatchStateSize.SizeLg)
  }

  private _display: MatchItemDisplayMode
  get display(): MatchItemDisplayMode {
    return this._display
  }
  @Input() set display(_d: MatchItemDisplayMode) {
    this._display = _d
    this.isBanner = this.display === MatchItemDisplayMode.Banner
  }

  get match(): Match {
    return this._match
  }
  @Input() set match(m: Match) {
    this._match = transformMatch(m)
  }
  @Input() direction: MatchItemDisplayDirection
  @Input() templates: MatchItemTemplateConfig[] = DEFAULT_TEMPLATES
  @Input() extraClasses: string
  @Input() showLivePlay: boolean

  @Output() playAction: EventEmitter<any> = new EventEmitter<any>()

  readonly templateType = MatchItemTemplate
  readonly placeholder = PLACEHOLDER_IMAGES.COMPETITION
  readonly status = LiveClipStatus
  readonly playButtonPath = environment.STATICS_URL_ASSETS + 'icon/live-play.svg'

  size: MatchStateSize
  isBanner: boolean
  contentPermission = ContentPermissionKey.LiveVideo

  get cssClass(): string {
    return [this.extraClasses, this.display, this.direction].filter(Boolean).join(' ')
  }
}
