import { inject, Injectable } from '@angular/core'
import { ToastService, ToastType } from '@mediacoach/ui'
import { Action } from '@ngrx/store'
import { Observable, OperatorFunction, throwError } from 'rxjs'
import { catchError, switchMap, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
  providedIn: 'root',
})
export class BaseEffectsHandler {
  protected readonly _toast = inject(ToastService)
  protected readonly _translate = inject(TranslateService)

  protected okMessage = 'MTR_SAVE_OK'
  protected koMessage = 'MTR_SAVE_KO'

  protected handleRequest<T>(
    actions: Action[],
    okMessage: string = this.okMessage,
    koMessage: string = this.koMessage,
  ): OperatorFunction<T, Action> {
    return (source): Observable<Action> =>
      source.pipe(
        switchMap(() => this._showToast(okMessage, 'success')),
        switchMap(() => actions),
        catchError((err) => {
          return this._showToast(koMessage, 'error').pipe(switchMap(() => throwError(() => err)))
        }),
      )
  }

  protected _showToast(i18nMsgKey: string, toastType: ToastType): Observable<any> {
    return this._translate
      .get(i18nMsgKey)
      .pipe(tap((text: string) => this._toast.show({ message: text }, { type: toastType })))
  }
}
