import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { Cacheable } from '@shared/decorators/cacheable.decorator'
import { REFRESH_TIME } from '@core/constants/time.constants'
import { ApiPlayer, ApiPlayers } from '@core/models/models/player.models'
import { ApiMetric } from '@core/models/models/metric.models'

@Injectable()
export class PlayerApi {
  constructor(private readonly _http: HttpClient) {}

  @Cacheable(REFRESH_TIME.THIRTY_MIN * 1000)
  comparePlayersOverall(comparison): Observable<any> {
    return this._http.post<any>(environment.INTEGRATED_API.PLAYERS_COMPARE, comparison)
  }

  fetchPlayers(seasonId: string, competitionId: string, teamId: string): Observable<ApiPlayers> {
    return this._http.get<ApiPlayers>(
      environment.INTEGRATED_API.PLAYERS(seasonId, competitionId, teamId),
      { params: { showInscriptionExpired: 'true' } },
    )
  }

  fetchPlayer(
    id: string,
    seasonId: string,
    competitionId: string,
    teamId: string,
  ): Observable<ApiPlayer> {
    return this._http.get<ApiPlayer>(
      environment.INTEGRATED_API.PLAYERS(seasonId, competitionId, teamId, id),
    )
  }

  fetchPlayerMetrics(
    seasonId: string,
    competitionId: string,
    teamId: string,
    id: string,
    playerPosition,
  ): Observable<ApiMetric> {
    return this._http.get<ApiMetric>(
      environment.INTEGRATED_API.PLAYERS_METRIC_RESULTS(seasonId, competitionId, teamId, id),
      { params: { playerPosition } },
    )
  }
}
