<ng-container *ngIf="field?.options | optionsResolver as options">
  <ng-template #opt let-item="item"> {{ item.label | translate }}</ng-template>
  <ng-template #selected let-displayText="displayText">
    {{ displayText | multipleTranslate }}</ng-template
  >

  <mcui-select
    class="mcp-filter__multiselect"
    panelStyleClass="mcui-select-panel--common"
    [maxSelectedItemsToDisplay]="field?.maxLabelsAllowed"
    [multi]="true"
    [options]="options"
    [optionTemplate]="opt"
    [optionSelectedTemplate]="selected"
    checkboxSize="mini"
    checkboxBackgroundColor="#4d4d4d"
    [formControl]="control"
    placeholder="{{ 'FILTERS_LAB_DEFAULT' | translate }}"
    label="{{ field?.placeholder | translate }}"
    textToDisplayWhenMaxReached="{{ 'FILTERS_LAB_PLURAL' | translate }}"
    selectAllLabel="{{ 'MATCHES_RESULTS_BTN_SELECTALL' | translate }}"
    emptyMsg="{{ 'MTR_COMMON_NO_RESULTS' | translate }}"
  ></mcui-select>
</ng-container>
