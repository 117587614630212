import { Injectable } from '@angular/core'

import { filter } from 'rxjs/operators'
import { BehaviorSubject, fromEvent } from 'rxjs'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WindowEventsService {
  private isDragging = new BehaviorSubject<boolean>(false)

  constructor() {
    fromEvent(window, 'drop')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.isDragging.next(false))
    fromEvent(window, 'dragenter')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.isDragging.next(true))
    fromEvent(window, 'dragleave')
      .pipe(
        untilDestroyed(this),
        filter<MouseEvent>(({ screenX, screenY }) => screenX + screenY === 0),
      )
      .subscribe(() => this.isDragging.next(false))
  }

  getDraggingEvent() {
    return this.isDragging
  }
}
