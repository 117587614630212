<div class="c-list-view">
  <ng-container *ngIf="items?.length > 0; else noResultsWrapper">
    <ng-content></ng-content>
    <ng-container *ngTemplateOutlet="isProgressiveLoad && loading"></ng-container>
  </ng-container>
  <ng-template #noResultsWrapper>
    <div
      class="c-list-view__no-content"
      *ngIf="items?.length === 0 && !isProgressiveLoad; else loading"
    >
      {{ 'MATCHES_LOADS_LAB_NO_RESULT' | translate }}
    </div>
  </ng-template>
</div>
<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
