export enum ReportRoute {
  teams = 'teams' as any,
  players = 'players' as any,
  goalkeepers = 'goalkeepers' as any,
  styleEfficiency = 'style-of-play' as any,
  playerStats = 'player-stats-comparator' as any,
  accumulatedTeams = 'teams-accumulated' as any,
  physicalPerformance = 'physical-performance' as any,
  physicalIntervals = 'interval-physical' as any,
  gameInterruptions = 'game-interruptions' as any,
  compEvolutionEffectiveTime = 'effective-time-evolution' as any,
  referees = 'referees' as any,
  playersMLPhysical = 'players-ml-physical' as any,
  teamsMl = 'teams-ml' as any,
  playersMl = 'players-ml' as any,
  wcsMl = 'wcs-ml' as any,
  finishing = 'finishing' as any,
  playModels = 'play-models' as any,
  history = 'history' as any,
}
