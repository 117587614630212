import { ACTIVE_FILTERS_TYPE } from '@features/playlist/constants/playlist.constants'
import { SafeObjectData } from '@mediacoach-ui-library/global'
import { FilterType, QuickTagTime, TagType } from '@features/playlist/enums/playlist.enums'
import { MatchTeamData } from '@core/models/dto/match.dto'
import {
  TagResponse,
  TagVideoInfoResponse,
} from '@features/playlist/models/playlist.response.models'

export const hasFiltersActive = (filterValues) =>
  !!(
    ACTIVE_FILTERS_TYPE.some((type) => SafeObjectData(filterValues, [type], []).length) ||
    SafeObjectData(filterValues, [FilterType.All], []).filter((item) => item.id !== FilterType.All)
      ?.length
  ) as boolean

export const getTeamPlayers = (team: MatchTeamData) =>
  team.squad && team.squad.length > 0 ? team.squad : team.lineup || []

export const translateTagName = (codeSnapshot, translations) => {
  if (!codeSnapshot) {
    return ''
  }
  if (codeSnapshot.category) {
    const localizedCategory = translations[codeSnapshot.category]?.toUpperCase()
    if (codeSnapshot.subCategory) {
      return `${localizedCategory || codeSnapshot.category} - ${translations[codeSnapshot.subCategory] || codeSnapshot.subCategory}`
    }
    return localizedCategory || codeSnapshot.category
  }
  return translations[codeSnapshot.name]?.toUpperCase() || codeSnapshot.name
}

export const createQuickTag = ({
  periodNumber,
  videosInfo,
}: {
  periodNumber: number
  videosInfo: TagVideoInfoResponse[]
}): TagResponse => ({
  clientUniqueId: crypto.randomUUID() as string,
  comment: '',
  periodNumber: periodNumber ?? 1,
  videosInfo,
  tagTypeId: TagType.Event,
  isQuick: true,
  codeSnapshot: {
    id: crypto.randomUUID() as string,
    name: 'MTR_TAGGING_QUICK_TAG',
    color: '#212121',
    secondsVisualizeAfter: QuickTagTime.After,
    secondsVisualizeBefore: QuickTagTime.Before,
  },
})

export const getMatchMoment = (value: number, periods: any[]): number | null => {
  if (periods.length === 0) {
    return null
  } else {
    let matchMoment = 0
    for (const period of periods) {
      if (value >= period.startTime) {
        const multiplier = 1000 * period.type + period.start
        matchMoment = Math.floor((value - period.startTime) / 60) + multiplier
      }
    }
    return matchMoment
  }
}

export const getCurrentPeriod = (value: number, periods: any[]): number | null => {
  const matchMoment = getMatchMoment(value, periods)
  if (matchMoment !== null) {
    return Math.floor(matchMoment / 1000)
  }
  return null
}
