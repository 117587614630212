import { Injectable } from '@angular/core'
import { SmartLookError, SmartLookUser } from '@core/analytics/models/smartlook.models'
import { environment } from '@env'

declare const smartlook

@Injectable()
export class SmartLookService {
  constructor() {
    if (environment.enableSmartLook) {
      smartlook('record', {
        forms: false,
        numbers: false,
        emails: true,
        ips: false,
      })
    }
  }

  trackUser(userId: string, userData: SmartLookUser): void {
    smartlook('identify', userId, userData)
  }

  trackError({ code, message }: SmartLookError): void {
    smartlook('error', `[CODE:${code}] ${message}`)
  }
}
