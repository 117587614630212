<div class="mcp-stream-header">
  <div class="mcp-stream-header__match-item">
    <mc-match-item
      *ngIf="matchItemData"
      [match]="matchItemData"
      [modifiers]="[matchItemModifier.Dark]"
    >
    </mc-match-item>
  </div>
  <div class="mcp-stream-header__navigation">
    @if (!hideNavigation) {
      <div
        *permissionKey="routePermissionKey; isRouteKey: true"
        class="mcp-stream-modal__navigation mcp-stream-modal__button c-match-streaming-modal__button c-match-streaming-modal__button--go-to-detail"
        (click)="navigate.emit(match)"
      >
        {{ 'MATCHDETAIL_GO_TO_MATCHDETAIL' | translate | uppercase }}
        <i class="u-icon--dart-right"></i>
      </div>
    }
  </div>

  <div class="mcp-stream-header__asset-selector">
    <mcp-camera-selector
      [ref]="ref"
      [isLive]="match?.isLive"
      [disabled]="match?.isLive"
      [cameraList]="cameraList"
      [selected]="selectedCamera"
      (cameraChange)="cameraChange.emit($event)"
    ></mcp-camera-selector>
  </div>
  <div class="mcp-stream-header__close c-modal__btn" (click)="close.emit()">
    <mcp-morph-button [state]="'close'" [color]="'white'"></mcp-morph-button>
  </div>
</div>
