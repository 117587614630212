import { createFeatureSelector, createSelector } from '@ngrx/store'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { NavigationState } from '@core/state/models/navigation.state'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'
import { getMergedRoute } from '@core/router/state/selectors/router.selectors'
import { MergedRoute } from '@core/router/models'
import { checkReplaceUrlMatchers } from '@core/utils/navigation.utils'

export const selectNavigation = createFeatureSelector<NavigationState>(StoreToken.navigation)

const getNavigableItems = createSelector(
  selectNavigation,
  (state: NavigationState): NavigableItem[] => state.items,
)

export const getNavigationItems = createSelector(
  getNavigableItems,
  getMergedRoute,
  (navigableItems: NavigableItem[], route: MergedRoute): NavigableItem[] =>
    navigableItems.map((navigableItem) => {
      const _navItem = {
        ...navigableItem,
        route: checkReplaceUrlMatchers(navigableItem.replaceByCurrentUrlMatchers, route.url)
          ? route.url
          : navigableItem.route,
      }
      if (navigableItem.items) {
        _navItem.items = navigableItem.items.map((item) => ({
          ...item,
          styleClass: '/' + item.route === route.url ? 'selected' : undefined,
        }))
      }
      return _navItem
    }),
)
