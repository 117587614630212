<ng-template [ngIf]="routeDataChanges$ | async">
  <div class="error-feedback-page__wrapper">
    <mc-error-feedback
      [errorType]="errorType"
      (onUpdatePasswordClick)="updatePassword.modal.openModal()"
      (onButtonClick)="onErrorFeedBackButtonClick()"
    ></mc-error-feedback>
  </div>
  <app-update-password-modal #updatePassword></app-update-password-modal>
</ng-template>
