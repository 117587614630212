export const MatchItemDisplayMode = { Small: 'small', Banner: 'banner', Row: 'row' }
export type MatchItemDisplayMode = (typeof MatchItemDisplayMode)[keyof typeof MatchItemDisplayMode]

export const MatchItemDisplayDirection = { Vertical: 'vertical', Horizontal: 'horizontal' }
export type MatchItemDisplayDirection =
  (typeof MatchItemDisplayDirection)[keyof typeof MatchItemDisplayDirection]

export enum MatchItemTemplate {
  Info = 'info',
  Result = 'result',
  Competition = 'competition',
  Round = 'round',
  Date = 'date-wrapper',
  State = 'state',
  AssetAvailability = 'asset',
  PlayerParticipation = 'player-participation',
  PlayerMinutesPlayed = 'player-minutes-played',
}

export class MatchItemTemplateConfig {
  template: MatchItemTemplate
  wrapperCssClasses?: string
  templateCssClasses?: string
  wrapperStyle?: Partial<CSSStyleDeclaration>
  templateStyle?: Partial<CSSStyleDeclaration>
}
