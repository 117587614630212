<div class="mcp-dialog-content-container">
  <div class="mcp-dialog-content-container__content">
    <ng-content />
  </div>
  <div class="mcp-dialog-content-container__footer">
    <mcui-button
      [disabled]="disabled"
      (click)="apply.emit()"
      [text]="'MTR_WIDGET_DIALOG_APPLY' | translate"
    ></mcui-button>
  </div>
</div>
