import { Component, ViewChild } from '@angular/core'

import { ModalComponent } from '../modal/modal.component'

import { environment } from '@env'

@Component({
  selector: 'app-update-password-modal',
  templateUrl: './update-password-modal.component.html',
  styleUrls: ['./update-password-modal.component.scss'],
})
export class UpdatePasswordModalComponent {
  @ViewChild('modal') modal: ModalComponent

  URLChangePassword =
    environment.IDENTITY_SERVER.AUTHORITY_URL + '/Manage/ChangePassword?embedded=true'

  constructor() {}
}
