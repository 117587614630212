import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { Match } from '@core/models/dto/match.dto'
import { MatchItemTemplateConfig } from '@shared/components/match-item/models/match-item.model'
import { DEFAULT_ROW_TEMPLATES } from '@shared/components/match-item/constants/match-item.constants'
import { SELECTED_MATCH_LIMIT } from './constants/match-item-list.constants'
import { ITableConfig } from '@shared/components/table/table.model'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs/operators'
import { MatchItemListFooterComponent } from '@shared/components/match-item-list/components/match-item-list-footer/match-item-list-footer.component'
import { createUniqueArrayByKey } from '@core/utils/collection.utils'
import { getSelectedMatchList } from '@core/state/selectors/selection.selectors'
import {
  selectMatch,
  setSelectedMatches,
  unselectMatch,
} from '@core/state/actions/selection.actions'

@Component({
  selector: 'mcp-match-item-list-container',
  templateUrl: './match-item-list-container.component.html',
  styleUrls: ['./match-item-list-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchItemListContainerComponent implements OnDestroy {
  private _matches: Match[]

  get matches() {
    return this._matches
  }

  @Input() set matches(m: Match[]) {
    this._setMatches(m)
  }

  @Input() liveMatches: Match[]
  @Input() showLiveMatchesFirst: boolean
  @Input() loading = true
  @Input() keepSelection = true
  @Input() stickyHeader = true
  @Input() config: ITableConfig[]
  @Input() template: TemplateRef<unknown>
  @Input() matchItemTemplates: MatchItemTemplateConfig[] = DEFAULT_ROW_TEMPLATES
  @Input() blockParentOverflow: boolean

  @Output() matchesSelected = new EventEmitter<Match[]>()

  @ViewChild(MatchItemListFooterComponent)
  listFooterComponent: MatchItemListFooterComponent

  limit = SELECTED_MATCH_LIMIT
  selected: Match[] = []

  selectedMatches$ = this._store
    .select(getSelectedMatchList)
    .pipe(tap((value) => (this.selected = value)))

  constructor(private readonly _store: Store) {}

  private _setMatches(m: Match[]): void {
    this._matches = m

    if (!this.keepSelection) {
      this._store.dispatch(setSelectedMatches({ matches: [] }))
    }

    if (m) {
      this._matches = this.matches.map((match, index) => ({
        ...match,
        matchIndex: index,
      }))
      this.setLastLiveStyle()
    }
  }

  private setLastLiveStyle() {
    const liveMatches = this._matches.filter((match) => match.isLive)
    if (liveMatches.length) {
      const lastLive = liveMatches[liveMatches.length - 1]
      this._matches[lastLive.matchIndex] = {
        ...this._matches[lastLive.matchIndex],
        extraClass: 'c-match-item__last-live',
      }
    }
  }

  ngOnDestroy() {
    this._store.dispatch(setSelectedMatches({ matches: [] }))
  }

  isAllSelected() {
    return (
      this.matches.every((match) => this.selected.some((s) => s.id === match.id)) &&
      this.matches.length > 0
    )
  }

  onClose(): void {
    this.matchesSelected.emit(this.selected.slice(0, this.limit))
  }

  onToggleChange(checked: boolean): void {
    if (checked) {
      this._store.dispatch(
        setSelectedMatches({
          matches: createUniqueArrayByKey([...this.selected, ...this.matches], 'id'),
        }),
      )
    } else {
      const matches = this.selected.filter((match) => !this.matches.some((s) => s.id === match.id))
      this._store.dispatch(setSelectedMatches({ matches }))
    }
  }

  onMatchSelected(match: Match): void {
    const idx = this.selected.findIndex((i) => i.id === match.id)
    if (idx === -1) {
      this._store.dispatch(selectMatch({ match }))
    } else {
      this._store.dispatch(unselectMatch({ match }))
    }
  }
}
