import { ChangeDetectionStrategy, Component } from '@angular/core'
import { PlaylistDialogBase } from '@features/playlist/models/playlist-dialog.base'
import { selectPlaylists } from '@core/state/selectors/stream-playlist.merged-selectors'
import { Observable } from 'rxjs'
import { PlaylistResponse } from '@features/playlist/models/playlist.response.models'
import { MANAGE_PLAYLIST_MENU } from '@features/playlist/dialogs/dialog-manage-playlist/constants/manage-playlist.constants'
import { MenuItemEvent } from '@mediacoach/ui'
import { selectUserId } from '@core/state/selectors/user.selectors'
import { ManagePlaylistAction } from '@features/playlist/dialogs/dialog-manage-playlist/enums/manage-playlist.enums'
import {
  leavePlaylist,
  openDeletePlaylistDialog,
  openEditPlaylistDialog,
} from '@core/state/actions/stream-playlist.merged-actions'
import { MergedTokens } from '@core/state/models/merged-tokens.model'
import { map } from 'rxjs/operators'

@Component({
  selector: 'mcp-dialog-manage-playlist',
  templateUrl: './dialog-manage-playlist.component.html',
  styleUrl: './dialog-manage-playlist.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogManagePlaylistComponent extends PlaylistDialogBase {
  readonly contextMenu = MANAGE_PLAYLIST_MENU
  playlists$: Observable<PlaylistResponse[]>
  userId$: Observable<string> = this._store.select(selectUserId)

  private get _identifier(): MergedTokens | undefined {
    return this._dialogConfig?.data?.identifier
  }

  constructor() {
    super()
    this._resolvePayload()
  }

  private _resolvePayload() {
    this.playlists$ = this._store
      .select(selectPlaylists(this.selector))
      .pipe(map((playlists) => playlists.filter((playlist) => !playlist.isDefault)))
  }

  onMenuClick(menuItem: MenuItemEvent, playlist: PlaylistResponse) {
    switch (menuItem.item.id) {
      case ManagePlaylistAction.leavePlaylist:
        this._store.dispatch(leavePlaylist(this._identifier)({ playlist }))
        break

      case ManagePlaylistAction.deletePlaylist:
        this._store.dispatch(
          openDeletePlaylistDialog(this._identifier)({ playlist, displayMode: this.displayMode }),
        )
        break

      case ManagePlaylistAction.editPlaylist:
        this._store.dispatch(
          openEditPlaylistDialog(this._identifier)({ playlist, displayMode: this.displayMode }),
        )
        break
    }
  }

  close() {}
}
