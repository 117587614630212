import { Component, Input } from '@angular/core'

import { TeamStatsModel } from './team-stats.constants'

@Component({
  selector: 'app-team-stats',
  templateUrl: './team-stats.component.html',
  styleUrls: ['./team-stats.component.scss'],
})
export class TeamStatsComponent {
  @Input() statsData: TeamStatsModel[]

  constructor() {}
}
