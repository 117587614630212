import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '@env'
import { map } from 'rxjs/operators'
import { ReportInitialData } from '@core/models/models/powerbi.models'

@Injectable()
export class PowerBIApi {
  constructor(private readonly _http: HttpClient) {}

  fetchPowerBiReportToken(reportId: string): Observable<any> {
    return this._http
      .get(environment.API.GET_POWER_BI_REPORTS + '/' + reportId)
      .pipe(map((data: ReportInitialData) => data.embedToken))
  }
}
