import { Pipe, PipeTransform } from '@angular/core'
import { PlayerList } from '@shared/components/player-comparison/player-comparison.models'

@Pipe({
  name: 'excludePlayer',
})
export class ExcludePlayerPipe implements PipeTransform {
  transform(playerList: PlayerList, playerId: string): PlayerList {
    if (playerList && playerId) {
      return {
        ...playerList,
        groups: [{ items: playerList.groups[0].items.filter((p) => p.id !== playerId) }],
      }
    }
    return playerList
  }
}
