import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { Column, TemplateData } from '@shared/components/grid/models/grid.models'
import {
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_ROW_HEIGHT,
} from '@shared/components/grid/constants/grid.constants'

@Directive({
  selector: '[mcpGridTpl]',
})
export class GridTemplateDirective<T = unknown> {
  private _columns: Column[]
  private _rows: T[]
  private _headerHeight: string
  private _rowHeight: string
  private _firstRun = true

  @Input('mcpGridTpl')
  set data({ columns, rows, headerHeight, rowHeight }: TemplateData) {
    this._columns = columns
    this._rows = rows as T[]
    this._rowHeight = rowHeight || DEFAULT_ROW_HEIGHT
    this._headerHeight = headerHeight || DEFAULT_HEADER_HEIGHT

    if (columns && rows && columns.length > 0 && rows.length > 0) {
      this.handleTemplate()
    }
  }

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2,
  ) {}

  handleTemplate(): void {
    this._renderer.setStyle(
      this._host.nativeElement,
      'grid-template-columns',
      `${this._columns.reduce((acc, c) => {
        acc += ` ${c.width || '1fr'}`
        return acc
      }, '')}`,
    )
    this._renderer.setStyle(
      this._host.nativeElement,
      'grid-template-rows',
      `${this._headerHeight} repeat(${this._rows.length}, minmax(${this._rowHeight}, 1fr))`,
    )
    this._firstRun = false
  }
}
