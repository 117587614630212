export enum ContentType {
  Text = 'text',
  Image = 'image',
  Template = 'template',
  Date = 'date',
}

export enum DefaultClasses {
  AlignLeft = 'c-table__cell--align-left',
  Fixed = 'c-table__cell--fixed',
  CellSmall = 'c-table__cell--s',
}

export enum ItemDisplay {
  Sm = 'u-hidden--until@sm',
  Md = 'u-hidden--until@md',
  Lg = 'u-hidden--until@lg',
  Xl = 'u-hidden--until@xl',
}

export interface ITableConfig {
  type: ContentType
  header?: string
  value?: string
  titleValue?: string
  defaultImageValue?: string
  label?: string
  template?: any
  defaultClass?: DefaultClasses
  extraClass?: string
  extraClassHeader?: string
  display?: ItemDisplay
  imageControl?: string
  dateFormat?: string
}
