import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'mcp-dialog-content-container',
  templateUrl: './dialog-content-container.component.html',
  styleUrl: './dialog-content-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentContainerComponent {
  @Input() disabled: boolean

  @Output() apply: EventEmitter<void> = new EventEmitter<void>()
}
