import { createAction } from '@ngrx/store'
import { StorageType } from '@shared/services/storage/storage.service'

export const storeData = createAction(
  '[Storage] store data to localstorage',
  (storageKey: string, data: any, storageType: StorageType = StorageType.Local) => ({
    storageKey,
    data,
    storageType,
  }),
)

export const storeRawData = createAction(
  '[Storage] store raw data to localstorage',
  (storageKey: string, data: any, storageType: StorageType = StorageType.Local) => ({
    storageKey,
    data,
    storageType,
  }),
)
