import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'navigableMatchUrl',
})
export class NavigableMatchUrlPipe implements PipeTransform {
  transform(match: any): string {
    return `match-detail/${match.matchId || match.id}`
  }
}
