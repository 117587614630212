import { Observable, Subject } from 'rxjs'
import { Component, ElementRef, Input, TemplateRef, Type } from '@angular/core'

import { MetricCategory, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

import { environment } from '@env'
import { Store } from '@ngrx/store'
import { getDistinctSimplifiedLang } from '@core/state/selectors/user.selectors'
import { McpMetricSelected } from '@core/models/dto/metric.dto'
import { debounceTime, tap } from 'rxjs/operators'
import { DialogBase } from '@shared/components/dialog/dialog.base'

export interface MetricModalOptions {
  ref?: Element | ElementRef | TemplateRef<Type<unknown>> | unknown
  isDual?: boolean
  type?: MetricCategory
  extraClasses?: string
}

@Component({
  selector: 'app-metric-modal',
  templateUrl: './metric-modal.component.html',
  styleUrls: ['./metric-modal.component.theme.scss', './metric-modal.component.scss'],
})
export class MetricModalComponent extends DialogBase {
  @Input() data: McpMetricSelected
  @Input() type: MetricCategory = MetricCategory.Player
  @Input() isDual: boolean
  @Input() extraClasses = ''

  language$: Observable<string> = this._store.pipe(getDistinctSimplifiedLang())
  open$: Subject<Element | ElementRef | TemplateRef<Type<unknown>> | unknown> = new Subject()
  open$$ = this.open$.pipe(
    debounceTime(0),
    tap((ref) => this.open(ref)),
  )

  MetricCategory = MetricCategory
  envType = environment.envType

  readonly playerPlaceholder = PLACEHOLDER_IMAGES.PLAYER
  readonly teamPlaceholder = PLACEHOLDER_IMAGES.TEAM
  readonly competitionPlaceholder = PLACEHOLDER_IMAGES.COMPETITION

  readonly competitionIdentifier = 'LALIGA'

  constructor(private readonly _store: Store) {
    super()
  }

  openWithData(data: McpMetricSelected, options?: MetricModalOptions, scrollableRef?: string) {
    this.data = data
    if (options) {
      this.ref = options.ref
      this.isDual = options.isDual
      this.type = options.type
      this.extraClasses = options.extraClasses

      const element = (document.querySelector(scrollableRef) || this.ref) as Element
      element?.scrollIntoView()
    }
    this.open$.next(this.ref)
  }
}
