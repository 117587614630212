import { ApiLineupPlayer, ApiMatch } from '@core/models/models/match.models'
import { McpPlayer } from '@core/models/dto/player.dto'
import { ApiPlayer, ApiSquad } from '@core/models/models/player.models'
import {
  GetConcatUnit,
  LA_LIGA_LIST_NAME,
  PARSE_PLAYER_METRICS,
  PARSE_PLAYERS,
  ParseMetricResults,
  ParseSpiderChartData,
  parseStatic,
  ParseStatics,
  PLACEHOLDER_IMAGES,
  StaticOrientation,
  TeamType,
} from '@mediacoach-ui-library/global'
import { areArrayValuesEmpty } from './collection.utils'
import { mapMetricsAsList, parseLeftRightMetrics } from './metrics.utils'
import {
  MatchPlayerMetric,
  PlayerMetric,
} from '@features/match-tabs/components/match-tab-lineup-player-metrics/models/player-metric.model'
import { parseBooleanToNumber } from '@core/utils/number.utils'
import { GroupWeight, PlayerPosition } from '@core/enums/player.enums'
import {
  PLAYER_AVERAGE_BY_POSITION,
  PLAYER_POSITION_KEYS,
  SORTED_POSITIONS,
} from '@core/constants/player.constants'
import { McpFormattedMetricsConfig } from '@core/models/dto/metric.dto'
import { AGGREGATED_METRIC_LABEL_MAP } from '@core/constants/metric-aggregation.constants'
import { MetricAggregation } from '@core/enums/metric.enums'
import { Column, safeObjectData } from '@mediacoach/ui'

export const getMetricPlayerName = ({
  shirtNumber,
  formattedName,
}: ApiPlayer | ApiLineupPlayer): string => `${shirtNumber}. ${formattedName}`

export const mapPlayers = (
  squad: ApiSquad,
  seasonId: string,
  competitionId: string,
  teamId: string,
) => {
  let players: McpPlayer[] = []
  if (squad) {
    players = (squad.players || []).map((player) =>
      mapPlayer(player, seasonId, competitionId, teamId),
    )
    return players
  }
}

export const mapPlayer = (
  player: ApiPlayer | ApiLineupPlayer,
  seasonId: string,
  competitionId: string,
  teamId: string,
): McpPlayer =>
  ({
    ...player,
    ...ParseStatics(player.statics, [StaticOrientation.Portrait]),
    metricPlayerName: getMetricPlayerName(player),
    name: player.formattedName,
    seasonId,
    competitionId,
    teamId,
  }) as McpPlayer

export const getCurrentPlayer = (data: any, playerPosition: any) => {
  let currentPlayer = data.playerA ? data.playerA : data
  const cPosition = playerPosition || currentPlayer.playerPosition
  currentPlayer = {
    ...currentPlayer,
    metricPlayerPosition: cPosition,
    metricPlayerPositionKey: PLAYER_POSITION_KEYS[cPosition],
  }
  return {
    ...currentPlayer,
    metricPlayerPosition: cPosition,
    metricPlayerPositionKey: PLAYER_POSITION_KEYS[cPosition],
  }
}

export const parseShortUniValues = (unit: string): string =>
  ['MTR_UNIT_KILÓMETROS', 'MTR_UNIT_METROS', 'MTR_UNIT_MINUTO'].includes(unit)
    ? GetConcatUnit(unit, '_SHORT')
    : unit

export const getPlayerUrl = (
  seasonId: string,
  competitionId: string,
  teamId: string,
  playerId: string,
): string =>
  `/players/${playerId}/teams/${teamId}/competitions/${competitionId}/seasons/${seasonId}`

export const parseComparedPlayers = (comparedData: any, playerA: any, playerB: any) => ({
  ...comparedData,
  playerA: {
    ...comparedData.playerA,
    playerId: playerA.id,
    name: comparedData.playerA.formattedName,
    metricPlayerName: `${comparedData.playerA.shirtNumber}. ${comparedData.playerA.formattedName}`,
    teamId: playerA.teamId,
    team: { teamId: playerA.teamId },
    competitionId: playerA.competitionId,
    teamType: playerA.teamType,
    seasonId: playerA.seasonId,
    ...ParseStatics(comparedData.playerA.statics, [StaticOrientation.Portrait]),
  },
  playerB: {
    ...comparedData.playerB,
    playerId: playerB.id,
    name: comparedData.playerB.formattedName,
    metricPlayerName: `${comparedData.playerB.shirtNumber}. ${comparedData.playerB.formattedName}`,
    teamId: playerB.teamId,
    team: { teamId: playerB.teamId },
    competitionId: playerB.competitionId,
    seasonId: playerB.seasonId,
    teamType: playerB.teamType,
    ...ParseStatics(comparedData.playerB.statics, [StaticOrientation.Portrait]),
  },
})

export const getSinglePlayerAverageHeader = (
  playerHolder: any,
  isMatch: boolean,
  position: PlayerPosition,
  competitionImg: string,
) => ({
  leftContent: {
    img: playerHolder?.portraitLogo,
    title: playerHolder?.metricPlayerName,
    subtitle:
      AGGREGATED_METRIC_LABEL_MAP[
        isMatch ? MetricAggregation.MatchValues : MetricAggregation.AverageValues
      ],
    isPlayer: true,
  },
  rightContent: {
    img: isMatch ? playerHolder?.portraitLogo : competitionImg || PLACEHOLDER_IMAGES.COMPETITION,
    title: isMatch ? playerHolder?.metricPlayerName : LA_LIGA_LIST_NAME,
    subtitle: isMatch
      ? AGGREGATED_METRIC_LABEL_MAP.averageValues
      : PLAYER_AVERAGE_BY_POSITION[position || playerHolder.playerPosition],
    isPlayer: !!isMatch,
  },
})

export const getSinglePlayerAccumulatedHeader = (playerHolder: any, isMatch: boolean) => ({
  leftContent: {
    img: playerHolder?.portraitLogo,
    title: playerHolder?.metricPlayerName,
    subtitle:
      AGGREGATED_METRIC_LABEL_MAP[
        isMatch ? MetricAggregation.MatchValues : MetricAggregation.AverageValues
      ],
    isPlayer: true,
  },
  rightContent: {
    img: playerHolder?.portraitLogo,
    title: playerHolder?.metricPlayerName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[MetricAggregation.AccumulatedValues],
    isPlayer: true,
  },
})

export const getPlayerAggregationMode = (
  isMatch: boolean,
  isVsEnabled: boolean,
): MetricAggregation => {
  if ((isMatch && !isVsEnabled) || (!isMatch && isVsEnabled)) {
    return MetricAggregation.AverageValues
  } else if (isMatch && isVsEnabled) {
    return MetricAggregation.MatchValues
  } else {
    return MetricAggregation.AccumulatedValues
  }
}

export const getComparedPlayerHeader = (playerHolder: any, aggregation: string) => ({
  leftContent: {
    img: playerHolder?.playerA?.portraitLogo,
    title: playerHolder?.playerA?.metricPlayerName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[aggregation],
    isPlayer: true,
  },
  rightContent: {
    img: playerHolder?.playerB?.portraitLogo,
    title: playerHolder?.playerB?.metricPlayerName,
    subtitle: AGGREGATED_METRIC_LABEL_MAP[aggregation],
    isPlayer: true,
  },
  fromMatch: true,
})

export const getPlayerMetrics = (
  data: any,
  fromMatch: boolean,
  currentPlayer: any,
  competitionImg: string,
  competitionName = LA_LIGA_LIST_NAME,
) => {
  const { summary, chartMetricResults, metricsOverview, playerB, playerA } = data
  const playerHolder = playerA ? { playerA, playerB } : summary
  const position = currentPlayer.metricPlayerPosition
  const isComparison = !!playerHolder?.playerB

  const matchValuesHeader = isComparison
    ? getComparedPlayerHeader(playerHolder, MetricAggregation.MatchValues)
    : getSinglePlayerAverageHeader(playerHolder, fromMatch, position, competitionImg)
  const averageValuesHeader = isComparison
    ? getComparedPlayerHeader(playerHolder, MetricAggregation.AverageValues)
    : getSinglePlayerAverageHeader(playerHolder, fromMatch, position, competitionImg)
  const accumulatedValuesHeader = isComparison
    ? getComparedPlayerHeader(playerHolder, MetricAggregation.AccumulatedValues)
    : getSinglePlayerAccumulatedHeader(playerHolder, fromMatch)

  const configOverview: any = {}
  const configChart: McpFormattedMetricsConfig = {
    left: {
      img: playerA ? playerA.portraitLogo : summary.portraitLogo,
      title: playerA ? playerA.metricPlayerName : summary.metricPlayerName,
      isPlayer: true,
      subtitle: !!playerB && 'MTR_COMMON_AVERAGE',
    },
    right: {
      img: playerB ? playerB.portraitLogo : summary.portraitLogo,
      title: playerB ? playerB.metricPlayerName : summary.metricPlayerName,
      isPlayer: !!playerB,
      subtitle: playerB
        ? undefined
        : PLAYER_AVERAGE_BY_POSITION[currentPlayer.metricPlayerPosition],
    },
    fromMatch: !!playerB,
  }
  const chartMetricsProperty = ParseMetricResults(chartMetricResults)
  const { metrics }: any = mapMetricsAsList(
    {
      ...configChart,
      metricsProperty: chartMetricsProperty as {
        key: string
        groups: any[]
      },
    },
    competitionImg,
    competitionName,
  )
  const overview = mapMetricsAsList(
    {
      ...configOverview,
      metricsProperty: metricsOverview as {
        key: string
        groups: any[]
      },
    },
    competitionImg,
    competitionName,
    matchValuesHeader,
    averageValuesHeader,
    accumulatedValuesHeader,
  )

  return {
    chartMetrics: metrics,
    summary,
    playerChartMetricsData: {
      spiderChart: ParseSpiderChartData(data),
      isSpiderChartEmpty: areArrayValuesEmpty(parseLeftRightMetrics(chartMetricResults)),
      spiderChartHeader: metrics.header,
      overview,
      summary,
    },
  }
}

const mapPlayerMetrics = (lineup: any): PlayerMetric[] =>
  PARSE_PLAYERS(lineup)
    .map((players: any) => ({
      ...players,
      ...PARSE_PLAYER_METRICS(players, (unit) => parseShortUniValues(unit)),
    }))
    .map((player: any) => ({ ...player, accumulatedMetricResult: player.acumulatedMetricResult }))

export const mapMatchPlayerMetrics = ({ homeTeam, awayTeam }): MatchPlayerMetric => ({
  [TeamType.Home]: mapPlayerMetrics((homeTeam.lineup || homeTeam.squad || {}).players),
  [TeamType.Away]: mapPlayerMetrics((awayTeam.lineup || awayTeam.squad || {}).players),
})

export const sortByMatchPlayerFn = (a: any, b: any, column: Column) => {
  const sortDirection = column.sortDirection
  const groupWeightA = a.starter ? GroupWeight.Starter : GroupWeight.Substitute
  const starterNumericA = parseBooleanToNumber(a.starter)
  const positionOrderA = SORTED_POSITIONS.indexOf(a.playerPosition as PlayerPosition)

  const groupWeightB = b.starter ? GroupWeight.Starter : GroupWeight.Substitute
  const starterNumericB = parseBooleanToNumber(b.starter)
  const positionOrderB = SORTED_POSITIONS.indexOf(b.playerPosition as PlayerPosition)

  return sortDirection === 'desc'
    ? groupWeightB - groupWeightA ||
        starterNumericB - starterNumericA ||
        positionOrderB - positionOrderA ||
        a.shirtNumber - b.shirtNumber
    : groupWeightB - groupWeightA ||
        starterNumericB - starterNumericA ||
        positionOrderA - positionOrderB ||
        a.shirtNumber - b.shirtNumber
}

export const mapMatchPlayersStartersAndSubstitutes = (match: ApiMatch) =>
  ['starters', 'substitutes'].reduce(
    (rData, squadType) => ({
      ...rData,
      [squadType]: [TeamType.Home, TeamType.Away].reduce((tData, teamKey) => {
        const team = match[`${teamKey}Team`]
        return {
          ...tData,
          [teamKey]: (team.lineup || team.squad || {}).players
            ?.map((player: any) => ({
              ...player,
              name: player.formattedName,
              position: player.playerPositionKey,
              imgUrl: parseStatic(
                safeObjectData(player, 'statics', []),
                StaticOrientation.Portrait,
              ),
            }))
            .filter((p) => p.starter === (squadType === 'starters')),
        }
      }, {}),
    }),
    {},
  )
