<div class="c-match-card">
  <div class="c-match-card__header">
    <img
      *ngIf="match"
      class="c-match-card__img"
      [srcUrl]="match.competition?.logo"
      [title]="match.competition?.officialName"
      [appDefaultImage]="placeholder.COMPETITION"
    />
  </div>
  <div class="c-match-card__main u-margin-bottom--lg">
    <div class="c-match-card__info">
      <span>{{ match?.season?.officialName }}</span> |
      <span
        >{{ 'MATCHES_RESULTS_LAB_MATCHDAY_LETTER' | translate }} {{ match.matchdayNumber }}</span
      >
    </div>
    <mc-match-item
      [match]="match"
      [hasState]="true"
      [modifiers]="[itemModifier.Card]"
    ></mc-match-item>
    <div class="c-match-card__date" *ngIf="match?.schedule">
      <span>
        {{
          match?.schedule?.toBeConfirmed
            ? ('MATCHES_CONTENT_LAB_TOBEDEFINED' | translate)
            : (match?.schedule?.translated?.date | localizedDate: 'EEEE')
        }} </span
      >&nbsp;<span>
        {{
          match?.schedule?.translated?.date
            | localizedDate: (match?.schedule?.toBeConfirmed ? 'dd/MM/y ' : 'dd/MM/y - HH:mm')
        }}
      </span>
    </div>
  </div>
  <div class="c-match-card__footer">
    <span
      *permissionKey="permissionKey; isRouteKey: true"
      class="c-match-card__cta"
      (click)="goToMatchDetail.emit(match)"
    >
      {{ 'MATCHES_RESULTS_BTN_SHOWDETAIL' | translate }}
      <i
        class="c-match-card__cta-icon u-icon--dart-right-squared mc-translate-gradient-animation"
      ></i>
    </span>
  </div>
  <ng-content></ng-content>
</div>
