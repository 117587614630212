<div class="c-label__container">
  <label class="c-label__label" *ngIf="label">{{ label | translate }}</label>
  <div class="c-label__tooltip" *ngIf="tooltip">
    <span
      [ngClass]="isIconHover ? 'u-icon--info-selected' : 'u-icon--info-outlined'"
      (mouseenter)="isIconHover = true"
      (mouseleave)="isIconHover = false"
      [mcTooltip]="tooltip | translate"
      [tooltipStyleClass]="tooltipStyleClass"
      [tooltipAsHtml]="true"
      tooltipPosition="top"
    ></span>
  </div>
</div>
