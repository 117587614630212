import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core'
import { iif } from 'rxjs'
import { tap } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { PermissionsService } from '@core/services/permissions.service'

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[permissionKey]',
})
export class PermissionKeyDirective implements OnInit {
  @Input() permissionKeyIsRouteKey = false
  @Input() permissionKey: string

  private _isVisible = false

  constructor(
    private _viewContainerRef: ViewContainerRef,
    private _templateRef: TemplateRef<any>,
    private _permissionService: PermissionsService,
  ) {}

  ngOnInit() {
    //  We subscribe to the roles$ to know the roles the user has
    iif(
      () => this.permissionKeyIsRouteKey,
      this._permissionService.isRouteUrlPermitted$(this.permissionKey, false),
      this._permissionService.isPermissionKeyPermitted$(this.permissionKey),
    )
      .pipe(
        tap((isKeyPermitted) => {
          if (isKeyPermitted) {
            if (!this._isVisible) {
              this._isVisible = true
              this._viewContainerRef.createEmbeddedView(this._templateRef)
            }
          } else {
            this._isVisible = false
            this._viewContainerRef.clear()
          }
        }),
        untilDestroyed(this),
      )
      .subscribe()
  }
}
