import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { removeUser, signInSilent } from '@core/state/actions/oidc.actions'

@Component({
  selector: 'mcp-silent',
  template: '',
})
export class SilentComponent implements OnInit {
  constructor(private readonly _store: Store) {}

  ngOnInit() {
    this._store.dispatch(signInSilent())
    this._store.dispatch(removeUser())
  }
}
