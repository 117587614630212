import { VideoType } from '@mediacoach-ui-library/global'

export enum FilterKey {
  Season = 'seasonId',
  Competition = 'competitionId',
  MatchDays = 'matchdayIds',
  Team = 'teamId',
  Demarcation = 'demarcation',
  FreeText = 'freetext',
}

export enum MatchDayType {
  All = -1,
  Current = 0,
  CurrentPlusTwo = 2,
  CurrentPlusThree = 3,
}

export enum VideoTypeMap {
  Tv = VideoType.Tv as any,
  Panoramic = VideoType.Panoramic as any,
  Tac = VideoType.Tactical as any,
  Live = VideoType.Live as any,
}
