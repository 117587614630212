import { Injectable, NgZone } from '@angular/core'
import { EventParams } from '@core/analytics/models/gtag.models'
import { environment } from '@env'
import { Store } from '@ngrx/store'
import { McpProfile } from '@auth/models/auth.dto'
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router'
import { UntilDestroy } from '@ngneat/until-destroy'
import { mapRouteParams } from '@core/analytics/utils/gtag.utils'
import { PARAM_MAP, QUERY_MAP } from '@core/analytics/constants/gtag.constants'
import { AnalyticsParam } from '@core/analytics/enums/gtag-params.enum'
import { AnalyticsEvent } from '@core/analytics/enums/gtag-events.enum'
import { AnalyticsCategory } from '@core/analytics/enums/gtag-categories.enum'
import { TranslateService } from '@ngx-translate/core'

declare const gtag: any

@UntilDestroy()
@Injectable()
export class GtagService {
  private _user: McpProfile
  private _currentTitle: string
  private _previousTitle: string
  private _currentUrl: string
  private _previousUrl: string

  constructor(
    private readonly _zone: NgZone,
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _translate: TranslateService,
  ) {
    gtag('set', 'user_properties', { [AnalyticsParam.environment]: environment?.envType })
  }

  trackPage(event: NavigationEnd) {
    const _snapshot = this._getCurrentRouteSnapshot()
    this._updateCurrentValues(event.urlAfterRedirects, _snapshot)
    this._logPageView()
  }

  trackUser(userProfile: McpProfile) {
    this._user = userProfile
    this._setUserProperties(userProfile)
  }

  event(action: string, params?: EventParams) {
    this._zone.runOutsideAngular(() => {
      this._setUserProperties(this._user)
      gtag('event', action, {
        ...params,
        ...this._getRouteParams(),
        [AnalyticsParam.pageName]: this._currentTitle,
        [AnalyticsParam.pagePath]: this._currentUrl,
        [AnalyticsParam.pageLocation]: window.location.href,
        [AnalyticsParam.pageReferrerName]: this._previousTitle,
        [AnalyticsParam.pageReferrer]: this._previousUrl,
        ...this._buildUserProperties(this._user),
        event_timeout: 3000,
      })
    })
  }

  private _getRouteParams(_?: string, snapshot?: ActivatedRouteSnapshot): { [k: string]: string } {
    const _snapshot = snapshot || this._getCurrentRouteSnapshot()
    return {
      ...this._parseParamMap(_snapshot?.params, PARAM_MAP, /compare/.test(this._router.url)),
      ...this._parseParamMap(_snapshot?.queryParams, QUERY_MAP),
    }
  }

  private _logPageView() {
    this.event(AnalyticsEvent.pageView, {
      [AnalyticsParam.category]: AnalyticsCategory.navigation,
    })
  }

  private _updateCurrentValues(url: string, snapshot: ActivatedRouteSnapshot) {
    this._previousTitle = this._currentTitle
    this._previousUrl = this._currentUrl
    this._currentUrl = url
    this._currentTitle = `${snapshot?.data['gtagTitle']}${
      !!snapshot?.data['concatParam'] ? ' | ' + snapshot?.params[snapshot?.data['concatParam']] : ''
    }`
  }

  private _getCurrentRouteSnapshot(): ActivatedRouteSnapshot {
    let child = this._route.firstChild
    while (child?.firstChild) {
      child = child.firstChild
    }
    return child?.snapshot
  }

  private _parseParamMap(
    params: { [key: string]: string },
    paramMap: { [key: string]: string },
    isComparison?: boolean,
  ): { [key: string]: string } {
    const _paramMap = mapRouteParams(params, paramMap, isComparison)

    if (isComparison) {
      _paramMap['team_id_A'] = _paramMap['team_id_A'] || this._user?.favouriteTeamId
      _paramMap['competition_id_A'] =
        _paramMap['competition_id_A'] || this._user?.favouriteCompetitionId
    }

    return _paramMap
  }

  private _setUserProperties(userProfile: McpProfile): void {
    if (userProfile) {
      gtag('set', { user_id: userProfile?.userId })
      gtag('set', 'user_properties', this._buildUserProperties(userProfile))
    }
  }

  private _buildUserProperties(userProfile: McpProfile): any {
    if (userProfile) {
      return {
        [AnalyticsParam.userId]: userProfile?.userId,
        [AnalyticsParam.customerId]: userProfile?.customerId,
        [AnalyticsParam.customerName]: userProfile?.customerName,
        [AnalyticsParam.favTeamName]: userProfile?.favouriteTeamName,
        [AnalyticsParam.favTeamId]: userProfile?.favouriteTeamId,
        [AnalyticsParam.favCompetitionId]: userProfile?.favouriteCompetitionId,
        [AnalyticsParam.environment]: environment?.envType,
      }
    }
  }
}
