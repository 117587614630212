export enum AnalyticsParam {
  environment = 'environment',
  userId = 'user_id',
  customerId = 'customer_id',
  favCompetitionId = 'favourite_competition_id',
  favCompetitionName = 'favourite_competition_name',
  favTeamId = 'favourite_team_id',
  favTeamName = 'favourite_team_name',
  category = 'event_category',
  pagePath = 'page_path',
  pageLocation = 'page_location',
  pageName = 'page_name',
  pageReferrer = 'page_referrer',
  pageReferrerName = 'page_referrer_name',
  searchText = 'search_text',
  numMatches = 'num_matches',
  competitionId = 'competition_id',
  seasonId = 'season_id',
  dynamicReportName = 'dynamic_report_name',
  matchId = 'match_id',
  matchName = 'match_name',
  teamId = 'team_id',
  teamName = 'team_name',
  playerId = 'player_id',
  assetId = 'asset_id',
  assetType = 'asset_type',
  assetQuality = 'asset_quality',
  assetExtension = 'asset_extension',
  assetContentType = 'asset_content_type',
  exportType = 'export_type',

  fileName = 'file_name',
  installerName = 'installer_name',
  vsTeamIdA = 'team_id_A',
  vsPlayerIdA = 'player_id_A',
  vsTeamIdB = 'team_id_B',
  vsPlayerIdB = 'player_id_B',
  position = 'player_position_comparison',
  videoSeekEvent = 'video_seek_event',
  videoTime = 'video_time',
  videoName = 'video_name',
  videoFullscreen = 'video_fullscreen',
  videoCamera = 'video_camera',
  videoCinema = 'video_cinema',
  videoPip = 'video_pip',
  mcVideoId = 'mc_video_id',
  mcVideoCategory = 'mc_video_category',
  mcVideoProduct = 'mc_video_product',
  suiteApp = 'suite_application',

  onboardingStepIndex = 'onboarding_step_index',
  onboardingStepName = 'onboarding_step_name',
  tabId = 'tab_id',

  avgChecked = 'match_avg_checked',
  streamTitle = 'stream_title',
  version = 'version',
  customerName = 'customer_name',
  vsSeasonIdA = 'season_id_A',
  vsCompetitionIdA = 'competition_id_A',
  vsSeasonIdB = 'season_id_B',
  vsCompetitionIdB = 'competition_id_B',
}
