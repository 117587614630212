import { StreamState } from '@core/state/models/stream.state'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { filter, map } from 'rxjs/operators'
import { pipe } from 'rxjs'
import { getLiveMatches, getTopic } from '@core/state/selectors/socket.selectors'
import { Topic } from '@sockets/enums/socket.enum'
import { Match } from '@core/models/dto/match.dto'

export const streamFeatureSelector = createFeatureSelector<StreamState>(StoreToken.stream)

export const getStream = createSelector(streamFeatureSelector, (state) => state.stream)

export const getLiveStream = () =>
  pipe(
    select(
      createSelector(
        getStream,
        getLiveMatches,
        (currentStream, streamList) =>
          currentStream && (streamList || []).find((m) => m.id === currentStream.id),
      ),
    ),
    filter((updateMatch) => !!updateMatch),
  )

export const getStreamChanged = () =>
  pipe(
    select(
      createSelector(
        getStream,
        getTopic(Topic.MatchChanged),
        (currentStream, matchChanged: Match) => ({ currentStream, matchChanged }),
      ),
    ),
    filter(({ matchChanged }) => !!matchChanged),
    map(({ matchChanged }) => matchChanged),
  )
