import { Injectable } from '@angular/core'
import { GtagService } from '@core/analytics/services/gtag.service'
import { McpProfile } from '@auth/models/auth.dto'
import { NavigationEnd } from '@angular/router'
import { CustomParams, EventParams } from '@core/analytics/models/gtag.models'

@Injectable()
export class AnalyticsFacade {
  constructor(private readonly _gtag: GtagService) {}

  trackUser(userProfile: McpProfile, team: string) {
    this._gtag.trackUser(userProfile)
  }

  trackPage(event: NavigationEnd) {
    this._gtag.trackPage(event)
  }

  trackEvent(name: string, params?: CustomParams) {
    this._gtag.event(name, params)
  }

  logEvent(name: string, params?: EventParams) {
    this._gtag.event(name, params)
  }
}
