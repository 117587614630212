import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { DEFAULT_IMAGE_BY_DIMENSION_TYPE, TagDimensionType } from '@mediacoach/ui'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { InputType } from '@features/report-issue/enums/report-issue.enum'
import { TagItem } from '@shared/components/tag-item/models/tag-item.models'

@Component({
  selector: 'mcp-tag-info',
  templateUrl: './tag-info.component.html',
  styleUrls: ['./tag-info.component.theme.scss', './tag-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagInfoComponent {
  @Input() item: TagItem
  @Input() isFormMode: boolean

  @Output() commentChange: EventEmitter<string> = new EventEmitter()

  readonly placeholder = PLACEHOLDER_IMAGES

  get isEntity() {
    return [TagDimensionType.Team, TagDimensionType.Player].includes(this.item?.dimensionType)
  }

  get placeholderImage() {
    return DEFAULT_IMAGE_BY_DIMENSION_TYPE[this.item?.dimensionType]
  }

  protected readonly inputType = InputType
}
