import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-competition-selector',
  templateUrl: './competition-selector.component.html',
  styleUrls: ['./competition-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionSelectorComponent {
  readonly competitionPlaceholder: string = PLACEHOLDER_IMAGES.COMPETITION

  @Input() competitions: Competition[]
  @Input() currentCompetitionId: string
  @Output() competitionSelected = new EventEmitter<Competition>()
}
