import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { StoreToken } from '@core/state/enums/store-token.enum'
import { AssetsState } from '@core/state/models/assets.state'
import { pipe } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { exists } from '@core/utils/object.utils'

export const selectAssets = createFeatureSelector<AssetsState>(StoreToken.assets)

export const getVersions = createSelector(selectAssets, (state) => state.versions)
export const getAssets = createSelector(selectAssets, (state) => state.assets)

export const getAssetsRefreshFlag = () =>
  pipe(
    select(selectAssets),
    take(1),
    map(
      ({ assets, versions, requestTimestamp, ttl }: AssetsState) =>
        ({
          assets:
            !assets ||
            (!!assets &&
              exists(requestTimestamp) &&
              Math.abs(requestTimestamp - new Date().getTime()) >= ttl),
          versions:
            !versions ||
            (!!versions &&
              exists(requestTimestamp) &&
              Math.abs(requestTimestamp - new Date().getTime()) >= ttl),
        }) as { assets: boolean; versions: boolean },
    ),
  )
