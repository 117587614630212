import { WidgetColumn } from '@widgets/models/widget.models'
import { PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'
import { TeamSpaceTransposedCustomRow } from '@features/team-space/models/team-space-metrics.models'
import { compareWithDirection } from '@mediacoach/ui'

export const TEAM_SPACE_TRANSPOSED_FIRST_COLUMN: WidgetColumn = {
  label: 'MTR_PLAYER',
  sortable: false,
  width: 'minmax(200px, 1fr)',
  alignment: 'left',
  styleClass: 'left',
  sticky: true,
  key: 'metricName',
  sortDirection: 'asc',
}

export const TEAM_SPACE_UNTRANSPOSED_FIRST_COLUMN: WidgetColumn = {
  label: 'MTR_PLAYER',
  sortable: true,
  alignment: 'left',
  styleClass: 'left',
  sticky: true,
  width: 'minmax(200px, 1fr)',
  key: 'shortName',
  displayType: 'textAndImage',
  placeholderImage: PLACEHOLDER_IMAGES.PLAYER,
  imageKey: 'img',
  extraTextKey: 'specificPosition',
  sortDirection: 'asc',
  sortFn: (a: any, b: any, column) => {
    const sortDirection = column.sortDirection
    const valA = a[column.key] ?? 0
    const valB = b[column.key] ?? 0
    return compareWithDirection(valA, valB, sortDirection) || a?.shirtNumber - b?.shirtNumber
  },
}

export const TEAM_SPACE_UNTRANSPOSED_CUSTOM_COLUMNS: WidgetColumn[] = [
  {
    label: 'MTR_PLAYER_SHIRT_NUMBER',
    sortable: true,
    key: 'shirtNumber',
    sticky: true,
    width: '70px',
    alignment: 'center',
  },
  {
    label: 'MTR_PLAYER_GENERAL_POSITION',
    sortable: true,
    key: 'playerPosition',
    sticky: true,
    width: '130px',
    alignment: 'center',
    sortFn: (a: any, b: any, column) => {
      const sortDirection = column.sortDirection
      return sortDirection === 'desc'
        ? b.positionOrder - a.positionOrder || a.shirtNumber - b.shirtNumber
        : a.positionOrder - b.positionOrder || a.shirtNumber - b.shirtNumber
    },
  },
  {
    label: 'MTR_PLAYER_AGE',
    sortable: true,
    key: 'age',
    sticky: true,
    width: '70px',
    alignment: 'center',
  },
  {
    label: 'MTR_PLAYER_HEIGHT',
    sortable: true,
    key: 'height',
    sticky: true,
    unit: 'cm',
    width: '70px',
    alignment: 'center',
  },
  {
    label: 'MTR_PLAYER_FAVOURITELEG',
    sortable: true,
    key: 'preferredFootKey',
    sticky: true,
    width: '100px',
    alignment: 'center',
  },
]

export const TEAM_SPACE_TRANSPOSED_LAST_COLUMN: WidgetColumn = {
  label: 'MTR_COMMON_TOTAL_SEASON',
  alignment: 'center',
  sticky: true,
  styleClass: 'right',
  sortable: true,
  skipSortAware: true,
  width: '100px',
  key: 'total',
  roundPipePattern: '1.0-2',
}

export const TEAM_SPACE_TRANSPOSED_CUSTOM_ROWS: TeamSpaceTransposedCustomRow[] = [
  {
    metricName: 'MTR_PLAYER_SHIRT_NUMBER',
    key: 'shirtNumber',
  },
  {
    metricName: 'MTR_PLAYER_GENERAL_POSITION',
    key: 'playerPosition',
  },
  {
    metricName: 'MTR_PLAYER_AGE',
    key: 'age',
  },
  {
    metricName: 'MTR_PLAYER_HEIGHT',
    key: 'height',
    unit: 'cm',
  },
  {
    metricName: 'MTR_PLAYER_FAVOURITELEG',
    key: 'preferredFootKey',
  },
]

export const TEAM_SPACE_MANAGERS_COLUMNS: WidgetColumn[] = [
  {
    label: 'COMMONS_PROFILE_LAB_NAME',
    sticky: true,
    key: 'formattedName',
    alignment: 'left',
    styleClass: 'left',
    displayType: 'textAndImage',
    placeholderImage: PLACEHOLDER_IMAGES.AVATAR,
    subtitleKey: 'age',
    extraTextKey: 'nationalityKey',
    imageKey: 'static',
    width: 'minmax(270px, 1fr)',
    nestedKey: 'name',
    sortable: true,
    sortableKey: 'alias',
  },
  {
    label: 'MEMBERSHIP_COL_START',
    key: 'joinDate',
    alignment: 'center',
    width: 'minmax(100px, 200px)',
    displayType: 'date',
    emptyIfNested: true,
    sortable: true,
  },
  {
    label: 'MEMBERSHIP_COL_END',
    key: 'leaveDate',
    alignment: 'center',
    width: 'minmax(100px, 200px)',
    displayType: 'date',
    emptyIfNested: true,
    sortable: true,
  },
]
