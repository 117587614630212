import { environment } from '@env'
import {
  FilterSelectionType,
  VideoFilterType,
  VideoTagCategory,
  VideoTagStatus,
} from '@features/videos/enums/videos.enums'

export const FEATURE_TOKEN_VIDEOS = 'videos'

export const VIDEOS_URL_DEFAULT = '/more/videos'
export const DEFAULT_ITEMS_PER_PAGE = 24

export const THUMBNAIL_DEFAULT_WIDTH = 640
export const PLAYER_DEFAULT_WIDTH = 640
export const PLAYER_DEFAULT_HEIGHT = 360
export const FILTER_Z_INDEX_CLASS = 'document--filters-z-index'

export const PRODUCTS_ORDER = ['Suite', 'Desktop', 'Portal', 'Live', 'Reports', 'Wimu', 'Vision']

export const DEFAULT_CONNECTION = `/users${environment.VIMEO_API_USER}/videos`

export const SEARCH_FILTER_INIT = [
  {
    id: VideoFilterType.Product,
    title: 'VIDEO_LIBRARY_FILTERS_PRODUCT',
    filterType: FilterSelectionType.Single,
  },
  {
    id: VideoFilterType.Typology,
    title: 'VIDEO_LIBRARY_FILTERS_CATEGORY',
    filterType: FilterSelectionType.Multiple,
  },
  {
    id: VideoFilterType.Search,
    title: 'MATCHES_FILTERS_LAB_SEARCH',
    filterType: FilterSelectionType.Search,
  },
]

export const VIDEO_TAGS_TRANSLATIONS = {
  [VideoTagStatus.Hidden]: '',
  [VideoTagCategory.Communication]: 'VIDEO_LIBRARY_CATEGORY_COMMUNICATION',
  [VideoTagCategory.Promotion]: 'VIDEO_LIBRARY_CATEGORY_PROMOTION',
  [VideoTagCategory.Tutorial]: 'VIDEO_LIBRARY_CATEGORY_TUTORIAL',
  [VideoTagCategory.Event]: 'VIDEO_LIBRARY_CATEGORY_EVENTS',
  [VideoTagCategory.Formation]: 'VIDEO_LIBRARY_CATEGORY_FORMATION',
}
