<mcp-loader [loading]="loading">
  <app-list-view [hidden]="loading" *ngIf="matches && matches.length > 0" [items]="matches">
    <div class="matches-list__container">
      <app-table
        [items]="matches"
        [stickyHeader]="stickyHeader"
        [contentConfig]="config$ | async"
        [headerExtraClasses]="display"
        [isSelectable]="true"
        [blockParentOverflow]="blockParentOverflow"
        [selectedItems]="selectedMatches"
        [isTypeGrid]="true"
        #matchesTable
      >
        <ng-template #matchAssets let-match>
          <div (click)="matchSelect.emit(match)">
            <ng-container
              *ngTemplateOutlet="
                template ? template : $any(defaultTemplate);
                context: { $implicit: match }
              "
            ></ng-container>
          </div>
        </ng-template>
      </app-table>
    </div>
  </app-list-view>
  <ng-template #defaultTemplate let-match>
    <mcp-match-item display="row" [match]="match" [templates]="matchItemTemplates"></mcp-match-item>
  </ng-template>
</mcp-loader>
