import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { StepProgress } from '@shared/modules/tour/models/tour-step.models'

@Component({
  selector: 'mcp-tour-progress',
  templateUrl: './tour-progress.component.html',
  styleUrls: ['./tour-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TourProgressComponent {
  @Input() stepProgress: StepProgress
}
