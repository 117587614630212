<mcp-match-item-limit-reached-modal
  #modal
  [limit]="limit"
  (close)="onClose()"
></mcp-match-item-limit-reached-modal>
<app-loader [loading]="loading">
  <mcp-match-item-list
    [matches]="matches"
    [stickyHeader]="stickyHeader"
    [template]="template"
    [matchItemTemplates]="matchItemTemplates"
    [keepSelection]="keepSelection"
    [config]="config"
    [selectedMatches]="selected"
    [blockParentOverflow]="blockParentOverflow"
    (matchSelect)="onMatchSelected($event)"
  ></mcp-match-item-list>
</app-loader>
<mcp-match-item-list-footer
  [limit]="limit"
  [matches]="selected"
  [total]="matches?.length"
  [allSelected]="isAllSelected()"
  (matchesSelected)="matchesSelected.emit($event)"
  (toggleChange)="onToggleChange($event)"
  (limitReached)="modal.open()"
></mcp-match-item-list-footer>
<ng-container *ngIf="selectedMatches$ | async"></ng-container>
