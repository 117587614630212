import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env'
import { Observable } from 'rxjs'
import {
  PlaylistCollaboratorResponse,
  PlaylistRequest,
  PlaylistsResponse,
  TagCodeResponse,
  TagListResponse,
  TagResponse,
} from '@features/playlist/models/playlist.response.models'
import { TagItem } from '@shared/components/tag-item/models/tag-item.models'
import { PlaylistClipSharePayload } from '@features/playlist/dialogs/dialog-playlist-share/models/dialog-playlist-share.models'

@Injectable()
export class PlaylistApi {
  constructor(private readonly _http: HttpClient) {}

  exportPlaylistTags(id: string, matchId: string): Observable<any> {
    return this._http.get<any>(`${environment.API.EXPORT_PLAYLIST_TAGS(id)}?matchId=${matchId}`, {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      responseType: 'text',
      headers: { Accept: 'application/xml' },
    })
  }

  deleteTag(id, tagId) {
    return this._http.delete(`${environment.API.DELETE_PLAYLIST_TAG(id, tagId)}`)
  }

  getPlaylists(matchId: string) {
    return this._http.get<PlaylistsResponse>(
      `${environment.API.PLAYLISTS}?matchId=${matchId}&offset=0&limit=1000000`,
    )
  }

  getPlaylistTags(id, matchId: string, filters?: any) {
    return this._http.get<TagListResponse>(
      `${environment.API.GET_PLAYLIST_TAGS(id)}?matchId=${matchId}${filters ? `&${filters}` : ''}`,
    )
  }

  getPlaylistDimensions(id, matchId: string): Observable<string[]> {
    return this._http.get<string[]>(
      `${environment.API.GET_PLAYLIST_DIMENSIONS(id)}?matchId=${matchId}`,
    )
  }

  getPlaylistCodes(id, matchId: string): Observable<TagCodeResponse[]> {
    return this._http.get<TagCodeResponse[]>(
      `${environment.API.GET_PLAYLIST_CODES(id)}?matchId=${matchId}`,
    )
  }

  updateTag(matchId: string, tagId: string, tagItem: TagItem) {
    return this._http.put(environment.API.UPDATE_TAG(matchId, tagId), tagItem)
  }

  fetchPlaylistCollaborators(): Observable<PlaylistCollaboratorResponse> {
    return this._http.get<PlaylistCollaboratorResponse>(environment.API.PLAYLISTS_COLLABORATORS)
  }

  createPlaylist(payload: Partial<PlaylistRequest>): Observable<any> {
    return this._http.post(environment.API.PLAYLISTS, payload)
  }

  shareTags(matchId: string, payload: PlaylistClipSharePayload) {
    return this._http.post(environment.API.SHARE_TAGS(matchId), payload)
  }

  shareAllTags(matchId: string, playlistId: string, payload: PlaylistClipSharePayload) {
    return this._http.post(environment.API.SHARE_TAGS(matchId, playlistId), payload)
  }

  updatePlaylist({ id, ...playlist }: Partial<PlaylistRequest>): Observable<any> {
    return this._http.put(environment.API.PLAYLIST(id), playlist)
  }

  unsubscribePlaylist(id: string): Observable<any> {
    return this._http.put(environment.API.UNSUBSCRIBE_PLAYLIST(id), {})
  }

  deletePlaylist(id: string): Observable<any> {
    return this._http.delete(environment.API.PLAYLIST(id))
  }

  createQuickTag(matchId: string, quickTag: TagResponse) {
    return this._http.post(environment.API.QUICK_TAG(matchId), { tags: [quickTag] })
  }
}
