import { Directive, ElementRef, inject, Input, Renderer2 } from '@angular/core'

@Directive({ selector: '[mcpBackgroundImage]' })
export class BackgroundImageDirective {
  private readonly _renderer = inject(Renderer2)
  private readonly _host = inject(ElementRef)

  @Input({ required: true, alias: 'mcpBackgroundImage' }) set logo(url: string) {
    if (url) {
      this._renderer.setStyle(this._host.nativeElement, 'backgroundImage', `url("${url}")`)
      this._renderer.setStyle(this._host.nativeElement, 'backgroundSize', 'contain')
      this._renderer.setStyle(this._host.nativeElement, 'backgroundRepeat', 'no-repeat')
      this._renderer.setStyle(this._host.nativeElement, 'backgroundPosition', 'center')
    }
  }
}
