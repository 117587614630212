export enum FilterSelectionType {
  Single = 'single',
  Multiple = 'multiple',
  Search = 'search',
}

export enum VideoFilterType {
  Product = 'product',
  Typology = 'typology',
  Search = 'search',
}

export enum VideoTagCategory {
  Communication = 'tp_com',
  Promotion = 'tp_promo',
  Tutorial = 'tp_tutorial',
  Event = 'tp_event',
  Formation = 'tp_formacion',
}

export enum VideoTagDifficulty {
  NoApply = 'dif_0',
  Low = 'dif_1',
  Mid = 'dif_2',
  High = 'dif_3',
}

export enum VideoTagStatus {
  Highlighted = 'high_1',
  Hidden = 'hidden',
}

export enum VideoTagGeneral {
  TacticalCam = 'g_tac',
  Metrics = 'g_metricas',
}

export enum VideoTagSpecific {
  Dk_VirtualBoard = 'dk_pizarravirtual',
  Dk_Quantification = 'dk_cuantificacion',
  Dk_EventsFilter = 'dk_filtroeventos',
  Dk_Clips = 'dk_clips',
  Dk_MultipleAnalysis = 'dk_analisismultiple',
  Dk_Production = 'dk_produccion',

  Lv_Dashboard = 'lv_cuadromando',
  Lv_Templates = 'lv_templates',
  Lv_Alarms = 'lv_alarmas',
  Lv_Comparator = 'lv_comparador',

  Pr_Monitoring = 'pr_seguimiento',
}
