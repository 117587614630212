import { nowInSeconds } from '@core/utils/date.utils'
import { User } from 'oidc-client'
import { AUTH_EXPIRY_BUFFER } from '../constants/auth.constants'
import { McpProfile } from '@auth/models/auth.dto'

export function isValidToken(user: User, buffer: number = AUTH_EXPIRY_BUFFER): boolean {
  if (user && user.expires_at) {
    const now = nowInSeconds()
    return now < user.expires_at + buffer
  } else {
    return false
  }
}

export const parseProfile = (profile: McpProfile): McpProfile => ({
  ...profile,
  favourites: {
    competitionId: profile.favouriteCompetitionId,
    teamId: profile.favouriteTeamId,
  },
})
