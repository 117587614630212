import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { filter, tap } from 'rxjs/operators'

@UntilDestroy()
@Injectable()
export class RouterEventService {
  private _hasNavigatedBack = false
  private _url: string
  private _previousUrl: string

  constructor(private router: Router) {
    this._url = router.url
    router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(({ url, navigationTrigger }: NavigationStart) => {
          this._previousUrl = this._url
          this._url = url
          this._hasNavigatedBack = navigationTrigger === 'popstate'
        }),
        untilDestroyed(this),
      )
      .subscribe()
  }

  checkUrl(url) {
    return this._url === url
  }

  checkBackNavigation(url = this.router.url) {
    return this._hasNavigatedBack && this._url === url
  }

  checkHistoryNavigation(previousUrl: string, url: string) {
    return this._previousUrl === previousUrl && this._url === url
  }

  goToLastPage() {
    this._previousUrl === this.router.url
      ? window.location.reload()
      : this.router.navigate([this._previousUrl])
  }
}
