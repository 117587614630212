<ng-container *ngIf="data">
  <div class="player__container" [class.is-from-match]="isFromMatch">
    <ng-container *ngIf="isFromMatch">
      <div class="player__container--title">
        <app-title
          extraClasses="no-gradient"
          [size]="TitleSize.M"
          [title]="'MTR_METRICCATEGORY_RESUMEN' | translate"
        ></app-title>
      </div>
    </ng-container>
    <mc-spider-chart
      [flatMode]="true"
      [data]="data?.spiderChart"
      [header]="data?.spiderChartHeader"
      (onClickMetric)="chartMetricChange.emit($event)"
    >
      <ng-template mcTemplate="header">
        <div class="player__position-btn">
          <div (click)="changePlayerPosition()" class="player__position-text">
            <span>{{ currentPlayer.metricPlayerPositionKey | translate }}</span>
            <div class="player__position-text-icon" *ngIf="canChangePlayerPosition()">
              <i class="u-icon u-icon--loop"></i>
            </div>
          </div>
        </div>
      </ng-template>
    </mc-spider-chart>
    <ng-container *ngIf="isFromMatch">
      <div class="player__container--title">
        <app-title
          extraClasses="no-gradient"
          [size]="TitleSize.M"
          [title]="
            'MTR_METRIC_OF'
              | translate
                : {
                    position: currentPlayer.metricPlayerPositionKey | translate,
                  }
          "
        ></app-title>
      </div>
    </ng-container>
    <mc-list
      class="avg-switch-event"
      [data]="metricsData"
      [showTeamMarks]="showTeamMarksInList"
      [isCollapsible]="isCollapsibleList"
      [itemTemplate]="metricItem"
      [aggregationHeader]="aggregationHeader"
      [showAggregationIcon]="showAggregationIcon"
      (aggregationModeClick)="aggregationModeClick.emit()"
    >
    </mc-list>
    <ng-container *ngIf="!hasLineup && aggregationMode === MetricAggregation.MatchValues">
      <div class="player__not-available">
        <p>{{ 'MATCHDETAIL_MEDIA_LAB_NOTAVAILABLE_FOR' | translate }}</p>
        <img
          *ngIf="competition"
          class="player__not-available-img"
          [srcUrl]="competition?.logo"
          [title]="competition?.officialName"
          appDefaultImage
        />
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #metricItem let-item>
  <mcui-metric-item
    [title]="item.text | translate"
    [leftValue]="item.homeValue"
    [leftUnit]="item.homeUnit | translate"
    [rightValue]="item.awayValue"
    [rightUnit]="item.awayUnit | translate"
  />
</ng-template>
