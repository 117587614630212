import { AnimationOptions } from '../models/animation.models'
import {
  animate,
  animateChild,
  AnimationReferenceMetadata,
  group,
  query,
  useAnimation,
} from '@angular/animations'

export function useAnimationIncludingChildren(
  animation: AnimationReferenceMetadata,
  options?: AnimationOptions,
) {
  return [
    ...(options && options.animateChildren === 'before'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
    group([
      useAnimation(animation),
      ...(!options || !options.animateChildren || options.animateChildren === 'together'
        ? [query('@*', animateChild(), { optional: true })]
        : []),
    ]),
    ...(options && options.animateChildren === 'after'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
  ]
}

export function animateIncludingChildren(
  easing: 'ease-in' | 'ease-out',
  options?: AnimationOptions,
) {
  return [
    ...(options && options.animateChildren === 'before'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
    group([
      group([
        query('@*', animateChild(), { optional: true }),
        animate('{{duration}}' + 'ms ' + '{{delay}}' + 'ms ' + easing),
      ]),
      ...(!options || !options.animateChildren || options.animateChildren === 'together'
        ? [query('@*', animateChild(), { optional: true })]
        : []),
    ]),
    ...(options && options.animateChildren === 'after'
      ? [query('@*', animateChild(), { optional: true })]
      : []),
  ]
}
