import { createAction, props } from '@ngrx/store'
import { AssetMatch, Match } from '@core/models/dto/match.dto'
import { TeamType } from '@mediacoach-ui-library/global'
import { TimelineConfigDto } from '@core/models/dto/timeline.dto'
import { PDFMetadata } from '@features/matches/models/match-details.models'
import { MergedTokens } from '../models/merged-tokens.model'
import { StreamType } from '@core/models/dto/assets.dto'
import { MarkersGroup } from '@core/state/models/stream.state'
import { ComparablePlayer } from '@core/models/dto/player.dto'
import { MetricAggregation } from '@core/enums/metric.enums'

export const fetchMatch = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] fetch match`,
    props<{ matchId: string; fetchMetadata?: boolean; fetchStream?: boolean }>(),
  )

export const fetchMatchMetadata = (identifier: MergedTokens) =>
  createAction(`[${identifier}] fetch match success`, props<{ matchId: string }>())

export const fetchMatchData = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] fetch match data`,
    props<{ matchId: string; fetchMetadata?: boolean; fetchStream?: boolean }>(),
  )

export const setMatch = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match`, props<{ currentMatch: AssetMatch }>())

export const setMatchLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match loader`, props<{ matchLoader: boolean }>())

export const updateMatchWithSocket = (identifier: MergedTokens) =>
  createAction(`[${identifier}] update match with socket`, props<{ updatedMatch: Match }>())

export const fetchMatchStream = (identifier: MergedTokens) =>
  createAction(`[${identifier}] fetch stream`, props<{ id: string }>())

export const setMatchStream = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match stream`, props<{ stream: Match }>())

export const setStreamType = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match stream type`, props<{ streamType: StreamType }>())

export const debouncedFetchMatchData = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Debounce fetch`, props<{ matchId: string }>())

export const refreshMatchOnLive = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Refresh match on live`, props<{ updatedMatch: Match }>())

export const refreshMatchOnStateChange = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Refresh match on state change`, props<{ matchId: string }>())

export const fetchTimelineConfig = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Fetch timeline`, props<{ matchId: string }>())

export const setTimelineConfig = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Set timeline`, props<{ timelineConfig: TimelineConfigDto }>())

export const setTimelineLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] Set timeline loader`, props<{ timelineLoader: boolean }>())

export const parseMatchMarkers = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] Parse markers`,
    props<{ timelineConfig: TimelineConfigDto; defaultTranslation: string }>(),
  )

export const setMatchMarkers = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set markers`, props<{ markers: MarkersGroup }>())

export const aggregateMetrics = (identifier: MergedTokens) =>
  createAction(`[${identifier}] aggregate metrics`)

export const setMetricsLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set metrics loader`, props<{ metricsLoader: boolean }>())

export const setMatchPlayerMetricsLoader = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set match player metrics loader`,
    props<{ playerMetricsLoader: boolean }>(),
  )

export const fetchMatchTeamMetrics = (identifier: MergedTokens) =>
  createAction(`[${identifier}] fetch match team metrics`, props<{ matchId: string }>())

export const setMatchTeamMetrics = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match team metrics`, props<{ teamMetrics: any }>())

export const setMatchTeamMetricsLoader = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set match team metrics loader`,
    props<{ teamMetricsLoader: boolean }>(),
  )

export const fetchMatchPassMatrix = (identifier: MergedTokens) =>
  createAction(`[${identifier}] fetch match pass matrix`, props<{ matchId: string }>())

export const setMatchPassMatrix = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match pass matrix`, props<{ passMatrix: any }>())

export const setMatchPassMatrixLoader = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set match pass matrix loader`,
    props<{ passMatrixLoader: boolean }>(),
  )

export const fetchMatchHeatMap = (identifier: MergedTokens) =>
  createAction(`[${identifier}] fetch match heat map`, props<{ matchId: string }>())

export const setMatchHeatMap = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match heat map`, props<{ heatMap: string }>())

export const setMatchHeatMapLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set match heat map loader`, props<{ heatMapLoader: boolean }>())

export const fetchMatchPlayer = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] fetch match player`,
    props<{ playerId: string; teamType: TeamType; position?: string }>(),
  )

export const setMatchSelectedPlayer = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set selected player`, props<{ selectedPlayer: ComparablePlayer }>())

export const setMatchSelectedPlayerLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set selected player loader`, props<{ playerLoader: boolean }>())

export const exportMetricsToPDF = (identifier: MergedTokens) =>
  createAction(`[${identifier}] export metrics to PDF`, props<PDFMetadata>())

export const setExportMetricsToPDFLoader = (identifier: MergedTokens) =>
  createAction(`[${identifier}] set export pdf loader`, props<{ pdfLoader: boolean }>())

export const fetchMatchPlayerComparison = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] fetch match player comparison`,
    props<{
      playerA: ComparablePlayer
      playerB: ComparablePlayer
      position: string
    }>(),
  )

export const setMatchPlayerComparison = (identifier: MergedTokens) =>
  createAction(`[${identifier}]  set match player comparison`, props<{ comparison: any }>())

export const setComparisonLoader = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}]  set player comparison loader`,
    props<{ comparisonLoader: boolean }>(),
  )

export const wipeStreamData = (identifier: MergedTokens) =>
  createAction(`[${identifier}]  wipe stream data`)

export const setIsPlaylistTabSelected = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set is playlist tab selected`,
    props<{
      isPlaylistTabSelected: boolean
    }>(),
  )

export const setMatchAggregationMode = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set match aggregation mode`,
    props<{ aggregationMode: MetricAggregation }>(),
  )

export const setMatchPlayerAggregationMode = (identifier: MergedTokens) =>
  createAction(
    `[${identifier}] set match player aggregation mode`,
    props<{ aggregationMode: MetricAggregation; isVsEnabled: boolean }>(),
  )
