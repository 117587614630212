import {
  AfterContentInit,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core'
import {
  ARROW_SIZE,
  ASPECT_RATIO,
  CUSTOM_STEP_MAX_WIDTH_VW,
  DISTANCE_FROM_TARGET,
  STEP_HEIGHT,
  STEP_MIN_WIDTH,
} from '@shared/modules/tour/constants/tour.constants'
import { StepPosition, TourStep } from '@shared/modules/tour/models/tour-step.models'
import { adjustDimensions, getDimensionsByAspectRatio } from '../utils/tour.utils'
import { ElementDimensions, Size } from '../models/tour-dom.models'
import { getElementFixedLeft, getElementFixedTop } from '@shared/modules/tour/utils/dom.utils'
import { DomService } from '@shared/modules/tour/services/dom/dom.service'
import { TourStepsContainerService } from '@shared/modules/tour/services/tour-steps-container/tour-steps-container.service'
import {
  adjustBottomPosition,
  adjustLeftPosition,
  adjustRightPosition,
  adjustTopPosition,
} from '@shared/modules/tour/utils/draw.utils'
import { TourArrowComponent } from '@shared/modules/tour/components/tour-arrow/tour-arrow.component'
import { LoggerService } from '@shared/modules/tour/services/logger/logger.service'
import { STEP_POSITION_METHOD } from '@shared/modules/tour/constants/draw.constants'
import { TourOptions } from '@shared/modules/tour/models/tour-options.models'
import { TourStepService } from '@shared/modules/tour/services/tour-step/tour-step.service'
import { EventListenerService } from '@shared/modules/tour/services/event-listener/event-listener.service'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Directive({
  selector: '[mcpStepPosition]',
})
export class StepPositionDirective implements AfterContentInit {
  private _position: string
  private _targetElement: Element
  private _step: TourStep
  private _container: Element
  private _arrow: Element
  private _targetDimensions: ElementDimensions = {}
  private _stepDimensions: Partial<ElementDimensions> = {
    width: STEP_MIN_WIDTH,
    height: STEP_HEIGHT,
  }
  private _positionAlreadyFixed: boolean
  private _isCustomized: boolean
  private _stepPosition: StepPosition

  get _element(): Element {
    return this._host.nativeElement
  }

  @HostListener('click', ['$event'])
  onClick(ev: Event) {
    ev.stopPropagation()
    ev.preventDefault()
  }

  @Input('mcpStepPosition') set data(_data: {
    step: TourStep
    options: TourOptions
    isCustomized: boolean
  }) {
    this._step = _data?.step
    if (_data) {
      this._position = _data?.step?.isElementOrAncestorFixed ? 'fixed' : 'absolute'
      this._targetElement = document.querySelector(_data?.step?.selector)
      this._isCustomized = _data?.isCustomized
      this._stepPosition = _data?.step?.position || _data?.options?.stepDefaultPosition
    }
  }

  @ContentChild(TourArrowComponent, { read: ElementRef }) set arrowRef(ref: ElementRef) {
    if (ref) {
      this._arrow = ref.nativeElement
    }
  }

  @ContentChild('stepContainer', { read: ElementRef }) set containerRef(ref: ElementRef) {
    if (ref) {
      this._container = ref.nativeElement
    }
  }

  constructor(
    private readonly _host: ElementRef,
    private readonly _renderer: Renderer2,
    private readonly _dom: DomService,
    private readonly _logger: LoggerService,
    private readonly _stepContainer: TourStepsContainerService,
    private readonly _stepService: TourStepService,
    private readonly _events: EventListenerService,
  ) {
    this._events.resizeEvent.pipe(untilDestroyed(this)).subscribe(() => this._reDraw())
  }

  ngAfterContentInit() {
    if (this._isCustomized) {
      this._renderer.setStyle(this._container, 'max-width', CUSTOM_STEP_MAX_WIDTH_VW + 'vw')
      this._updateStepDimensions()
    } else {
      this._updateStepDimensions(this._getDefaultDimensions())
      this._renderer.setStyle(this._container, 'width', this._stepDimensions.width + 'px')
      this._renderer.setStyle(this._container, 'height', this._stepDimensions.height + 'px')
    }

    this._draw()
  }

  getTargetDimensions(): ElementDimensions {
    return this._targetDimensions
  }

  getStepDimensions(): ElementDimensions {
    return this._stepDimensions
  }

  private _reDraw() {
    this._updateStepDimensions()
    this._draw()
  }

  private _getDefaultDimensions(): Size {
    return adjustDimensions(
      getDimensionsByAspectRatio(
        this._container.clientWidth,
        this._container.clientHeight,
        ASPECT_RATIO,
      ),
    )
  }

  private _draw() {
    this._renderer.setStyle(this._element, 'position', this._position)
    this._renderer.setStyle(this._element, 'transform', this._step.transformCssStyle)

    this._updateTargetDimensions()
    this[STEP_POSITION_METHOD[this._stepPosition]]()
  }

  private _updateStepDimensions(dim?: Pick<ElementDimensions, 'width' | 'height'>) {
    this._stepDimensions.width = dim?.width ?? this._container.clientWidth
    this._stepDimensions.height = dim?.height ?? this._container.clientHeight
  }

  private _updateTargetDimensions() {
    this._targetDimensions.width = this._targetElement.getBoundingClientRect().width
    this._targetDimensions.height = this._targetElement.getBoundingClientRect().height
    this._targetDimensions.absoluteLeft =
      this._position === 'fixed'
        ? getElementFixedLeft(this._targetElement)
        : this._dom.getElementAbsoluteLeft(this._targetElement)
    this._targetDimensions.absoluteTop =
      this._position === 'fixed'
        ? getElementFixedTop(this._targetElement)
        : this._dom.getElementAbsoluteTop(this._targetElement)
  }

  private _getStepArrowLeft(): { stepLeft: number; arrowLeft: number } {
    const absoluteLeft =
      this._targetDimensions.width / 2 -
      this._stepDimensions.width / 2 +
      this._targetDimensions.absoluteLeft
    const { stepLeft, arrowLeft } = adjustRightPosition(
      adjustLeftPosition(absoluteLeft, this._stepDimensions.width / 2 - ARROW_SIZE),
      this._stepDimensions,
      this._dom.docRef.body.clientWidth,
    )
    this._stepDimensions.absoluteLeft = absoluteLeft
    this._stepDimensions.left = stepLeft
    return { stepLeft, arrowLeft }
  }

  private _getStepArrowTop(): { stepTop: number; arrowTop: number } {
    const absoluteTop =
      this._targetDimensions.absoluteTop +
      this._targetDimensions.height / 2 -
      this._stepDimensions.height / 2
    const { stepTop, arrowTop } = adjustBottomPosition(
      adjustTopPosition(absoluteTop, this._stepDimensions.height / 2 - ARROW_SIZE, absoluteTop),
      this._stepDimensions,
      this._dom.documentHeight,
    )
    this._stepDimensions.absoluteTop = absoluteTop
    this._stepDimensions.top = stepTop
    return { stepTop, arrowTop }
  }

  private _setStyleTop() {
    this._stepContainer.updatePosition(this._step.name, 'top')
    this._stepDimensions.top =
      this._targetDimensions.absoluteTop - DISTANCE_FROM_TARGET - this._stepDimensions.height
    this._stepDimensions.absoluteTop = this._stepDimensions.top

    const { stepLeft, arrowLeft } = this._getStepArrowLeft()

    this._render(this._stepDimensions.top, stepLeft, this._stepDimensions.height, arrowLeft)
    this._autofixTopPosition()
  }

  private _setStyleBottom() {
    this._stepContainer.updatePosition(this._step.name, 'bottom')
    this._stepDimensions.top =
      this._targetDimensions.absoluteTop + this._targetDimensions.height + DISTANCE_FROM_TARGET
    this._stepDimensions.absoluteTop = this._stepDimensions.top

    const { stepLeft, arrowLeft } = this._getStepArrowLeft()
    this._render(this._stepDimensions.top, stepLeft, -ARROW_SIZE, arrowLeft)
    this._autofixBottomPosition()
  }

  private _setStyleRight() {
    this._stepContainer.updatePosition(this._step.name, 'right')
    const { stepTop, arrowTop } = this._getStepArrowTop()

    this._stepDimensions.left =
      this._targetDimensions.absoluteLeft + this._targetDimensions.width + DISTANCE_FROM_TARGET
    this._stepDimensions.absoluteLeft = this._stepDimensions.left
    this._render(stepTop, this._stepDimensions.left, arrowTop, -ARROW_SIZE)
    this._autofixRightPosition()
  }

  private _setStyleLeft() {
    this._stepContainer.updatePosition(this._step.name, 'left')
    const { stepTop, arrowTop } = this._getStepArrowTop()

    this._stepDimensions.left =
      this._targetDimensions.absoluteLeft - this._stepDimensions.width - DISTANCE_FROM_TARGET
    this._stepDimensions.absoluteLeft = this._stepDimensions.left
    this._render(stepTop, this._stepDimensions.left, arrowTop, this._stepDimensions.width)
    this._autofixLeftPosition()
  }

  private _setStyleCenter() {
    this._renderer.setStyle(this._element, 'position', 'fixed')
    this._renderer.setStyle(this._element, 'top', '50%')
    this._renderer.setStyle(this._element, 'left', '50%')
    this._updateStepDimensions()
    this._renderer.setStyle(
      this._element,
      'transform',
      `translate(-${this._stepDimensions.width / 2}px, -${this._stepDimensions.height / 2}px)`,
    )
  }

  private _setAbsoluteBottomRight() {
    this._renderer.setStyle(this._element, 'position', 'fixed')
    this._renderer.setStyle(this._element, 'bottom', '60px')
    this._renderer.setStyle(this._element, 'right', '16px')
    this._updateStepDimensions()
  }

  private _autofixTopPosition() {
    if (this._positionAlreadyFixed) {
      this._logger.warn('No step positions found for this step. The step will be centered.')
    } else if (this._targetDimensions.absoluteTop - this._stepDimensions.height - ARROW_SIZE < 0) {
      this._positionAlreadyFixed = true
      this._setStyleRight()
    }
  }

  private _autofixRightPosition() {
    if (
      this._targetDimensions.absoluteLeft +
        this._targetDimensions.width +
        this._stepDimensions.width +
        ARROW_SIZE >
      this._dom.docRef.body.clientWidth
    ) {
      this._setStyleBottom()
    }
  }

  private _autofixBottomPosition() {
    if (
      this._targetDimensions.absoluteTop +
        this._stepDimensions.height +
        ARROW_SIZE +
        this._targetDimensions.height >
      this._dom.documentHeight
    ) {
      this._setStyleLeft()
    }
  }

  private _autofixLeftPosition() {
    if (this._targetDimensions.absoluteLeft - this._stepDimensions.width - ARROW_SIZE < 0) {
      this._setStyleTop()
    }
  }

  private _render(stepTop: number, stepLeft: number, arrowTop: number, arrowLeft: number): void {
    this._renderer.setStyle(this._element, 'top', stepTop + 'px')
    this._renderer.setStyle(this._element, 'left', stepLeft + 'px')
    this._renderer.setStyle(this._arrow, 'top', arrowTop + 'px')
    this._renderer.setStyle(this._arrow, 'left', arrowLeft + 'px')
  }
}
