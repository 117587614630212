<div
  class="not-available"
  [class.not-available--message]="!!message"
  *ngIf="competition || message"
>
  <p>{{ message || 'MATCHDETAIL_MEDIA_LAB_NOTAVAILABLE_FOR' | translate }}</p>
  <img
    *ngIf="competition"
    class="not-available__img"
    [srcUrl]="competition?.logo"
    [title]="competition?.officialName"
    [appDefaultImage]="placeholder.COMPETITION"
  />
</div>
