import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { Competition, PLACEHOLDER_IMAGES } from '@mediacoach-ui-library/global'

@Component({
  selector: 'mcp-unavailable-content',
  templateUrl: './unavailable-content.component.html',
  styleUrls: ['./unavailable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.no-padding]': 'noPadding',
  },
})
export class UnavailableContentComponent {
  @Input() competition: Competition
  @Input() message: string
  @Input() noPadding: boolean

  readonly placeholder = PLACEHOLDER_IMAGES
}
