<div class="mcp-sidebar" [class.closed]="collapsed">
  <div class="mcp-sidebar__trigger" (click)="toggle()">
    <i class="u-icon u-icon--arrow-right-rounded"></i>
  </div>
  <div
    class="mcp-sidebar__container"
    [@collapseHorizontally]="collapsed"
    (@collapseHorizontally.done)="onAnimationDone()"
  >
    <ng-content></ng-content>
  </div>
</div>
