<div
  class="c-carousel"
  [ngClass]="extraClasses"
  [class.is-empty]="items?.length < 1"
  [class.is-centered]="!enableCarousel && isCentered"
  *ngIf="items; else loadingTemplate"
>
  <ng-container *ngIf="items?.length > 0; else noItemsTemplate">
    <ng-template [ngIf]="selectedIndex != null">
      <div
        class="c-carousel__viewport container-fluid"
        (swiperight)="move(CarouselDirection.Prev)"
        (swipeleft)="move(CarouselDirection.Next)"
        (pan)="onSlide($event)"
        #carouselViewport
      >
        <div
          class="c-carousel__wrapper"
          [ngStyle]="{ transform: 'translate3d(' + carouselTranslateX + '%, 0, 0)' }"
        >
          <div
            *ngFor="let item of items; let i = index; trackBy: trackByFn; last as isLast"
            class="c-carousel__item"
            [class.c-carousel__item--no-click]="!(canSelect$ | async)"
            [attributes]="item.attributes"
            (click)="selectItem(i)"
            #carouselItem
          >
            <ng-container
              *ngTemplateOutlet="
                itemTemplate;
                context: {
                  $implicit: item,
                  isSelected: item === items[selectedIndex],
                  isLast: isLast,
                  disableSelect
                }
              "
            ></ng-container>
          </div>
        </div>
      </div>
      <ng-container
        *ngFor="
          let direction of [CarouselDirection.Prev, CarouselDirection.Next];
          let isNext = index
        "
      >
        <div
          *ngIf="
            enableCarousel &&
            (!isNext
              ? carouselTranslateX < 0
              : boundToEdge
                ? carouselTranslateX > carouselEdgeX
                : previewIndex < indexEdge[CarouselDirection.Next])
          "
          class="c-carousel__arrow"
          [ngClass]="'c-carousel__arrow--' + (!isNext ? 'left' : 'right')"
        >
          <div
            *ngIf="previewTemplate; else arrowIcon"
            class="c-carousel__arrow-inner"
            [class.has-preview]="previewItemWrapper.scrollWidth"
            [class.is-active]="previewItemWrapper.scrollWidth && activeButtonState[direction]"
            [class.is-centered]="iconWrap.offsetHeight !== previewItemWrapper.offsetHeight"
            (mouseenter)="setActiveButton(direction)"
            (mouseleave)="setActiveButton(direction, false)"
            (appOnClickOutside)="resetButton(direction)"
          >
            <span
              class="c-carousel__icon-wrap"
              (click)="
                previewItemWrapper.scrollWidth ? onClickCarouselButton(direction) : move(direction)
              "
              #iconWrap
            >
              <i [ngClass]="'u-icon--arrow-' + (!isNext ? 'left' : 'right')"></i>
            </span>
            <div
              class="c-carousel__preview"
              [ngClass]="'c-carousel__preview--' + (!isNext ? 'left' : 'right')"
              #previewItemWrapper
            >
              <ng-container
                *ngTemplateOutlet="
                  previewTemplate;
                  context: { $implicit: items[previewIndex + (isNext ? 1 : -1)] }
                "
              ></ng-container>
            </div>
          </div>
          <ng-template #arrowIcon>
            <div class="c-carousel__arrow-inner">
              <span class="c-carousel__icon-wrap" (click)="move(direction)">
                <i [ngClass]="'u-icon--arrow-' + (!isNext ? 'left' : 'right')"></i>
              </span>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <div class="c-carousel__bottom">
        <ng-container
          *ngTemplateOutlet="bottomTemplate; context: { $implicit: items[selectedIndex] }"
        ></ng-container>
      </div>
      <div *ngIf="enableCarousel" class="c-carousel__navigation-button-wrapper">
        <span
          *ngFor="let item of navigationButtons; let index = index"
          [class.is-selected]="index === previewIndex"
          (click)="move(index)"
        ></span>
      </div>
    </ng-template>
  </ng-container>
</div>
<ng-template *ngIf="enableCarousel && (autoMove$ | async)"></ng-template>
<ng-template *ngIf="onResize$ | async"></ng-template>
<ng-template *ngIf="move$ | async"></ng-template>
<ng-template *ngIf="selectIndex$ | async"></ng-template>
