interface DateParams {
  day?: number
  month?: number
  year?: number
  offset?: number
}

export const formatTimeToDisplay = (seconds) => {
  const timeFormat = (val) => `0${Math.floor(val)}`.slice(-2)
  const hours = seconds / 3600
  const minutes = (seconds % 3600) / 60
  return [hours, minutes, seconds % 60].map(timeFormat).join(':')
}

export const now = new Date()
export const getDate = (dateParams: DateParams = <DateParams>{}) => {
  const { day, year, month, offset } = dateParams
  const date = new Date(year || now.getFullYear(), month || now.getMonth(), day || now.getDate())
  if (offset) {
    date.setDate(date.getDate() + offset)
  }
  return date
}
export const nowDate = getDate()

export const getDateFromCurrent = (days: number) => {
  const date = new Date(nowDate)
  date.setDate(date.getDate() + days)
  return date
}

export const getISODate = (date, universal = false, toISOString = false, inverse = false) => {
  date = date && new Date(date)
  if (date && universal) {
    date.setUTCMinutes(date.getTimezoneOffset() * (inverse ? 1 : -1))
  }
  return !date || !toISOString ? date : date.toISOString()
}

export const setEndDate = (date, toISOString = false) => {
  date = date && new Date(date)
  if (date) {
    date.setDate(date.getDate() + 1)
    date.setMilliseconds(date.getMilliseconds() - 1)
  }
  return !date || !toISOString ? date : date.toISOString()
}

export const fromUTC = (dateString) =>
  !dateString || dateString.includes('Z') ? dateString : dateString + 'Z'

export const DateFromString = (dateString, universal) => {
  const date =
    dateString && !(dateString instanceof Date)
      ? new Date(fromUTC(universal ? dateString.split('T')[0] + 'T00:00:00' : dateString))
      : dateString
  return getISODate(date, universal, false, true)
}

export const nowInSeconds = () => Math.round(new Date().getTime() / 1000)

export const getLocalDate = (
  utcDate: Date,
  lang: string,
): { weekday: string; hour: string; date: Date } => {
  const date = new Date(utcDate)
  return {
    weekday: date.toLocaleDateString(lang, { weekday: 'long' }),
    hour: date.toLocaleTimeString(),
    date,
  }
}

export const getExportableFileDate = (): string =>
  new Date().toLocaleString().replace(/[^a-zA-Z0-9]/g, '-')
