<div class="c-dispatcher-card" (click)="onclick.emit(data)">
  <img
    mcuiDefaultImage
    class="c-dispatcher-card__background"
    [srcUrl]="data.background"
    [alt]="data.title | translate"
    #bgImage="defaultImage"
  />
  <skeleton-image
    class="c-dispatcher-card__background--skeleton"
    *ngIf="!bgImage?.isImgLoaded"
    height="700"
    effect="pulse"
  ></skeleton-image>
  <div class="c-dispatcher-card__footer">
    <div class="c-dispatcher-card__footer-content">
      <img
        mcuiDefaultImage
        class="c-dispatcher-card__footer-image"
        [srcUrl]="data.imgPath"
        [alt]="data.title | translate"
        #iconImage="defaultImage"
      />
      <skeleton-image *ngIf="!iconImage?.isImgLoaded" width="65" height="32.5"></skeleton-image>
      <span class="c-dispatcher-card__footer-label">
        <h1 [title]="(data.title | translate) || '&nbsp;'">
          {{ (data.title | translate) || '&nbsp;' }}
        </h1>
      </span>
    </div>
    <span class="c-dispatcher-card__footer-icon">
      <mcui-icon svgIcon="arrow-right-rounded"></mcui-icon>
    </span>
  </div>
</div>
