<div class="c-filter__wrapper" [formGroup]="form" *ngIf="form">
  <mcp-query
    *ngFor="let item of fields"
    class="c-filter__item"
    [ngClass]="'c-filter__item--' + item.controlType"
    [query]="item"
    [control]="form?.get(item.key)"
    (queryChange)="handleOptions($event)"
  ></mcp-query>
</div>
