import { environment } from '@env'
import { ReportType } from '@core/enums/reports.enum'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'
import { ReportRoute } from '@features/reports/enums/reports.enum'
import { ReplaceUrlMatcher } from '@core/enums/navigation.enums'

const { STATICS_URL_ASSETS: staticAssetsPath } = environment

export const NAVIGATION_MONITORING_SUB_ITEMS: NavigableItem[] = [
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_TEAMS',
    route: `monitoring/${ReportRoute.teams}`,
    id: 'monitoring_teams',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-teams.svg',
    background: staticAssetsPath + 'img/dispatcher/teams-bckg.png',
    analytics: {
      action: 'click_monitoring_competition',
      label: 'Click Monitoring Competition',
    },
    qaId: 'monitoring_teams',
    extraInfo: ReportType.Teams,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PLAYERS',
    route: `monitoring/${ReportRoute.players}`,
    id: 'monitoring_players',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-players.svg',
    background: staticAssetsPath + 'img/dispatcher/players-bckg.png',
    analytics: {
      action: 'click_monitoring_players',
      label: 'Click Monitoring Players',
    },
    qaId: 'monitoring_players',
    extraInfo: ReportType.Players,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_GOALKEEPERS',
    route: `monitoring/${ReportRoute.goalkeepers}`,
    id: 'monitoring_goalkeepers',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-goalkeepers.svg',
    background: staticAssetsPath + 'img/dispatcher/goalkeepers-bckg.png',
    analytics: {
      action: 'click_monitoring_goalkeepers',
      label: 'Click Monitoring Goalkeepers',
    },
    qaId: 'monitoring_goalkeepers',
    extraInfo: ReportType.Goalkeepers,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_STYLE_EFFICIENCY',
    route: `monitoring/${ReportRoute.styleEfficiency}`,
    id: 'monitoring_style_efficiency',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-style-efficiency.svg',
    background: staticAssetsPath + 'img/dispatcher/style-and-efficiency-bckg.png',
    analytics: {
      action: 'click_monitoring_style_efficiency',
      label: 'Click Monitoring Style/Efficiency',
    },
    qaId: 'monitoring_style_efficiency',
    extraInfo: ReportType.StyleEfficiency,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PLAYER_STATS',
    route: `monitoring/${ReportRoute.playerStats}`,
    id: 'monitoring_player_stats',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-players-stats.svg',
    background: staticAssetsPath + 'img/dispatcher/player-stats-bckg.png',
    analytics: {
      action: 'click_monitoring_player_stats',
      label: 'Click Monitoring Player Stats',
    },
    qaId: 'monitoring_player_stats',
    extraInfo: ReportType.PlayerStats,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_TEAMS_ACCUMULATED',
    route: `monitoring/${ReportRoute.accumulatedTeams}`,
    id: 'monitoring_team_accumulated',
    imgPath: 'assets/img/portal/dispatcher-icons/monitoring-accumulated-teams.svg',
    background: staticAssetsPath + 'img/dispatcher/team-accumulated-bckg.png',
    analytics: {
      action: 'click_monitoring_team_accumulated',
      label: 'Click Monitoring Team Accumulated',
    },
    qaId: 'monitoring_team_accumulated',
    extraInfo: ReportType.AccumulatedTeams,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PHYSICAL_PERFORMANCE',
    route: `monitoring/${ReportRoute.physicalPerformance}`,
    id: 'monitoring_physical_performance',
    imgPath: 'assets/img/portal/dispatcher-icons/monitoring-physical-performance.svg',
    background: staticAssetsPath + 'img/dispatcher/physical-performance-bckg.png',
    analytics: {
      action: 'click_monitoring_physical_performance',
      label: 'Click Monitoring Physical Performance',
    },
    qaId: 'monitoring_physical_performance',
    extraInfo: ReportType.PhysicalPerformance,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PHYSICAL_INTERVALS',
    route: `monitoring/${ReportRoute.physicalIntervals}`,
    id: 'monitoring_physical_intervals',
    imgPath: 'assets/img/portal/dispatcher-icons/monitoring-physical-intervals.svg',
    background: staticAssetsPath + 'img/dispatcher/physical-intervals-bckg.png',
    analytics: {
      action: 'click_monitoring_physical_intervals',
      label: 'Click Monitoring Physical by Intervals',
    },
    qaId: 'monitoring_physical_intervals',
    extraInfo: ReportType.PhysicalIntervals,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_REFEREES',
    route: `monitoring/${ReportRoute.referees}`,
    id: 'monitoring_referees',
    imgPath: 'assets/img/portal/dispatcher-icons/monitoring-referees.svg',
    background: staticAssetsPath + 'img/dispatcher/referees-bckg.png',
    analytics: {
      action: 'click_monitoring_referees',
      label: 'Click Monitoring Referees',
    },
    qaId: 'monitoring_referees',
    extraInfo: ReportType.Referees,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_GAME_INTERRUPTIONS',
    route: `monitoring/${ReportRoute.gameInterruptions}`,
    id: 'monitoring_referees',
    imgPath: 'assets/img/portal/dispatcher-icons/game-interruptions.svg',
    background: staticAssetsPath + 'img/dispatcher/game-interruptions-bckg.png',
    analytics: {
      action: 'click_monitoring_game_interruptions',
      label: 'Click Monitoring Game Interruptions',
    },
    qaId: 'monitoring_game_interruptions',
    extraInfo: ReportType.GameInterruptions,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_EFFECTIVE_TIME_EVOLUTION',
    route: `monitoring/${ReportRoute.compEvolutionEffectiveTime}`,
    id: 'monitoring_referees',
    imgPath: 'assets/img/portal/dispatcher-icons/comparison-evolution-effective-time.svg',
    background: staticAssetsPath + 'img/dispatcher/comparison-evolution-effective-time-bckg.png',
    qaId: 'monitoring_comparison_evolution_effective_time',
    analytics: {
      action: 'click_monitoring_comparison_evolution_effective_time',
      label: 'Click Monitoring Comparison Evolution Effective Time',
    },
    extraInfo: ReportType.CompEvolutionEffectiveTime,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_FINISHING',
    route: `monitoring/${ReportRoute.finishing}`,
    id: 'monitoring_finishing',
    imgPath: 'assets/img/portal/dispatcher-icons/finishing.svg',
    background: staticAssetsPath + 'img/dispatcher/finishing-bckg.png',
    qaId: 'monitoring_finishing',
    analytics: {
      action: 'click_monitoring_finishing',
      label: 'Click Monitoring Finishing',
    },
    extraInfo: ReportType.Finishing,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PLAY_MODELS',
    route: `monitoring/${ReportRoute.playModels}`,
    id: 'monitoring_play-models',
    imgPath: 'assets/img/portal/dispatcher-icons/play-models.svg',
    qaId: 'monitoring_play-models',
    analytics: {
      action: 'click_monitoring_play-models',
      label: 'Click Monitoring Play Models',
    },
    extraInfo: ReportType.PlayModels,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_HISTORY',
    route: `monitoring/${ReportRoute.history}`,
    id: 'monitoring_history',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-history.svg',
    background: staticAssetsPath + 'img/dispatcher/history-bckg.png',
    analytics: {
      action: 'click_monitoring_history',
      label: 'Click Monitoring History',
    },
    qaId: 'monitoring_history',
  },
]

export const NAVIGATION_BEYOND_STATS_SUB_ITEMS: NavigableItem[] = [
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_TEAMS',
    route: `beyond-stats/${ReportRoute.teamsMl}`,
    id: 'beyond-stats_teams',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/monitoring-teams.svg',
    background: staticAssetsPath + 'img/dispatcher/teams_ext-bckg.jpg',
    analytics: {
      action: 'click_beyond-stats_teams',
      label: 'Click Beyond Stats Teams',
    },
    qaId: 'beyond-stats_teams',
    extraInfo: ReportType.TeamsMl,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PLAYERS_TECHNICAL_TACTICAL',
    route: `beyond-stats/${ReportRoute.playersMl}`,
    id: 'beyond-stats_players_technical_tactical',
    imgPath:
      staticAssetsPath + 'img/portal/dispatcher-icons/beyond-stats_players-technical-tactical.svg',
    background:
      staticAssetsPath + 'img/dispatcher/beyond-stats_players-technical-tactical-bckg.png',
    analytics: {
      action: 'click_beyond-stats_players_technical_tactical',
      label: 'Click Beyond Stats stats_players_technical_tactical',
    },
    qaId: 'beyond-stats_players_technical_tactical',
    extraInfo: ReportType.PlayersMl,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_PLAYERS_PHYSICAL',
    route: `beyond-stats/${ReportRoute.playersMLPhysical}`,
    id: 'beyond-stats_players_physical',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/beyond-stats_players-physical.svg',
    background: staticAssetsPath + 'img/dispatcher/beyond-stats_players-physical-bckg.png',
    analytics: {
      action: 'click_beyond-stats_players_physical',
      label: 'Click Beyond Stats Players Physical',
    },
    qaId: 'beyond-stats_players_physical',
    extraInfo: ReportType.PlayersMLPhysical,
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MONITORING_WCS',
    route: `beyond-stats/${ReportRoute.wcsMl}`,
    id: 'beyond-stats_wcs-ml',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/beyond-stats_wcs.svg',
    background: staticAssetsPath + 'img/dispatcher/wcs.jpg',
    analytics: {
      action: 'click_beyond-stats_wcs',
      label: 'Click WCS',
    },
    qaId: 'beyond-stats_wcs',
    extraInfo: ReportType.WCSMl,
  },
]

export const NAVIGATION_MORE_SUB_ITEMS: NavigableItem[] = [
  {
    label: 'P_COMMONS_MENU_OP_MENU_VIDEO_TUTORIALS',
    route: 'more/videos',
    id: 'videosmc',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/mediacoach-videos.svg',
    background: staticAssetsPath + 'img/dispatcher/mediacoach-videos-bckg.jpg',
    analytics: {
      action: 'click_more_videosmc',
      label: 'Click VideosMC',
    },
    qaId: 'videosmc',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_REPORT_ISSUE',
    route: 'more/report-issue',
    id: 'report-issue',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/report-issue.svg',
    background: staticAssetsPath + 'img/dispatcher/report-issue-bckg.jpg',
    analytics: {
      action: 'click_more_report_issue',
      label: 'Click Report Issue',
    },
    qaId: 'report-issue',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_DOCUMENTATION',
    route: 'more/documentation',
    id: 'documentation',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/documentation.svg',
    background: staticAssetsPath + 'img/dispatcher/documentation-bckg.png',
    analytics: {
      action: 'click_more_documentation',
      label: 'Click Documentation',
    },
    qaId: 'documentation',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_DOWNLOAD',
    route: 'more/downloads',
    id: 'downloads',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/downloads.svg',
    background: staticAssetsPath + 'img/dispatcher/downloads-bckg.jpg',
    analytics: {
      action: 'click_more_downloads',
      label: 'Click Downloads',
    },
    qaId: 'downloads',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_PLAYGROUND',
    route: 'more/playground',
    id: 'playground',
    imgPath: staticAssetsPath + 'img/portal/dispatcher-icons/playground.svg',
    background: staticAssetsPath + 'img/dispatcher/playground-bckg.jpg',
    analytics: {
      action: 'click_more_playground',
      label: 'Click Playground',
    },
    qaId: 'playground',
  },
].map((item) => ({ ...item, title: item.label }))

export const NAVIGATION_CONFIG: NavigableItem[] = [
  {
    label: 'P_COMMONS_MENU_OP_MENU_COMPETITION',
    route: 'competitions',
    id: 'competitions',
    analytics: {
      action: 'click_competitions',
      label: 'Click Competitions',
    },
    qaId: 'competitions',
    replaceByCurrentUrlMatchers: [ReplaceUrlMatcher.CompetitionSpace],
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MATCHES',
    route: 'matches',
    id: 'matches',
    analytics: {
      action: 'click_matches',
      label: 'Click Matches',
    },
    qaId: 'matches',
    cssClass: 'min-width-ml',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_TEAMS',
    route: 'teams',
    id: 'teams',
    analytics: {
      action: 'click_teams',
      label: 'Click Teams',
    },
    qaId: 'teams',
    replaceByCurrentUrlMatchers: [ReplaceUrlMatcher.TeamSpace],
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_PLAYERS',
    route: 'players',
    id: 'players',
    analytics: {
      action: 'click_players',
      label: 'Click Players',
    },
    qaId: 'players',
    replaceByCurrentUrlMatchers: [ReplaceUrlMatcher.PlayerSpace],
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_CALENDAR',
    route: 'calendar',
    id: 'calendar',
    analytics: {
      action: 'click_calendar',
      label: 'Click Calendar',
    },
    qaId: 'calendar',
  },
  {
    label: 'P_COMMONS_MENU_OP_MENU_APPLICATIONS',
    route: 'applications',
    id: 'applications',
    analytics: {
      action: 'click_applications',
      label: 'Click Applications',
    },
    qaId: 'applications',
  },
  // {
  //   label: 'P_COMMONS_MENU_OP_MENU_MONITORING_REPORT',
  //   route: 'monitoring',
  //   id: 'monitoring',
  //   analytics: {
  //     action: 'click_monitoring',
  //     label: 'Click Monitoring',
  //   },
  //   qaId: 'monitoring',
  //   panelMinWidth: 220.59,
  //   isPanelCentered: true,
  // },
  // {
  //   label: 'P_COMMONS_MENU_OP_MENU_BEYOND_STATS',
  //   route: 'beyond-stats',
  //   id: 'beyond-stats',
  //   analytics: {
  //     action: 'click_beyond_stats',
  //     label: 'Click Beyond Stats',
  //   },
  //   qaId: 'beyond-stats',
  //   cssClass: 'min-width-lg',
  // },
  {
    label: 'P_COMMONS_MENU_OP_MENU_MORE',
    route: 'more',
    id: 'more',
    analytics: {
      action: 'click_more',
      label: 'Click More',
    },
    qaId: 'more',
    isPanelCentered: true,
    panelMinWidth: 148,
  },
]
