import { NgModule } from '@angular/core'
import { MainContainerComponent } from './main-container.component'
import { RouterModule } from '@angular/router'
import { HeaderComponent } from '@core/main/components/header/header.component'
import { SharedModule } from '@shared/shared.module'
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component'
import { CompetitionDialogComponent } from './components/competition-dialog/competition-dialog.component'
import { NavigationBarModule } from '@core/main/containers/navigation-bar/navigation-bar.module'
import { TourModule } from '@shared/modules/tour/tour.module'
import { environment } from '@env'

@NgModule({
  declarations: [
    MainContainerComponent,
    HeaderComponent,
    ProfileDialogComponent,
    CompetitionDialogComponent,
  ],
  exports: [MainContainerComponent],
  imports: [
    RouterModule,
    SharedModule,
    NavigationBarModule,
    TourModule.forRoot({ debug: !environment.production }),
  ],
})
export class MainModule {}
