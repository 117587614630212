import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core'

import { timer } from 'rxjs'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { debounceTime, startWith, tap } from 'rxjs/operators'

import { roundNumber } from '@core/utils/number.utils'

import { ScrollService } from '../services/scroll.service'

import { DebounceTimeType } from '@mediacoach-ui-library/global'

const OFFSET_TOP = 65
const stickyComponents = []

@UntilDestroy()
@Directive({
  selector: '[appIsSticky]',
})
export class IsStickyDirective implements OnInit, OnDestroy, AfterViewInit {
  private _accurateHeight = 0

  @HostBinding('style.top.px') private _top
  @HostBinding('class.d-sticky') private _dSticky = false
  @HostBinding('class.is-sticky') private _isSticky = false
  @HostBinding('class.can-animate') private _canAnimate = false

  @Input() stickyOrder = 0
  @Input() offsetTop = OFFSET_TOP

  constructor(
    private elementRef: ElementRef,
    private scroll: ScrollService,
    private ref: ChangeDetectorRef,
  ) {}

  private _setTop() {
    if (this.stickyOrder > 0) {
      const top = stickyComponents
        .slice(0, this.stickyOrder)
        .filter((components) => ((components || [])[0] || {})._accurateHeight)
        .reduce((acc, components) => acc + components[0]._accurateHeight, 0)
      this._top = this.offsetTop + (top || 0)
      this.ref.detectChanges()
    }
  }

  private _triggerListeners() {
    this.scroll.scrollObs
      .pipe(
        debounceTime(DebounceTimeType.ForCrashes),
        tap(() => this._isStickyFn()),
        untilDestroyed(this),
      )
      .subscribe()

    this.scroll.resizeObs
      .pipe(
        debounceTime(DebounceTimeType.InputChanges),
        tap(() => this._calculateHeight()),
        untilDestroyed(this),
      )
      .subscribe()
  }

  private _isStickyFn() {
    if (this.stickyOrder > -1) {
      const { nativeElement } = this.elementRef
      const wrapperTop = nativeElement.parentNode.offsetTop
      const elementTop = nativeElement.offsetTop
      this._isSticky = wrapperTop < elementTop
      this._dSticky = this._dSticky || this._isSticky
      this._canAnimate =
        this._isSticky && window.pageYOffset > (this._top || 0) + this._accurateHeight + 200
    }
  }

  private _calculateHeight() {
    if (this.stickyOrder > -1) {
      this._accurateHeight = roundNumber(
        this.elementRef.nativeElement.getBoundingClientRect().height,
        3,
      )
      this._setTop()
    }
  }

  ngOnInit() {
    this._triggerListeners()
    stickyComponents[this.stickyOrder] = [...(stickyComponents[this.stickyOrder] || []), this]
  }

  ngAfterViewInit() {
    timer(1000)
      .pipe(
        startWith(true),
        tap(() => this._calculateHeight()),
        untilDestroyed(this),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    stickyComponents[this.stickyOrder] = (stickyComponents[this.stickyOrder] || []).filter(
      (elem) => elem !== this,
    )
  }
}
