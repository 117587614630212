export const clearStorageByPrefix = (storage, prefix) =>
  Array(storage.length)
    .fill(1)
    .map((v, i) => storage.key(i))
    .filter((key) => key.indexOf(prefix) === 0)
    .forEach((key) => storage.removeItem(key))

export const appendBuildInfo = (buildVersion, buildNum, buildDate) => {
  const $buildInfo = document.createComment(
    ' Version ' + buildVersion + ' | Build ' + buildNum + ' | Release ' + buildDate + ' ',
  )
  const $head = document.getElementsByTagName('head')[0]
  const $headFirstChild = $head.firstChild
  $head.insertBefore($buildInfo, $headFirstChild)
}

export const getScrollParent = (document, element, includeHidden = false) => {
  let style = getComputedStyle(element)
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') {
    return document.body
  }
  for (let parent = element; (parent = parent.parentElement); ) {
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent
    }
  }

  return document.body
}

let lastId = 0
export const uniqueId = () => `c-unique-id-${lastId++}-`

export const throttle = (callback: (...args) => void, delay: number): ((...args) => void) => {
  let lastCalled = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCalled < delay) {
      return
    }
    lastCalled = now
    return callback(args)
  }
}
