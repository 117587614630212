<mcui-select
  *ngIf="model?.items; else rootElement"
  #select
  class="mcp-navigable-dropdown"
  panelStyleClass="mcp-navigable-dropdown__panel"
  [options]="model?.items"
  [routerLink]="model?.route"
  [routerLinkActive]="'active'"
  [routerLinkActiveOptions]="{ exact: false }"
  [optionSelectedTemplate]="selectedItemTemplate || selectedItem"
  [optionTemplate]="optionTemplate || optionItem"
  [disableAutoScroll]="true"
  [showOnHover]="showOnHover"
  [noAccent]="true"
  panelMaxHeight="500px"
  panelMinWidth="{{ model?.panelMinWidth ? model?.panelMinWidth + 'px' : undefined }}"
>
  <ng-template #selectedItem>
    <mcp-navigable-dropdown-item
      styleClass="mcp-navigable-dropdown__selected-item"
      [item]="model"
      itemType="selected-item"
      [currentUrl]="currentUrl"
      (itemClick)="itemClick.emit(model)"
    ></mcp-navigable-dropdown-item>
  </ng-template>
  <ng-template let-item="item" #optionItem>
    <mcp-navigable-dropdown-item
      *ngIf="item.route; else itemNotRoute"
      styleClass="mcp-navigable-dropdown__option"
      itemType="option"
      [item]="item"
      [currentUrl]="currentUrl"
      [showSeparator]="true"
      [selectable]="true"
      [routerActiveAware]="true"
      (itemClick)="handleDropdownClick()"
    ></mcp-navigable-dropdown-item>
    <ng-template #itemNotRoute>
      <mcp-navigable-dropdown-item
        styleClass="mcp-navigable-dropdown__item"
        itemType="option"
        [item]="item"
        [currentUrl]="currentUrl"
        [showSeparator]="true"
        (itemClick)="itemClick.emit(item)"
      ></mcp-navigable-dropdown-item>
    </ng-template>
  </ng-template>
</mcui-select>

<ng-template #rootElement>
  <mcp-navigable-dropdown-item
    itemType="root"
    styleClass="mcp-navigable-dropdown__root-item {{ model?.cssClass }} {{
      model?.id === 'matches' ? 'matches' : ''
    }}"
    [item]="model"
    [currentUrl]="currentUrl"
    [routerActiveAware]="true"
    [liveBullet]="model?.id === 'matches' && liveMatches"
    (itemClick)="itemClick.emit(model)"
  ></mcp-navigable-dropdown-item>
</ng-template>
