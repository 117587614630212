<mcp-dialog-content-container
  [disabled]="
    !selectedSeason ||
    (selectedSeason?.id === seasonId &&
      (!selectedCompetition || selectedCompetition?.id === competitionId))
  "
  (apply)="applySelection()"
>
  <mcp-season-competition-selector
    [hideSeasons]="hideSeasons"
    [seasons]="seasons$ | async"
    [seasonId]="seasonId"
    [competitionId]="competitionId"
    (competitionChange)="selectedCompetition = $event"
    (seasonChange)="selectedSeason = $event"
  ></mcp-season-competition-selector>
</mcp-dialog-content-container>
