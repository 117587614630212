import { Match, MatchTeam } from '@core/models/dto/match.dto'

const mapMatchItemTeam = ({ score, team }: MatchTeam): MatchTeam => {
  return {
    score,
    team: {
      ...team,
      name: team.shortName || team.name,
    },
  }
}

export const transformMatch = (m: Match): Match => {
  if (m) {
    return {
      ...m,
      home: mapMatchItemTeam(m.home),
      away: mapMatchItemTeam(m.away),
    }
  }
  return undefined
}
