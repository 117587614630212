import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { NavigableItem } from '@core/main/containers/navigation-bar/models/navigable-item.model'

@Component({
  selector: 'mcp-navigable-dropdown-item',
  templateUrl: './navigable-dropdown-item.component.html',
  styleUrls: ['./navigable-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigableDropdownItemComponent {
  @Input() item: NavigableItem
  @Input() currentUrl: string
  @Input() styleClass: string
  @Input() selectable: boolean
  @Input() routerActiveAware: boolean
  @Input() liveBullet: boolean
  @Input() showSeparator: boolean
  @Input() itemType: 'root' | 'option' | 'selected-item'

  @Output() itemClick = new EventEmitter<NavigableItem>()
}
