import { createAction, props } from '@ngrx/store'
import { ActivatedRoute } from '@angular/router'
import { Match } from '@core/models/dto/match.dto'

export const navigate = createAction(
  '[Router] Navigate',
  props<{ path: string; relativeTo?: ActivatedRoute; replaceUrl?: boolean }>(),
)
export const navigateToNewTab = createAction(
  '[Router] Navigate to new tab',
  props<{ path: string; relativeTo?: ActivatedRoute; replaceUrl?: boolean }>(),
)
export const navigateExternal = createAction(
  '[Router] Navigate External',
  props<{ path: string }>(),
)
export const navigateByUrl = createAction('[Router] Navigate by url', props<{ path: string }>())
export const navigateToSelectedMatch = createAction(
  '[Router] Navigate to selected matches',
  props<{ selectedMatches: Match[] }>(),
)
export const changeLocation = createAction(
  '[Router] Change location without navigation',
  props<{ path: string }>(),
)
